import React from 'react'
import { AbsoluteFill, Img, } from 'remotion';

const FaceNarration = ({ src, position, height, width, shape, multiplyNum }) => {

    let mystyle = {
        position: "absolute",
        borderRadius: shape === "circle" ? "50%" : "",
        height: `${height * multiplyNum}px`,
        width: `${width * multiplyNum}px`,
        padding: `${10 * multiplyNum}px`,
    }

    if (position === "top-left") {
        mystyle.top = 0;
        mystyle.left = 0;
    } else if (position === "top-center") {
        mystyle.top = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)"
    } else if (position === "top-right") {
        mystyle.top = 0;
        mystyle.right = 0;
    }
    else if (position === "bottom-center") {
        mystyle.bottom = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)"
    } else if (position === "bottom-right") {
        mystyle.bottom = 0;
        mystyle.right = 0;
    } else if (position === "bottom-left") {
        mystyle.bottom = 0;
        mystyle.left = 0;
    } else if (position === "center-right") {
        mystyle.top = "50%";
        mystyle.right = 0;
        mystyle.transform = "translateY(-50%)"
    } else if (position === "center-left") {
        mystyle.top = "50%";
        mystyle.left = 0;
        mystyle.transform = "translateY(-50%)"
    } else {
        mystyle.top = "50%";
        mystyle.left = "50%";
        mystyle.transform = "translate(-50%,-50%)";
    }

    return (
        <AbsoluteFill  >
            <Img
                key={src}
                style={mystyle}
                src={src}
                onError={(event) => {
                    // Handle image loading error here
                    console.log("error")
                }}
            />
        </AbsoluteFill >
    )
}

export default FaceNarration
