import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import AiToolsCard from './AiToolsCard';
import Slider from "react-slick";
import CusstomToolModel from '../Common/CusstomToolModel';
import { useSelector } from 'react-redux';
const Sales = ({ data, loader }) => {
  const agencyId = useSelector(state => state.agency.activeAgency.id)
  const [toolId, setToolId] = useState({})
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleOpenModel = (curElem) => {

    setShow6(true);
    setToolId(curElem)
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    rows: 4,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2.2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>

      <div>
        <h4 className='pb-3'>Sales Templates</h4>
        {
          loader.fetch ? <div className="col-12 mt-4">
            <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
          </div> :
            <div className='slider-container'>
              {data.filter((curElem) => curElem.category === "Sales").length > 0 ?

                <Slider {...settings}>
                  {
                    data.filter((curElem) => curElem.category === "Sales").map((curElem, index) => {
                      return (
                        <React.Fragment key={index}>
                          <AiToolsCard curElem={curElem} handleShow={handleOpenModel} />
                        </React.Fragment>
                      )
                    })
                  }
                </Slider> :
                <div className='d-flex align-items-center justify-content-center'>You do not have sales templates yet!</div>
              }
            </div>

        }
      </div>

      <CusstomToolModel show={show6} handleClose={handleClose6} toolId={toolId} type="tool" agencyId={agencyId} />
    </>
  )
}

export default Sales