import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onCheckedToggle, onFetchLanding, onUpdateLanding } from "../../../../Redux/Actions/ProjectActions";
import HeaderSection from "./HeaderSection";
import VideoSection from "./VideoSection";
import FooterSection from "./FooterSection";
import AddonDomain from "./AddonDomain";
import PageStyleSection from "./PageStyleSection";
import BonusSection from "./BonusSection";
import BenifitSection from "./BenifitSection";
import ThankyouSection from "./ThankyouSection";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import Footer from "../../../Common/Footer";
import TitleBar from "../../../Common/TitleBar";
import Header from "../../../Common/Header";
import SidePanel from "../../../Common/SidePanel";


const CustomizeWebsite = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id = queryString.parse(location.search).id
  const landingData = useSelector(state => state.landingPage.data)
  console.log(landingData)

  const [loader, setLoader] = useState({
    submitLoader: false,
    pageLoader: true
  })

  const [addon, setAddon] = useState(false)

  const fetchLandingData = () => {
    let data = {
      id: id
    }
    dispatch(onFetchLanding(data, loader, setLoader))
  }

  const handleChecked = (e) => {
    const { name, checked } = e.target
    if (name === "addon") {
      if (addon) {
        setAddon(false)
      } else {
        setAddon(true)
      }
    }
    dispatch(onCheckedToggle(name, checked))
  }

  const onCopyLink = () => {
    var copyText = document.getElementById("liveLink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const validateUrl = (url, alertMessage) => {
    try {
      new URL(url);
    } catch (error) {
      dispatch(setAlert(alertMessage, "danger"));
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    let data = {
      ...landingData
    }
    delete data.domain

    if (data.headers.affilateLink) {
      if (!validateUrl(data.headers.affilateLink, "Please enter valid affiliate link")) {
        return;
      }
    }

    if (data.footer.privacyUrl) {
      if (!validateUrl(data.footer.privacyUrl, "Please enter valid privacy URL")) {
        return;
      }
    }

    if (data.footer.tosUrl) {
      if (!validateUrl(data.footer.tosUrl, "Please enter valid Terms Of Service URL")) {
        return;
      }
    }

    setLoader({
      ...loader,
      submitLoader: true
    })
    dispatch(onUpdateLanding(data, navigate, loader, setLoader))
  }

  const handlePreview = () => {
    window.open(landingData.liveLink, "_blank")
  }

  useEffect(() => {
    if (id) {
      fetchLandingData()
    }
  }, [id])

  useEffect(() => {
    if (landingData.domain.enable) {
      setAddon(landingData.domain.enable)
    }
  }, [landingData])

  return (
    loader.pageLoader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <TitleBar title="Landing Page" />
        <Header />

        <div className='page-wrap'>
          <div className="side-nav">
            <SidePanel />
          </div>

          <div className="page-container pt-0 pe-0 site-hero-wrap">
            <div className="projectTitle-wrap">
              <div className="projectTitle flex-wrap justify-content-between w-100">
                <div className="col-sm-6">
                  <h4>Edit Landing Page</h4>
                </div>
                <div className="col-auto">
                  <div className="projectTitle-right pt-sm-0 gap-3 d-flex">
                    <Link className="site-link m-0" to={`/marketing/page-builder/website-editor?liveLink=${landingData.liveLink}&productId=${id}`} target="_blank"><span>Edit in WYSIWYG</span></Link>
                    <button onClick={handlePreview} className="site-link m-0" ><span>Preview</span></button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="business-wrap m-4">
                <div className="business-list">
                  <div className="inpLabelWrap mt-0">
                    <span className="labelTxt mb-4"><strong>Live Link</strong></span>

                    <div className="inpLabel">
                      <label htmlhtmlFor="liveLink">Live Link</label>
                      <input style={{ background: "#fff" }}
                        className="inpLabel-inp radius-inp"
                        value={landingData.liveLink}
                        type="text"
                        readOnly
                        id="liveLink"
                      />
                      <button className="site-link radius-btn " style={{ cursor: "pointer" }} onClick={onCopyLink}>
                        <FiCopy /> <span className="ps-1">Copy</span>
                      </button>
                    </div>
                  </div>

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Add Your Domain</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          onChange={(e) => handleChecked(e)}
                          name="addon"
                          checked={addon}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    addon ?
                      <AddonDomain />
                      : ""
                  }

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Page Style</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="style"
                          checked={landingData.style.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {landingData.style.enable ?
                    <PageStyleSection /> : ""}

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Header Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="header"
                          checked={landingData.headers.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.headers.enable ?
                      <HeaderSection />
                      : ""
                  }

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Video Embed Code Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="videos"
                          checked={landingData.videos.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round">
                        </span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.videos.enable ?
                      <VideoSection />
                      : ""
                  }

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Product Features Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="benefits"
                          checked={landingData.benefits.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.benefits.enable ?
                      <BenifitSection />
                      : ""
                  }

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Bonus Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="bonus"
                          checked={landingData.bonus.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.bonus.enable ?
                      <BonusSection />
                      : ""
                  }

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Thank You Page Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="thankyou"
                          checked={landingData.thankyou.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.thankyou.enable ?
                      <ThankyouSection />
                      : ""
                  }
                  {/* <AicoachSection /> */}

                  <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                    <label htmlFor="">Footer Section</label>
                    <div className="d-flex">
                      <label className="switch site-switch">
                        <input
                          type="checkbox"
                          name="footer"
                          checked={landingData.footer.enable}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {
                    landingData.footer.enable ?
                      <FooterSection />
                      : ""
                  }

                  <div className="btn-sec text-end mt-4">
                    <button onClick={handleSubmit} className="site-link m-0"><span>{loader.submitLoader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <TitleBar title="Bonus Page" />
        <div className="page-wrap">
          
        </div> */}
        <Footer />
      </>
  )
}

export default CustomizeWebsite;