import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeVideo } from '../../../../Redux/Actions/ProjectActions'

const VideoSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.landingPage.data)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeVideo(name, value))
    }

    return (
        <div className='boxStyle'>
            <div className="inpLabelWrap mt-0">
                <span className="labelTxt pb-3">Video Embed Code </span>
                <div className="inpLabel">
                    <label htmlFor="heading">Embed Code</label>
                    <textarea
                        className="inpLabel-inp"
                        placeholder="Enter Embed Code Here"
                        type="text"
                        name='iFrameData'
                        value={landingData.videos.iFrameData}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default VideoSection