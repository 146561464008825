import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { MdArrowBackIos } from "react-icons/md";
import WriteDataModal from "./WriteDataModal";
import { FaArrowRight } from "react-icons/fa6";
import Footer from "../../Common/Footer";
import SidePanel from "../../Common/SidePanel";
import { Navbar } from "react-bootstrap";
import TitleBar from "../../Common/TitleBar";
import {
  onFetchScript,
  onUpdateScript,
} from "../../../Redux/Actions/ProjectActions";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import Header from "../../Common/Header";
const UpdateScript = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = queryString.parse(location.search);
  const [pageLoader, setPageLoader] = useState(true);

  const [videoData, setVideoData] = useState(false);
  const [loader, setLoader] = useState(true);
  const [buttonLoader, setButtonLoader] = useState({
    save: false,
    next: false,
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleSubmit = (type) => {
    if (videoData) {
      let data = {
        id: id,
        script: videoData.script,
        text: videoData.script,
        buttonType: type,
        voiceClone: videoData.voiceClone,
        faceNarration: videoData.faceNarration,
        mediaType: videoData.mediaType,
        dimension: videoData.dimension,
      };
      setButtonLoader({
        ...buttonLoader,
        next: type === "save" ? false : true,
        save: type === "save" ? true : false,
      });
      dispatch(onUpdateScript(data, navigate, buttonLoader, setButtonLoader));
    } else {
      dispatch(setAlert("Please provide script!", "danger"));
    }
  };
  const handleChange = (e) => {
    setVideoData({ ...videoData, [e.target.name]: e.target.value });
  };
  const fetchVideo = () => {
    let data = {
      id: id,
    };
    setLoader(true);
    dispatch(onFetchScript(data, setVideoData, setLoader));
  };
  useEffect(() => {
    if (id) {
      fetchVideo();
    }
  }, [id]);

  return (
    <>
      {loader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <TitleBar title="Create Video" />
          <Header />

          <section className="page-wrap">
            {/* <div className='container'> */}
            <div className="side-nav">
              <SidePanel />
            </div>
            <div className="content-video-container w-100">
              <div className="cont-wrap">
                <div className="cont_area">
                  <div className="white_label_marketing_wrap">
                    <div className="projectTitle-wrap">
                      <div className="projectTitle row">
                        <div className="flex-bar align-items-center flex-wrap gap-4 d-flex justify-content-between align-items-center">
                            <div className="projectTitle-left">
                                <h2>
                                <Link to={"/marketing/video-maker"}>
                              <MdArrowBackIos className="pb-1" />
                              Review Script
                            </Link>
                                </h2>
                            </div>
                          {/* <h4 style={{ fontSize: 28, color: "white" }}>
                            
                          </h4> */}
                          <div className="text-center">
                            <button
                              className="site-link bdr mt-0"
                              onClick={() => handleSubmit("save")}
                            >
                              <span>
                                {buttonLoader.save ? (
                                  <>
                                    Saving{" "}
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  "Save Script"
                                )}
                              </span>
                            </button>
                            <button
                              className="site-link ms-3 mt-0"
                              onClick={handleShow}
                            >
                              <span>
                                {buttonLoader.next ? (
                                  <>
                                    Converting{" "}
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  "Next "
                                )}
                                <FaArrowRight />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="page-container">
                    <div className="container-fluid">
                      <div className="cont-box sm-gap">
                        <div className="inp-bg">
                          <div className="inp-bg-top">
                            <p>
                              <strong>Edit your script here</strong>
                            </p>
                          </div>
                          <div className="inp-bg-mid">
                            <textarea
                              className="common-input"
                              style={{ width: "100%", minHeight:"300px", height:"50vh" }}
                              name="script"
                              value={videoData.script}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}
          </section>
          <WriteDataModal
            show={show}
            handleClose={handleClose}
            videoData={videoData}
            // chapterData={chapterData}
            id={id}
            // chapId={chapterId}
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default UpdateScript;
