import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ConnectModal from './ConnectModal'
import ConnectRows from './ConnectRows'
import { baseURL } from '../../../Global/Global'
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from 'axios'

const IntegrationAcc = (props) => {
    // console.log(props)
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleConnect = () => {
        if (props.type === "GC") {
            window.open(`${baseURL}google-calender-connect/${auth.user.id}`, "_blank")
        } else {
            handleShow()
        }
    }


    const responseFacebook = async (response) => {
        // try {
        //     const res = await axios.get(
        //         `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
        //     );

        //     setDetails({
        //         ...details,
        //         name: response.name,
        //         userId: response.userID,
        //         openPopup: true,
        //         buttonText: "Connect",
        //         accessToken: response.accessToken,
        //         pages: res.data.data,
        //     });
        // } catch (err) {
        //     console.log(err);
        // }

        // try {
        //     const res = await axios.get(
        //         " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
        //     );

        //     setGroups(res.data.data);
        // } catch (err) {
        //     console.log(err);
        // }
    }




    return (
        <div className='dashboard-block'>
            <div className="integrations-single">
                <div className="integrations-in">
                    <div className="integration-left">
                        <div className="integration-img"><img alt="" src={props.image} /></div>
                        <div className="integration-txt">
                            <h6>{props.name}</h6>
                            <p>{props.desc}</p>
                        </div>
                    </div>
                    <div className="integration-right">
                        {/* use "connected" class to disable button */}
                        {props.type === "FB" ?
                            <FacebookLogin
                                appId="780210132546000" //APP ID NOT CREATED YET
                                fields="name,email"
                                // scope="pages_manage_metadata,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                callback={(e) => responseFacebook(e)}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} className="site-link sm"><span>Connect</span></button>
                                )}
                            />
                            :
                            <button className="site-link sm" disabled={props.type === "EL" ? props.data.length > 0 : ""} style={{ background: props.type === "EL" ? props.data.length > 0 ? "gray" : "" : "", cursor: props.type === "EL" ? props.data.length > 0 ? "not-allowed" : "" : "" }} onClick={handleConnect}><span>Connect</span></button>

                        }


                    </div>
                </div>


                {props.data.length > 0 ?
                    props.data.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ConnectRows
                                    curElem={curElem}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}

                <ConnectModal
                    show={show}
                    handleClose={handleClose}
                    name={props.name}
                    url={props.url}
                    shortType={props.type}
                />
            </div>
        </div>
    )
}

export default IntegrationAcc