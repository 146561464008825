import React, { useState, useEffect } from "react";
import iconCredits from "../../../../images/icon-credits.svg";
import { Tab, Nav, Navbar } from "react-bootstrap";
import Audio from "./Audio/Audio"
import Story from "./Story/Story";
import Text from "./Text/Text";
import Media from "./Media/Media";
import BackgroundMedia from "./Background/BackgroundMedia";
import Setting from "./Setting/Setting";
import Credits from "./Credits/Credits";
import Transition from "./Transition/Transition";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import VoiceOver from "./VoiceOvers/VoiceOver";
import VideoTiles from "./VideoTiles/VideoTiles";
import { onFetchVideoData, onRenderVideo, onUnmountEditor } from "../../../../Redux/Actions/VideoActions";
import Preview from "./Preview/Preview";
import TalkingHead from "./TalkingHead/TalkingHead";
import { RiUserFill } from "react-icons/ri";
import { IoClose, IoEyeOutline } from "react-icons/io5";
import { MdOutlineAnimation } from "react-icons/md";
import Animation from "./Animation/Animation";
import TitleBar from "../../../Common/TitleBar";
import Header from "../../../Common/Header";


const Editor = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = queryString.parse(location.search)
  const campaign = useSelector(state => state.video.data)


  const editor = useSelector(state => state.editor)
  const [pageLoader, setPageLoader] = useState(true)
  const [preLoader, setPreLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const handleClosePreviewModal = () => setShowPreview(false)

  const fetchVideos = () => {
    let data = {
      id: id
    }
    dispatch(onFetchVideoData(data, setPageLoader))
  }

  const handleRenderVideo = () => {
    let data = {
      id: id
    }
    setLoader(true)
    dispatch(onRenderVideo(data, navigate, setLoader, campaign.id, campaign.type));
  };

  let div = document.getElementById("beacon-container")

  useEffect(() => {
    if (div) {
      div.style.display = "none"
    }

    return () => {
      if (div) {
        div.style.display = "block"
      }
    }
  }, [div])

  useEffect(() => {
    if (campaign) {
      setPageLoader(false)
    }
  }, [campaign])

  useEffect(() => {
    if (id) {
      fetchVideos()
    }
  }, [id])



  useEffect(() => {
    return () => {
      dispatch(onUnmountEditor())
    }
  }, [])


  return (

    pageLoader ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div> :

      <>
      <Header />
      
        <TitleBar title="Editor" />

        <section className="siteWrap forEditor content-video-editor">

          <div className="campaignBar for-editor justify-content-between">
            <div>
              <h2 className="pageTitle main-title">{campaign?.name?.length > 100 ? <>{campaign?.name?.slice(0, 100)}...</> : campaign?.name}</h2>
            </div>

            <div className="campaignBar-right">
              <button
                className="site-link cursor-pointer me-3"
                onClick={() => setShowPreview((pre) => !pre)}
                style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                disabled={editor.voiceOver.isGenerating}
              >
                {showPreview ? <span>Close <IoClose /></span> : <span>Preview <IoEyeOutline /></span>}
              </button>

              <button
                className="site-link cursor-poninter"
                onClick={handleRenderVideo}
                style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                disabled={editor.voiceOver.isGenerating}
              >
                <span>{loader ? <>Rendering <i className="fa fa-spinner fa-spin mx-1" /></> : "Render"}</span>
              </button>

            </div>


          </div>

          <div className="editorWrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey="story">

              <div className="editor-left">
                <div>
                  <Tab.Content>

                    <Tab.Pane eventKey="story">
                      <Story />
                    </Tab.Pane>

                    <Tab.Pane eventKey="text">
                      <Text />
                    </Tab.Pane>

                    <Tab.Pane eventKey="bgMedia">
                      <BackgroundMedia />
                    </Tab.Pane>

                    <Tab.Pane eventKey="media">
                      <Media />
                    </Tab.Pane>

                    <Tab.Pane eventKey="voiceOver">
                      <VoiceOver />
                    </Tab.Pane>

                    <Tab.Pane eventKey="talkHead">
                      <TalkingHead />
                    </Tab.Pane>

                    <Tab.Pane eventKey="audio">
                      <Audio />
                    </Tab.Pane>

                    <Tab.Pane eventKey="settings">
                      <Setting />
                    </Tab.Pane>

                    <Tab.Pane eventKey="credits">
                      <Credits
                        chapterId={id}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="animation">
                      <Animation />
                    </Tab.Pane>

                    <Tab.Pane eventKey="transition">
                      <Transition />
                    </Tab.Pane>



                  </Tab.Content>
                </div>
              </div>

              <div className="editor-right">
                <div className="campaignTop">

                  <div className="campaignNav">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="story">
                          <div>
                            <span><img src={require('../../../../images/editor/story.svg').default} alt='' /></span>
                            <span>Story</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="text">
                          <div>
                            <span><img src={require('../../../../images/editor/text.svg').default} alt='' /></span>
                            <span>Text</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bgMedia">
                          <div>
                            <span><img src={require('../../../../images/editor/background.svg').default} alt='' /></span>
                            <span>Background</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="media">
                          <div>
                            <span><img src={require('../../../../images/editor/media.svg').default} alt='' /></span>
                            <span>Media</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="voiceOver">
                          <div>
                            <span><img src={require('../../../../images/editor/voice-over.svg').default} alt='' /></span>
                            <span>Voiceover</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="talkHead">
                          <div>
                            <span><RiUserFill /></span>
                            <span>Taking Head</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="audio">
                          <div>
                            <span><img src={require('../../../../images/editor/music.svg').default} alt='' /></span>
                            <span>Audio</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="animation">
                          <div>
                            <span><MdOutlineAnimation /></span>
                            <span>Animation</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="transition">
                          <div>
                            <span><img src={require('../../../../images/editor/transition.svg').default} alt='' /></span>
                            <span>Transition</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="settings">
                          <div>
                            <span><img src={require('../../../../images/editor/settings.svg').default} alt='' /></span>
                            <span>Settings</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="credits">
                          <div>
                            <span><img src={iconCredits} /></span>
                            <span>Credits</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>


                {/* ------------ Preview --------- */}
                <Preview
                  preLoader={preLoader}
                  showPreview={showPreview}
                  handleClosePreviewModal={handleClosePreviewModal}
                />
              </div>
            </Tab.Container>
          </div>
        </section>

        <div className="editorReel content-video-editorReel">
          <VideoTiles />
        </div>
      </>
  )
}

export default Editor;