import React, { useEffect, useState } from 'react'
import LivePreview from './LivePreview'
import queryString from "query-string";
import { useLocation } from 'react-router';
import { onAddLoadCount, onFetchPaymentData, onFetchToolData } from '../../Redux/Actions/CreateToolActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { themeColor } from '../../Global/Global';
import Swal from 'sweetalert2';
import WebFont from "webfontloader"
import { fetchToolRebrandData } from '../../Redux/Actions/RebrandingAction';

const SharePreview = () => {

    const toolData = useSelector(state => state.createTool.data);
    const dispatch = useDispatch();
    const location = useLocation()
    const { id, userId } = queryString.parse(location.search)
    const paymentId = queryString.parse(location.search).paymentId

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchTool = () => {
        let data = { id: id }
        dispatch(onFetchToolData(data, loader, setLoader))
    }

    const fetchPaymentData = () => {
        let data = {
            paymentId: paymentId
        }
        dispatch(onFetchPaymentData(data, handleClose, Swal));
    }

    useEffect(() => {
        if (paymentId) {
            if (paymentId === "error") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Payment failed!',
                    confirmButtonColor: themeColor
                })
            } else {
                if (show) {
                    fetchPaymentData();
                }
            }
        }
    }, [paymentId, show])

    useEffect(() => {
        if ((toolData.integrations.stripe.enable && toolData.integrations.stripe.stripeId && toolData.integrations.stripe.amount)
            ||
            (toolData.integrations.autoresponder.enable && toolData.integrations.autoresponder.autoresponderId)
            ||
            (toolData.integrations.smtp.enable && toolData.integrations.smtp.smtpId)) {
            handleShow();
        }
    }, [toolData.integrations])

    const getLoadCount = () => {
        let data = {
            toolId: id
        }
        dispatch(onAddLoadCount(data));
    };
    const fetchRebrand = () => {
        let data = {
            userId: userId
        }
        dispatch(fetchToolRebrandData(data))
    }
    useEffect(() => {
        if (userId) {
            fetchRebrand()
        }
        if (id) {
            fetchTool()
            getLoadCount();
        }

    }, [id, userId])


    useEffect(() => {
        WebFont.load({
            google: {
                families: [toolData.design.font.fontFamily]
            }
        })
    }, [toolData.design.font.fontFamily])

    let iframe = document.getElementById("prev-frame-id")
    useEffect(() => {
        if (iframe) {
            iframe.onload = function () {
                const iframeBody = iframe.contentWindow.document.body;
                iframeBody.style.background = 'transparent';
            };
        }
    }, [iframe])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%"
                }}>
                <LivePreview userId={userId} previewType="live" />
            </div>
    )
}

export default SharePreview
