import React, { useEffect, useState } from 'react'
import { addTextHighlight, onFontBgColor, onFontColor, removeHighlightColor, updateTextHighlight } from '../../../../../Redux/Actions/VideoActions'
import { useDispatch } from 'react-redux'
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import { colorObj } from '../../../../../Global/ColorCodes';
import { SketchPicker } from 'react-color';
import { BsQuestionCircle } from 'react-icons/bs';
import { themeColor } from '../../../../../Global/Global';
import { Tooltip } from 'react-tooltip';

const FontColor = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [colVal, setColVal] = useState({
        hex: "",
        r: "",
        g: "",
        b: "",
        a: ""
    })
    const handleChooseColor = (curElem, index) => {
        props.setSlideAll(false)
        if (props.isHighlight) {
            if (props.dataHigh.text !== "") {
                const arrIndex = props.selectedText.textToHighlight.findIndex(({ text }) => text === props.dataHigh.text)

                if (index === 0) {
                    removeBgColor(arrIndex)
                }
                else {
                    setColVal({
                        ...colVal,
                        hex: curElem.hex,
                        r: curElem.rgb.r,
                        g: curElem.rgb.g,
                        b: curElem.rgb.b,
                        a: curElem.rgb.a
                    })
                    if (arrIndex === -1) {
                        let data = {
                            text: props.dataHigh.text,
                            color: props.type === "highlight" ? curElem.hex : props.dataHigh.color,
                            background: props.type === "highlight background" ? curElem.hex : props.dataHigh.background
                        }
                        props.setDataHigh(data)
                        dispatch(addTextHighlight(data, props.selectedSlideIndex, props.textIndex));
                    }
                    else {
                        if (props.type === "highlight") {
                            props.setDataHigh({
                                ...props.dataHigh,
                                color: curElem.hex
                            })
                        } else {
                            props.setDataHigh({
                                ...props.dataHigh,
                                background: curElem.hex
                            })
                        }

                        dispatch(updateTextHighlight(curElem.hex, props.type, arrIndex, props.selectedSlideIndex, props.textIndex));
                    }
                }
            }
        } else {
            let rgba = `rgba(${curElem.rgb.r}, ${curElem.rgb.g}, ${curElem.rgb.b}, ${curElem.rgb.a.toFixed(2)})`
            let hex = `#${rgbHex(rgba)}`

            let val = hex
            if (props.type === "text") {
                dispatch(onFontColor(val, props.selectedSlideIndex, props.textIndex));

            } else {
                if (index === 0) {
                    val = ""
                }
                dispatch(onFontBgColor(val, props.selectedSlideIndex, props.textIndex));
            }
        }

    }

    const removeBgColor = (index) => {
        props.setSlideAll(false)
        dispatch(removeHighlightColor(props.type, index, props.selectedSlideIndex, props.textIndex));
    }

    useEffect(() => {
        if (props.color) {
            if (props.color.includes("#")) {
                let hex = hexRgb(props.color)
                setColVal({
                    ...colVal,
                    hex: props.color,
                    r: hex.red,
                    g: hex.green,
                    b: hex.blue,
                    a: hex.alpha
                })
            }
        }
    }, [props])

    return (
        <div className="fontStyle-block-wrap">
            <div className='d-flex align-items-center justify-content-between'>
                <h2 className='text-capitalize'>{props.type} Color</h2>
                {props.isHighlight ?
                    <>
                        <p style={{ display: "inline-block", position: "relative", top: "-1px" }} data-tooltip-id={`my-tooltip-${props.type}`}>
                            <BsQuestionCircle color={themeColor} className='mb-0 ms-1' size={17} cursor="help" /></p>
                        <Tooltip
                            id={`my-tooltip-${props.type}`}
                            style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                            place="top"
                            content={"Please select words first to highlight it."}
                        />

                    </> : null}


            </div>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-6">
                        <div className="inpWrap">
                            <input
                                className="ps-5"
                                type="text"
                                value={colVal.hex}
                                readOnly
                            />
                            <span
                                className="colorValue cursor-pointer"
                                id="t-color-btn"
                                style={{ background: colVal.hex !== false ? colVal.hex : '#fff' }}
                                onClick={() => setShow(true)}
                            ></span>
                            <span className="label">HEX</span>
                            {show ?
                                <>
                                    <div className="swatch-cover" onClick={() => setShow(false)} />
                                    <SketchPicker color={colVal.hex} onChange={(e) => handleChooseColor(e)} />
                                </> : null}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={colVal.r} readOnly />
                            <span className="label">R</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={colVal.g} readOnly />
                            <span className="label">G</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={colVal.b} readOnly />
                            <span className="label">B</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="color-palette">
                <ul>
                    {colorObj.length > 0 ?
                        colorObj.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div
                                        style={{ background: curElem.hex, position: "relative" }}
                                        className="color-palette-single cursor-pointer"
                                        onClick={() => handleChooseColor(curElem, index)}>
                                        {
                                            index === 0 && (props.type === 'text background') ?
                                                <div className="remove_line"></div>
                                                : ""
                                        }
                                    </div>
                                </li>
                            )
                        }) : ""}
                </ul>
            </div>
        </div>
    )
}

export default FontColor