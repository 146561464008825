import icon from "../../../images/apps-icons.png"
import { Link } from 'react-router';
import React, { useEffect, useState } from 'react'
import { Trash } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { onCheckLandingPageStatus } from '../../../Redux/Actions/LandingActions';
import { FiExternalLink } from "react-icons/fi";
import { GoGift, GoShareAndroid } from "react-icons/go";
import ShareThisModal from "./ShareThisModal";
import { useSelector } from "react-redux";
import CommonAlert from "../../Common/CommonAlert";

const LandingPageCard = ({ curElem, landingPage, setLandingPage }) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        check: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        status: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false
    })

    const [show, setShow] = useState(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: curElem.contentType
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-landing", data, landingPage, setLandingPage, setSweet, false))

    }

    const fetchVideo = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckLandingPageStatus(data, common, setCommon, interval))
    }


    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = true
            let progress = 100
            let bgColor = "#122bc9"
            videoUrl = curElem.videoUrl
            switch (+curElem.status) {
                case 1:
                    text = "Active"
                    showProg = true
                    activeIntrval = true
                    url = `/marketing/page-builder/customize-website?id=${curElem.id}`
                    bgColor = "#18BD15"
                    break;
                case 2:
                    text = "Generating..."
                    canEdit = false
                    bgColor = "#ffa500"
                    break;
                case 3:
                    text = "Draft"
                    canEdit = false
                    url = `/marketing/page-builder/choose-templates?id=${curElem.id}`
                    bgColor = "#122bc9"
                    break;
                default:
                    text = ""
                    canEdit = false
            }

            setCommon({
                ...common,
                statusText: text,
                status: +curElem.status,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnail,
                failedData: curElem.logFile ? JSON.parse(curElem.logFile) : false,
                progress: progress,
                bgColor: bgColor
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])

    return (
        <>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="videoProject-single white-videoProject-single">
                    <div className="videoProject-single-top">
                        <div className="video-card-wrapper-1 dashboard-card-bg">
                            <div className="video-card-content">
                                <img className="mb-3 vidThumb-img  img-fluid" src={icon} />
                            </div>
                            <span className={`qz-tag ${common.status === 1 ? "active" : ""}`} style={{ background: common.bgColor }}>{common.statusText}</span>
                        </div>
                        <div className="vidHover">
                            <div><p>{curElem.created}</p></div>
                            <div className="vidiconAll">
                                <div className="vidHover-single">

                                    {auth.user.isClientAccount === "1" ? "" :
                                        common.status === 1 || common.status === 3 ?
                                            <Link to={common.urlToRedirect}>
                                                <span className="vidHover-icon">
                                                    {loader.check ? <i className="fa fa-spinner fa-spin" /> :
                                                        <i class="fa fa-pencil-square-o" title="Edit" aria-hidden="true"></i>}
                                                </span>
                                                <span>Edit</span>
                                            </Link>
                                            :
                                            ""
                                    }

                                    {common.status === 1 ?
                                        <>
                                            <Link to={curElem.liveLink} target='_blank'>
                                                <span className="vidHover-icon" title="Goto"><i class="fas fa-external-link-alt"></i>
                                                </span>
                                                <span>View</span>
                                            </Link>
                                            <Link to={curElem.thankyouLink} target='_blank'>
                                                <span className="vidHover-icon" title="ThankYou Page"><i class="fas fa-gift"></i>
                                                </span>
                                                <span>ThankYou Page</span>
                                            </Link>
                                            <Link onClick={() => setShow(true)}>
                                                <span className="vidHover-icon" title="Share"><i class="fas fa-share-alt"></i>
                                                </span>
                                                <span>Share</span>
                                            </Link>
                                        </>
                                        :
                                        ""
                                    }
                                    {auth.user.isClientAccount === "1" ? "" :
                                        <a onClick={onDelete}>
                                            <span className="vidHover-icon" title="Delete"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                            <span>Delete</span>
                                        </a>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="videoProject-single-bottom">
                        <div>
                            <h6>{curElem.name}</h6>
                            <p>{curElem.created.split(" ")[0]}</p>
                        </div>
                    </div>
                </div>
            </div >

            <ShareThisModal
                url={curElem.liveLink}
                show={show}
                setShow={setShow}
            />

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this Landing page "${curElem.name}"`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </>

    )
}

export default LandingPageCard
