import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import sample from "../../../../../images/sample.png";
import { onUploadIR, removeIO, updateIOStatus } from '../../../../../Redux/Actions/VideoActions';
import { themeColor } from '../../../../../Global/Global';

const IntroOutro = (props) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [ir, setIr] = useState("")

    const handleStatus = (e) => {
        dispatch(updateIOStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            dispatch(removeIO(props.type))
        }
    }

    const onInputVideo = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].type === "video/mp4") {
                const allowedSize = 20000000;
                if (e.target.files[0].size < allowedSize) {

                    const formData = new FormData();
                    setLoader(true)
                    formData.append('file', e.target.files[0])
                    formData.append('upload_type', props.type)
                    dispatch(onUploadIR(formData, setLoader, props.type, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 20MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Upload ${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Video!`,
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <div className="add-block-main">
                        <div className="add-block-title">
                            <h2 className='text-capitalize'>{props?.type}</h2>
                            <div className="switch-single">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        id={props?.type}
                                        checked={props?.data?.enable}
                                        onChange={(e) => handleStatus(e)}
                                        name={props?.type}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {props?.data?.enable ?
                            <>
                                <div className="fileUpload mt-4" >
                                    <i className="fa fa-cloud-upload" aria-hidden="true" />

                                    <p className='text-capitalize'>
                                        Upload {props?.type}
                                    </p>
                                    <input
                                        type="file"
                                        className='cursor-pointer'
                                        accept="video/*"
                                        onChange={(e) => onInputVideo(e)}
                                        ac
                                    />
                                </div>
                                <div className="itemDisp">
                                    <div className="itemDisp-left">
                                        <ul>
                                            <li style={{ position: 'relative' }}>
                                                {loader ? <i className="fa fa-spinner fa-spin loader-center" style={{ color: "#32cd32" }} />
                                                    : <img
                                                        src={props?.data?.thumbnail ? props?.data?.thumbnail : sample}
                                                        alt=""
                                                        className="uploadImg"
                                                    />
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </> : ""}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default IntroOutro;
