import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import TransCard from './TransCard';
import { fetchData } from '../../../../../Redux/Actions/CommonActions';

const Transition = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchTransition = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-transition", {}, setData, loader, setLoader))
    }

    useEffect(() => {
        fetchTransition()
    }, [])

    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Transition</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <ul>
                                {data.length > 0 ?
                                    data.map((curElem, index) => {
                                        if (curElem.image !== "") {
                                            return (
                                                <li key={index} className="col-6" >
                                                    <TransCard
                                                        name={curElem.name}
                                                        preview={curElem.preview}
                                                        thumbnail={curElem.thumbnail}
                                                    />

                                                </li>
                                            )
                                        }
                                    }) : ''}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transition

