import { React, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router'
import { GoHomeFill } from "react-icons/go";
import { IoExtensionPuzzleSharp } from "react-icons/io5";
import { BsFillBuildingsFill } from "react-icons/bs";
import { TbBriefcaseFilled } from "react-icons/tb";
import { FaBox, FaRegNewspaper } from "react-icons/fa6";
import { TbCopyPlusFilled } from "react-icons/tb";
import { IoWallet } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onActiveAgency, onDeactiveAgency } from '../../Redux/Actions/AgencyActions';
import queryString from 'query-string';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { FaVideo } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa6";
import { LuHeadphones } from "react-icons/lu";
import { fetchDataRedux } from '../../Redux/Actions/CommonActions';

const SidePanel = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { aid } = queryString.parse(location.search)
  const agency = useSelector(state => state.agency.list)
  const rebrand = useSelector(state => state.rebrand.data)
  const auth = useSelector(state => state.auth);

  const activeAgency = useSelector(state => state.agency.activeAgency)
  const [activeButton, setActiveButton] = useState("")


  const [show, setShow] = useState(() => {
    const savedShow = localStorage.getItem("submenuOpen");
    return savedShow === "true";
  });

  const handleToggle = () => {
    const newShowState = !show;
    setShow(newShowState);
    localStorage.setItem("submenuOpen", newShowState);

  };
  const handleFalse = () => {
    setShow(false);
    localStorage.setItem("submenuOpen", "false"); // Update localStorage consistently
  };

  const fetchAgency = () => {
    dispatch(fetchDataRedux("fetch-all-agency", {}, "FETCH_AGENCY_LIST", false, false))
  }

  useEffect(() => {
    if (auth.user.isClientAccount === "1") {
      let clientAgency = agency.find(({ id }) => +id === +auth.user.agencyId);
      if (clientAgency) {
        dispatch(onActiveAgency(clientAgency));
      }
    }
    else {
      if (aid) {
        let data = agency.find(({ id }) => +id === +aid)
        if (data) {
          dispatch(onActiveAgency(data))
        }
      }
      else {
        if (agency.length > 0) {
          dispatch(onActiveAgency(agency[0]))
        } else {
          dispatch(onDeactiveAgency())
        }
      }
    }
  }, [aid, agency, auth.user])

  useEffect(() => {
    fetchAgency()
  }, [])

  useEffect(() => {
    if (location.pathname === "/update-script") {
      setActiveButton("active");
    } else {
      setActiveButton("");
    }
  }, [location.pathname])

  return (
    <>
      <div className='side-link'>
        <div className="header-logo mb-4">
          <Link to={'/dashboard'}>  <img className='logo-full' src={rebrand ? rebrand.logo : require("../../images/logo.svg").default} alt="" /></Link>
          <Link to={'/dashboard'}> <img className='logo-small' src={rebrand ? rebrand.logo : require("../../images/logo-small.svg").default} alt="" /></Link>
        </div>
        <ul>
          <li><NavLink onClick={handleFalse} to="/dashboard"><span><GoHomeFill /> <div>Dashboard</div></span></NavLink></li>
          <li><NavLink onClick={handleFalse} to={activeAgency ? `/explore-ai-tools?aid=${activeAgency.id}` : "/explore-ai-tools"}><span><FaBox /> <div>Explore AI Tools</div></span></NavLink></li>
          <li><NavLink onClick={handleFalse} to={activeAgency ? `/my-tools?aid=${activeAgency.id}` : "/my-tools"}><span><TbBriefcaseFilled /> <div>My Tools</div></span></NavLink></li>
          <li><NavLink onClick={handleFalse} to={activeAgency ? `/my-collections?aid=${activeAgency.id}` : "/my-collections"}><span><TbCopyPlusFilled /> <div>My Collections</div></span></NavLink></li>

          {auth.user.isClientAccount === "1" ? "" : <li><NavLink to="/integrations" onClick={handleFalse}><span><IoExtensionPuzzleSharp /> <div>Integrations</div></span></NavLink></li>
          }
          {auth.user.isClientAccount === "1" ? "" : <li><NavLink to="/agency"><span><BsFillBuildingsFill /> <div>Agency</div></span></NavLink></li>}
          <li className={`kap_nav ${show ? "active" : ""}`} onClick={handleToggle}>
            <span><IoWallet /> <div className='m_kap'>Marketing</div> </span>
            <span
              className='m_dir'
              style={{
                position: " relative",
                top: "2px"
              }}
            >
              {show ? (
                <MdKeyboardArrowDown fontSize={28} />
              ) : (
                <MdKeyboardArrowRight fontSize={28} />
              )}</span>
          </li>

          {show ?
            <div className='content_submenu'>
              <li><NavLink to="/marketing/sales-copywriter"><span><IoWallet /> <div>Sales Copywriter</div></span></NavLink></li>
              <li><NavLink to="/marketing/page-builder"><span> <FaRegNewspaper /> <div>Page Builder</div></span></NavLink></li>
              <li className={activeButton === "active" ? "kap_nav_submenu active" : ""}><NavLink to="/marketing/video-maker"><span><FaVideo />

                <div>Video Maker</div></span></NavLink></li>
              <li><NavLink to="/marketing/image-maker"><span><FaRegImage />
                <div>Image Maker</div></span></NavLink></li>
            </div>
            : ""}
          <li><NavLink to="/help-and-support"><span><LuHeadphones /> <div>Help and Support</div></span></NavLink></li>
        </ul >
      </div >
    </>
  )
}

export default SidePanel