import React from 'react'

const Plateform = ({ data }) => {
    return (
        data?.length > 0 ?
            <>
                <optgroup label={data[0].group_name}>
                    {data.map((curElem) => {
                       let dim = curElem.dimension === "1920x1080" ? "16x9" : "9x16"
                        return (
                            <option value={curElem.dimension}>{curElem.name}{" "}({dim})</option>
                        )
                    })
                    }
                </optgroup>
            </>
            : ""


    )
}

export default Plateform