import React, { useEffect, useState } from 'react'
import ImagesModal from './Modals/ImagesModal';
import defaultImage from "../../../../images/dummy.jpg"
import { FiUpload } from 'react-icons/fi'
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { onCancleMember, onChangeBonusColor, onChangeTeam } from '../../../../Redux/Actions/ProjectActions';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';
import { TbReplace } from "react-icons/tb";
import BonusModal from './Modals/BonusModal';
import { Trash } from 'iconsax-react';
import GradientBox from '../../../Common/GradientBox';
import CommonAlert from '../../../Common/CommonAlert';

const BonusSectionRows = ({ curElem, index, handleChange, data }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [colorShow, setColorShow] = useState({
        bg: false,
        font: false
    });
    const [type, setType] = useState("Gradient")
    const [isGradient, setIsGradient] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleCancel = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        dispatch(onCancleMember(index))
        onCancel()
    }

    const handleShowColor = (name) => {
        setColorShow({
            ...colorShow,
            [name]: true
        })
    }

    const handleHideColor = (name) => {
        setColorShow({
            ...colorShow,
            [name]: false
        })
    }

    const handleChangeColor = (e, name) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        dispatch(onChangeBonusColor(hex, name, index))
    }

    const handleAddMemberImage = (data) => {
        dispatch(onChangeTeam("icon", data.url, index))
    }

    const getFinalGradient = (index, finalColor) => {
        dispatch(onChangeBonusColor(finalColor, "bg", +index))
    }

    useEffect(() => {
        if (isGradient) {
            setType("Gradient")
        } else {
            setType("Color")
        }
    }, [isGradient])

    return (
        <div className="col-lg-4 mb-4">
            <div className="benefit-single" style={{ position: "relative" }}>
                <div className='d-flex align-items-center justify-content-between'>
                    <h6>Bonus {index + 1}</h6>
                    <div>
                        <span
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={handleShow1}
                            title='Change Bonus'
                        >
                            <TbReplace color="#1a1a1a" />
                        </span>
                        <span
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => handleCancel(index)}
                            className='ms-2'
                            title='Remove'
                        >
                            <RxCross2 color='#1a1a1a' />
                        </span>
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Image</span>
                    <div className="inpLabel">
                        <span onClick={handleShow} style={{ cursor: "pointer" }} className="fullLink"></span>
                        <span className='uploaded-img alt'><img src={curElem.icon ? curElem.icon : defaultImage} alt="" /></span>
                        <input
                            className="inpLabel-inp"
                            placeholder=""
                            type="text"
                            name='image'
                        />
                        <button className="site-link  inpBtn-icon">
                            <FiUpload style={{ position: "relative", marginRight: "5px" }} /> <span className="pl-1">Choose</span>
                        </button>
                    </div>
                </div>

                <div className="inpLabelWrap">
                    <span className="labelTxt">Name</span>
                    <div className="inpLabel">
                        <label htmlFor="">Name</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Enter Name"
                            type="text"
                            value={curElem.name}
                            name="name"
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Heading</span>
                    <div className="inpLabel">
                        <label htmlFor="">Heading</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Enter Heading"
                            type="text"
                            value={curElem.heading}
                            name="heading"
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Description</span>
                    <div className="inpLabel">
                        <label htmlFor="" style={{ width: "100%", borderTopLeftRadius: ".5rem", borderTopRightRadius: ".5rem", background: "#e4e4e4", paddingBottom: "9px" }}>Enter Description</label>
                        <textarea style={{ paddingTop: "3.2rem", paddingBottom:"1rem" }}
                            className="inpLabel-inp"
                            type="text"
                            value={curElem.description}
                            name="description"
                            placeholder='Enter Description'
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-12'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>Background Color Type</span>
                            <select
                                className='common-input site-common-inp'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="Color">Color</option>
                                <option value="Gradient">Gradient</option>
                            </select>
                        </div>
                        <div className='col-lg-12'>
                            <div className='inpLabelWrap mt-1'>
                                <span className='labelTxt'>{type}</span>
                                {type === "Color" ?
                                    <div className='primary-background'>
                                        <div className='color-wrap' style={{ height: "4.3rem" }}>
                                            <span
                                                className='colorBox'
                                                style={{ background: curElem.color.bg }}
                                                onClick={() => handleShowColor("bg")}
                                            ></span>
                                            <span className='colorVal'>{curElem.color.bg}</span>
                                        </div>
                                        {colorShow.bg ?
                                            <>
                                                <div
                                                    style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                    onClick={() => handleHideColor("bg")}
                                                />
                                                <span className="color-box">
                                                    <SketchPicker
                                                        color={curElem.color.bg}
                                                        onChange={(e) => handleChangeColor(e, "bg")}
                                                    />
                                                </span>
                                            </> : null}
                                    </div> :
                                    <div className='primary-gradient'>
                                        <GradientBox
                                            getFinalGradient={getFinalGradient}
                                            colorString={curElem.color.bg}
                                            setIsGradient={setIsGradient}
                                            type={index}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='col-xl-12'>
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Font Color</span>
                            <div className='color-wrap' style={{ height: "4.3rem" }}>
                                <span
                                    className='colorBox'
                                    style={{ background: curElem.color.font }}
                                    onClick={() => handleShowColor("font")}
                                ></span>
                                <span className='colorVal'>{curElem.color.font}</span>
                            </div>
                            {colorShow.font ?
                                <>
                                    <div
                                        style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                        onClick={() => handleHideColor("font")}
                                    />
                                    <span className="color-box">
                                        <SketchPicker
                                            color={curElem.color.font}
                                            onChange={(e) => handleChangeColor(e, "font")}
                                        />
                                    </span>
                                </> : null
                            }

                        </div>
                    </div>
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddMemberImage}
                imageIndex={index}
            />

            <BonusModal
                show={show1}
                handleClose={handleClose1}
                data={data}
                bonusIndex={index}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to remove this bonus?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                icon={<Trash />}
            />
        </div >
    )
}

export default BonusSectionRows