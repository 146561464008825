import React from 'react';
import ReactPlayer from "react-player";

const week4 = () => {
    const videos = [

        {
            id: 1,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Conclusion.mp4"
        },

        {
            id: 2,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Introduction.mp4"
        },

        {
            id: 3,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+1.mp4"
        },

        {
            id: 4,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+2.mp4"
        },

        {
            id: 5,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+3.mp4"
        },

        {
            id: 6,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+4.mp4"
        },

        {
            id: 7,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+5.mp4"
        },

        {
            id: 8,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+6.mp4"
        },

        {
            id: 9,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+7.mp4"
        },

        {
            id: 10,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+8.mp4"
        },

        {
            id: 11,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+4+Compressed/Video+9.mp4"
        }

    ]

    return (
        <div
            className="tab-pane fade"
            id="v-pills-week-4"
            role="tabpanel"
            aria-labelledby="v-pills-week-4-tab"
        >
            <div className="row ">
                <h2>Master Class Week 4</h2>
                {videos.map((curElem) => {
                    return (
                        <div className="col-lg-6 col-12 mx-auto " key={curElem.id}>
                            <div className="master-box-content dashboard-block p-2">
                                <ReactPlayer
                                    url={curElem.url}
                                    loop="true"
                                    muted
                                    controls
                                    width="100%"
                                    height="100%"
                                    playing={false}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default week4;