import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { HelpReducer } from "./HelpReducer";
import { SocialReducer } from "./SocialReducer";
import { ExtraReducer } from "./ExtraReducer";
import { EditorReducer } from "./EditorReducer";
import { AgencyReducer } from "./AgencyReducer";
import { VideoReducer } from "./VideoReducer";
import { LandingReducer } from "./LandingReducer";
import { CreateToolReducer } from "./CreateToolReducer";
import { ImageReducer } from "./ImageReducer";
import { WidgetReducer } from "./WidgetReducer";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    rebrand: RebrandingReducer,
    help: HelpReducer,
    social: SocialReducer,
    extra: ExtraReducer,
    editor: EditorReducer,
    agency: AgencyReducer,
    video: VideoReducer,
    image: ImageReducer,
    landingPage: LandingReducer,
    createTool : CreateToolReducer,
    widget: WidgetReducer,
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;