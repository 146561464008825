import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from "query-string";
import Header from '../../Common/Header';
import SidePanel from '../../Common/SidePanel';
import TrainingTitles from './TrainingTitles';
import TrainingContents from './TrainingContents';
import Footer from '../../Common/Footer';


const Training = () => {
    const location = useLocation()
    const supportData = useSelector(state => state.help.articles);
    const articleId = queryString.parse(location.search).id;
    return (
        <>
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    {/* <div className='container-area'> */}

                    <div className='account-wrap pb-5 for-post'>
                        {/* <div className='titleBar'> */}
                        {/* <div className='titleBar-left'> */}
                        {/* <h2>Help</h2>
                                    <p>Select from Popular Topics</p> */}
                        <div className='account-left'>
                            {/* <div className='upgrade-nav'> */}
                            <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                            {/* {/* </div> */}
                        </div>
                        <div className='account-right large'>

                            <TrainingContents location={location} />
                        </div>
                        {/* </div> */}

                        {/* </div> */}
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Training