import React from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router';

import { LuTrash2 } from "react-icons/lu";
import { BsStars } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";

function MyTemplates() {
  return (
    <>
      <Header />

        <div className='page-wrap'>
           <div className="side-nav">
             <SidePanel />
           </div>
           <div className="page-container">
                <div className="pageTitle">
                    <div className='pageTitle-left'>
                        <h4>Templates</h4>
                        <p>This is where you can further optimize and manage your templates.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Influencer Finder</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Influencer Finder</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Influencer Finder</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>
                        </div>
                    </div>
                </div>
           </div>
        </div>

        <Footer />
    </>
  )
}

export default MyTemplates