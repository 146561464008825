import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { onExportUserCsv, onFetchResults } from '../../Redux/Actions/CreateToolActions';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { setAlert } from '../../Redux/Actions/AlertActions';

function Results() {

  const toolData = useSelector(state => state.createTool.data);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [loader, setLoader] = useState({
    fetch: true,
    export: false
  });

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(result.length / 15);
  const [pageRowsOption, setPageRowOption] = useState([])

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, result.length);
  const currentItems = result.slice(startIndex, endIndex);

  const changePage = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const handleExport = () => {
    if (result.length > 0) {
      let data = {
        id: toolData.id
      }
      setLoader({
        ...loader,
        export: true
      });
      dispatch(onExportUserCsv(data, loader, setLoader))
    }
    else {
      dispatch(setAlert("No data found to export it!", "danger"))
    }
  }

  const fetchResults = () => {
    let data = {
      toolId: toolData.id
    }
    dispatch(onFetchResults(data, setResult, loader, setLoader))
  };

  useEffect(() => {
    const arr = Array.from({ length: totalPages }, (_, index) => (index + 1) * 15);
    setPageRowOption(arr);
  }, [totalPages])

  useEffect(() => {
    fetchResults();
  }, [])

  return (
    <>
      <div className='builder-area d-block'>
        <div className='d-flex align-items-center justify-content-between'>
          <h5>Lead Results</h5>
          <button className={`site-link ${result.length > 0 ? "" : "btn-disable"}`} onClick={handleExport} disabled={result.length > 0 ? false : true}><span>{loader.export ? <>Exporting <FaSpinner className="fa-spin" /> </> : "Export"}</span></button>
        </div>
        <div className='table-responsive mt-4'>
          <table className='table themeTable'>
            <thead>
              <tr>
                <th></th>
                <th>S.No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Created</th>
              </tr>
            </thead>

            <tbody>
              {currentItems.length > 0 ?
                currentItems.map((curElem, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td>{index + 1}</td>
                      <td>{curElem.name}</td>
                      <td>{curElem.email}</td>
                      <td>{curElem.type}</td>
                      <td>{curElem.created.split(":")[0]}</td>
                    </tr>
                  )
                })
                : ""
              }

              {result.length === 0 ?
                <tr className='text-center'>
                  <td colSpan={6} style={{ background: "none" }}>
                    {
                      loader.fetch ?
                        <FaSpinner className="fa-spin" size={25} />
                        : "No data found!"
                    }
                  </td>
                </tr>
                : ""
              }

            </tbody>

          </table>
        </div>

        <div className='mt-3 d-flex align-items-center justify-content-between'>
          <h6>Total Leads: {result.length}</h6>
          {pageRowsOption.length > 0 ?
            <div className='d-flex align-items-center gap-2'>
              <p><small style={{ whiteSpace: 'nowrap' }}>Page Size</small></p>
              <select
                className='input small'
                onChange={changePage}
              >
                {pageRowsOption.map((curElem, index) => {
                  return (
                    <option value={curElem} key={index} selected={+curElem === +pageSize}>{curElem}</option>
                  )
                })
                }
              </select>
            </div>
            : ""
          }

        </div>
      </div >
    </>
  )
}

export default Results