import React, { useEffect, useState } from "react";
import SidePanel from '../Common/SidePanel'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { NavLink, useLocation, useNavigate } from 'react-router';

import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { MdOutlineModeEdit } from "react-icons/md";

import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { LuTrash2 } from "react-icons/lu";
import { BsStars } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";
import Design from "./Design";
import BuilderIntegration from "./BuilderIntegration";
import Share from "./Share";
import Results from "./Results";
import TitleBar from "../Common/TitleBar";
import Builder from "./Builder/Builder";

import Modal from 'react-bootstrap/Modal';
import { MdChevronLeft } from "react-icons/md";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { onCopyTool, onDeleteAiTool, onFetchToolData, onSubmitToolData } from "../../Redux/Actions/CreateToolActions";
import { useSelector } from "react-redux";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { FaSpinner } from "react-icons/fa";
import GenerateAiModal from "./EditPopups/GenerateAiModal";
import CommonAlert from "../Common/CommonAlert";
import { Trash } from "iconsax-react";
import CusstomToolModel from "../Common/CusstomToolModel";
import { setAlert } from "../../Redux/Actions/AlertActions";

const CreateAiTool = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth);
    const { aid, id, t } = queryString.parse(location.search)
    const createTool = useSelector(state => state.createTool)

    const [tab, setTab] = useState({
        first: true,
        second: false,
        third: false,
        forth: false,
        fifth: false,
    })

    const [loader, setLoader] = useState({
        fetch: true,
        submit: false
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const handleTabNext = () => {
        let arr = Object.keys(tab)
        for (let i = 0; i < arr.length - 1; i++) {
            if (tab[arr[i]]) {
                setTab({
                    ...tab,
                    [arr[i]]: false,
                    [arr[i + 1]]: true,
                })
                break;
            }
        }
    }

    const handleTabPrev = () => {
        let arr = Object.keys(tab)
        for (let i = 1; i < arr.length - 1; i++) {
            if (tab[arr[i]]) {
                setTab({
                    ...tab,
                    [arr[i]]: false,
                    [arr[i - 1]]: true,
                })
                break;
            }
        }
    }

    const handleTabs = (tabName) => {
        if (checkValidation()) {
            return;
        }

        let objj = { ...tab }
        if (!tab[tabName]) {
            Object.keys(tab).forEach((curElem) => {
                objj[curElem] = tabName === curElem ? true : false
            })
            setTab(objj)
            handleSubmit(false, true)
        }
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            id: id,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteAiTool(data, navigate, setSweet))
    }

    const checkValidation = () => {
        if (tab.third && createTool.data.integrations.stripe.enable && !createTool.data.integrations.stripe.stripeId) {
            dispatch(setAlert("Please select Stripe account before next", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.stripe.enable && !createTool.data.integrations.stripe.amount) {
            dispatch(setAlert("Please enter amount before next", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.stripe.enable && +createTool.data.integrations.stripe.amount === 0) {
            dispatch(setAlert("Amount cannot be zero.", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.openai.enable && !createTool.data.integrations.openai.openAiId) {
            dispatch(setAlert("Please select Open AI account before next", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.autoresponder.enable && !createTool.data.integrations.autoresponder.autoresponderId) {
            dispatch(setAlert("Please select Autoresponder account before next", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.domain.enable && !createTool.data.integrations.domain.domain) {
            dispatch(setAlert("Please select Domain account before next", "danger"));
            return true;
        }
        if (tab.third && createTool.data.integrations.smtp.enable) {
            if (!createTool.data.integrations.smtp.smtpId) {
                dispatch(setAlert("Please select SMTP account before next", "danger"));
                return true;
            }
            if (!createTool.data.integrations.smtp.subject) {
                dispatch(setAlert("Please enter subject before next", "danger"));
                return true;
            }
            if (!createTool.data.integrations.smtp.name) {
                dispatch(setAlert("Please enter name before next", "danger"));
                return true;
            }
            if (!createTool.data.integrations.smtp.replyTo) {
                dispatch(setAlert("Please enter reply to before next", "danger"));
                return true;
            }
            if (!createTool.data.integrations.smtp.email) {
                dispatch(setAlert("Please enter email to before next", "danger"));
                return true;
            }
        }
        return false;
    }

    const handleCopy = () => {
        let data = { id: createTool.data.id }
        setLoader({
            ...loader,
            copy: true
        })
        dispatch(onCopyTool(data, aid, fetchTool, navigate, loader, setLoader))
    }

    const handleSubmit = (e, isLoader) => {

        if (checkValidation()) {
            return;
        }

        let obj = { ...createTool.data }
        let fun
        if (isLoader) {
            fun = false
        } else {
            setLoader({
                ...loader,
                submit: true
            })
            fun = handleTabNext
        }
        let redirect = false
        if (tab.fifth) {
            redirect = navigate
        }
        dispatch(onSubmitToolData(obj, fun, loader, setLoader, redirect))
    }

    const fetchTool = (aid) => {
        let data = { id: aid ? aid : id }
        dispatch(onFetchToolData(data, loader, setLoader))
    }

    useEffect(() => {
        fetchTool()
    }, [])

    useEffect(() => {
        if (t) {
            if (t !== "first") {
                setTab({
                    ...tab,
                    [t]: true,
                    first: false
                })
            }
        }
    }, [t])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Create AI Tool" />
                <div className='page-wrap expand'>
                    <div className="side-nav">
                        <SidePanel />
                    </div>

                    <div className="page-container">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="builder-header">

                                <div className='builder-header-left'>

                                    {auth.user.isClientAccount === "1" ? "" : <div className='link' onClick={() => navigate(-1)}><LuChevronLeft /></div>}
                                    <h6 className="px-3 clips">{createTool.data.name.slice(0, 30)}</h6>
                                    {/* <div className='link'><MdOutlineModeEdit /></div> */}
                                    {auth.user.isClientAccount === "1" ? "" : <Dropdown className="drop-style-1">
                                        <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                            <BiDotsVerticalRounded />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a style={{ cursor: "pointer" }} onClick={handleShow}><FiEdit /> Edit Tool</a>
                                            <a style={{ cursor: "pointer" }} onClick={handleCopy}>{loader.copy ? <FaSpinner className="fa-spin" /> : <MdOutlineContentCopy />} Copy Tool</a>
                                            <a style={{ cursor: "pointer" }} onClick={onDelete}><LuTrash2 /> Delete Tool</a>
                                            <a style={{ cursor: "pointer" }} onClick={handleShow3}><BsStars /> Generate with Ai</a>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                    }
                                </div>
                                <div className="builder-header-mid">
                                    <Nav variant="v" className="">
                                        <Nav.Item>
                                            <Nav.Link onClick={() => handleTabs("first")} className={tab.first ? "active" : ""}>BUILDER</Nav.Link>
                                        </Nav.Item>
                                        <div className='link'><LuChevronRight /></div>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => handleTabs("second")} className={tab.second ? "active" : ""}>DESIGN</Nav.Link>
                                        </Nav.Item>
                                        <div className='link'><LuChevronRight /></div>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => handleTabs("third")} className={tab.third ? "active" : ""}>INTEGRATIONS</Nav.Link>
                                        </Nav.Item>
                                        <div className='link'><LuChevronRight /></div>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => handleTabs("forth")} className={tab.forth ? "active" : ""}>SHARE</Nav.Link>
                                        </Nav.Item>
                                        <div className='link'><LuChevronRight /></div>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => handleTabs("fifth")} className={tab.fifth ? "active" : ""}>RESULTS</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="builder-header-right">
                                    {auth.user.isClientAccount === "1" ? "" : <button className="site-link" style={tab.first ? { background: "gray", cursor: "not-allowed" } : {}} onClick={handleTabPrev}><span><GoArrowLeft /> BACK</span></button>}
                                    <button className="site-link ms-1" onClick={(e) => handleSubmit(e, false)}><span>NEXT {loader.submit ? <><FaSpinner className="fa-spin" /></> : <GoArrowRight />}</span></button>
                                </div>
                            </div>

                            <Tab.Content>
                                <Tab.Pane className={tab.first ? "active show" : ""} ><Builder first={tab.first} /></Tab.Pane>
                                <Tab.Pane className={tab.second ? "active show" : ""} ><Design /></Tab.Pane>
                                <Tab.Pane className={tab.third ? "active show" : ""} ><BuilderIntegration /></Tab.Pane>
                                <Tab.Pane className={tab.forth ? "active show" : ""} ><Share /></Tab.Pane>
                                <Tab.Pane className={tab.fifth ? "active show" : ""} ><Results /></Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                    </div>

                </div>

                <GenerateAiModal
                    show={show3}
                    handleClose={handleClose3}
                />

                <CusstomToolModel
                    show={show}
                    handleClose={handleClose}
                    toolId={{ id: createTool.data.toolId }}
                    id={createTool.data.id}
                    type={createTool.data.type}
                    editData={createTool.data}
                    fetchTool={fetchTool}
                />

                <CommonAlert
                    show={sweet.enable}
                    message={`Are you sure you want to delete this tool?`}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                    alertLoader={sweet.loader}
                    icon={<Trash />}
                />
            </>
    )
}

export default CreateAiTool