import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router";
import Dropdown from 'react-bootstrap/Dropdown';
import { IoIosArrowDown } from "react-icons/io";

import { LuUser2, LuKeyRound, LuUserCog, LuHeadphones, LuLogOut } from "react-icons/lu";
import { MdOutlineElectricBolt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import CreateAgencyModal from "../Agency/CreateAgencyModal";
import { fetchDataRedux } from "../../Redux/Actions/CommonActions";
import { onActiveAgency, onDeactiveAgency } from "../../Redux/Actions/AgencyActions";
import { FaEye } from "react-icons/fa";
import queryString from "query-string"

import { FaCirclePlus } from "react-icons/fa6";
import { themeColor } from "../../Global/Global";
import CusstomToolModel from "./CusstomToolModel";
import TeamplateModel from "./TeamplateModel";

import { IoMenu } from "react-icons/io5";
import { FiPlusSquare } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeAgency = useSelector(state => state.agency.activeAgency)
  const rebrand = useSelector(state => state.rebrand.data)

  const [isActive, setIsActive] = useState(false);

  const [memberShip, setMemberShip] = useState([])

  const auth = useSelector(state => state.auth);

  const agency = useSelector(state => state.agency.list)
  const { aid } = queryString.parse(location.search)

  const [toolId, setToolId] = useState("0")
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);


  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const logout = () => {
    dispatch(logoutUser());
  }

  const handleSelectCompany = (data) => {
    if (+activeAgency.id !== +data.id) {
      dispatch(onActiveAgency(data))
      const url = new URL(window.location.href);
      url.searchParams.set('aid', data.id);
      window.history.pushState(null, '', url.toString());
    }
  }


  const fetchAgency = () => {

    dispatch(fetchDataRedux("fetch-all-agency", {}, "FETCH_AGENCY_LIST", false, false))
  }

  useEffect(() => {
    if (auth.user.isClientAccount === "1") {
      let clientAgency = agency.find(({ id }) => +id === +auth.user.agencyId);
      if (clientAgency) {
        dispatch(onActiveAgency(clientAgency));
      }
    }
    else {
      if (aid) {
        let data = agency.find(({ id }) => +id === +aid)
        if (data) {
          dispatch(onActiveAgency(data))
        }
      }
      else {
        if (agency.length > 0) {

          dispatch(onActiveAgency(agency[0]))
        } else {
          dispatch(onDeactiveAgency())
        }
      }
    }
  }, [aid, agency, auth.user])

  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])


  useEffect(() => {
    fetchAgency()
  }, [])


  return (
    <>

      <header className="site-header">
        <div className="header-left">
          <div className="d-flex align-items-center gap-2">
            <div className={isActive ? "navToggle active" : "navToggle"} onClick={toggleClass}><IoMenu /></div>
            <div className="header-logo"><img src={rebrand ? rebrand.logo : require("../../images/logo.svg").default} alt="" /></div>
          </div>

          {!activeAgency ?
            <Tooltip
              id={'my-tooltip-custom-tool'}
              style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
              place="top"
              content={"Please create an agency first to create a custom tool."}
            /> : null}
          {auth.user.isClientAccount === "1" ? "" : <button
            className="site-link"
            type="button"
            onClick={handleShow7}
            style={activeAgency ? {} : { background: "gray", cursor: "not-allowed" }}
            disabled={!activeAgency}
            data-tooltip-id="my-tooltip-custom-tool"
          >
            <span><MdOutlineElectricBolt /> Create Custom Tool</span>
          </button>}
          {/* <button className="site-link" type="button" onClick={handleShow7}><span><MdOutlineElectricBolt /> Create Custom Tool</span></button> */}

        </div>
        <div className="header-right">
          {!activeAgency ?
            <Tooltip
              id={'my-tooltip-custom-tool'}
              style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
              place="top"
              content={"Please create an agency first to create a custom tool."}
            /> : null}
          {auth.user.isClientAccount === "1" ? "" : <button
            className="site-link"
            type="button"
            onClick={handleShow7}
            style={activeAgency ? {} : { background: "gray", cursor: "not-allowed" }}
            disabled={!activeAgency}
            data-tooltip-id="my-tooltip-custom-tool"
          >
            <span><MdOutlineElectricBolt /> Create Custom Tool</span>
          </button>}

          {agency.length > 0 ?
            <Dropdown className="drop-style-1 bdr">
              <Dropdown.Toggle variant="" className="user-btn" id="dropdown-basic">
                <div className="drop-img"><img src={activeAgency.image} alt="" /></div>
                <h6>{activeAgency.name}</h6>
              </Dropdown.Toggle>

              <Dropdown.Menu  >
                <div className="compDrop" style={{ border: "none" }}>
                  {auth.user.isClientAccount === "1" ? "" : <Dropdown.Item onClick={handleShow}><FaCirclePlus /> Create New Agency</Dropdown.Item>}

                  {auth.user.isClientAccount === "1" ? agency.slice(0, 5).filter(({ id }) => +id === +auth.user.agencyId).map((curElem, index) => {
                    return (
                      <Dropdown.Item key={index} onClick={() => handleSelectCompany(curElem)} style={+activeAgency.id === +curElem.id ? { background: themeColor, color: "#fff" } : {}}>
                        <div className="drop-img"><img src={curElem.image} alt="" /></div>
                        <p>{curElem.name}</p>
                      </Dropdown.Item>
                    )
                  }) : agency.slice(0, 5).map((curElem, index) => {
                    return (
                      <Dropdown.Item key={index} onClick={() => handleSelectCompany(curElem)} style={+activeAgency.id === +curElem.id ? { background: themeColor, color: "#fff" } : {}}>
                        <div className="drop-img"><img src={curElem.image} alt="" /></div>
                        <p>{curElem.name}</p>
                      </Dropdown.Item>
                    )
                  })}
                  {auth.user.isClientAccount === "1" ? "" : <Link to={`/agency`}><FaEye size={16} />  <span>View All</span></Link>}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            :
            <> {
              auth.user.isClientAccount === "1" ? "" : <div className="drop-style-1 bdr">
                <button type="button" aria-expanded="false" className="user-btn" onClick={handleShow}>
                  <div className="drop-img"><img src="https://reeelapps-app.s3.us-west-2.amazonaws.com/AIwhitelabels1/1736590571_fTegUBMkr9XJPSysPy3HdoAIwhitelabels1.png" alt="" /></div>
                  <h6>Create Agency</h6>
                </button>
              </div>}</>
          }


          <Dropdown className="drop-style-1">
            <Dropdown.Toggle variant="" className="user-btn" id="dropdown-basic">
              <div className="drop-img"><img src={auth.user.profile} alt="" /></div>
              <div className="drop-info">
                <h6>{auth.user.name}</h6>
              </div>
              <IoIosArrowDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {auth.user.isClientAccount === "1" ? <>
                <NavLink to="/my-account"><LuUser2 /> My Account</NavLink>
                <NavLink to="/privacy"><LuKeyRound /> Privacy</NavLink>
                <NavLink to="/help-and-support"><LuHeadphones /> Help and Support</NavLink>
                <a style={{ cursor: "pointer" }} onClick={() => logout()}><LuLogOut /> Logout</a>
              </> :
                <> <NavLink to="/my-account"><LuUser2 /> My Account</NavLink>
                  <NavLink to="/privacy"><LuKeyRound /> Privacy</NavLink>
                  {memberShip.includes("enterprise") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                    <NavLink to="/account-management"><LuUserCog /> Account Management</NavLink> : null}

                  {memberShip.includes("enterprise") || memberShip.includes("premium") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                    <NavLink to="/upgrades"><FiPlusSquare /> Upgrades</NavLink> : null}
                  <NavLink to="/help-and-support"><LuHeadphones /> Help and Support</NavLink>
                  <a style={{ cursor: "pointer" }} onClick={() => logout()}><LuLogOut /> Logout</a></>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header >


      <CreateAgencyModal
        show={show}
        handleClose={handleClose}
        fetchAgency={fetchAgency}
      />
      <CusstomToolModel show={show6} handleClose={handleClose6} toolId={toolId} type="custom" agencyId={activeAgency.id} />
      <TeamplateModel show={show7} handleClose={handleClose7} />

    </>
  )
}

export default Header;