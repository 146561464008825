import React from 'react'
import { Modal } from 'react-bootstrap'

const CommonAlert = (props) => {
    return (
        <Modal className="VideoModal sm-modal" show={props.show} backdrop="static" centered>
            <Modal.Body className='p-4'>
                {/* <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span> */}
                <div className="text-center pt-2">
                    <div className="modalIcon">{props.icon}</div>
                </div>
                <p className="pt-4 text-center">{props.message}</p>
                <div className='btnGroup mt-4 mb-4 text-center d-flex align-items-center justify-content-center gap-3'>
                    <button
                        className="site-link m-0"
                        onClick={props.handleClose}
                        style={{
                            background: props.cancelButtonColor
                        }}
                    >
                        <span>{props.cancelButtonName}</span>
                    </button>
                    <button
                        className="site-link m-0"
                        onClick={props.performDelete}
                        disabled={props.alertLoader}
                        style={{
                            background: props.confirmButtonColor
                        }}
                    >
                        <span>{props.confirmButtonName} {props.alertLoader ? <i className='fa fa-spinner fa-spin' /> : null}</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommonAlert