import React from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { FiChevronLeft } from "react-icons/fi";
import FirstTab from './FirstTab';
import SecondTab from './SecondTab';
import ThirdTab from './ThirdTab';
import FourthTab from './FourthTab';
import FifthTab from './FifthTab';
import SixthTab from './SixthTab';

function SalesPage() {
  return (
    <>
      <Header />

        <div className='page-wrap'>
           <div className="side-nav">
             <SidePanel />
           </div>
           <div className="page-container">
           <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className='sales-nav'>
                    <button className='site-link white' style={{width: 40, paddingLeft: 0, paddingRight: 0}}><span><FiChevronLeft /></span></button>
                    <div className='nav-mid text-center'>
                      <h5>Portal Details</h5>
                      <p>Customize the design of your new website</p>
                    </div>
                    <button className='site-link'><span>Next</span></button>
                </div>

                <div className='sales-nav justify-content-center'>
                    <Nav variant="v" className="">
                        <Nav.Item>
                        <Nav.Link eventKey="first">1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="second">2</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="third">3</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="fourth">4</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="fifth">5</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <Tab.Content className='pt-3'>
                    <Tab.Pane eventKey="first"><FirstTab /></Tab.Pane>
                    <Tab.Pane eventKey="second"><SecondTab /></Tab.Pane>
                    <Tab.Pane eventKey="third"><ThirdTab /></Tab.Pane>
                    <Tab.Pane eventKey="fourth"><FourthTab /></Tab.Pane>
                    <Tab.Pane eventKey="fifth"><FifthTab /></Tab.Pane>
                </Tab.Content>
            </Tab.Container>
           </div>
        </div>

        <Footer />
    </>
  )
}

export default SalesPage