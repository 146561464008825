import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import AudioFiles from '../AudioFiles';
import { onFetchMedia } from '../../../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../../../Global/Global';

const Music = ({ onSelectAudio, selectedAudio, setSelectedAudio }) => {
    const dispatch = useDispatch()
    const [pageCount, setPageCount] = useState(1);
    const [audio, setAudio] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchAudios = (page = 1) => {
        let data = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }
        setPageCount(page)
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchMedia("load-library-audio", data, audio, setAudio, loader, setLoader, false))
    }

    useEffect(() => {
        fetchAudios()
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt" id="scrollableAudio123">
                <div className="mediaList">
                    <ul>
                        <InfiniteScroll
                            dataLength={audio.length} //This is important field to render the next data
                            next={() => fetchAudios(pageCount + 1)}
                            hasMore={true}
                            scrollableTarget="scrollableAudio123"
                        >
                            <div className="media-ul-list audioList-single-music">
                                {audio.length > 0 ?
                                    audio.map((curElem, index) => {
                                        return (
                                            <AudioFiles
                                                name={curElem.title}
                                                url={curElem.url}
                                                type="music"
                                                fetchAudios={fetchAudios}
                                                selectedAudio={selectedAudio}
                                                setSelectedAudio={setSelectedAudio}
                                                onSelectAudio={onSelectAudio}
                                                key={index}
                                                id={curElem.id}
                                            />
                                        )
                                    })
                                    : ''}
                            </div>
                            {
                                loader.fetch ?
                                    <div className="col-12 col-md-12">
                                        <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: themeColor }} /></h4>
                                    </div>
                                    : ''
                            }
                        </InfiniteScroll>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Music