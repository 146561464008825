import React, { useState } from 'react'
import LeftBuilder from './LeftBuilder';
import MiddleBuilder from './MiddleBuilder';
import RightBuilder from './RightBuilder';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch } from 'react-redux';
import { onTestPrompt } from '../../../Redux/Actions/CreateToolActions';
import { useSelector } from 'react-redux';

const Builder = ({ first }) => {
    const dispatch = useDispatch()
    const createTool = useSelector(state => state.createTool)

    const [testData, setTestData] = useState("")
    const [loader, setLoader] = useState({
        test: false
    })

    const testPrompt = (e) => {
        e.preventDefault()
        let obj = {
            ...createTool.data,
        }
        let type = testData ? "regenerate" : "test"
        setLoader({
            ...loader,
            [type]: true
        })

        dispatch(onTestPrompt(obj, setTestData, loader, setLoader, type))
    }

    return (
        <>
            <form onSubmit={testPrompt} >

                <div className="builder-area">
                    <LeftBuilder
                        testData={testData}
                        setTestData={setTestData}
                        loaderTest={loader}
                    />
                    <DndProvider backend={HTML5Backend}>
                        <MiddleBuilder
                            first={first}
                        />
                        <RightBuilder
                        />
                    </DndProvider>
                </div>
            </form>

        </>
    )
}

export default Builder