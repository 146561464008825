import React, { useEffect, useState } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { BsFillMicFill, BsFillMicMuteFill, BsLink } from "react-icons/bs";
import { deleteBackTile, deleteTile, onAddSlide, onCheckInnerStatus, onRemovePlaySlide, onSelectInnerBackground, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../../../Redux/Actions/VideoActions';
import Swal from 'sweetalert2';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import CommonAlert from '../../../../Common/CommonAlert';
import { themeColor } from '../../../../../Global/Global';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import ImageBox from './ImageBox';


const VideoTiles = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state?.video?.data)
  const selectedSlideIndex = campaign?.slides?.findIndex(({ isSelected }) => isSelected === "1")

  const [generatingText, setGenerationText] = useState("")
  const [loader, setLoader] = useState(false);
  const [boxLoad, setBoxLoad] = useState(false)
  const [state, setState] = useState({
    slide: false,
    index: -1
  })

  const [sweet, setSweet] = useState({
    enable: false,
    id: false,
    confirmButtonName: "Delete",
    loader: false
  })


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };


  const onConfirm = () => {
    if (sweet.slideIndex !== undefined) {
      setSweet({
        ...sweet,
        confirmButtonName: "Deleting",
        loader: true
      })
      dispatch(deleteBackTile(sweet.slideIndex, sweet.backIndex, setSweet))
      dispatch(setAlert("Deleted Successfully", "success"))
    } else {
      if (campaign.slides.length === 1) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You cannot delete last slide!',
          confirmButtonColor: themeColor
        })
      } else {
        setSweet({
          ...sweet,
          confirmButtonName: "Deleting",
          loader: true
        })
        dispatch(deleteTile(state.slide, state.index, setSweet))
      }
    }
  }

  const onCancel = () => {
    setSweet({
      enable: false,
      id: false,
      confirmButtonName: "Delete",
      loader: false
    })
  }
  const onSetRecorder = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(campaign?.slides, result.source.index, result.destination.index);
    dispatch(rearrangeTiles(items));
  }

  const onSelectTile = (index) => {
    if (index !== selectedSlideIndex) {
      dispatch(updateScript(index, selectedSlideIndex))
      dispatch(onRemovePlaySlide(selectedSlideIndex))
    }
  }

  const selectInnerBackground = (index, ind) => {
    setBoxLoad(true)
    dispatch(onSelectInnerBackground(index, ind))
  }

  const handleDeleteAlert = (e, slide, index) => {
    e.stopPropagation()
    setState({
      ...state,
      slide: slide,
      index: index
    })
    setSweet({
      ...sweet,
      enable: true
    })
  }

  const handleDelete = (e, slideindex, backIndex) => {
    e.stopPropagation()
    setSweet({
      ...sweet,
      enable: true,
      slideIndex: slideindex,
      backIndex: backIndex
    })
  }


  const handleTts = (slideIndex, e) => {
    e.stopPropagation()
    dispatch(removeTtsFromSlides(slideIndex))
  }

  const handleAddSlide = (data) => {

    let obj = {
      id: data.videoId
    }
    if (campaign.slides.length > 20) {
      dispatch(setAlert("You can create max 20 slides!", "danger"))
    } else {
      setLoader(true)
      dispatch(onAddSlide(obj, setLoader))
    }

  }

  const fetchVideo = (interval) => {
    let data = {
      id: campaign.id
    }
    dispatch(onCheckInnerStatus(data, setGenerationText, interval))
  }

  useEffect(() => {
    if (+campaign.status === 1) {
      let text = `Generated ${campaign?.slides?.length}/${+campaign?.totalSlides}`
      setGenerationText(text)
      const interval = setInterval(() => {
        fetchVideo(interval)
      }, 5000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [campaign])

  return (

    <>
      <div className="editorReelwrap">
        <div className="editorReel-scroll">
          <DragDropContext onDragEnd={onSetRecorder}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {campaign?.slides?.length > 0 ?
                    campaign?.slides?.map((curElem, index) => {
                      return (
                        <>
                          <Draggable key={index} draggableId={`id_${index}`} index={index}>
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={index}
                                onClick={() => onSelectTile(index)}
                                className={`cursor-pointer editor_main_tiles ${+curElem.isSelected ? "active-tile-border" : "deactive-tile-border"}`}
                              >
                                <div
                                  className="editorReel-single-li"
                                  style={{
                                    border: +curElem.background[0].isSelected ? `2px solid ${themeColor} ` : "",
                                    background: curElem.background[0].type === "color" ? curElem.background[0].thumbnail : ""
                                  }}
                                  onClick={(e) => selectInnerBackground(index, 0)}
                                >
                                  <div className="editorReel-single">
                                    {/* {curElem.background[0].type === "color" ? "" :
                                      curElem.background[0].type === "image" || curElem.background[0].type === "video" ?
                                        <img src={curElem.background[0].thumbnail} alt="" /> : ""
                                      // <video
                                      //   src={curElem.background[0].src}
                                      //   muted
                                      //   style={{ height: "100%", width: "100%" }}
                                      // />
                                    } */}
                                    <ImageBox
                                      data={curElem.background[0]}
                                      boxLoad={boxLoad}
                                      setBoxLoad={setBoxLoad}
                                    />
                                  </div>

                                  <div className="IconDel">
                                    <FiTrash2
                                      onClick={(e) => handleDeleteAlert(e, curElem, index)}
                                      style={{
                                        fontSize: "15px",
                                        cursor: 'pointer',
                                        color: `${+curElem.background[0].isSelected === 1 ? "#32cd32" : '#fff'}`,
                                      }}

                                    />
                                  </div>

                                </div>
                                <ul className='m-0'>
                                  {curElem.background.length > 0 ?
                                    curElem.background.map((curElem, ind) => {
                                      return (ind !== 0 ?
                                        <li
                                          className="editorReel-single-sub"
                                          key={ind}
                                          onClick={(e) => selectInnerBackground(index, ind)}
                                        >
                                          <div className="Sub-editorReel-single-li"
                                            style={{ background: curElem.type === "color" ? curElem.thumbnail : "", border: +curElem.isSelected === 1 ? `2px solid #32cd32` : "" }}
                                          >
                                            <div className="editorReel-single">
                                            <ImageBox
                                                data={curElem}
                                                boxLoad={boxLoad}
                                                setBoxLoad={setBoxLoad}
                                              />
                                              {/* {curElem.type === "color" ? "" :
                                                curElem.type === "image" || curElem.type === "video" ?
                                                  <img src={curElem.src} alt="" /> :
                                                  ""
                                              } */}
                                            </div>
                                            <div className="IconDel">
                                              <FiTrash2
                                                onClick={(e) => handleDelete(e, index, ind)}
                                                style={{
                                                  fontSize: "15px",
                                                  cursor: 'pointer',
                                                  color: `${+curElem.isSelected === 1 ? themeColor : '#fff'}`,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </li> : "")
                                    })
                                    : ""}

                                </ul>
                                <div className="micON">
                                  <div className="d-flex">
                                    <div className='micON'>
                                      <div className="d-flex">
                                        {curElem?.voiceOver?.enable ?
                                          <BsFillMicFill
                                            style={{
                                              fontSize: "19px",
                                              cursor: 'pointer',
                                              color: "#32cd32"
                                            }}
                                            onClick={(e) => handleTts(index, e)}
                                          />
                                          :
                                          <BsFillMicMuteFill
                                            className="voiceIcn-off"
                                            style={{
                                              color: "#32cd32",
                                              fontSize: "19px",
                                              cursor: 'pointer',
                                            }}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                          {curElem.transitionName !== "" && curElem.transitionName ?
                            <div className="transition-wrapper mx-2" title={curElem.transitionName}>
                              <BsLink style={{ color: themeColor }} size={25} />
                            </div> : ""}
                          <div className="addReelBt" title={"Add Slide"} >
                            {
                              generatingText ?
                                <></>
                                :
                                loader ?
                                  <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: '25px' }} />
                                  :
                                  <span onClick={() => handleAddSlide(curElem)}>
                                    <AiFillPlusCircle size={25} color={themeColor} />
                                  </span>
                            }
                          </div>
                        </>
                      )
                    }) : ""}
                  {provided.placeholder}
                  {
                    generatingText ?
                      <div className='d-flex align-items-center'>
                        <div>
                          <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: '25px' }} />
                        </div>
                        <div className="mx-3" style={{ fontSize: 12 }}>
                          Generating more slide. Please be patient
                          <p>{generatingText}</p>
                        </div>

                      </div>
                      :
                      <></>
                  }
                </ul >

              )}
            </Droppable>
          </DragDropContext>

          <CommonAlert
            show={sweet.enable}
            message={"Are you sure you want to delete this tile?"}
            confirmButtonColor={""}
            cancelButtonColor={""}
            confirmButtonName={sweet.confirmButtonName}
            cancelButtonName={"Cancel"}
            handleClose={onCancel}
            alertLoader={sweet.loader}
            performDelete={onConfirm}
            icon={<FaTrash />}
          />

        </div>
      </div>
    </>

  );
};




export default VideoTiles;
