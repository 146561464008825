import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchData, onCreateCamp } from '../../../Redux/Actions/CommonActions';

const CreateProject = ({ show3, handleClose3 }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth)
    const agencyId = useSelector(state => state?.agency?.activeAgency?.id)
    const [language, setLanguage] = useState([]);
    const [tool, setTool] = useState([]);
    const [customActive, setCustomActive] = useState(false)
    const [loader, setLoader] = useState(false)
    const [projectDetails, setProjectDetails] = useState({
        campaign_name: "",
        product_name: "",
        language: "English",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value === "custom") {
            setCustomActive(true)
        }
        if (name === "product_name") {
            if (customActive) {
                setCustomActive(false)
            }
        }
        setProjectDetails({
            ...projectDetails,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        let data = { ...projectDetails }
        if (data.product_name === "custom") {
            data.product_name = data.custom
        }
        delete data.custom
        dispatch(onCreateCamp(data, navigate, setLoader))
    }


    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"));
    };

    const fetchTool = () => {
        let data = {
            agencyId: agencyId
        }
        dispatch(fetchData("fetch-all-user-tools", data, setTool));
    };

    useEffect(() => {
        if (agencyId) {
            fetchTool()
        }
    }, [agencyId])

    useEffect(() => {
        fetchLanguage();
    }, [])

    // add the default value of the product name in the projectDetails object
    useEffect(() => {
        if (tool.length > 0) {
            setProjectDetails({
                ...projectDetails,
                product_name: tool[0]?.name + "-" + tool[0]?.description
            })
        }
    }, [tool.length]);


    return (
        <>
            {+auth.user.isClientAccount !== 1 ?
                <Modal show={show3} onHide={handleClose3} className='fade VideoModal lg-modal modal project_modal  '>
                    <Modal.Body>
                        <Modal.Title className='mb-4'>Create Campaign </Modal.Title>
                        <div className='modalClose' onClick={handleClose3}><AiOutlineClose /></div>
                        <div className='col-12 '>
                            <form onSubmit={handleSubmit}>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Campaign Name</label>
                                    <input
                                        className='input'
                                        placeholder='Enter campaign name'
                                        type="text"
                                        name='campaign_name'
                                        value={projectDetails.campaign_name}
                                        required
                                        maxLength={30}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Product Name</label>
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        className='input'
                                        name='product_name'
                                        onChange={handleChange}
                                        required
                                        value={projectDetails.product_name}
                                    >
                                        {tool.filter((cur)=>cur.name!=="").map((t) => (
                                            <option key={t?.id} value={t?.name + "-" + t?.description}>
                                                {t.name}
                                            </option>
                                        ))}

                                        {tool.length>0? "" :<option value="">Select Product</option>}
                                        <option value={"custom"}>Create Custom</option>
                                    </select>
                                    {customActive ?
                                        <input
                                            className='input mt-3'
                                            placeholder='Enter product name'
                                            type="text"
                                            name='custom'
                                            value={projectDetails.custom}
                                            required
                                            maxLength={30}
                                            onChange={handleChange}
                                        /> : ""
                                    }

                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Language</label>
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        className='input'
                                        onChange={handleChange}
                                        name='language'
                                        required
                                        value={projectDetails.language}
                                    >
                                        <option value={""}>Select Language</option>
                                        {language && language.map((lang, index) => (
                                            <option key={index} value={lang.name}>{lang.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="project_btn mt-4 text-end">
                                    <button className='site-link' type='submit' >
                                        <span>{loader ? <>Submitting <i className="fa fa-spinner fa-spin mx-1 loader-log" /></> : "Submit"}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>

                </Modal> : null}
        </>
    )
}

export default CreateProject