import { BrowserRouter as Router, Routes, Route } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";

import BlankPage from "./Components/Blank-Page/BlankPage";
import Dashboard from "./Components/Dashboard/Dashboard";
import AccountManagement from "./Components/Common-Module/Account-Management/AccountManagement";
import MyAccount from "./Components/Common-Module/My-Account/MyAccount";
import Privacy from "./Components/Common-Module/Privacy/Privacy";
import Integrations from "./Components/Common-Module/Integrations/Integrations";
import HelpSupport from "./Components/Common-Module/Help-Support/HelpSupport";
import HelpPost from "./Components/Common-Module/Help-Support/HelpPost";
import ProjectScript from "./Components/Create-Project/ProjectScript";
import ExploreAITools from "./Components/Explore-AI-Tools/ExploreAITools";
import MyCollection from "./Components/My-Collection/MyCollection";
import MyTemplates from "./Components/My-Collection/MyTemplates";
import Portals from "./Components/My-Collection/Portals";
import SalesPage from "./Components/Sales-Page/SalesPage";
import MyTools from "./Components/My-Tools/MyTools";
import { loadUser } from "./Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "./Redux/Actions/AlertActions";
import { useEffect, useState } from "react";
import Alert from "./Components/Common/Alert";
import ForgetPassword from "./Components/Auth/ForgetPassword";
import ResetPassword from "./Components/Auth/ResetPassword"
import PrivateRoute from "./Components/Common/PrivateRoute";
import MasterLogin from "./Components/Auth/MasterLogin";
import Help from "./Components/Common-Module/Help-Support/Help";
import Training from "./Components/Common-Module/Help-Support/Training";
import TrainingArticles from "./Components/Common-Module/Help-Support/TrainingArticles";
import Upgrades from "./Components/Upgrades/Upgrades";
import SiteHero from "./Components/Marketing/SiteHero/SiteHero";
import CopyHero from "./Components/Marketing/CopyHero/CopyHero";
import ContentVideo from "./Components/Marketing/ContentVideo/ContentVideo";
import ContentImage from "./Components/Marketing/ContentImage/ContentImage";
import Agency from "./Components/Agency/Agency";
import UpdateScript from "./Components/Marketing/ContentVideo/UpdateScript";
import Editor from "./Components/Marketing/ContentVideo/Editor/Editor";
import Content from "./Components/Marketing/CopyHero/CreateContent/Content";
import ChooseTemplate from "./Components/Marketing/SiteHero/CreateLanding/ChooseTemplate";
import CustomizeWebsite from "./Components/Marketing/SiteHero/CustomizeWebsite/CustomizeWebsite";
import WebsiteEditor from "./Components/Marketing/SiteHero/WebsiteEditor";
import CreateAiTool from "./Components/CreateAiTools/CreateAiTool";
import ImageEditor from "./Components/Marketing/ContentImage/ImageEditor/ImageEditor";
import ImagePreview from "./Components/Marketing/ContentImage/ImageEditor/ImagePreview";
import SharePreview from "./Components/CreateAiTools/SharePreview";

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const auth = useSelector(state => state.auth)

  const [memberShip, setMemberShip] = useState([])


  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])



  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  useEffect(() => {
    fetchUser()
  }, [])
  return (
    <ThemeProvider>
      <div className="App">
        <Alert />
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/share" element={<SharePreview />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/master-login" element={<MasterLogin />} />
            <Route exact path="/forgot-password" element={<ForgetPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/help-and-support" element={<PrivateRoute><Help /> </PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /> </PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /> </PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /> </PrivateRoute>} />

            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/integrations" element={<PrivateRoute><Integrations /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/help-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/help-post" element={<PrivateRoute><HelpPost /></PrivateRoute>} />

            <Route exact path="/blank-page" element={<PrivateRoute><BlankPage /></PrivateRoute>} />
            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/project-script" element={<PrivateRoute><ProjectScript /></PrivateRoute>} />

            <Route exact path="/explore-ai-tools" element={<PrivateRoute><ExploreAITools /></PrivateRoute>} />
            {/* <Route exact path="/create-new-ai-tool" element={<PrivateRoute><BuilderScreen /></PrivateRoute>} /> */}
            <Route exact path="/create-new-ai-tool" element={<PrivateRoute><CreateAiTool /></PrivateRoute>} />
            <Route exact path="/my-collections" element={<PrivateRoute><MyCollection /></PrivateRoute>} />

            <Route exact path="/my-templates" element={<PrivateRoute><MyTemplates /></PrivateRoute>} />
            <Route exact path="/portals" element={<PrivateRoute><Portals /></PrivateRoute>} />

            <Route exact path="/sales-pages" element={<PrivateRoute><SalesPage /></PrivateRoute>} />

            <Route exact path="/my-tools" element={<PrivateRoute><MyTools /></PrivateRoute>} />

            <Route exact path="/agency" element={<PrivateRoute><Agency /></PrivateRoute>} />

            <Route exact path="/marketing/page-builder" element={<PrivateRoute><SiteHero /></PrivateRoute>} />
            <Route path="/marketing/page-builder/choose-templates" element={<PrivateRoute><ChooseTemplate /> </PrivateRoute>} />
            <Route path="/marketing/page-builder/customize-website" element={<PrivateRoute><CustomizeWebsite /> </PrivateRoute>} />
            <Route path="/marketing/page-builder/website-editor" element={<PrivateRoute><WebsiteEditor /> </PrivateRoute>} />


            <Route exact path="/marketing/sales-copywriter" element={<PrivateRoute><CopyHero /></PrivateRoute>} />
            <Route exact path="/marketing/sales-copywriter/project-content" element={<PrivateRoute><Content /></PrivateRoute>} />
            <Route exact path="/marketing/video-maker" element={<PrivateRoute><ContentVideo /></PrivateRoute>} />


            <Route exact path="/marketing/image-maker" element={<PrivateRoute><ContentImage /></PrivateRoute>} />
            <Route exact path="/marketing/image-editor" element={<PrivateRoute><ImageEditor /></PrivateRoute>} />
            <Route exact path="/marketing/image-preview" element={<PrivateRoute><ImagePreview /> </PrivateRoute>} />

            <Route exact path="/update-script" element={<PrivateRoute><UpdateScript /> </PrivateRoute>} />
            <Route exact path="/editor" element={<PrivateRoute><Editor /> </PrivateRoute>} />

          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
