import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FiUploadCloud } from "react-icons/fi";
import { FiEdit } from 'react-icons/fi';
import { changeProfile } from '../../../Redux/Actions/AuthActions';

const AccountImage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState({
        image: false
    })

    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({ ...loader, image: true })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#000"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected an invalid file type!',
                confirmButtonColor: "#000"
            })
        }
    }

    return (
        <div className='dashboard-block'>
            <h2 className='account-title'>Basic Info</h2>
            <div className='info-img'>
                <img src={auth.user.profile} alt="user-img" width="100px" height="100px" />
                <div className={`${loader.image === false ? 'hide-social' : ''} my-account-loader`}>
                    <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                </div>
                <div className='account-pic-upload'>
                    <FiUploadCloud />
                    <input type="file"
                        onChange={(e) => onImageChange(e)}
                        accept="image/*"
                    />
                </div>
            </div>
            <div className='text-center'><h6 className=''>{auth.user.name}</h6></div>
            <div className='text-center mt-3'><button className='site-link blue'><span>  {
                auth.user ?
                    auth.user.membership.split("__")[(auth.user.membership.split("__").length - 1)]
                    : null
            }</span></button></div>
        </div>
    )
}

export default AccountImage
