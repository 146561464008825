import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router';
import queryString from "query-string";

const TrainingTitles = ({ id, name }) => {
    const location = useLocation()
    const supportData = useSelector(state => state.help);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.article);
    const currentArt = supportData.article.filter((article) => article.id === id);
    return (
        <div className='account-left'>

            {/* <div className="mb-2 support-data text-white">
                <Link to="/help-and-support" className="support-link">Support </Link>
                /
                <Link className={`${name ? '' : 'active-help'} support-link`} to={`training?id=${currentArt[0].id}`}> {currentArt[0].name} </Link>
                {
                    name ? <> / <span className="active-help" style={{ color: " #18bd15" }}>{name.title}</span></> : ''
                }

            </div> */}

            <div className="account-left">
                <h5 className="pb-4">POPULAR TOPICS</h5>

                <div className="account-nav">

                    <ul className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            articles.length > 0 ?
                                articles.map((item, index) => {
                                    return (
                                        <li> <Link key={index} className={`nav-link themelink ${articleId === item.id ? 'active' : ''}`} to={`/training?id=${item.id}`} >
                                            {item.name}
                                        </Link>
                                        </li>
                                    )
                                })
                                : ''
                        }

                    </ul>


                </div>

            </div>
        </div>
    )
}

export default TrainingTitles