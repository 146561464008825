import React, { useEffect, useState } from 'react';
import grapesjs from "grapesjs"
import 'grapesjs/dist/css/grapes.min.css';
import newsletter from 'grapesjs-preset-newsletter';
import gjsblockbasic from 'grapesjs-blocks-basic';
import queryString from "query-string";
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { onDeleteUserImages, onFetchUserImages, onGenerateAiContent, onGenerateAiImage, onLoadMoreImages, onSearchImages, onUploadUserImages } from '../../../Redux/Actions/LandingActions';
import { baseURL, themeColor } from '../../../Global/Global';
import { Trash } from 'iconsax-react';
import CommonAlert from '../../Common/CommonAlert';


const WebsiteEditor = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const liveLink = queryString.parse(location.search).liveLink;
    const productId = queryString.parse(location.search).productId;
    const auth = useSelector(state => state.auth);
    const [uploadedImages, setUploadedImages] = useState([])


    const [editor, setEditor] = useState(null)

    const generateImageModalHtml = (images) => {
        return (`
            <div class="Universal_modal">

                <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-upload-tab" data-bs-toggle="pill" data-bs-target="#pills-upload"
                            type="button" role="tab" aria-controls="pills-upload" aria-selected="true">Upload</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-images-tab" data-bs-toggle="pill" data-bs-target="#pills-images"
                            type="button" role="tab" aria-controls="pills-images" aria-selected="false">Stock images</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-ai-images-tab" data-bs-toggle="pill" data-bs-target="#pills-ai-images"
                            type="button" role="tab" aria-controls="pills-ai-images" aria-selected="false">AI Images</button>
                    </li>
                </ul>


                <div class="tab-content Universal_content_wrap" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-upload" role="tabpanel" aria-labelledby="pills-upload-tab"
                        tabindex="0">
                        <div class="Universal_content_wrap">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="Universal_modal_upload d-flex flex-column gap-2">
                                    <i class="fa-solid fa-upload"></i>
                                        <p>Upload image</p>
                                            <div id="website-builder-upload-image-loader" style="display:none;">   
                                                <i class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px;"></i>
                                            </div>
                                        <input id="website-builder-upload-image" type="file" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 mt-md-0 mt-3">
                                    <div class="Universal_modal_addImage">
                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Enter image URL</label>
                                            <input type="text" id="website-builder-add-url-image" class="site-common-inp" id="exampleFormControlInput1"
                                                placeholder="https://" />
                                        </div>
                                        <div class="col-12 text-end">
                                            <button type="button" id="website-builder-add-url-btn" class="btn site-link " style="margin-top:0"><span> Add
                                                Image</span> <i id="website-builder-add-image-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i> 
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="Universal_content_images">
                                <div class="row g-3" id="website-builder-upload-images-container">                            

            ${images.length > 0 ?
                images.map((img, index) => `
                    <div class="col-xl-3 col-sm-6 col-12" key=${index}>
                    <div class="Universal_url_images">
                    <span id="${img.id}" style="cursor:pointer;right:13px;top:6px;" class="delete-media website-builder-upload-images-delete" style="z-index: 2;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg></span>
                            <img class="asset-manager-image" src="${img.url}" alt="Image ${index}" />
                        </div>
                    </div>
                `).join('')
                : ''
            }                                            
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-images"" role="tabpanel" aria-labelledby="pills-images-tab"
                        tabindex="0">
                        <div class="Universal_content_wrap">
                            <div class="Universal_modal_search-container">
                                <input id="website-builder-search-image-keyword" type="text" class="site-common-inp" placeholder="Search images..." />
                                <button id="website-builder-search-image-btn" class="Universal_modal_search-btn bg-unset">
                                    <i id="website-builder-search-icon" class="fas fa-search"></i>
                                    <i id="website-builder-search-image-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i>
                                </button>
                            </div>
                            <div class="Universal_images_searchContent">
                                <div class="row g-3" id="website-builder-search-image-container">                                                      
                                </div>
                                
                            </div>
                            <div style="width: 100%;display: flex;justify-content: end; margin-top: 20px;">
                            <button type="button" id="website-builder-load-more-btn" class="btn site-link " style="display:none;"> <span>Load
                                                more</span> <i id="website-builder-load-more-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-ai-images" role="tabpanel" aria-labelledby="pills-ai-images-tab"
                        tabindex="0">
                        <div class="Universal_content_wrap pt-0">
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="mb-3"> Describe the image you want</h6>
                                    <div class="ai_describe">
                                      <textarea class="form-control editor-input" id="website-builder-ai-textarea" placeholder="e.g: A rocket launching at sunset." rows="4"></textarea>
                                      
                                        <div class="col-12 text-end mt-3">
                                            <button id="website-builder-ai-btn" type="button" class="btn site-link" style="margin-top:0;"><span>Generate </span>
                                                <i id="website-builder-ai-btn-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12" id="website-builder-generate-ai-img-container" style="height:17.5rem";>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        `)
    };


    const searchImages = (keyword, loader, type, icon) => {
        loader.style.display = "block";
        if (type === "search") {
            let data = {
                keyword: keyword,
                page_number: 1,
                search_type: "images"
            }
            icon.style.display = "none";
            dispatch(onSearchImages(data, loader, editor, icon))
        } else {
            let imageContainer = document.getElementById("website-builder-search-image-container");
            let pageCount = Math.ceil(imageContainer.childElementCount / 20);
            if (imageContainer.childElementCount === 20) {
                pageCount = 2;
            }
            let data = {
                keyword: keyword,
                page_number: pageCount,
                search_type: "images"
            }
            dispatch(onLoadMoreImages(data, loader, editor))
        }
    }

    const fetchUserImages = () => {
        const data = {
            type: "images"
        }
        dispatch(onFetchUserImages(data, setUploadedImages, editor));
    }


    const generateAiContent = (myEditor) => {
        const selected = myEditor.getSelected();
        if (selected) {
            const textOnly = selected.view.el.textContent?.trim()?.replace(/\n/g, "");
            selected.view.el.innerHTML = 'Please wait...';
            let data = {
                text: textOnly
            }
            dispatch(onGenerateAiContent(data, selected));
        }
    }

    const generateAiImage = (key, imageContainer) => {
        const data = {
            keyword: key
        }
        let loaderText = `<span>Generating </span><i id="website-builder-ai-btn-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:block;"></i>`
        document.getElementById("website-builder-ai-btn").innerHTML = loaderText;
        dispatch(onGenerateAiImage(data, imageContainer, editor));
    }


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = (ind) => {
        setSweet({
            ...sweet,
            id: ind,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        const formData = new FormData()
        formData.append("id", sweet.id)
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteUserImages("delete-user-uploaded-file", formData, uploadedImages, setUploadedImages, setSweet, editor))

    }

    const updateUi = () => {
        editor.trigger('asset:update', uploadedImages)
    }

    useEffect(() => {
        fetchUserImages();
    }, [])

    useEffect(() => {
        if (editor) {

            editor.on('asset:addUrlImage', () => {
                const imageModal = editor.Modal.getContentEl();
                let loader = imageModal.querySelector('#website-builder-add-image-loader');
                loader.style.display = "block";

                const imageUrl = imageModal.querySelector('#website-builder-add-url-image').value;
                if (!imageUrl) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Please enter image URL.',
                        confirmButtonColor: themeColor
                    });
                    loader.style.display = "none";
                    return;
                }

                const finalUrl = `${imageUrl}?not-download-from-cache`;
                fetch(finalUrl)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Image could not be fetched');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const formData = new FormData();
                        const file = new File([blob], 'image.png', { type: blob.type });
                        formData.append('upload_type', 'images');
                        formData.append('file', file);
                        dispatch(onUploadUserImages(formData, fetchUserImages, uploadedImages, setUploadedImages, loader));
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `Failed to fetch the image: ${error.message}`,
                            confirmButtonColor: themeColor
                        });
                        loader.style.display = "none";
                    });
            });

            editor.on('asset:upload', (e) => {
                const imageModal = editor.Modal.getContentEl();
                let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
                const formData = new FormData()
                if (e.target.files.length > 0) {
                    if (allowedExt.includes(e.target.files[0].type)) {
                        if (e.target.files[0].size < 5000000) {
                            formData.append('upload_type', "images")
                            formData.append('file', e.target.files[0])
                            let loader = imageModal.querySelector('#website-builder-upload-image-loader')
                            loader.style.display = "block"
                            dispatch(onUploadUserImages(formData, fetchUserImages, uploadedImages, setUploadedImages, loader))
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Max allowed size for images is 5MB!',
                                confirmButtonColor: themeColor
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'You have Selected Invalid File Type!',
                            confirmButtonColor: themeColor
                        })
                    }
                }
            })

            editor.on('asset:generateImage', (imageModal) => {
                let keyword = imageModal.querySelector("#website-builder-ai-textarea").value;
                let imageContainer = imageModal.querySelector("#website-builder-generate-ai-img-container");
                if (keyword) {
                    generateAiImage(keyword, imageContainer);
                }
            })

            editor.on('asset:searchImages', (imageModal) => {
                let keyword = imageModal.querySelector("#website-builder-search-image-keyword").value;
                let loader = imageModal.querySelector('#website-builder-search-image-loader');
                let icon = imageModal.querySelector('#website-builder-search-icon');
                searchImages(keyword, loader, "search", icon);
            })

            editor.on('asset:loadMoreSearch', (imageModal) => {
                let keyword = imageModal.querySelector("#website-builder-search-image-keyword").value;
                let loader = imageModal.querySelector('#website-builder-load-more-loader');
                searchImages(keyword, loader, "loadMore");
            })

            editor.on('asset:open', (asset) => {
                updateUi()
            })

            editor.on('asset:deleteImages', (id) => {
                onDelete(id)
            })

            editor.on('asset:update', (newImageArrays) => {

                editor.Modal.setTitle("Add Images");
                editor.Modal.setContent(generateImageModalHtml(newImageArrays ? newImageArrays : uploadedImages));

                const imageModal = editor.Modal.getContentEl();

                const fileInput = imageModal.querySelector('#website-builder-upload-image');
                fileInput.addEventListener('change', (e) => { editor.trigger('asset:upload', e) });

                const addUrlImage = imageModal.querySelector('#website-builder-add-url-btn');
                addUrlImage.addEventListener('click', () => { editor.trigger('asset:addUrlImage') });

                const searchImageBtn = imageModal.querySelector('#website-builder-search-image-btn');
                searchImageBtn.addEventListener('click', () => { editor.trigger('asset:searchImages', imageModal) });

                const loadMoreBtn = imageModal.querySelector('#website-builder-load-more-btn');
                loadMoreBtn.addEventListener('click', () => { editor.trigger('asset:loadMoreSearch', imageModal) });

                const generateButton = imageModal.querySelector('#website-builder-ai-btn');
                generateButton.addEventListener('click', () => { editor.trigger('asset:generateImage', imageModal) });

                const images = imageModal.querySelectorAll('.asset-manager-image');
                const deleteBtn = imageModal.querySelectorAll('.website-builder-upload-images-delete');
                images.forEach((image) => {
                    image.addEventListener('click', function () {
                        let selectedImg = image.getAttribute("src")
                        editor.trigger('asset:clickImage', selectedImg)
                    });
                });
                deleteBtn.forEach((btn) => {
                    btn.addEventListener('click', function () {
                        let selectedIndex = btn.getAttribute("id")
                        editor.trigger('asset:deleteImages', selectedIndex)
                    });
                });

            })

            editor.on('asset:clickImage', (selectedImg) => {
                const selectedComponent = editor.getSelected();
                selectedComponent.set('src', selectedImg);
                editor.AssetManager.close();
            })

            editor.on('asset:clickSearchImage', (selectedImg) => {
                const selectedComponent = editor.getSelected();
                selectedComponent.set('src', selectedImg);
                editor.AssetManager.close();
            })

            editor.on('asset:clickAiImage', (src) => {
                const selectedComponent = editor.getSelected();
                selectedComponent.set('src', src);
                editor.AssetManager.close();
            })

            return () => {
                editor.off('asset:addUrlImage');
                editor.off('asset:deleteImages');
                editor.off('asset:upload');
                editor.off('asset:open');
                editor.off('asset:update');
                editor.off('asset:clickImage');
                editor.off('asset:generateImage');
                editor.off('asset:clickAiImage');
                editor.off('asset:clickSearchImage');
                editor.off('asset:loadMoreSearch');
                editor.off('asset:searchImages');
            };
        }
    }, [editor, uploadedImages]);

    useEffect(() => {
        const myEditor = grapesjs.init({
            container: '#gjs',
            plugins: [gjsblockbasic, newsletter],

            blockManager: {
                appendTo: '#blocks'
            },
            storageManager: {
                type: 'remote',
                stepsBeforeSave: 1,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                autoload: true,
                storeCss: true,
                options: {
                    remote: {
                        headers: {
                            'Authorization': auth.token
                        },
                        contentTypeJson: true,
                        credentials: 'omit',
                        urlLoad: `${baseURL}fetch-html/${productId}`,
                        urlStore: `${baseURL}/save-html`,
                        onStore: (data) => {
                            const htmlContent = myEditor.getHtml();
                            return { id: productId, htmlData: data, rowHtml: htmlContent }
                        },
                        // onStore: data => ({ id: productId, htmlData: data }),
                    }
                }
            },
        });


        myEditor.on('run:export-template', (data) => {
            const htmlContent = myEditor.getHtml();
            console.log('template', htmlContent)
        });

        fetch(liveLink)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.text();
            })
            .then((data) => {
                myEditor.setComponents(data);
            })
            .catch((error) => {
                console.error('Error loading content:', error);
            });


        // remove buttons
        const viewPanel = myEditor.Panels.getPanel('views');
        viewPanel.buttons.remove("open-blocks");
        const optionsPanel = myEditor.Panels.getPanel('options');
        optionsPanel.buttons.remove("fullscreen");

        myEditor.on('run:fullscreen', () => {
            console.log('fullscreen')
            document.getElementById("blocks").style.display = "none";
            document.querySelector(".gjs-cv-canvas").style.maxWidth = "100%";
        });

        myEditor.RichTextEditor.add('aiText', {
            icon: '<b>AI</b>',
            attributes: { title: 'replace you text with ai' },
            result: () => {
                generateAiContent(myEditor)
            }
        });


        myEditor.on('stop:fullscreen', () => {
            document.getElementById("blocks").style.display = "block";
            document.querySelector(".gjs-cv-canvas").style.maxWidth = "";
        });

        myEditor.on('run:preview', () => {
            console.log('preview')
            document.getElementById("blocks").style.display = "none";
            document.querySelector(".gjs-cv-canvas").style.maxWidth = "100%";
        });
        myEditor.on('stop:preview', () => {
            console.log('preview')
            document.getElementById("blocks").style.display = "block";
            document.querySelector(".gjs-cv-canvas").style.maxWidth = "";
        });

        setEditor(myEditor)
    }, [])

    return (
        <div>
            <div className='alert alert-warning text-center tempAlert'>
                All changes will be auto saved
            </div>

            <div id="gjs">
            </div>
            <div id="blocks" className='left_panel'></div>

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this Image`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </div>
    );
}

export default WebsiteEditor;
