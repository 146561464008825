import React, { useEffect, useState } from 'react'
import { SearchNormal } from 'iconsax-react';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import SidePanel from '../../Common/SidePanel';
import Footer from '../../Common/Footer';
import ImageData from './ImageData/ImageData';
import { IoSearchOutline } from "react-icons/io5";
const ImageCourses = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [selectedCity, setSelectedCity] = useState(null);
    const [totalCourses, setTotalCourses] = useState(0);
    const [mergeVideo, setMergeVideo] = useState([]);
    const [type, setType] = useState({ name: 'Video', code: 1 });
    const [createData, setCreateData] = useState({
        url: "",
        type: "",
        show: false,
        fetch: false
    })
    const [loader, setLoader] = useState({ fetch: true })
    const [campaignData, setCampaignData] = useState({
        name: ""
    })
    const vType = [
        { name: 'Video', code: 1 },
        { name: 'Merged Video', code: 2 }
    ];

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const handleCloseModal = () => {
        setCreateData({
            ...createData,
            show: false,
        })
    }
    const handleChangeType = (val) => {
        setType(val);
    };
    const handleCreate = (type) => {
        if (type === "video") {
            setCreateData({
                ...createData,
                url: "create-video",
                type: "video",
                show: true,
                dimension: campaignData.dimension
            })
        } else if (type === "bonus") {
            setCreateData({
                ...createData,
                url: "create-landing",
                type: "landing page",
                show: true,
            })
        }
        else if (type === "ebook") {
            setCreateData({
                ...createData,
                url: "create-ebook",
                type: "ebook",
                show: true,
            })
        }
        else if (type === "optin") {
            setCreateData({
                ...createData,
                url: "create-optin",
                type: "opt-in",
                show: true,
            })
        }
        else if (type === "blog") {
            setCreateData({
                ...createData,
                url: "create-blog",
                type: "blog",
                show: true,
            })
        }
        else if (type === "banner") {
            setCreateData({
                ...createData,
                url: "create-ads",
                type: "banner",
                show: true,
            })
        }
        else {
            setCreateData({
                ...createData,
                url: "create-ads",
                type: 'image',
                show: true,
            })
        }
    }
    const fetchMergeVideo = () => {
        let data = {}
        dispatch(fetchData("fetch-custom-image-all", data, setMergeVideo, loader, setLoader))
    }

    useEffect(() => {
        if (type.code === 2) {
            fetchMergeVideo();
        }
    }, [type]);
    return (
        <>
            <section className="siteWrap white_label_image_marketing">
                <div className="cont_wrap">
                    <div className="cont_area">
                        <div className="white_label_marketing_wrap">
                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className='title-head mob-margin d-flex justify-content-md-between justify-content-center align-items-center flex-wrap gap-4'>
                                        <div class="projectTitle-left"><h2>ALL IMAGES | <span>{totalCourses} Image{totalCourses >= 2 ? "s" : ""}</span> </h2></div>
                                        <div className='d-flex align-items-center gap-3 mob-flex'>
                                            <div className='tabSearch'>
                                                <input class="custom-search" type="text" placeholder="Search" required="" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                                <span class="inp-icon"><IoSearchOutline fontSize={23} /></span>
                                            </div>
                                            {auth.user.isClientAccount === "1" ? "" : <button className='site-link  mt-0' onClick={() => handleCreate("video")}><span>Create New Image</span></button>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="page-container" style={{ paddingTop: "0" }}>
                            <div className="container-fluid">
                                <ImageData
                                    searchKey={searchKey}
                                    setTotalCourses={setTotalCourses}
                                    handleCreate={handleCreate}
                                    createData={createData}
                                    handleClose={handleCloseModal}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ImageCourses