import React from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

function BlankPage() {
  return (
    <>
      <Header />

        <div className='page-wrap'>
           <div className="side-nav">
             <SidePanel />
           </div>
           <div className="page-container">

           </div>
        </div>

        <Footer />
    </>
  )
}

export default BlankPage