import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { FaRegEdit } from 'react-icons/fa'
import { CiGlobe } from "react-icons/ci";
import { Link } from 'react-router'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { onCheckImageStatus } from '../../../../Redux/Actions/ImageAction'
import { MdOutlineDownload } from "react-icons/md";
import { CgScreen } from "react-icons/cg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import film from '../../../../images/apps-icons.png'
import { MdDelete } from "react-icons/md";
import { baseURL } from '../../../../Global/Global';
import CommonAlert from '../../../Common/CommonAlert';


const ImageRows = ({ curElem, index, videos, setVideos }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })
    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
        bgColor: "#9a15c2"
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id,
            type: "customImage"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, videos, setVideos, setSweet))
    }



    const fetchVideo = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckImageStatus(data, common, setCommon, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }

    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let imageUrl = ""
            let activeIntrval = false
            let activeTrans = false
            let progress = 100
            let bgColor = "#117899"
            imageUrl = curElem.imageUrl
            switch (+curElem.status) {
                case 1:
                    text = "Generating Image..."
                    showProg = true
                    activeIntrval = true
                    bgColor = "#9a15c2"

                    break;
                case 2:
                    text = "Generated Successfully"
                    canEdit = true
                    url = `/marketing/image-editor?id=${curElem.id}`
                    bgColor = "#0f9628"
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }
            setCommon({
                ...common,
                statusText: text,
                status: +curElem.status,
                isEditabel: canEdit,
                isDownloadable: imageUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.imageUrl,
                failedData: curElem.log_file ? JSON.parse(curElem.log_file) : false,
                progress: progress,
                bgColor: bgColor
            })


            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (

        <div className={`videoProject-single ${curElem?.imageSize?.height === 1920 ? "videoProject-single-2" : "videoProject-single-1"}`}>
            {common.failedData && common.statusText === "Generation Failed" ?
                <Tooltip
                    id={`my-tooltip-${curElem.id}`}
                    style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0, zIndex: 9, overflow: "hidden" }}
                    place="bottom"
                    content={common.failedData.errorMessage}
                />
                : ""}
            <div className={` ${curElem?.imageSize?.height === 1920 ? "videoProject-single-top-2" : "videoProject-single-top-1"}`}>
                <div className="video-card-wrapper-1 dashboard-card-bg">
                    {common.thumbUrl ?
                        <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} /> : null}
                    {common.thumbUrl ? "" :
                        <div className="video-card-content">
                            <img className="vidThumb-img  img-fluid" src={film} />
                        </div>
                    }
                    <span className={`qz-tag ${+common.status === 2 ? "active" : ""}`} style={{ background: common.bgColor, zIndex: 5 }} data-tooltip-id={`my-tooltip-${curElem.id}`}>

                        {
                            common.showRendering ?
                                <>{common.status === 12 ? common.progress : <> {common.statusText}</>}</>
                                : common.statusText
                        }
                    </span>
                </div>
                <div className="vidHover">
                    <div><p>{curElem.create}</p></div>
                    <div className="vidiconAll">
                        {common.isEditabel ?
                            <div className="vidHover-single">
                                {auth.user.isClientAccount === "1" ? "" :
                                    <Link to={common.urlToRedirect}>
                                        <span className="vidHover-icon">

                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </span>
                                        <span>Edit</span>
                                    </Link>
                                }
                            </div> : ""}
                        {common.isEditabel ?
                            <div className="vidHover-single">

                                <Link to={`/marketing/image-preview?id=${curElem.id}`}>
                                    <span className="vidHover-icon">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </span>
                                    <span>Preview</span>
                                </Link>

                            </div> : ""}
                        <div className="vidHover-single">
                            {auth.user.isClientAccount === "1" ? "" :
                                <a onClick={() => onDelete(curElem.id)}>
                                    <span className="vidHover-icon"><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                    <span>Delete</span>
                                </a>
                            }
                        </div>
                        {common.isDownloadable ?
                            <>
                                <div className="vidHover-single">
                                    <a href={`${common.isDownloadable}`} target='_blank'>
                                        <span className="vidHover-icon"><i class="fa-solid fa-download"></i></span>
                                        <span>Download</span>
                                    </a>
                                </div>
                                {/* {+auth.user.isClientAccount !== 1 ?
                                    <div className="vidHover-single cursor-pointer">
                                        <a onClick={handlePublish} >
                                            <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                            <span>Publish</span>
                                        </a>
                                    </div> : ""} */}
                            </> : ""}
                    </div>
                </div>

                <CommonAlert
                    show={sweet.enable}
                    message={"Are you sure you want to delete this image?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    alertLoader={sweet.loader}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancel}
                    performDelete={onConfirm}
                    icon={<MdDelete />}
                />
            </div>
            <div className="videoProject-single-bottom">
                <h6 className='single-line'>{curElem.name}</h6>
                {/* <p>Date Created: 12-01-24</p> */}
            </div>
        </div>
    )
}

export default ImageRows