import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchData, onUploadMedia } from '../../Redux/Actions/CommonActions';
import AudioFiles from '../Marketing/ContentVideo/Editor/AudioFiles';


const UploadMusic = ({ type, onSelectAudio, selectedAudio, setSelectedAudio }) => {

    const dispatch = useDispatch()
    let allowedExt = ['audio/mpeg'];
    const [percent, setPercent] = useState(0)
    const [audio, setAudio] = useState([])
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })



    const onInputFile = (e) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    const formData = new FormData()
                    formData.append('upload_type', type)
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchAudios, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for Music File is 5MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }



    const fetchAudios = () => {
        let data = {
            type: type
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setAudio, loader, setLoader))
    }

    useEffect(() => {
        fetchAudios()
    }, [])

    return (
        <div className='row'>
            <div className='col-md-12 mt-4'>
                <div className="tabSearch">
                    <label htmlFor="absInp" className='w-100 position-relative'>
                        <button className="w-100 site-link btn-block mt-0 text-capitalize"><span><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload Music</span></button>
                        <input id='absInp' className="absInp" type="file" accept="audio/mp3" onChange={(e) => onInputFile(e)} />
                    </label>
                </div>
                {
                    loader.upload ?
                        <div className="progress mt-3">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%

                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="innertab-scroll" id="scrollableMediaImage" style={{ height: "100%" }}>
                <div className="row">
                    <div className="mediaList ">
                        <ul>
                            <div className="media-ul-list">
                                {audio.length > 0 ?
                                    audio.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <AudioFiles
                                                    name={curElem.name}
                                                    url={curElem.url}
                                                    type="uploaded"
                                                    fetchAudios={fetchAudios}
                                                    selectedAudio={selectedAudio}
                                                    setSelectedAudio={setSelectedAudio}
                                                    onSelectAudio={onSelectAudio}
                                                    key={index}
                                                    id={curElem.id}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    loader.fetch ?
                                        <div className='fetch-loader-block' style={{ height: "100%", width: "100%", margin:"auto" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div>
                                        : <li className='text-center' style={{margin:"auto" }}>No audio uploaded yet</li>
                                }
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadMusic;
