import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router";
import { onCreate } from "../../../../Redux/Actions/ImageAction";
import Plateform from "../ImageData/Plateform";
import { onFetchPlatforms } from "../../../../Redux/Actions/CommonActions";
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import { useSelector } from "react-redux";

import modalCloseIcon from '../../../../images/modal-close.png';
const CreateModal = ({ show, handleClose, fetchData1 }) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const agency = useSelector(state => state.agency.activeAgency.id)
  const navigate = useNavigate();
  const [platform, setPlatform] = useState(false)
  const [tool, setTool] = useState([]);
  const [customActive, setCustomActive] = useState(false)
  const [createData, setCreateData] = useState({
    name: "",
    keyword: "",
    dimension: "16x9",
  });
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (value === "custom") {
      setCustomActive(true)
    }
    if (name === "keyword") {
      if (customActive) {
        setCustomActive(false)
      }
    }
    if (name === "dimension") {
      value = value === "1920x1080" ? "16x9" : "9x16"
    }
    setCreateData({
      ...createData,
      [name]: value,
    });
  };
  const fetchPlatform = () => {
    dispatch(onFetchPlatforms(setPlatform))
  }
  // console.log(createData)
  const handleSubmit = (e) => {
    e.preventDefault();
    const [ratioWidth, ratioHeight] = createData.dimension.split('x').map(Number);
    const width = ratioWidth === 16 ? 1920 : 1080;
    const height = ratioHeight === 16 ? 1920 : 1080;

    let data = {
      name: createData.name,
      keyword: createData.keyword,
      imageSize: {
        width: Math.round(width),
        height: Math.round(height)
      },
      agencyId: agency,
      toolId: 0,
    };
    let obj = { ...data }
    if (obj.keyword === "custom") {
      obj.keyword = createData.custom
    }
    // delete obj.custom
    let url = "create-canvas";
    setLoader(true)
    dispatch(onCreate(url, obj, setLoader, handleClose, fetchData1, navigate));
  };



  const fetchTool = () => {
    let data = {
      agencyId: agency
    }
    dispatch(fetchData("fetch-all-user-tools", data, setTool));
  };

  useEffect(() => {
    if (agency) {
      fetchTool()
    }
  }, [agency])


  useEffect(() => {
    fetchPlatform()
  }, [])

      useEffect(() => {
          if (tool.length > 0) {
              setCreateData({
                  ...createData,
                  keyword: tool[0]?.name + " " + tool[0]?.description
              })
          }
      }, [tool.length]);

  return (
    <Modal
      size="lg"
      className="fade VideoModal lg-modal modal project_modal"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Body >
        <div className="modalClose" onClick={handleClose}>
          <img src={modalCloseIcon} alt="" />
        </div>
        <h3 className="text-capitalize pt-3">Create Image</h3>
        <form className="row" onSubmit={handleSubmit}>
          <div className="common-input-wrap col-12">
            <label htmlFor="" className="text-capitalize">
              Image Name
            </label>
            <input
              className="input"
              type="text"
              placeholder={`Enter Image Name Here`}
              name="name"
              onChange={handleChange}
              value={createData?.name}
              required
            />
          </div>
          <div className="common-input-wrap col-12">
            <label htmlFor="" className="text-capitalize">
              Keyword
            </label>
            {/* <input
              className="input"
              type="text"
              placeholder={`Enter Keyword Here`}
              name="keyword"
              onChange={handleChange}
              value={createData?.keyword}
              required
            /> */}
            <select
              class="form-select"
              aria-label="Default select example"
              className='input'
              name='keyword'
              required
              onChange={handleChange}
              value={createData.keyword}
            >
              {tool.filter((cur)=>cur.name!=="").map((t) => (
                <option key={t?.id} value={t?.name + " " + t?.description}>
                  {t.name}
                </option>
              ))}
              {tool.length > 0 ? "" : <option value="">Select Keyword</option>}
              <option value={"custom"}>Create Custom</option>
            </select>
            {customActive ?
              <input
                className='input mt-3'
                placeholder='Enter keyword name'
                type="text"
                name='custom'
                value={createData.custom}
                required
                maxLength={30}
                onChange={handleChange}
              /> : ""
            }
          </div>
          <div className='common-input-wrap  col-12'>
            <label htmlFor="">Platform</label>
            <select
              name="dimension"
              className="input"
              onChange={handleChange}
              style={{ textTransform: "capitalize" }}
            >
              <option value={"1920x1080"}>{"Standard YouTube Videos"}</option>
              {platform ?
                <>
                  <Plateform
                    data={platform?.Facebook}
                  />
                  <Plateform
                    data={platform?.Instagram}
                  />
                  <Plateform
                    data={platform?.LinkedIn}
                  />
                  <Plateform
                    data={platform?.Pinterest}
                  />
                  <Plateform
                    data={platform?.Snapchat}
                  />
                  <Plateform
                    data={platform?.TikTok}
                  />
                  <Plateform
                    data={platform?.Twitter}
                  />
                  <Plateform
                    data={platform?.YouTube}
                  />
                </>

                : ""}
            </select>
          </div>
          <div className="common-input-wrap  text-end">
            <button className="site-link mt-2" type="submit">
              <span>
                {loader ? (
                  <>
                    Creating <i className="fa fa-spinner fa-spin" />{" "}
                  </>
                ) : (
                  "Create"
                )}
              </span>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
