import { useState } from 'react';
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router';

import { LuTrash2 } from "react-icons/lu";
import { BsStars } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";

import Modal from 'react-bootstrap/Modal';

import { FiPlus } from "react-icons/fi";

function Portals() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />

        <div className='page-wrap'>
           <div className="side-nav">
             <SidePanel />
           </div>
           <div className="page-container">
                <div className="pageTitle">
                    <div className='pageTitle-left'>
                        <h4>Portals</h4>
                        <p>List of your portals where you can sell tools on your custom domain.</p>
                    </div>
                    <div className='pageTitle-right'>
                        <Link className='site-link white' to="/sales-pages"><span><FiPlus /> Create New Portal</span></Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle pb-0'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Free AI-Powered SEO tools</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>

                            <div className='portal-meta'>
                                <div className='portal-meta-single'>
                                    <h6>$504</h6>
                                    <p>Revenue</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>64</h6>
                                    <p>Active Users</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>5</h6>
                                    <p>Collections</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle pb-0'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Free AI-Powered SEO tools</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>

                            <div className='portal-meta'>
                                <div className='portal-meta-single'>
                                    <h6>$504</h6>
                                    <p>Revenue</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>64</h6>
                                    <p>Active Users</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>5</h6>
                                    <p>Collections</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mt-4">
                        <div className='tempSingle pb-0'>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div><img src={require("../../images/monitor-large.svg").default} alt="" /></div>
                                <div>
                                <Dropdown className="drop-style-1">
                                    <Dropdown.Toggle variant="" className="user-btn link" id="dropdown-basic">
                                       <div className='actionBtn'>Action <MdKeyboardArrowDown /></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to=""><FiEdit /> Option - 1</NavLink> 
                                        <NavLink to=""><MdOutlineContentCopy /> Option - 2</NavLink>
                                        <NavLink to=""><LuTrash2 /> Option - 3</NavLink>
                                        <NavLink to=""><BsStars /> Option - 4</NavLink>
                                        <NavLink to=""><IoGridOutline /> Option - 5</NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                            <h5>Free AI-Powered SEO tools</h5>
                            <p className='pt-2'>Influence Finder Uses AI Technology to generate a list of potential influencers in any given niche Influence Finder Uses AI Technology to generate</p>

                            <div className='portal-meta'>
                                <div className='portal-meta-single'>
                                    <h6>$504</h6>
                                    <p>Revenue</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>64</h6>
                                    <p>Active Users</p>
                                </div>
                                <div className='portal-meta-single'>
                                    <h6>5</h6>
                                    <p>Collections</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>

        <Footer />
    </>
  )
}

export default Portals