import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { onCraeteCollection, onEditCollection, onFetchTools } from '../../Redux/Actions/MyCollection';
import { useSelector } from 'react-redux';
import appIcon from "../../images/appicon.jpg"
import { FaSpinner } from 'react-icons/fa';

const EditCollectionModel = ({ show, handleClose, onfetchAll, editData }) => {
    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [createData, setCreateData] = useState({
        "id": "",
        name: "",
        description: "",
        agencyId: agencyId,
        toolIds: []
    })
    const [modelCollection, setModelCollection] = useState([])
    const dispatch = useDispatch()
    const onfetchAllModel = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "agencyId": agencyId
        }
        dispatch(onFetchTools(formData, setModelCollection, loader, setLoader))
    }
    useEffect(() => {
        if (agencyId) {
            onfetchAllModel()
        }
    }, [agencyId])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateData({ ...createData, [name]: value })
    }
    const handleTool = (curElem) => {

        const isPresent = createData.toolIds.some((item) => +item.userToolId === +curElem.id);

        if (isPresent) {

            setCreateData((prevData) => ({
                ...prevData,
                toolIds: prevData.toolIds.filter((item) => +item.userToolId !== +curElem.id),
            }));
        } else {

            setCreateData((prevData) => ({
                ...prevData,
                toolIds: [...prevData.toolIds, curElem],
            }));
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onEditCollection(createData, handleClose, loader, setLoader, onfetchAll))

    }

    useEffect(() => {
        setCreateData({
            "id": editData.id,
            name: editData.name,
            description: editData.description,
            agencyId: agencyId,
            toolIds: editData.userTools || []
        })
    }, [editData])

    return (
        <Modal className='VideoModal' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='p-2'>
                    <h4>Edit Collection</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-6 mt-3">
                                <label className='label' htmlFor="">Name</label>
                                <input className='input fill small' type="text" placeholder='Collection name' name="name" value={createData.name} onChange={handleChange} required />
                            </div>
                            <div className="col-6 mt-3">
                                <label className='label' htmlFor="">Description</label>
                                <input className='input fill small' type="text" placeholder='short description of the collection' name="description" value={createData.description} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className='mt-3' style={{ height: "calc(100vh - 25rem)", overflowX: "hidden", overflowY: "auto", scrollbarWidth: "thin" }}>
                            <div className='row pt-4 px-12'>
                                {loader.fetch ? <div className="col-12 mt-4">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : modelCollection.length > 0 ? modelCollection.map((curElem, index) => {
                                    return (
                                        <div className='col-lg-6' key={index} onClick={() => handleTool(curElem)}>
                                            <div className={`temp sm ${createData.toolIds.map(val => val.userToolId || val.id).includes(curElem.id) ? "active" : ""}`}>
                                                <div className='temp-img'><img src={curElem.icon ? curElem.icon : appIcon} alt="" /></div>
                                                <div className='temp-txt'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <h6>{curElem.name}</h6>
                                                        <img src={require("../../images/badge.svg").default} alt="" />
                                                    </div>
                                                    <p>{curElem.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <div className='d-flex align-items-center justify-content-center'> You do not have collection</div>}

                            </div>
                        </div>
                        <div className='text-center pt-4 d-flex align-items-center justify-content-end gap-2'>
                            <button className='site-link grey' type="button" onClick={handleClose}><span> Cancel</span></button>
                            <button className='site-link' type="submit" ><span> {loader.create ? <>Updating <FaSpinner className="fa-spin" /></> : "Update"}</span></button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default EditCollectionModel
