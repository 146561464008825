import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { MdChevronLeft } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { onGenerateToolFromAI } from '../../../Redux/Actions/CreateToolActions'
import { FaSpinner } from 'react-icons/fa'

const GenerateAiModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const createTool = useSelector(state => state.createTool)
    const activeAgency = useSelector(state => state.agency.activeAgency)
    const [loader, setLoader] = useState({
        create: false
    })
    const [state, setState] = useState({
        text: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let obj = {
            ...state,
        }
        obj.agencyId = activeAgency.id
        obj.toolId = createTool.data.id
        dispatch(onGenerateToolFromAI(obj, handleClose, loader, setLoader))
    }
    return (

        <Modal className='VideoModal md-modal' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <form className='p-4' onSubmit={handleSubmit}>
                    <h4 className='text-center'>Generate Tool with AI</h4>
                    <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>Describe what data you want to collect, what the tool <br /> should do and let AI build it for you.</h6>

                    <div className='pt-5'>
                        <label className='label' htmlFor="">Describe a tool you want to build</label>
                        <textarea
                            style={{ height: 150 }}
                            className='input fill'
                            name="text"
                            placeholder='I want to create a meal plan generator ...'
                            value={state.text}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='text-center pt-4 d-flex align-items-center justify-content-center gap-2'>
                        <button className='site-link grey' type='button' onClick={handleClose}><span><MdChevronLeft /> Back</span></button>
                        <button className='site-link' type='submit'><span> {loader.create ? <>Generating <FaSpinner className='fa-spin' /></> : "Generate Tool"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default GenerateAiModal