import React, { useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Upload from '../../../../../Components/Common/Upload';
import Library from '../../../../../Components/Common/Library';
import AiImageGenerate from '../../../../../Components/Common/AiImageGenerate';
import { addMediaLayer, updateMediaLayer } from '../../../../../Redux/Actions/VideoActions';
// import UploadComponent from '../../uploadFiles/UploadComponent';


const Media = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const selectedSlide = campaign?.slides?.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign?.slides?.findIndex(({ isSelected }) => isSelected === "1")
    const selectedLayer = selectedSlide?.layers?.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide?.layers?.findIndex(({ isSelected }) => isSelected === true)

    const [checkMedia, setCheckMedia] = useState(false)

    const handleBackground = (val, type) => {
        if (selectedLayerIndex !== -1) {
            if (selectedLayer.type === "image" || selectedLayer.type === "video") {
                if (type === "images") {
                    dispatch(updateMediaLayer(val, selectedSlideIndex, selectedLayerIndex, "image"))
                } else if (type === "video") {
                    dispatch(updateMediaLayer(val, selectedSlideIndex, selectedLayerIndex, "video"))
                } else if (type === "ui") {
                    let data = {
                        url: val
                    }
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "image"))
                }

            } else {
                if (type === "images") {
                    dispatch(addMediaLayer(val, selectedSlideIndex, "image", campaign.dimension))
                } else if (type === "video") {
                    dispatch(addMediaLayer(val, selectedSlideIndex, "video", campaign.dimension))
                } else if (type === "ui") {
                    let data = {
                        url: val
                    }
                    dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
                }
            }

        } else {
            if (type === "images") {
                dispatch(addMediaLayer(val, selectedSlideIndex, "image", campaign.dimension))
            } else if (type === "video") {
                dispatch(addMediaLayer(val, selectedSlideIndex, "video", campaign.dimension))
            } else if (type === "ui") {
                let data = {
                    url: val
                }
                dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
            }
        }
    }


    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="video-tab">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="aiImage-tab">AI Image</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <Library
                                handleBackground={handleBackground}
                                sType="images"
                                checkMedia={checkMedia}
                                setCheckMedia={setCheckMedia}
                            />

                        </Tab.Pane>
                        <Tab.Pane eventKey="video-tab">
                            <Library
                                handleBackground={handleBackground}
                                sType="video"
                                checkMedia={checkMedia}
                                setCheckMedia={setCheckMedia}
                            />

                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">
                            <Upload
                                handleBackground={handleBackground}
                                checkMedia={checkMedia}
                                setCheckMedia={setCheckMedia}
                            />

                        </Tab.Pane>

                        <Tab.Pane eventKey="aiImage-tab">
                            <AiImageGenerate
                                handleBackground={handleBackground}
                                checkMedia={checkMedia}
                                setCheckMedia={setCheckMedia}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};



export default Media;
