import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string"
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { GiCheckMark } from "react-icons/gi"
import { BsArrowRight } from "react-icons/bs";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { onUpdateTemplate } from "../../../../Redux/Actions/LandingActions";
import { themeColor } from "../../../../Global/Global";
import TemplatePreview from "./TemplatePreview";
import { AiOutlineEye } from "react-icons/ai";
import TitleBar from "../../../Common/TitleBar";
import Header from "../../../Common/Header";
import SidePanel from "../../../Common/SidePanel";
import Footer from "../../../Common/Footer";

const ChooseTemplate = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = queryString.parse(location.search)
    const [templates, setTemplates] = useState([])
    const [loader, setLoader] = useState({
        fetch: true,
        next: false
    })

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = (img) => {
        setShow(true)
        setState({
            ...state,
            image: img
        })
    }

    const [state, setState] = useState({
        templateId: "",
        image: ""
    })

    const handleSelect = (id, image) => {
        setState({
            ...state,
            templateId: id,
            image: image
        })
    }

    const handleSubmit = () => {
        if (+state.templateId === 0) {
            dispatch(setAlert("Please select a template to continue!", "danger"))
            return
        }

        let data = {
            ...state,
            id: id,
            method: "generate"
        }

        setLoader({
            ...loader,
            next: true
        })
        dispatch(onUpdateTemplate(data, navigate, loader, setLoader))
    }

    const fetchTemplates = () => {
        dispatch(fetchData("fetch-templates", {}, setTemplates, loader, setLoader))
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    return (
        <>
            <TitleBar title="Landing Page" />
            <Header />
            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>

                <div className="page-container pt-0 pe-0 site-hero-wrap">
                    <div className="cont_wrap">

                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle w-100">
                                    <div className="col-lg-6">
                                        <h4>Select a template</h4>
                                    </div>
                                    <div className="col-auto">
                                        <div className="projectTitle-right">
                                            <button class="site-link" onClick={handleSubmit}><span>{loader.next ? <> Next <i className="fa fa-spinner fa-spin mr-2" /></> : <> Next <BsArrowRight /></>}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid">


                                <div className="tempList m-4">

                                    <div className={`${state.dimension === "9x16" ? " col-lg-8 col-10" : ""}`} style={{ margin: "0 auto" }}>
                                        <ul>
                                            {templates.length > 0 ?
                                                templates.map((curElem, index) => {
                                                    return (
                                                        <li className="temp-prev" key={index}>
                                                            <div className="tempList-Single">
                                                                <div className={`${state.dimension === "9x16" ? "template-single_dynmic" : "template-single"}`} style={{ cursor: "pointer" }}>
                                                                    <div className={`${state.dimension === "16x9" ? "template-img_16X9" : "template-img"}`} style={{ position: "relative" }}>
                                                                        <img src={curElem.preview_image} alt='image' />
                                                                        <div className="message-image-opt">
                                                                            <button class="site-link small m-0" onClick={() => handleSelect(curElem.id, curElem.preview_image)}><span>Select</span></button>
                                                                            <div className="embed-user-icon" onClick={() => handleShow(curElem.preview_image)}><AiOutlineEye /></div>
                                                                        </div>
                                                                        {+curElem.id === +state.templateId ?
                                                                            <div className='select-tile-style' style={{ borderRadius: 11, zIndex: "unset" }}>
                                                                                <GiCheckMark style={{ zIndex: 4, position: "relative" }} />
                                                                            </div> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })

                                                :
                                                <div className='text-center mt-3 d-flex align-items-center justify-content-center' style={{ height: "63vh", width: "100%" }}>
                                                    {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 40 }} /> : ""}
                                                </div>
                                            }

                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <TemplatePreview
                show={show}
                handleClose={handleClose}
                image={state.image}
            />

            <Footer />

        </>
    )

}

export default ChooseTemplate
