import { React, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'

import AccountNav from '../Account-Nav/AccountNav'
import { FiUploadCloud } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa6";

import { IoSearchOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router';

function HelpSupport() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right large'>

                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Help and Support</h2>
                                    <p>You can find answers and get direct support here.</p>
                                </div>
                                <div className='titleBar-right'>
                                    <a target="_blank" className="site-link blue mt-4" href="https://support.vineasx.com/"><span><FaRegEnvelope /> Email Support</span></a>
                                    {/* <button className='site-link blue'><span><FaRegEnvelope /> Email Support</span></button> */}
                                </div>
                            </div>

                            <div className='input-wrap mt-4'>
                                <div className='input-with-icon'>
                                    <input className='input' type="text" placeholder='Search' />
                                </div>
                            </div>

                            <div className='support-list'>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Account Info Privacy</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Create Custom Agent/Staff</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Integrations / WhiteLabel</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Manage Team Member</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Add Departments & Project Knowledge Base</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Setting Up & Managing Company Profile</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Manage Hire/Fire Staff</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3'>
                                        <Link to="/help-post">
                                            <div className='support-single'>
                                                <div className='support-icon'><img src="https://backend-centralized.aisuites.io/assets/tutorial/category/1727432292.png" alt="" /></div>
                                                <p>Video Training</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className='titleBar pt-5'>
                                <div className='titleBar-left'>
                                    <h2>Popular Video Tutorial</h2>
                                </div>
                            </div>


                            <div className='support-vid'> 
                                <div className='row'>
                                    <div className='col-sm-6 mt-4'>
                                        <div className='support-vid-single' onClick={handleShow}>
                                            <div className='support-video-thumb'><img src="https://app.aioffices.io/static/media/appthumb.22595d9c7054825960aa.png" alt="" /></div>
                                            <div className='support-txt'>START HERE: Setting up and Managing Company Profile</div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mt-4'>
                                        <div className='support-vid-single' onClick={handleShow}>
                                            <div className='support-video-thumb'><img src="https://app.aioffices.io/static/media/appthumb.22595d9c7054825960aa.png" alt="" /></div>
                                            <div className='support-txt'>START HERE: Setting up and Managing Company Profile</div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mt-4'>
                                        <div className='support-vid-single' onClick={handleShow}>
                                            <div className='support-video-thumb'><img src="https://app.aioffices.io/static/media/appthumb.22595d9c7054825960aa.png" alt="" /></div>
                                            <div className='support-txt'>START HERE: Setting up and Managing Company Profile</div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mt-4'>
                                        <div className='support-vid-single' onClick={handleShow}>
                                            <div className='support-video-thumb'><img src="https://app.aioffices.io/static/media/appthumb.22595d9c7054825960aa.png" alt="" /></div>
                                            <div className='support-txt'>START HERE: Setting up and Managing Company Profile</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='dashboard-block text-center mt-5'>
                                <h5>Can't Find Your Answer? We're Here for You!</h5>
                                <p className='pt-3'>It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or
                                    documentation. Don't worry - we've got your back!</p>
                                <button className='site-link blue mt-4'><span>Contact Support</span></button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <Footer />


            <Modal className='VideoModal' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='vidClose' onClick={handleClose}><IoMdClose /></div>
                    <div className='modalVidWrap'>
                        <div className='modalVid'><iframe src="https://player.vimeo.com/video/1013518139" title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default HelpSupport