import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../../images/modal-close.png';
import { useDispatch } from 'react-redux';
import { onCreateLanding } from '../../../../Redux/Actions/LandingActions';
import { useNavigate } from 'react-router';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';

const CreateLandingModal = ({ show, handleClose }) => {

    const activateAgency = useSelector(state => state.agency.activeAgency)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [customActive, setCustomActive] = useState(false)

    const [loader, setLoader] = useState({
        create: false
    })

    const [state, setState] = useState({
        agencyId: "",
        toolId: "",
        name: "",
        keyword: "",
        language: "English",
        languageCode: "en-US",
        country: "United States",
        style: "controversial"
    })
    const [language, setLanguage] = useState([])
    const [toolData, setToolData] = useState([])
    const [country, setCountry] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target
        if (value === "custom") {
            setCustomActive(true)
        }
        if (name === "keyword") {
            if (customActive) {
                setCustomActive(false)
            }
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            ...state,
            method: "update"
        }
        let count = country.find(({ country_name }) => country_name === state.country)
        let lang = language.find(({ name }) => name === state.language)
        if (count && lang) {
            obj.languageCode = `${lang.code}-${count.code}`
        }

        if (obj.keyword === "custom") {
            obj.keyword = obj.custom
        }
        delete obj.custom

        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateLanding(obj, navigate, loader, setLoader))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    }

    const fetchToolData = () => {
        let data = {
            agencyId: activateAgency.id
        }
        dispatch(fetchData("fetch-all-user-tools", data, setToolData, false, false, false, "shortDataLg"))
    }

    const fetchCountry = () => {
        let data = {}
        dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    }

    useEffect(() => {
        fetchLanguage()
        fetchCountry()
    }, [])

    useEffect(() => {
        if (activateAgency) {
            fetchToolData()
        }
    }, [activateAgency])

    useEffect(() => {
        setState({
            ...state,
            agencyId: activateAgency.id
        })
    }, [activateAgency.id])

        useEffect(() => {
            if (toolData.length > 0) {
                setState({
                    ...state,
                    keyword: toolData[0]?.name + " " + toolData[0]?.description
                })
            }
        }, [toolData.length]);

    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="modal-cross vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec pt-3  p-4" onSubmit={handleSubmit}>
                    <div className='row'>
                        <h5 style={{ marginBottom: "30px" }}>Create Landing Page</h5>
                        <div className="inpField">
                            <h6 className='pb-2'>Enter your product name</h6>
                            <input
                                type="text"
                                className="site-common-inp"
                                name='name'
                                value={state.name}
                                placeholder="Enter your product name"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="inpField">
                            <h6 className='pb-2 mt-3'>Keyword</h6>
                            <select
                                class="form-select"
                                aria-label="Default select example"
                                className='input'
                                name='keyword'
                                onChange={handleChange}
                                required
                                value={state.keyword}
                            >
                                {toolData.filter((cur)=>cur.name!=="").map((t) => (
                                    <option key={t?.id} value={t?.name + " " + t?.description}>
                                        {t.name}
                                    </option>
                                ))}
                                {toolData.length > 0 ? "" : <option value="">Select Keyword</option>}
                                <option value={"custom"}>Create Custom</option>
                            </select>
                            {customActive ?
                                <input
                                    className='input mt-3'
                                    placeholder='Enter keyword name'
                                    type="text"
                                    name='custom'
                                    value={state.custom}
                                    required
                                    maxLength={30}
                                    onChange={handleChange}
                                /> : ""
                            }
                        </div>

                        <div className='col-6 p-3'>
                            <label>Country</label>
                            <select
                                className='site-common-inp'
                                name='country'
                                value={state.country}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select Country</option>
                                {country.length > 0 ?
                                    country.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                        <div className='col-6 p-3'>
                            <label>Language</label>
                            <select
                                className='site-common-inp'
                                name='language'
                                value={state.language}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select language</option>
                                {language.length > 0 ?
                                    language.filter(val => +val.id !== 40).map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                        <div className='col-6 p-3'>
                            <label>Style</label>
                            <select
                                className='site-common-inp'
                                name='style'
                                value={state.style}
                                onChange={handleChange}
                                required
                            >
                                <option value={"controversial"}>Controversial</option>
                                <option value={"engaging"}>Engaging</option>
                                <option value={"fun"}>Fun</option>
                                <option value={"informative"}>Informative</option>
                                <option value={"news"}>News</option>
                                <option value={"promo"}>Promo</option>
                                <option value={"Product review"}>Product review</option>
                                <option value={"VSL"}>VSL</option>
                            </select>
                        </div>

                        {/* <div className='col-6 p-3'>
                            <label>Tool</label>
                            <select
                                className='site-common-inp'
                                name='toolId'
                                value={state.toolId}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select tool</option>
                                {toolData.length > 0 ?
                                    toolData.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.id}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div> */}
                    </div>
                    <div className='text-center'>
                        <button type="submit" className="site-link mt-3 create-modal-btn ms-auto d-flex"><span className='me-2'>Next</span> {loader.create ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateLandingModal