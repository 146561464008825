import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onChangeEmail } from '../../../Redux/Actions/AuthActions';
import { FaSpinner } from 'react-icons/fa';

const PrivacyEmail = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }

    return (
        <div className='dashboard-block'>
            <h2 className='account-title'>Change Email</h2>
            <form onSubmit={handleSubmit}>

                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>Current Email Address</label>
                    <input className='input' type="email"
                        value={auth.user?.email}
                        readOnly
                    />
                </div>

                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>New Email Address</label>
                    <input className='input' type="email" placeholder="Enter Email Address"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>


                <div className='input-wrap text-end mt-4'>
                    <button type="submit" className="site-link blue" disabled={loader}><span>{loader ? <>Updating  <i className="fa fa-spinner fa-spin mr-2" /></> : 'Update'}</span></button>
                </div>
            </form>
        </div>

    )
}

export default PrivacyEmail
