import React from 'react'
import { BsTypeH1, BsTypeH2, BsTextParagraph } from "react-icons/bs";
import { TbSeparator } from "react-icons/tb";
import { MdOutlineTextFields } from "react-icons/md";
import { FiAlignLeft } from "react-icons/fi";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { GoMultiSelect } from "react-icons/go";

import { TbNumber123 } from "react-icons/tb";
import { GoGlobe } from "react-icons/go";
import { BsFiletypePdf } from "react-icons/bs";
import { IoMdCheckboxOutline } from "react-icons/io";
import { IoColorPaletteOutline } from "react-icons/io5";
import { FiEyeOff } from "react-icons/fi";
import { BsImage } from "react-icons/bs";
import { IoArrowBackOutline } from "react-icons/io5";
import DragElements from './DragElements';
import { useSelector } from 'react-redux';
import { editElementIndex } from '../../../Redux/Actions/CreateToolActions';
import { useDispatch } from 'react-redux';
import EditToolFields from './EditToolFields';

const RightBuilder = () => {
    const dispatch = useDispatch()
    const createToolArr = useSelector(state => state.createTool)
    let data = {
        title: {
            "type": "Title",
            "text": "Title"
        },
        subtitle: {
            "type": "Subtitle",
            "text": "Subtitle"
        },
        paragraph: {
            "type": "Paragraph",
            "text": "Paragraph"
        },
        separator: {
            "type": "separator",
        },
        textarea: {
            "type": "textarea",
            "label": "Text Area Field",
            "placeholder": "Enter the text",
            "name": "textarea"
        },
        selectBox: {
            "type": "Dropdown",
            "label": "Select Option",
            "options": [{ text: "One", value: "one" }, { text: "Two", value: "two" }, { text: "Three", value: "three" }],
            "placeholder": "-- please select an option --",
            "name": "select"
        },
        inputField: {
            "type": "text",
            "label": "Text Field",
            "placeholder": "Enter your text",
            "name": "text"
        },
        inputHidden: {
            "type": "hidden",
            "name": "keyword"
        },
        inputColor: {
            "type": "color",
            "label": "Color Picker",
            "name": "color_field"
        },
        inputPdf: {
            "type": "pdf",
            "label": "PDF File",
            "name": "file"
        },
        inputImage: {
            "type": "image",
            "label": "Image Upload",
            "name": "image"
        },

        inputUrl: {
            "type": "url",
            "label": "Website URL",
            "placeholder": "Enter your website URL",
            "name": "web"
        },
        inputCheckbox: {
            "type": "checkbox",
            "label": "Checkbox",
            "placeholder": "I agree to the terms and conditions",
            "name": "checkbox"
        },

        inputEmail: {
            "type": "email",
            "label": "Email Address",
            "placeholder": "Enter your email",
            "name": "email"
        },
        inputNumber: {
            "type": "number",
            "label": "Number Field",
            "placeholder": "0",
            "name": "number_field"
        }
    }


    const editBack = () => {
        dispatch(editElementIndex(false))
    }


    return (
        <div className="builder-right hideScrollBar">

            {createToolArr.editElementIndex !== false ?
                <div>
                    <button className="site-link smaller mt-4" onClick={editBack}><span><IoArrowBackOutline /> Close</span></button>
                    <h6 className="pt-2">Edit Element Properties</h6>
                    {createToolArr.data.forms.length > 0 ?
                        createToolArr.data.forms.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {+createToolArr.editElementIndex === +index ?
                                        <EditToolFields
                                            curElem={curElem}
                                            index={index}
                                        /> : null}
                                </React.Fragment>
                            )
                        }) : ""}

                </div> :

                <>
                    <h6>Layout Elements</h6>
                    <div className="layout-elements">
                        <ul>
                            <DragElements
                                icon={<BsTypeH1 />}
                                fieldName={"Title"}
                                name={"title"}
                                data={data.title}

                            />
                            <DragElements
                                icon={<BsTypeH2 />}
                                fieldName={"Subtitle"}
                                name={"subtitle"}
                                data={data.subtitle}
                            />
                            <DragElements
                                icon={<BsTextParagraph />}
                                fieldName={"Paragraph"}
                                name={"paragraph"}
                                data={data.paragraph}
                            />
                            <DragElements
                                icon={<TbSeparator />}
                                fieldName={"Separator"}
                                name={"separator"}
                                data={data.separator}
                            />
                        </ul>
                    </div>

                    <h6 className="pt-4">Form Elements</h6>
                    <div className="layout-elements">
                        <ul>
                            <DragElements
                                icon={<MdOutlineTextFields />}
                                fieldName={"Text Field"}
                                name={"textField"}
                                data={data.inputField}
                            />
                            <DragElements
                                icon={<FiAlignLeft />}
                                fieldName={<>Textarea <br /> Field</>}
                                name={"textarea"}
                                data={data.textarea}
                            />
                            <DragElements
                                icon={<HiOutlineEnvelope />}
                                fieldName={<>Email <br /> Address</>}
                                name={"emailAddress"}
                                data={data.inputEmail}

                            />
                            <DragElements
                                icon={<GoMultiSelect />}
                                fieldName={"Select Option"}
                                name={"selectOption"}
                                data={data.selectBox}
                            />
                            <DragElements
                                icon={<TbNumber123 />}
                                fieldName={<>Number <br /> Field</>}
                                name={"numberFiled"}
                                data={data.inputNumber}

                            />
                            <DragElements
                                icon={<GoGlobe />}
                                fieldName={"Website URL"}
                                name={"websiteUrl"}
                                data={data.inputUrl}
                            />
                            <DragElements
                                icon={<BsFiletypePdf />}
                                fieldName={"PDF File"}
                                name={"pdfFile"}
                                data={data.inputPdf}
                            />
                            <DragElements
                                icon={<IoMdCheckboxOutline />}
                                fieldName={"Checkbox"}
                                name={"checkbox"}
                                data={data.inputCheckbox}
                            />
                            <DragElements
                                icon={<IoColorPaletteOutline />}
                                fieldName={<>Color <br /> picker</>}
                                name={"colorPicker"}
                                data={data.inputColor}
                            />
                            <DragElements
                                icon={<FiEyeOff />}
                                fieldName={"Hidden Field"}
                                name={"hiddenField"}
                                data={data.inputHidden}
                            />
                            <DragElements
                                icon={<BsImage />}
                                fieldName={"Images"}
                                name={"image"}
                                data={data.inputImage}
                            />
                        </ul>
                    </div>
                </>


            }

        </div>
    )
}

export default RightBuilder