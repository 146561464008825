import React, { useEffect, useState } from 'react'
import { Accordion, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { MdKeyboardArrowDown } from "react-icons/md";
const UpgradeMenu = () => {
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <div className="account-left">

                <div className='account-nav'>

                    <ul className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {memberShip.includes("enterprise") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                            <>
                                <li>
                                    <a className="nav-link" id="v-pills-wlrebranding-tab" data-bs-toggle="pill"
                                        href="#v-pills-wlrebranding" role="tab" aria-controls="v-pills-wlrebranding"
                                        aria-selected="false">Agency Rebranding</a>
                                </li>
                                <li>
                                    <a className="nav-link" id="v-pills-business-tab" data-bs-toggle="pill" href="#v-pills-business"
                                        role="tab" aria-controls="v-pills-business" aria-selected="false">Business Finder</a>
                                </li>

                                <li>
                                    <a className="nav-link" id="v-pills-reseller-tab" data-bs-toggle="pill" href="#v-pills-reseller"
                                        role="tab" aria-controls="v-pills-reseller" aria-selected="false">Reseller License</a>
                                </li>

                            </>
                            : ''}
                        {memberShip.includes("premium") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?

                            <li>
                                <a className="nav-link" id="v-pills-agency-tab" data-bs-toggle="pill" href="#v-pills-agency"
                                    role="tab" aria-controls="v-pills-agency" aria-selected="false">Agency Website</a>
                            </li>
                            : null}

                        {memberShip.includes("dfy") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                            <>
                                <li>
                                    < a className="nav-link" id="v-pills-dfy-course-tab" data-bs-toggle="pill"
                                        href="#v-pills-dfy-course" role="tab" aria-controls="v-pills-dfy-course"
                                        aria-selected="false">DFY Course</a>
                                </li>

                                <li>
                                    <a className="nav-link" id="v-pills-dfyarticles-tab" data-bs-toggle="pill"
                                        href="#v-pills-dfyarticles" role="tab" aria-controls="v-pills-dfyarticles"
                                        aria-selected="false">DFY Articles</a>
                                </li>

                            </> : ""}

                        {/* {memberShip.includes("business") || memberShip.includes("unlimitedlite") || memberShip.includes("unlimited") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                            <>
                                <li>

                                    <a className="nav-link" id="v-pills-udamy-tab" data-bs-toggle="pill" href="#v-pills-udamy"
                                        role="tab" aria-controls="v-pills-udamy" aria-selected="false">Acadeable</a> </li></> : ''
                        } */}


                        {memberShip.includes("unlimited") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                            <>

                                <li><a className="nav-link" id="v-pills-fbtemplate-tab" data-bs-toggle="pill"
                                    href="#v-pills-fbtemplate" role="tab" aria-controls="v-pills-fbtemplate"
                                    aria-selected="true">FB Templates</a></li>


                                <li><a className="nav-link" id="v-pills-webhosting-tab" data-bs-toggle="pill"
                                    href="#v-pills-webhosting" role="tab" aria-controls="v-pills-webhosting"
                                    aria-selected="false">Web Hosting</a></li>

                                <li><a className="nav-link" id="v-pills-client-tab" data-bs-toggle="pill" href="#v-pills-client"
                                    role="tab" aria-controls="v-pills-client" aria-selected="false">Client Contract</a></li>

                                <li><a className="nav-link" id="v-pills-dfylead-tab" data-bs-toggle="pill" href="#v-pills-dfylead"
                                    role="tab" aria-controls="v-pills-dfylead" aria-selected="false">DFY Lead Magnets</a></li>



                            </> : ""}

                        {memberShip.includes('diamond_vip') || memberShip.includes('dfy') ?
                            <>
                                <li>
                                    <a
                                        className="nav-link"
                                        style={{
                                            display: "inline-flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            fontSize: "15px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Master Class <MdKeyboardArrowDown fontSize={23} />
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a className="nav-link" id="v-pills-week-1-tab" data-bs-toggle="pill"
                                        href="#v-pills-week-1" role="tab" aria-controls="v-pills-week-1"
                                        aria-selected="true">Week 1</a>
                                </li>
                                <li class="nav-item">
                                    <a className="nav-link" id="v-pills-week-2-tab" data-bs-toggle="pill"
                                        href="#v-pills-week-2" role="tab" aria-controls="v-pills-week-2"
                                        aria-selected="false">Week 2</a>
                                </li>
                                <li class="nav-item">
                                    <a className="nav-link" id="v-pills-week-3-tab" data-bs-toggle="pill"
                                        href="#v-pills-week-3" role="tab" aria-controls="v-pills-week-3"
                                        aria-selected="false">Week 3</a>
                                </li>
                                <li class="nav-item">
                                    <a className="nav-link" id="v-pills-week-4-tab" data-bs-toggle="pill"
                                        href="#v-pills-week-4" role="tab" aria-controls="v-pills-week-4"
                                        aria-selected="false">Week 4</a>
                                </li>
                            </>
                            : ""}

                        {/* {memberShip.includes('diamond_vip') || memberShip.includes('dfy') ?
                  


                        {/* <a className="nav-link" id="v-pills-vidoeflix-tab" data-bs-toggle="pill" href="#v-pills-vidoeflix"
                      role="tab" aria-controls="v-pills-vidoeflix" aria-selected="false">Video Flix</a> */}

                        {/* <a className="nav-link" id="v-pills-dfycourse-tab" data-bs-toggle="pill" href="#v-pills-dfycourse"
                      role="tab" aria-controls="v-pills-dfycourse" aria-selected="false">DFY Course</a> */}



                        {/* <a className="nav-link" id="v-pills-videocommercial-tab" data-bs-toggle="pill"
          href="#v-pills-videocommercial" role="tab" aria-controls="v-pills-videocommercial"
          aria-selected="false">Video Commercial</a>
       <a className="nav-link" id="v-pills-videopromo-tab" data-bs-toggle="pill"
          href="#v-pills-videopromo" role="tab" aria-controls="v-pills-videopromo"
          aria-selected="false">Video Promos</a>


       <a className="nav-link" id="v-pills-social-tab" data-bs-toggle="pill" href="#v-pills-social"
          role="tab" aria-controls="v-pills-social" aria-selected="false">Social Media Assets</a>

       <a className="nav-link" id="v-pills-affiliate-tab" data-bs-toggle="pill"
          href="#v-pills-affiliate" role="tab" aria-controls="v-pills-afftrack_affiliate"
          aria-selected="false">Afftrack Affiliate Approval</a>
       <a className="nav-link" id="v-pills-youtube-tab" data-bs-toggle="pill"
          href="#v-pills-youtube" role="tab" aria-controls="v-pills-youtube"
          aria-selected="false">YouTube Keyword Research</a>
       <a className="nav-link" id="v-pills-intro-outro-tab" data-bs-toggle="pill"
          href="#v-pills-intro-outro" role="tab" aria-controls="v-pills-intro-outro"
          aria-selected="false">Intro/Outro Creater</a>
       <a className="nav-link" id="v-pills-Social-Traffic-Generator-tab" data-bs-toggle="pill"
          href="#v-pills-Social-Traffic-Generator" role="tab" aria-controls="v-pills-Social-Traffic-Generator"
          aria-selected="false">Social Traffic Generator</a> */}

                    </ul>

                </div>
            </div>

        </>
    )
}

export default UpgradeMenu