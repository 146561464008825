import React from 'react'

function FourthTab() {
  return (
    <>
    <div className='card-wrap h-100'>
      <div className='row align-items-end'>
        <div className="col">
          <label className='label' htmlFor="">Link URL</label>
          <input className='input small fill' type="text" placeholder='https://example.com' />
        </div>
        <div className="col">
          <label className='label' htmlFor="">Link Text</label>
          <input className='input small fill' type="text" placeholder='Click here' />
        </div>
        <div className="col-auto">
          <button className='site-link red'><span>Remove</span></button>
        </div>
      </div>

      <div className='row mt-4 align-items-end'>
        <div className="col">
          <label className='label' htmlFor="">Link URL</label>
          <input className='input small fill' type="text" placeholder='https://example.com' />
        </div>
        <div className="col">
          <label className='label' htmlFor="">Link Text</label>
          <input className='input small fill' type="text" placeholder='Click here' />
        </div>
        <div className="col-auto">
          <button className='site-link red'><span>Remove</span></button>
        </div>
      </div>

      <div className='row mt-4 align-items-end'>
        <div className="col">
          <label className='label' htmlFor="">Link URL</label>
          <input className='input small fill' type="text" placeholder='https://example.com' />
        </div>
        <div className="col">
          <label className='label' htmlFor="">Link Text</label>
          <input className='input small fill' type="text" placeholder='Click here' />
        </div>
        <div className="col-auto">
          <button className='site-link red'><span>Remove</span></button>
        </div>
      </div>

      <div className='row mt-4 align-items-end'>
        <div className="col">
          <label className='label' htmlFor="">Link URL</label>
          <input className='input small fill' type="text" placeholder='https://example.com' />
        </div>
        <div className="col">
          <label className='label' htmlFor="">Link Text</label>
          <input className='input small fill' type="text" placeholder='Click here' />
        </div>
        <div className="col-auto">
          <button className='site-link red'><span>Remove</span></button>
        </div>
      </div>

      <div className='row mt-4 align-items-end'>
        <div className="col">
          <label className='label' htmlFor="">Link URL</label>
          <input className='input small fill' type="text" placeholder='https://example.com' />
        </div>
        <div className="col">
          <label className='label' htmlFor="">Link Text</label>
          <input className='input small fill' type="text" placeholder='Click here' />
        </div>
        <div className="col-auto">
          <button className='site-link red'><span>Remove</span></button>
        </div>
      </div>
    </div>
    </>
  )
}

export default FourthTab