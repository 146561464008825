import React from 'react'

const CustomerSupport = () => {
    return (
        <div className='dashboard-block text-center mt-5'>
            <h5>Can't Find Your Answer? We're Here for You!</h5>
            <p className='pt-3'>It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or
                documentation. Don't worry - we've got your back!</p>
            <a target="_blank" className="site-link blue mt-4" href="https://support.vineasx.com/"><span>Contact Support</span></a>
        </div>
    )
}

export default CustomerSupport