import React from "react";
import Header from "../../Common/Header";
import SidePanel from "../../Common/SidePanel";
import Footer from "../../Common/Footer";
import TitleBar from "../../Common/TitleBar";
import { BsStars } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import VideoData from "./VideoData";
import { IoSearchOutline } from "react-icons/io5";

const ContentVideo = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [totalCourses, setTotalCourses] = useState(0);
  const [mergeVideo, setMergeVideo] = useState([]);
  const [type, setType] = useState({ name: "Video", code: 1 });
  const [createData, setCreateData] = useState({
    url: "",
    type: "",
    show: false,
    fetch: false,
  });
  const [loader, setLoader] = useState({ fetch: true });
  const [campaignData, setCampaignData] = useState({
    name: "",
  });
  const vType = [
    { name: "Video", code: 1 },
    { name: "Merged Video", code: 2 },
  ];

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseModal = () => {
    setCreateData({
      ...createData,
      show: false,
    });
  };
  const handleChangeType = (val) => {
    setType(val);
  };
  const handleCreate = (type) => {
    if (type === "video") {
      setCreateData({
        ...createData,
        url: "create-video",
        type: "video",
        show: true,
        dimension: campaignData.dimension,
      });
    } else if (type === "bonus") {
      setCreateData({
        ...createData,
        url: "create-landing",
        type: "landing page",
        show: true,
      });
    } else if (type === "ebook") {
      setCreateData({
        ...createData,
        url: "create-ebook",
        type: "ebook",
        show: true,
      });
    } else if (type === "optin") {
      setCreateData({
        ...createData,
        url: "create-optin",
        type: "opt-in",
        show: true,
      });
    } else if (type === "blog") {
      setCreateData({
        ...createData,
        url: "create-blog",
        type: "blog",
        show: true,
      });
    } else if (type === "banner") {
      setCreateData({
        ...createData,
        url: "create-ads",
        type: "banner",
        show: true,
      });
    }
  };
  const fetchMergeVideo = () => {
    let data = {};
    dispatch(
      fetchData("fetch-merge-campaign", data, setMergeVideo, loader, setLoader)
    );
  };
  useEffect(() => {
    fetchMergeVideo();
  }, []);

  return (
    <>
      <Header />

      <TitleBar title={"Video Maker"} />
      <div className="page-wrap">
        <div className="side-nav">
          <SidePanel />
        </div>

        <section className="content-video-container w-100">
          <div className="cont-wrap">
            <div className="cont_area">
              <div className="white_label_marketing_wrap">
                <div className="projectTitle-wrap">
                  <div className="projectTitle row">
                    <div className="title-head flex-column flex-md-row d-flex justify-content-md-between justify-content-center align-items-center flex-wrap gap-4">
                      <div class="projectTitle-left">
                        <h2>
                          ALL VIDEOS |{" "}
                          <span>
                            {totalCourses} Video{totalCourses >= 2 ? "s" : ""}
                          </span>{" "}
                        </h2>
                      </div>
                      <div className="d-flex flex-column flex-sm-row align-items-center gap-3">
                        <div className="tabSearch content-video-tabSearch">
                          <input
                            class="custom-search dd"
                            type="text"
                            placeholder="Search"
                            required=""
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                          <span class="inp-icon">
                            <IoSearchOutline fontSize={23} />
                          </span>
                        </div>
                        {auth.user.isClientAccount === "1" ? "" : <button
                          className="site-link mt-0"
                          onClick={() => handleCreate("video")}
                        >
                          <span>
                            <BsStars className="me-2" />
                            Create New Video
                          </span>
                        </button>}
                      </div>
                    </div>
                    {/* <div className="projectTitle-left">
              <h2 className="gradientTxt">
                <span>ALL COURSES | {" "} {totalCourses} Course{totalCourses >= 2 ? "s" : ""}{" "}</span>
              </h2>
            </div> */}
                    {/* 
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Project"
                                                onChange={(e) => setSearchKey(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <div className="select-style-1">
                                            <PrimeDropdown
                                                value={type}
                                                onChange={(e) => handleChangeType(e.value)}
                                                options={vType}
                                                optionLabel='name'
                                                placeholder="Select Video"
                                            />
                                        </div>

                                        {+auth.user.isClientAccount !== 1 ? (
                                            <button className="demoLink" onClick={handleShow}>
                                                <i className="fas fa-plus-circle"></i> Create New Project
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div> */}
                  </div>
                </div>
              </div>

              <div className="page-container">
                <div className="container-fluid">
                  <VideoData
                    searchKey={searchKey}
                    setTotalCourses={setTotalCourses}
                    handleCreate={handleCreate}
                    createData={createData}
                    handleClose={handleCloseModal}
                  />
                  {/* <div className="forproject">
                                {type.code === 1 ?
                                    <VideoData
                                        searchKey={searchKey}
                                        setTotalCourses={setTotalCourses}
                                        handleCreate={handleCreate}
                                        createData={createData}
                                        handleClose={handleCloseModal}
                                    />
                                    :
                                    <div className="row mt-2">
                                        {mergeVideo.length > 0 ? (
                                            mergeVideo.filter((curElem) => {
                                                if (searchKey) {
                                                    return curElem.name.toLowerCase().includes(searchKey.toLowerCase());
                                                } else {
                                                    return curElem;
                                                }
                                            })
                                                .map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <MergeCard
                                                                videos={mergeVideo}
                                                                searchKey={searchKey}
                                                                setVideos={setMergeVideo}
                                                                curElem={curElem}
                                                                key={index}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })
                                        ) : !loader.fetch ?
                                            <div className="no-campaign-card col-md-12">
                                                <div className="no-campaign-card-box text-center text-muted mt-3">
                                                    <h4>
                                                        You do not have any "Merge Course" in your account
                                                    </h4>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-md-12">
                                                <h4 className="text-center load-icon-help">
                                                    <i className="fa fa-spinner fa-spin mr-2" />
                                                </h4>
                                            </div>
                                        }

                                    </div>
                                }
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default ContentVideo;
