import React, { useEffect, useState } from 'react'
import { FaRegCopy, FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onFetchPaymentData, onFetchToolLanguage, onFetchToolVoice, onMakePayment, onPreviewSubmit } from '../../Redux/Actions/CreateToolActions';
import { useLocation } from 'react-router';
import queryString from "query-string";
import { GoArrowLeft } from 'react-icons/go';
import { setAlert } from '../../Redux/Actions/AlertActions';
import LivePreviewElements from './LivePreviewElements';
import styled from 'styled-components';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const LivePreview = ({ userId, previewType }) => {

    const toolData = useSelector(state => state.createTool.data);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [memberShip, setMemberShip] = useState([])

    const location = useLocation()
    const { id } = queryString.parse(location.search);
    const paymentId = queryString.parse(location.search).paymentId
    const [pollyLanguages, setPollyLanguages] = useState([]);
    const [pollyVoices, setPollyVoices] = useState([]);
    const [findArtist, setFindArtist] = useState(false)
    const rebrand = useSelector(state => state.rebrand.data)

    const [CustomDiv, setCustomDiv] = useState(styled.div`{}`)
    const [response, setResponse] = useState({
        isResponse: false
    })
    const [copyState, setCopyState] = useState(false)
    const [state, setState] = useState({
        id: id,
        userId: userId || auth.user.id,
        forms: [...toolData.forms]
    })


    const [loader, setLoader] = useState({
        download: false,
        upload: false,
        submit: false
    })

    const makePayment = () => {
        let data = {
            ...state,
            id: toolData.id,
            userId: toolData.userId
        }
        dispatch(onMakePayment(data, setResponse))
    }

    const handleSubmit = (e, type) => {
        if (type === "form") {
            e.preventDefault();
        }
        setLoader({
            ...loader,
            submit: true
        })

        let name = state.forms.find((val) => val.type === "text" && val.name === "name");
        let email = state.forms.find((val) => val.type === "email" && val.name === "email");
        let data = {
            ...state,
            name: name?.value || "",
            email: email?.value || "",
            type: previewType === "live" ? "public" : "private",
        }

        dispatch(onPreviewSubmit(data, response, setResponse, loader, setLoader, toolData, previewType));
    }

    const handleBack = () => {
        setResponse({
            isResponse: false
        })
        if (toolData.integrations.stripe.enable && toolData.integrations.stripe.stripeId && toolData.integrations.stripe.amount && previewType === "live") {
            makePayment();
        }
    }

    const handleCopy = async () => {
        try {
            let data = response.data.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "").replaceAll(`<pre style="background-color:black;color:white">`, "").replaceAll("</pre>", "").replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("<br>", "\n");
            await navigator.clipboard.writeText(data);
            dispatch(setAlert("Copied successfully", "success"))
        } catch (err) {
            dispatch(setAlert(err, "danger"))
        }
        setCopyState(true)

        setTimeout(() => {
            setCopyState(false)
        }, 5000)
    }

    function convertCssToJson(designData) {

        if (!designData.customCss) {
            designData = {
                ...designData,
                customCss: ""
            }
        }

        const finalCss = {
            'font-family': designData.font.enable ? designData.font.fontFamily : undefined,
            'background-color': designData.backgroundColor,
            'color': designData.textColor,
        };

        const buttonCss = `.desBtn{
                    background-color: ${designData.buttonBackgroundColor};
                color: ${designData.buttonFontColor};
        };`;

        const parseCss = (cssString) => {
            let cssObject = {};
            const blocks = cssString.split('}');
            blocks.forEach(block => {
                block = block.trim();
                if (!block) return;

                const parts = block.includes('{') ? block.split('{') : [null, block];
                const selector = parts[0] ? parts[0].trim() : 'universal';
                const rules = parts[1] ? parts[1].trim() : parts[0].trim();

                cssObject[selector] = cssObject[selector] || {};
                const ruleList = rules.split(';').filter(rule => rule.includes(':'));
                ruleList.forEach(rule => {
                    const [key, value] = rule.split(':');
                    if (key && value) {
                        cssObject[selector][key.trim()] = value.trim();
                    }
                });
            });

            return cssObject;
        };

        try {

            if (!designData?.customCss?.trim()?.startsWith(".") && !designData?.customCss?.trim()?.startsWith("#") && !designData?.customCss?.includes("{")) {
                designData.customCss += `.design-prev-window{${designData.customCss}}`;
            }

            let combinedCssObject = parseCss(buttonCss + designData.customCss);

            let combinedCss = {
                ...finalCss,
                ...combinedCssObject
            };

            Object.keys(combinedCss).forEach(key => {
                if (combinedCss[key] === undefined || (typeof combinedCss[key] === 'object' && Object.keys(combinedCss[key]).length === 0)) {
                    delete combinedCss[key];
                }
            });

            return combinedCss;
        } catch (e) {
            console.error("Error converting CSS to JSON:", e);
            let combinedCssObject = parseCss(buttonCss + designData.customCss);
            let defaultCss = {
                ...finalCss,
                ...combinedCssObject
            };

            return defaultCss;
        }
    }

    useEffect(() => {
        if (toolData.design) {
            setCustomDiv(styled.div`${convertCssToJson(toolData.design)}`)
        }
    }, [toolData.design])

    const handleDownload = async () => {
        setLoader({
            ...loader,
            download: true
        })

        const res = await fetch(response.data, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/octet-stream',
            }
        });
        if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'image.jpg';
            link.click();
            window.URL.revokeObjectURL(url);
            setLoader({
                ...loader,
                download: false
            })
        } else {
            setLoader({
                ...loader,
                download: false
            })
            console.error('Failed to download file:', res.status);
        }
    };


    const fetchPaymentDetails = (myInterval) => {
        let data = {
            paymentId: response.paymentId,
        }
        dispatch(onFetchPaymentData(data, response, setResponse, myInterval));
    }

    const fetchPollyLanguages = () => {
        dispatch(onFetchToolLanguage(setPollyLanguages))
    }

    const fetchPollyArtist = (value) => {
        dispatch(onFetchToolVoice(value, setPollyVoices))
    }

    useEffect(() => {
        let myInterval;
        if (response.payment && response.paymentId) {
            myInterval = setInterval(() => {
                fetchPaymentDetails(myInterval)
            }, 5000)
        }

        return (() => {
            clearInterval(myInterval)
        })
    }, [response.payment && response.paymentId])

    useEffect(() => {
        if (paymentId) {
            window.close();
        }
    }, [paymentId])

    useEffect(() => {
        setState({
            ...state,
            forms: [...toolData.forms]
        })
    }, [toolData.forms])

    useEffect(() => {
        if (pollyLanguages.length > 0 && state.forms.length > 0) {
            let objD = state.forms.find(({ isVoice }) => isVoice === true)
            if (objD) {
                let vId = pollyLanguages.find(({ code }) => code === objD.value)
                if (vId) {
                    fetchPollyArtist(vId.id)
                }
            }
        }
    }, [findArtist, pollyLanguages])

    useEffect(() => {
        if (toolData.integrations.stripe.enable && toolData.integrations.stripe.stripeId && toolData.integrations.stripe.amount && previewType === "live") {
            makePayment();
        }
        fetchPollyLanguages()
    }, [])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])



    return (
        <div className='design-right scrollHeight hideScrollBar'>
            <div className='design-prev'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    {response.isResponse && !response.payment ?
                        <>
                            <h5 className='link' onClick={handleBack} style={{ cursor: "pointer" }}><GoArrowLeft /> Back</h5>
                            {+toolData.toolId === 17 || (response.data.startsWith('https') && /\.(jpg|jpeg|png|gif)$/i.test(response.data)) ?
                                <h5 className='link' onClick={handleCopy} style={{ cursor: "pointer" }}><FaRegCopy /> {copyState ? "Copied URL" : "Copy URL"}</h5>
                                :
                                <h5 className='link' onClick={handleCopy} style={{ cursor: "pointer" }}><FaRegCopy /> {copyState ? "Copied" : "Copy"}</h5>
                            }
                        </>
                        :
                        userId ?
                            ""
                            :
                            <h5>Live Preview</h5>
                    }
                </div>
                {response.isResponse && !response.payment ?
                    <CustomDiv style={{ whiteSpace: 'pre-wrap', margin: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
                        {+toolData.toolId === 17 ?
                            <div className='text-center'>
                                <audio src={response.data} controls></audio>
                            </div>
                            :
                            response.data.startsWith('https') && /\.(jpg|jpeg|png|gif)$/i.test(response.data) ?
                                <div className="d-flex flex-column align-items-center">
                                    <img src={response.data} alt="Descriptive alt text" style={{ maxWidth: '100%' }} />
                                    <button className='desBtn mt-3' type='button' onClick={handleDownload}>
                                        <span>{loader.download ? <>Downloading <FaSpinner className="fa-spin" /> </> : "Download"}</span>
                                    </button>
                                </div>
                                :
                                <p className='p-2'>
                                    <ReactMarkdown children={response.data} rehypePlugins={[rehypeRaw]} />
                                </p>
                        }
                    </CustomDiv>
                    :
                    response.payment ?
                        <CustomDiv className='design-prev-window'>
                            <h5 className='text-center'>{toolData.name}</h5>
                            <p className='text-center pt-1'>{toolData.description}</p>

                            <div className='d-flex align-items-center justify-content-center mt-5'>
                                <FaSpinner className="fa-spin" size={75} />
                            </div>

                            <div className='text-center  mt-5'>
                                <h5 > You have been redirected to Stripe to complete the payment</h5>
                                <p className='mt-1'>Results will display automatically, after the payment.</p>
                            </div>

                            <div className='text-center mt-5'>
                                <h5 className='link' onClick={handleBack} style={{ cursor: "pointer" }}><GoArrowLeft />Back</h5>
                            </div>
                        </CustomDiv>
                        :
                        <form action="" onSubmit={(e) => handleSubmit(e, "form")}>
                            <CustomDiv className='design-prev-window'>
                                <div>
                                    {toolData.icon ?
                                        <div className='text-center mb-3 design-prev-img'><img src={toolData.icon} alt="" /></div>
                                        : ""
                                    }
                                    <h5 className='text-center'>{toolData.name}</h5>
                                    <p className='text-center pt-1'>{toolData.description}</p>
                                    {state.forms.length > 0 ?
                                        state.forms.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <LivePreviewElements
                                                        curElem={curElem}
                                                        index={index}
                                                        setState={setState}
                                                        findArtist={findArtist}
                                                        setFindArtist={setFindArtist}
                                                        pollyLanguages={pollyLanguages}
                                                        pollyVoices={pollyVoices}
                                                        userId={state.userId}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""}
                                    <div className='text-center pt-4'>
                                        {response.paymentUrl && toolData.integrations.stripe.enable && previewType === "live" ?
                                            <a href={response.paymentUrl} target='_blank' className='desBtn' type='submit' onClick={(e) => handleSubmit(e, "payment")}><span>{toolData.design.buttonText} {loader.submit ? <FaSpinner className="fa-spin" /> : ""}</span></a>
                                            :
                                            <button className='desBtn' disabled={previewType === "live" && !response.paymentUrl && toolData.integrations.stripe.enable} type='submit'><span>{toolData.design.buttonText} {loader.submit ? <FaSpinner className="fa-spin" /> : ""}</span></button>
                                        }
                                        {memberShip.includes("dfy") || memberShip.includes("diamond_vip") || memberShip.includes("enterprise") ?
                                            toolData.design.logo.enable ?
                                                <p className='pt-3 d-flex align-items-center justify-content-center gap-2' > App created on  <span span > <img style={{ maxWidth: '8rem' }} src={rebrand ? rebrand?.logo : require("../../images/logo.svg").default} alt="" /></span></p>
                                                : ""

                                            :
                                            toolData.design.logo.enable ?
                                                <p className='pt-3 d-flex align-items-center justify-content-center gap-2'>App created on<span><img style={{ maxWidth: '8rem' }} src={rebrand ? rebrand?.logo : require("../../images/logo.svg").default} alt="" /></span></p>
                                                : ""
                                        }

                                    </div>
                                </div>
                            </CustomDiv>
                        </form>
                }
            </div >
        </div >
    )
}

export default LivePreview
