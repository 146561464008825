import React from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import BenefitRows from './BenefitRows';
import { onAddBenefit, onChangeBenefit } from '../../../../Redux/Actions/ProjectActions';

const BenifitSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.landingPage.data)


    const handleChange = (e, index) => {
        const { name, value } = e.target
        dispatch(onChangeBenefit(name, value, index))
    }

    const addBenefit = () => {
        dispatch(onAddBenefit())
    }

    return (
        <div className='boxStyle'>
            <div className="inpLabelWrap">
                <span className="labelTxt">Features Heading</span>
                <div className="inpLabel">
                    <label>Features Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Features Heading"
                        type="text"
                        name='heading'
                        value={landingData.benefits.heading}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Features Description</span>
                <div className="inpLabel">
                    <label>Features Description</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Features description"
                        type="text"
                        name='description'
                        value={landingData.benefits.description}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="benefit-all pt-4">
                <div className="row">
                    {landingData.benefits.benefits.length > 0 ?
                        landingData.benefits.benefits.map((curElem, index) => {
                            return (
                                <BenefitRows
                                    curElem={curElem}
                                    index={index}
                                    handleChange={handleChange}
                                />
                            )
                        }) : ""}
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button className="site-link m-0" style={{ cursor: 'pointer' }} onClick={addBenefit}>
                    <span>
                        Add More Features
                        <BsFillPlusCircleFill className='ms-2' />
                    </span>
                </button>
            </div>


        </div >
    )
}

export default BenifitSection