import React from 'react';
import appIcon from "../../images/appicon.jpg"
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

const AiToolsCard = ({ curElem, handleShow }) => {
    const activeAgency = useSelector(state => state.agency.activeAgency)
    const auth = useSelector(state => state.auth);

    return (
        <div>
            <div className='temp'>
                <div className='temp-img'><img src={curElem.icon ? curElem.icon : appIcon} alt="" /></div>
                <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h6>{curElem.name}</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>{curElem.description}</p>
                    {!activeAgency ?
                        <Tooltip
                            id={`my-tooltip-use-this${curElem.id}`}
                            style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                            place="top"
                            content={"Please create an agency first to use this tool."}
                        /> : null}
                    {auth.user.isClientAccount === "1" ? "" : <button
                        data-tooltip-id={`my-tooltip-use-this${curElem.id}`}
                        className='site-link alt mt-3'
                        onClick={() => handleShow(curElem)}
                        style={activeAgency ? {} : { background: "gray", cursor: "not-allowed" }}
                        disabled={!activeAgency}
                    >
                        <span>Use this</span>
                    </button>}
                </div>
            </div>
        </div>
    )
}

export default AiToolsCard