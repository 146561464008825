import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import image from "../../images/logo.svg"
import Swal from 'sweetalert2'
import { themeColor } from '../../Global/Global'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData, onUploadMedia } from '../../Redux/Actions/CommonActions'
import { onChangeAgency, onCreateAgency, unmountCreateAgency } from '../../Redux/Actions/AgencyActions'

import { IoClose } from "react-icons/io5";
import { setAlert } from '../../Redux/Actions/AlertActions'

const CreateAgencyModal = ({ show, handleClose, id, fetchAgency }) => {
    const dispatch = useDispatch()
    const createAgency = useSelector(state => state.agency.create)

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        create: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeAgency(name, value))
    }

    const onCompeleteUpload = (url) => {
        dispatch(onChangeAgency("image", url))
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (createAgency.password !== createAgency.confirm_password) {
            dispatch(setAlert("Password or confirm password not same", "danger"))
            return;
        }
        setLoader({
            ...loader,
            create: true
        })
        let url = "create-agency"
        if (id) {
            url = "update-agency"
        }
        dispatch(onCreateAgency(url, createAgency, fetchAgency, handleClose, loader, setLoader))

    }



    useEffect(() => {
        if (!show) {
            dispatch(unmountCreateAgency())
        }
    }, [show])

    return (
        <Modal className='VideoModal md-modal' show={show} onHide={handleClose} centered>
            <Modal.Body className='p-4'>
                <span className='modalClose' onClick={handleClose}><IoClose /></span>
                <form className='p-2' onSubmit={handleSubmit}>
                    <h4 className='text-center'>{id ? "Update" : "Create New"} Agency</h4>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <label className='label' htmlFor="">Name</label>
                            <input
                                className='input fill'
                                type="text"
                                placeholder='Enter Agency Name'
                                name='name'
                                onChange={handleChange}
                                value={createAgency.name}
                                required

                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label className='label' htmlFor="">Description</label>
                            <textarea
                                style={{ height: '8rem' }}
                                className='input fill'
                                type="text"
                                placeholder='Enter Agency Description'
                                name='description'
                                onChange={handleChange}
                                value={createAgency.description}

                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label className='label' htmlFor="">Email</label>
                            <input
                                className='input fill'
                                type="email"
                                placeholder='Enter Email'
                                name='email'
                                onChange={handleChange}
                                value={createAgency.email}
                                required

                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label className='label' htmlFor="">Password</label>
                            <input
                                className='input fill'
                                type="password"
                                placeholder='Enter Password'
                                name='password'
                                onChange={handleChange}
                                value={createAgency.password}
                                required

                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label className='label' htmlFor=""> Confirm Password</label>
                            <input
                                className='input fill'
                                type="password"
                                placeholder='Enter Password'
                                name='confirm_password'
                                onChange={handleChange}
                                value={createAgency.confirm_password}
                                required

                            />
                        </div>

                        <div className="col-12 mt-3">
                            <div className="file-field upload-box d-flex align-items-center justify-content-center">

                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                                        {loader.upload ?
                                            <FaSpinner className="fa-spin" size={25} color={themeColor} />
                                            :
                                            <img alt="" src={createAgency.image} style={{ maxHeight: "100%" }} />
                                        }
                                    </div>
                                    <div>
                                        <h6>Click to Upload Agency Logo</h6>
                                        <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                    </div>
                                </div>
                                {loader.upload ? null :
                                    <input
                                        type="file"
                                        onChange={onInputImage}
                                        accept="image/jpg, image/png, image/jpeg"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='text-center pt-4 d-flex align-items-center justify-content-end gap-2'>
                        <button className='site-link grey' type='button' onClick={handleClose}><span> Cancel</span></button>
                        <button className='site-link' type='submit'><span>{loader.create ? <>{id ? "Updating" : "Creating"} <FaSpinner className="fa-spin" /> </> : id ? "Update Agency" : "Create Agency"}</span></button>
                    </div>
                </form>
            </Modal.Body>


        </Modal>
    )
}

export default CreateAgencyModal