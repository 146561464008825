import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'
import { FiSearch } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';
import TitleBar from '../Common/TitleBar';
import ToolRows from './ToolRows';
import { FaSpinner } from 'react-icons/fa';

const MyTools = () => {
    const dispatch = useDispatch()
    const activeAgency = useSelector(state => state.agency.activeAgency)
    const [q, setQ] = useState("")
    const [a, setA] = useState("ds")
    const [tools, setTools] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })


    const handleShort = (e) => {
        setA(e.target.value)
        let data = [...tools]
        if (e.target.value === "ds") {
            data.sort((a, b) => {
                if (a.created.toLowerCase() < b.created.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })

        } else if (e.target.value === "as") {
            data.sort((a, b) => {
                if (a.created.toLowerCase() > b.created.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
        }
        setTools(data)
    }


    const fetchTools = () => {
        let obj = {
            agencyId: activeAgency.id
        }
        dispatch(fetchData("fetch-all-user-tools", obj, setTools, loader, setLoader))
    }

    useEffect(() => {
        if (activeAgency) {
            setLoader({
                ...loader,
                fetch: true
            })
            fetchTools()
        }
    }, [activeAgency])

    return (
        <>
            <Header />
            <TitleBar title="My Tools" />
            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className="pageTitle">
                        <div className="pageTitle-left">
                            <h4>AI Tools</h4>
                            <p>List of tools you’ve built. You can see stats and manage them here.</p>
                        </div>
                        <div className="pageTitle-right">
                            <div className='d-flex justify-content-end mb-2'>
                                <select
                                    style={{ width: 'auto' }}
                                    className='input sm'
                                    value={a}
                                    onChange={handleShort}
                                >
                                    {/* <option value="">Sort By Date</option> */}
                                    <option value="as">Ascending</option>
                                    <option value="ds">Descending</option>
                                </select>
                            </div>
                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder='Search'
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                />
                                <span><FiSearch /></span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {tools.length > 0 ?
                            tools.filter((curElem) => {
                                return curElem.name.toLowerCase().includes(q.toLowerCase())
                            }).length > 0 ?
                                tools.filter((curElem) => {
                                    return curElem.name.toLowerCase().includes(q.toLowerCase())
                                }).map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ToolRows
                                                curElem={curElem}
                                                tools={tools}
                                                setTools={setTools}
                                                fetchTools={fetchTools}
                                            />
                                        </React.Fragment>
                                    )
                                }) :
                                <div className='col-12 text-center mt-5'>
                                    <h6>No tool available.</h6>
                                </div>
                            :
                            <div className='col-12 text-center mt-5'>
                                {loader.fetch ? <FaSpinner className='fa-spin' size={25} /> :
                                    <h6>You do not have tools created yet!</h6>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MyTools