import React, { useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import film from '../../../images/film.png'
import { MdDelete } from "react-icons/md";
import { onCheckStatus, onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { baseURL } from '../../../Global/Global';
import CommonAlert from '../../Common/CommonAlert';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";

const VideoRows = ({ curElem, index, videos, setVideos, fetchAllVideos }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })
    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
        bgColor: "#FFA500"
    })
    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    // const onConfirm = () => {
    //     let data = {
    //         id: sweet.id
    //     }
    //     setSweet({
    //         ...sweet,
    //         confirmButtonName: "Deleting",
    //         loader: true
    //     })
    //     dispatch(onDeleteCampaign("delete-video", data, videos, setVideos, setSweet))
    // }
    const onConfirm = () => {
        let data = { id: sweet.id, type: "videos" };
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true,
        });

        dispatch(onDeleteCampaign("delete-record", data, videos, setVideos, setSweet));

        if (setVideos) {
            setVideos(prevVideos => prevVideos.filter(video => video.id !== sweet.id));
        }

        if (fetchAllVideos) {
            fetchAllVideos();
        }
    };


    const fetchVideo = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }

    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let activeTrans = false
            let progress = 100
            let bgColor = "#117899"
            videoUrl = curElem.videoUrl
            switch (+curElem.status) {
                case 0:
                    text = "Awaiting Script Generation"
                    activeIntrval = false
                    canEdit = false
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#117899"
                    break;
                case 1:
                    text = "Generating Video..."
                    showProg = true
                    activeIntrval = true
                    bgColor = "#9a15c2"

                    break;
                case 2:
                    text = "Render Successful"
                    canEdit = true
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#0f9628"
                    break;
                case 3:
                    canEdit = true
                    text = "Rendering Failed"
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#a62812"
                    break;
                case 4:
                    canEdit = true
                    text = "Script Generated"
                    // url = "create-script"
                    break;
                case 5:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = `/editor?id=${curElem.id}`
                    progress = curElem.renderProgress
                    bgColor = "#ffa500"
                    break;
                case 6:
                    canEdit = true
                    text = "Awaiting Customization"
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#122bc9"
                    break;
                // case 7:
                //     canEdit = true
                //     text = "Transcribing Failed"
                //     activeIntrval = false
                //     bgColor = "#a62812"
                //     break;
                case 8:
                    canEdit = true
                    text = "Script Generated"
                    url = `/update-script?id=${curElem.id}`

                    bgColor = "#117899"
                    break;
                // case 9:
                //     text = "Transcribing..."
                //     activeTrans = true
                //     bgColor = "#8f840d"
                //     break;

                case 10:
                    text = "Downloading Resources..."
                    canEdit = true
                    activeIntrval = true
                    progress = curElem.renderProgress
                    bgColor = "#8f840d"
                    break;
                case 11:
                    canEdit = true
                    text = "Waiting in Queue..."
                    activeIntrval = true
                    showProg = true
                    url = `/editor?id=${curElem.id}`
                    break;
                case 12:
                    canEdit = true
                    text = curElem.renderProgress
                    activeIntrval = true
                    showProg = true
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#8f840d"
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }
            setCommon({
                ...common,
                statusText: text,
                status: +curElem.status,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnail,
                failedData: curElem.log_file ? JSON.parse(curElem.log_file) : false,
                progress: progress,
                bgColor: bgColor
            })


            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }

            // if (activeTrans) {
            //     const intCheck = setInterval(() => {
            //         checkTranscribe(intCheck)
            //     }, 5000)

            // }
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        // <tr>

        //     <td>
        //         <span className='vid-thumb'><img src={common.thumbUrl ? common.thumbUrl : film} alt="" /></span>
        //     </td>
        //     <td>{curElem.name}</td>
        //     <td>

        //         {common.statusText === "Rendering Failed" ?
        //             <span className='status-box' style={{ background: common.bgColor }} data-tooltip-id={`my-tooltip-${curElem.id}`} >{common.statusText}</span> :
        //             common.statusText === "Rendering" ?
        //                 <span className='status-box' style={{ background: common.bgColor }}>{common.statusText} {common.progress}%</span> :
        //                 <span className='status-box' style={{ background: common.bgColor }}>{common.statusText}</span>
        //         }
        //     </td>
        //     <td>{curElem.created}</td>
        //     <td>

        //         {common.isEditabel ?
        //             <Link className='link-icon' title='Edit' to={common.urlToRedirect}><FaRegEdit /></Link> : null}
        //         {/* <Link className='link-icon' title='Edit' to={`/editor`}><FaRegEdit /></Link> : null} */}
        //         {common.isDownloadable ?
        //             <>
        //                 <a className='link-icon' title='Download' onClick={handleDownload}><MdOutlineDownload /></a>
        //                 {/* <a className='link-icon' title='Publish' onClick={() => setShow(true)}><CiGlobe /></a> */}
        //                 {/* <a className='link-icon' title='OCP' onClick={() => handleOCP(curElem.id)}><CgScreen /></a> */}
        //             </>
        //             : null
        //         }
        //         <a className='link-icon' title='Delete' onClick={() => onDelete(curElem.id)}><BsTrash /></a>
        //     </td>
        //     {common.failedData && common.statusText === "Rendering Failed" ?
        //         <Tooltip
        //             id={`my-tooltip-${curElem.id}`}
        //             style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
        //             place="bottom"
        //             content={common.failedData.errorMessage}
        //         />
        //         : ""}

        //     {/* <PublishModal
        //         curElem={curElem}
        //         show={show}
        //         handleClose={handleClose}
        //     /> */}

        //     <CommonAlert
        //         show={sweet.enable}
        //         message={"Are you sure you want to delete this video?"}
        //         confirmButtonColor={""}
        //         cancelButtonColor={""}
        //         confirmButtonName={sweet.confirmButtonName}
        //         alertLoader={sweet.loader}
        //         cancelButtonName={"Cancel"}
        //         handleClose={onCancel}
        //         performDelete={onConfirm}
        //         icon={<MdDelete />}
        //     />


        // </tr>
        <div className={`videoProject-single m-0 ${curElem.dimension === "9x16" ? "videoProject-single-2" : "videoProject-single-1"}`}>
            {common.failedData && common.statusText === "Rendering Failed" ?
                <Tooltip
                    id={`my-tooltip-${curElem.id}`}
                    style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0, zIndex: 9, overflow: "hidden" }}
                    place="bottom"
                    content={common.failedData.errorMessage}
                />
                : ""}
            <div className={` ${curElem.dimension === "9x16" ? "videoProject-single-top-2" : "videoProject-single-top-1"}`}>
                <div className="video-card-wrapper-1 dashboard-card-bg">
                    {common.thumbUrl ?
                        <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} /> : null}
                    <div className="video-card-content">
                        <img className="mb-3 vidThumb-img  img-fluid" src="  /favicon.png" />
                    </div>

                    <span className={`qz-tag ${+common.status === 2 ? "active" : ""}`} style={{ background: common.bgColor, zIndex: 5 }} data-tooltip-id={`my-tooltip-${curElem.id}`}>

                        {
                            common.showRendering ?
                                <>{common.status === 12 ? common.progress : <>  Rendering {common.progress}%</>}</>
                                : common.statusText
                        }
                    </span>
                </div>
                <div className="vidHover">
                    <div><p>{curElem.create}</p></div>
                    <div className="vidiconAll">
                        {common.isEditabel ?
                            <div className="vidHover-single">
                                {auth.user.isClientAccount === "1" ? "" :
                                    <Link to={common.urlToRedirect}>
                                        <span className="vidHover-icon">

                                            <BiEdit />
                                        </span>
                                        <span>Edit</span>
                                    </Link>
                                }
                            </div> : ""}
                        <div className="vidHover-single">
                            {auth.user.isClientAccount === "1" ? "" :
                                <a onClick={() => onDelete(curElem.id)}>
                                    <span className="vidHover-icon"><RiDeleteBin6Line /></span>
                                    <span>Delete</span>
                                </a>
                            }
                        </div>
                        {common.isDownloadable ?
                            <>
                                <div className="vidHover-single">
                                    <a href={`${common.isDownloadable}`} target='_blank'>
                                        <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                        <span>Download</span>
                                    </a>
                                </div>
                                {/* {+auth.user.isClientAccount !== 1 ?
                                    <div className="vidHover-single cursor-pointer">
                                        <a onClick={handlePublish} >
                                            <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                            <span>Publish</span>
                                        </a>
                                    </div> : ""} */}
                            </> : ""}
                    </div>
                </div>
                <CommonAlert
                    show={sweet.enable}
                    message={"Are you sure you want to delete this video?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    alertLoader={sweet.loader}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancel}
                    performDelete={onConfirm}
                    icon={<MdDelete />}
                />
            </div>
            <div className="videoProject-single-bottom">
                <h6 className='single-line'>{curElem.name}</h6>
                {/* <p>Date Created: 12-01-24</p> */}
            </div>
        </div>
    )
}

export default VideoRows