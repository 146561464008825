import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { themeColor } from '../../Global/Global';
import { onUploadMedia, onUploadMediaPub } from '../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';

const LivePreviewElements = ({ curElem, index, setState, findArtist, setFindArtist, pollyLanguages, pollyVoices, userId }) => {

    const inputFileds = ["text", "color", "pdf", "url", "checkbox", "email", "number", "image"]
    const dispatch = useDispatch();
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        submit: false
    })

    const handleChange = (e) => {
        let { value, checked } = e.target;

        if (curElem.isVoice) {
            setFindArtist(!findArtist)
        }

        if (curElem.isArtist) {
            let artistData = pollyVoices.find(val => +val.id === +value)
            setState(prevState => {
                const updatedForms = [...prevState.forms];
                if (updatedForms[index]) {
                    updatedForms[index] = { ...updatedForms[index], artistData: artistData, value: value };
                }
                return { ...prevState, forms: updatedForms };
            });
        } else {
            setState(prevState => {
                const updatedForms = [...prevState.forms];
                if (updatedForms[index]) {
                    updatedForms[index] = { ...updatedForms[index], value: value };
                }
                return { ...prevState, forms: updatedForms };
            });
        }
    };

    const onCompeleteUpload = (url) => {
        setState(prevState => {
            const updatedForms = [...prevState.forms];
            if (updatedForms[index]) {
                updatedForms[index] = { ...updatedForms[index], value: url };
            }
            return { ...prevState, forms: updatedForms };
        });
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            formData.append("userId", userId)
            
            if (['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    if (['application/pdf'].includes(e.target.files[0].type.toLowerCase())) {
                        formData.append("upload_type", "docx")
                    } else {
                        formData.append("upload_type", "images")
                    }
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMediaPub(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    return (
        <div>
            {curElem.type.toLowerCase() === "title" ?
                <h5 className='text-center pt-3'>{curElem.text}</h5>
                : ""
            }
            {curElem.type.toLowerCase() === "subtitle" ?
                <h6 className='text-center pt-3'>{curElem.text}</h6>
                : ""
            }
            {curElem.type.toLowerCase() === "paragraph" ?
                <p className='text-center'>{curElem.text}</p>
                : ""
            }
            {curElem.type.toLowerCase() === "separator" ?
                <hr className='text-center' style={{ color: "#000" }} />
                : ""
            }
            {inputFileds.some((curVal) => curElem.type.toLowerCase().includes(curVal)) && curElem.type.toLowerCase() !== "textarea" ?
                curElem.type.toLowerCase().includes("image") || curElem.type.toLowerCase().includes("pdf") ?
                    <div className='mt-4'>
                        <label className='label' htmlFor="">{curElem.label}</label>
                        <input
                            className="input small"
                            type={"file"}
                            name={curElem.name}
                            onChange={onInputImage}
                            accept={curElem.type.toLowerCase().includes("image") ? "image/jpeg,image/png,image/webp" : ".pdf"}
                            placeholder={curElem.placeholder}
                            required={curElem.required}
                        />
                    </div>
                    :
                    <div className='mt-4'>
                        <label className='label' htmlFor="">{curElem.label}</label>
                        <input
                            className='input small'
                            type={
                                curElem.type.toLowerCase().includes("text") ? "text" :
                                    curElem.type.toLowerCase().includes("color") ? "color" :
                                        curElem.type.toLowerCase().includes("image") || curElem.type.toLowerCase().includes("pdf") ? "file" :
                                            curElem.type.toLowerCase().includes("url") ? "url" :
                                                curElem.type.toLowerCase().includes("checkbox") ? "checkbox" :
                                                    curElem.type.toLowerCase().includes("email") ? 'email' :
                                                        curElem.type.toLowerCase().includes("number") ? "number" :
                                                            "hidden"
                            }
                            name={curElem.name}
                            value={curElem.value}
                            onChange={handleChange}
                            placeholder={curElem.placeholder}
                            required={curElem.required}
                        />
                    </div>
                : ""
            }
            {curElem.type.toLowerCase() === "select" || curElem.type.toLowerCase() === "dropdown" ?
                <div className='mt-4'>
                    <label className='label' htmlFor="">{curElem.label}</label>
                    <select
                        className="input small"
                        name={curElem.name}
                        onChange={handleChange}
                        value={curElem.value}
                        required={curElem.required}
                    >
                        <option value="">{curElem.placeholder}</option>
                        {curElem.isVoice ?
                            pollyLanguages.length > 0 ?
                                pollyLanguages.map((curD, index) => {
                                    return (
                                        <option value={curD.code} key={index}>{curD.name}</option>
                                    )
                                })
                                : ""
                            : ""}
                        {curElem.isArtist ?
                            pollyVoices.length > 0 ?
                                pollyVoices.map((curD, index) => {
                                    return (
                                        <option value={curD.id} key={index}>{curD.voice_id}({curD.gender}), {curD.type}</option>
                                    )
                                })
                                : ""
                            : ""}
                        {curElem.options && !curElem.isVoice && !curElem.isArtist ?
                            curElem.options.map((op, ind) => {
                                return (
                                    <option value={op.text} key={ind}>{op.text}</option>
                                )
                            })
                            : ""}

                    </select>

                </div>
                : ""
            }
            {curElem.type.toLowerCase() === "textarea" ?
                <div className='mt-4'>
                    <label className='label' htmlFor="">{curElem.label}</label>
                    <textarea
                        className="input"
                        placeholder={curElem.placeholder}
                        style={{ position: "relative" }}
                        name={curElem.name}
                        value={curElem.value}
                        required={curElem.required}
                        onChange={handleChange}
                    />
                </div>
                : ""
            }
        </div>
    )
}

export default LivePreviewElements