import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router'
import { loginUser } from '../../Redux/Actions/AuthActions';
import { appName } from '../../Global/Global'
import TitleBar from '../Common/TitleBar';
import Footer from '../Common/Footer';
import LoginHeader from '../Common/LoginHeader';

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const [view, setView] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoader(true)
    dispatch(loginUser(loginInfo, setLoader))
  }

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard')
    }
  }, [auth])

  return (
    <> <TitleBar title="Login" />

    <div className="login-header"><LoginHeader /></div>

      <div className='login-container'>
        <div className='container'>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="login-left">
                {/* <div className="logo mb-4"><img src={require("../../images/logo.svg").default} alt="" /></div> */}
                <h3>Welcome to <span className='gradient-txt'>{appName}</span></h3>
                <p className='pt-3'>
                  Ready to create and sell your own AI tools? Log in to access a platform that empowers you to build, customize, and white-label cutting-edge AI solutions. Start turning your ideas into profitable tools you can brand as your own and sell to your audience with ease!</p>
              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="login-right">
                  <div className='login-card'>
                    <h2><span className='gradient-txt'> Login </span> to Your Account Now</h2>
                    <div className="input-wrap mt-4">
                      <label className='label' htmlFor="">Email:</label>
                      <input className='input' type="email" name="email" placeholder='Enter Your Email' onChange={(e) => handleChange(e)}
                        required />
                    </div>
                    <div className="input-wrap mt-4">
                      <label className='label' htmlFor="">Password:</label>
                      <input className='input' type="password" name='password' placeholder='Enter Your Password'
                        onChange={(e) => handleChange(e)}
                        required />
                    </div>
                    <div className="input-wrap mt-4">

                      <button type="submit" className="site-link lg full mt-1" disabled={loader}>
                        <span> Sign in to {appName}   {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</span>
                      </button>
                    </div>
                    {/* <p className='text-center pt-4'>Don't have account? <Link className='txt-orange' to="/register">Register</Link></p> */}
                    <p className='text-center pt-2'>Forgot Password? <Link className='txt-orange' to="/forgot-password">Reset Password</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="login-footer"><Footer /></div>

    </>
  )
}

export default Login