import React from 'react'
import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import TitleBar from '../../Common/TitleBar'
import Projects from './Projects'

const CopyHero = () => {
  return (
    <>
      <Header />
      <TitleBar title={"Sales Copywriter"} />
      <div className='page-wrap'>
        <div className="side-nav">
          <SidePanel />
        </div>

        <div className="page-container">
          <Projects />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default CopyHero