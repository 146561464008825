import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai'
import Plateform from './Plateform'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { onCreateVideo } from '../../../Redux/Actions/ProjectActions'
import { fetchData, onFetchPlatforms } from '../../../Redux/Actions/CommonActions'
import { useSelector } from 'react-redux'

const CreateModalAssets = (props) => {
    const allowedType = ["blog", "opt-in", "landing page"]
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const agencyId = useSelector(state => state?.agency?.activeAgency?.id)
    const [tool, setTool] = useState([]);
    const [customActive, setCustomActive] = useState(false)

    const { id } = queryString.parse(location.search)

    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [platform, setPlatform] = useState(false)
    const [mainLoader, setMainLoader] = useState({
        fetch: true
    })
    const [createData, setCreateData] = useState({
        name: "",
        topic: "",
        language: "English",
        country: "United States",
        langCode: "en-US",
        dimension: "16x9",
        style: "engaging",
        cId: props.cid,
        title: ""
    })
    const handleChange = (e) => {
        let { name, value } = e.target
        // if (name === "language") {
        //     const lang = props.language?.find(({ name }) => name === value)
        //     if (+lang.polly_support === 0) {
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Warning...',
        //             text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
        //             confirmButtonColor: "#3f429b"
        //         })
        //     }
        // }

        if (name === "dimension") {
            value = value === "1920x1080" ? "16x9" : "9x16"
        }
        if (value === "custom") {
            setCustomActive(true)
        }
        if (name === "topic") {
            if (customActive) {
                setCustomActive(false)
            }
        }
        setCreateData({
            ...createData,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {}
        obj = { ...createData, agencyId: agencyId, toolId: 0 }
        if (obj.topic === "custom") {
            obj.topic = obj.custom
        }
        delete obj.custom
        let count = country?.find(({ country_name }) => country_name === createData.country)

        let lang = language?.find(({ name }) => name === createData.language)
        obj.langCode = `${lang?.code}-${count?.code}`
        if (props.type !== "video") {
            delete obj.dimension
            delete obj.style
        }
        if (props.type === "ebook") {
            props.handleShowTemp(obj)
            props.handleClose()
        } else {
            setLoader(true)

            dispatch(onCreateVideo("create-video", obj, props.fetchData, props.handleClose, setLoader))
        }
    }


    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchPlatform = () => {
        dispatch(onFetchPlatforms(setPlatform))
    }
    const fetchCountry = () => {
        let data = {}
        dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    }


    const fetchTool = () => {
        let data = {
            agencyId: agencyId
        }
        dispatch(fetchData("fetch-all-user-tools", data, setTool));
    };

    useEffect(() => {
        if (agencyId) {
            fetchTool()
        }
    }, [agencyId])

            useEffect(() => {
                if (tool.length > 0) {
                    setCreateData({
                        ...createData,
                        topic: tool[0]?.name + " " + tool[0]?.description
                    })
                }
            }, [tool.length]);


    useEffect(() => {

        fetchLanguage()
        fetchPlatform()
        fetchCountry()

    }, [])

    useEffect(() => {
        setCreateData({
            ...createData,

            country: "United States",
            language: "English",
            languageCode: "en-US",
            method: "update"
        })
    }, [])
    return (
        <Modal size="lg" className='theme-modal VideoModal xl project_modal' show={props?.show} onHide={props?.handleClose} centered>
            <Modal.Body className='pt-4'>
                <div className='modalClose' onClick={props?.handleClose}><AiOutlineClose /></div>
                <h3 className='mb-4 modal-title h4'>Create Video</h3>
                <div className="col-12">
                    <form className="" onSubmit={handleSubmit}>
                        <div className='common-input-wrap'>
                            <label htmlFor="" className='text-capitalize'>Campaign Name</label>
                            <input
                                className='input'
                                type="text"
                                placeholder={`Enter Campaign Name Here`}
                                name='name'
                                onChange={handleChange}
                                value={createData?.name}
                                required
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <label htmlFor="" className='text-capitalize'>Topic</label>
                            {/* <input
                                className='input'
                                type="text"
                                placeholder={`Enter Topic`}
                                name='topic'
                                onChange={handleChange}
                                value={createData?.topic}
                                required
                            /> */}
                            <select
                                class="form-select"
                                aria-label="Default select example"
                                className='input'
                                name='topic'
                                required
                                onChange={handleChange}
                                value={createData.topic}
                            >
                                {tool.filter((cur)=>cur.name!=="").map((t) => (
                                    <option key={t?.id} value={t?.name + " " + t?.description}>
                                        {t.name}
                                    </option>
                                ))}
                                {tool.length > 0 ? "" : <option value="">Select Topic</option>}
                                <option value={"custom"}>Create Custom</option>
                            </select>
                            {customActive ?
                                <input
                                    className='input mt-3'
                                    placeholder='Enter topic name'
                                    type="text"
                                    name='custom'
                                    value={createData.custom}
                                    required
                                    maxLength={30}
                                    onChange={handleChange}
                                /> : ""
                            }
                        </div>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Country</label>
                            <select
                                className='input'
                                value={createData?.country}
                                onChange={handleChange}
                                name='country'
                                required
                            >
                                <option value={""}>Select Country</option>
                                {country?.length > 0 ?
                                    country?.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Language</label>
                            <select
                                className='input'
                                value={createData.language}
                                onChange={handleChange}
                                name='language'
                                required
                            >
                                <option value={""}>Select Language</option>
                                {language?.length > 0 ?
                                    language?.filter((lang) => {
                                        return +lang.id !== 40
                                    }).map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                        {/* {props?.type === "video" ? */}

                        <>
                            {/* <div className='common-input-wrap  col-6'>
                                        <label htmlFor="">Plateform</label>
                                        <select
                                            name="dimension"
                                            className="common-input"
                                            onChange={handleChange}
                                            // required
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            <option value={"1920x1080"}>{"Standard YouTube Videos"}</option>
                                            {platform ?
                                                <>
                                                    <Plateform
                                                        data={platform?.Facebook}
                                                    />
                                                    <Plateform
                                                        data={platform?.Instagram}
                                                    />
                                                    <Plateform
                                                        data={platform?.LinkedIn}
                                                    />
                                                    <Plateform
                                                        data={platform?.Pinterest}
                                                    />
                                                    <Plateform
                                                        data={platform?.Snapchat}
                                                    />
                                                    <Plateform
                                                        data={platform?.TikTok}
                                                    />
                                                    <Plateform
                                                        data={platform?.Twitter}
                                                    />
                                                    <Plateform
                                                        data={platform?.YouTube}
                                                    />
                                                </>

                                                : ""}
                                        </select>
                                    </div> */}
                            <div className='common-input-wrap'>
                                <label htmlFor="">Style</label>
                                <select
                                    className="input"
                                    name="style"
                                    onChange={handleChange}
                                    value={createData.style}
                                    required
                                >
                                    <option value={""}>Select Style</option>
                                    <option value={"VSL"}>VSL</option>
                                    <option value={"engaging"}>Engaging</option>
                                    <option value={"promo"}>Promo</option>
                                    <option value={"informative"}>Informative</option>
                                    <option value={"fun"}>Fun</option>
                                    <option value={"news"}>News</option>
                                    <option value={"controversial"}>Controversial</option>
                                    <option value={"Product review"}>Product review</option>
                                </select>
                            </div>
                        </>
                        <div className='project_btn mt-4 text-end'>
                            <button className='site-link' type='submit'><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin" /> </> : "Create"}</span></button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModalAssets