import React, { useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import CreateAgencyModal from './CreateAgencyModal'
import { onEditAgency } from '../../Redux/Actions/AgencyActions'
import { useDispatch } from 'react-redux'
import { Trash } from 'iconsax-react'
import { onDeleteData } from '../../Redux/Actions/CommonActions'
import CommonAlert from '../Common/CommonAlert'

const AgencyRows = ({ curElem, fetchAgency }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleEdit = () => {
        dispatch(onEditAgency(curElem))
        handleShow()
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteData("delete-agency", data, "DELETE_AGENCY", setSweet))
    }

    return (
        <div className="companyCard mt-4">
            <div className='companyCard-left'>
                <div className='companyCard-logo'><img src={curElem.image} alt="" /></div>
                <div>
                    <h6>{curElem.name}</h6>
                    <p><small>{curElem.created}</small></p>
                </div>
            </div>
            <div className='companyCard-right'>
                <button className='link-btn' onClick={handleEdit}><BiEdit /></button>
                <button className='link-btn' onClick={onDelete}><BsTrash /></button>
                {/* <button className='link-btn'>Launch</button> */}
            </div>

            <CreateAgencyModal
                show={show}
                handleClose={handleClose}
                fetchAgency={fetchAgency}
                id={curElem.id}
                curElem={curElem}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this agency?"}
                confirmButtonColor={""}
                icon={<Trash />}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default AgencyRows