import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import Section from './Section'
import { jsPDF } from 'jspdf';
import TitleBar from '../../../Common/TitleBar'
import Header from '../../../Common/Header'
import Footer from '../../../Common/Footer'
import { fetchCampData } from '../../../../Redux/Actions/CommonActions'
import SidePanel from '../../../Common/SidePanel'


const Content = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = queryString.parse(location.search).id;
  const [loader, setLoader] = useState({
    fetch: false,
  })
  const [completeData, setCompleteData] = useState({
    headline: {
      inputText: "",
      prompt: "",
      result: ""
    },
    subHeadline: {
      inputText: "",
      prompt: "",
      result: ""
    },
    introduction: {
      inputText: "",
      prompt: "",
      result: ""
    },
    bodyStatement: {
      inputText: "",
      prompt: "",
      result: ""
    },
    solution: {
      inputText: "",
      prompt: "",
      result: ""
    },
    offer: {
      inputText: "",
      prompt: "",
      result: ""
    },
    testimonials: {
      inputText: "",
      prompt: "",
      result: ""
    },
    guarantee: {
      inputText: "",
      prompt: "",
      result: ""
    },
    callToAction: {
      inputText: "",
      prompt: "",
      result: ""
    },
    urgency: {
      inputText: "",
      prompt: "",
      result: ""
    },
    postscript: {
      inputText: "",
      prompt: "",
      result: ""
    },
  })

  let prodName = completeData?.productName?.split("-")
  // const handlePdf = () => {
  //   const doc = new jsPDF("landscape");
  //   doc.setTextColor(0, 0, 0);
  //   let yPosition = 20;
  //   const leftMargin = 20;
  //   const topMargin = 20;
  //   const pageWidth = doc.internal.pageSize.width;

  //   Object.keys(completeData).forEach((key) => {
  //     const item = completeData[key];
  //     if (item && typeof item === 'object' && item.title && item.result) {
  //       doc.setFont("sans-serif", "bold");
  //       doc.setFontSize(20);
  //       doc.text(item.title, leftMargin, yPosition);
  //       yPosition += 8;
  //       const content = item.result;
  //       const splitText = doc.splitTextToSize(content, pageWidth);
  //       doc.setFont("sans-serif", "normal");
  //       doc.setFontSize(16);
  //       splitText.forEach(line => {
  //         if (yPosition + 6 > doc.internal.pageSize.height - topMargin) {
  //           doc.addPage();
  //           yPosition = topMargin;
  //         }
  //         doc.text(line, leftMargin, yPosition);
  //         yPosition += 6;
  //       });
  //       yPosition += 15;
  //     }
  //   });

  //   doc.save("document.pdf");
  // };

  const [hasdata, setHasdata] = useState(false)

  const handleDoc = () => {
    let documentName = prodName ? prodName[0]:"";
    let fileDownload = document.createElement("a");

    let data = Object.keys(completeData).map(key => {
      const item = completeData[key];
      if (item && typeof item === 'object' && item.title && item.result) {
        return `
        <b>${item.title}</b><br/>
        ${item.result.split("\n").map(line => `<p>${line}</p>`).join('')}
      `;
      }
    }).join("");

    let htmlContent =
      `<html>
        <head>
          <meta charset="utf-8" />
          <style>
            .app-title {
              font-size: 24px;
              font-weight: bold;
              text-align: center;
              margin-bottom: 25px;
            }
          </style>
        </head>
        <body>
          <div class="app-title">${documentName}</div>
          ${data}
        </body>
      </html>`;
    fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(htmlContent);
    fileDownload.download = `${documentName}.doc`;
    if (data) {
      fileDownload.click();
    }
  };


  const handleBack = () => {
    navigate(-1);
  }

  const fetchProject = () => {
    const data = { id }
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchCampData("fetch-user-campaign", data, setCompleteData, loader, setLoader))
  }
  useEffect(() => {
    fetchProject()
  }, [id])

  const handleButton = (data) => {
    setHasdata(data)
  }
  return (
    <>
      <TitleBar title="Campaign" />
      <Header />
      <section className="page-wrap" style={{ position: "relative" }}>
        <div className="side-nav">
          <SidePanel />
        </div>

        <div className="page-container">
          <div className="container-fluid">
            {loader.fetch ?
              < div className="loader-sec-new loader_space">
                <div className="loadernew" />
              </div> :
              <div className="row">
                <div className="col-12">
                  <div className="project_navbar mob-margin">
                    <button onClick={handleBack} className='site-link'>
                      <span><i class="fa-solid fa-arrow-left"></i> Back</span>
                    </button>
                    <button
                      disabled={!hasdata}
                      style={{ cursor: hasdata ? "" : "not-allowed", background: hasdata ? "" : "gray", border: hasdata ? "" : "gray" }}
                      className={`site-link ${hasdata ? "" : "disabled"}`}
                      onClick={handleDoc}>
                      <span> Download Doc</span>
                    </button>
                    {/* <button className="site-link" onClick={handleDoc} >
                    <span> Download Doc</span>
                  </button> */}
                    {/* <button className="site-link" onClick={handlePdf} >
                    <span> Download Pdf</span>
                  </button> */}
                  </div>
                  <div className="cont-box kap">
                    <div className="create_content_wrapper">
                      <div className="create_content_top">
                        <div className='common-input-wrap'>
                          <label htmlFor="">Campaign Name</label>
                          <h5>{completeData.campaignName}</h5>

                        </div>
                        <div className='common-input-wrap'>
                          <label htmlFor="">Product Name</label>
                          <h5>{prodName?prodName[0]:""}</h5>

                        </div>
                        <div className='common-input-wrap'>
                          <label htmlFor="">Language</label>
                          <h5 className='language-left-pad'>{completeData.language}</h5>

                        </div>
                      </div>
                      <Section
                        type="headline"
                        title="Headline"
                        question="What is the single most compelling benefit or solution your product or service provides to your target customer?"
                        data={completeData.headline}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="subHeadline"
                        title="SubHeadline"
                        question="How does your product or service uniquely solve the problem or deliver the benefit mentioned in the headline?"
                        data={completeData.subHeadline}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="introduction"
                        title="Introduction"
                        question="What common pain points, challenges, or desires do your target customers experience that your product or service addresses?"
                        data={completeData.introduction}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="bodyStatement"
                        title="Body/Problem Statement"
                        question="What specific problems, frustrations, or unmet needs does your target audience have that your product or service solves? How do these problems affect their daily life or business?"
                        data={completeData.bodyStatement}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="solution"
                        title="Solution"
                        question="How does your product or service specifically solve the problem or address the pain points you've outlined? What are the key benefits or outcomes your solution provides?"
                        data={completeData.solution}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="offer"
                        title="Offer"
                        question="What exactly are you offering to the customer (product, service, package) Include any special features, pricing, discounts, bonuses, or limited-time deals that make the offer attractive?"
                        data={completeData.offer}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="testimonials"
                        title="Testimonials/Proof"
                        question="What success stories, testimonials, or case studies do you have from previous customers or users that demonstrate the effectiveness of your product or service? Include specific results or benefits they experienced."
                        data={completeData.testimonials}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="guarantee"
                        title="Guarantee"
                        question="What type of guarantee or risk-reducing promise can you offer to reassure potential customers (e.g., money-back guarantee, satisfaction guarantee, warranty) How long does it last, and what does it cover?"
                        data={completeData.guarantee}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="callToAction"
                        title="Call to Action (CTA)"
                        question="What specific action do you want the reader to take (e.g., buy now, sign up, book a call)? What urgency or incentive can you add to encourage immediate action?"
                        data={completeData.callToAction}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="urgency"
                        title="Urgency/Scarcity"
                        question="Is there a time limit, limited stock, or exclusive deal associated with your offer that can motivate customers to act quickly What is the specific reason they need to act now?"
                        data={completeData.urgency}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                      <Section
                        type="postscript"
                        title="P.S. (Postscript)"
                        question="What is the most important point or benefit of your offer that you want to remind the reader of? Is there any additional incentive or urgency you can mention to encourage immediate action?"
                        data={completeData.postscript}
                        enableButton={handleButton}
                        setCompleteData={setCompleteData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>


      </section>
      <Footer />
    </>
  );
};

export default Content;