import React from 'react'
import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import TitleBar from '../../Common/TitleBar'
import ImageCourses from './ImageCourse'

const ContentImage = () => {
  return (
    <>
      <Header />
      <TitleBar title={"Image Maker"} />
      <div className='page-wrap' style={{ display: "block" }}>
        <div className="side-nav">
          <SidePanel />
        </div>

        <ImageCourses />
      </div>

      <Footer />
    </>
  )
}

export default ContentImage