import React, { useEffect, useState } from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import BonusModal from './Modals/BonusModal';
import { onChangeTeam } from '../../../../Redux/Actions/ProjectActions';
import BonusSectionRows from './BonusSectionRows';
import { fetchData } from '../../../../Redux/Actions/CommonActions';

const BonusSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.landingPage.data)

    const [bonus, setBonus] = useState([])

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleChange = (e, index) => {
        const { name, value } = e.target
        dispatch(onChangeTeam(name, value, index))
    }

    const addMember = () => {
        handleShow()
    }

    const fetchBonuses = () => {
        dispatch(fetchData("fetch-bonus", {}, setBonus))
    }

    useEffect(() => {
        fetchBonuses()
    }, [])

    return (
        <div className='boxStyle'>
            <div className="inpLabelWrap mt-0">
                <span className="labelTxt">Bonus Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Bonus Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Bonus Heading"
                        type="text"
                        name='heading'
                        value={landingData.bonus.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="benefit-all pt-4">
                <div className="row">
                    {landingData.bonus.products.length > 0 ?
                        landingData.bonus.products.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <BonusSectionRows
                                        curElem={curElem}
                                        index={index}
                                        handleChange={handleChange}
                                        data={bonus}
                                    />
                                </React.Fragment>
                            )
                        }) : ""
                    }
                </div>
            </div>

            <div className='d-flex justify-content-end'>
                <button className="site-link m-0" style={{ cursor: 'pointer' }} onClick={addMember}>
                    <span> Add More Bonuses
                        <BsFillPlusCircleFill className='ms-2' /></span>
                </button>
            </div>

            <BonusModal
                show={show}
                handleClose={handleClose}
                data={bonus}
            />
        </div >
    )
}

export default BonusSection