import React, { useEffect, useState } from 'react'
import { IoSettingsOutline, IoColorPaletteOutline, IoExtensionPuzzleOutline, IoShareSocialOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { LuCopy } from "react-icons/lu";
import { BsTrash } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router";
import { useDispatch } from 'react-redux';
import { onCopyTool } from '../../Redux/Actions/CreateToolActions';
import { FaSpinner } from 'react-icons/fa';
import CommonAlert from '../Common/CommonAlert';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { Trash } from 'iconsax-react';
import { useSelector } from 'react-redux';

const ToolRows = ({ curElem, tools, setTools, fetchTools }) => {
    const dispatch = useDispatch()
    const [conversion, setConversion] = useState(0)
    const auth = useSelector(state => state.auth);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [loader, setLoader] = useState({
        copy: false
    })

    const handleCopy = () => {
        let data = { id: curElem.id }
        setLoader({
            ...loader,
            copy: true
        })
        dispatch(onCopyTool(data, false, fetchTools, false, loader, setLoader))
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-user-tool", data, tools, setTools, setSweet))
    }

    useEffect(() => {
        if (curElem.generateCount || curElem.loadCount) {
            setConversion(parseInt((curElem.generateCount / curElem.loadCount) * 100))
        }
    }, [curElem])

    return (
        <div className="col-sm-4 mt-4">
            <div className="tool-card">
                <h4>{curElem.name}</h4>
                <p className='pt-3'>{curElem.description}</p>
                <div className="tool-section">
                    <ul>
                        <li>
                            <h6>{curElem.generateCount}</h6>
                            <p>Usage count</p>
                        </li>
                        <li>
                            <h6>{curElem.loadCount}</h6>
                            <p>Load count</p>
                        </li>
                        <li>
                            <h6>{conversion}%</h6>
                            <p>Conversion Rate</p>
                        </li>
                    </ul>
                </div>

                <div className='text-end'>
                    <div className='btn-option'>
                        <Dropdown className="drop-style-1">
                            <Dropdown.Toggle variant="" className="user-btn" id="dropdown-basic">
                                Actions
                                <IoIosArrowDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {auth.user.isClientAccount === "1" ? "" : <NavLink to={`/create-new-ai-tool?aid=${curElem.agencyId}&id=${curElem.id}`}><IoSettingsOutline /> Edit Tool</NavLink>}
                                <NavLink to={`/create-new-ai-tool?aid=${curElem.agencyId}&id=${curElem.id}&t=second`}><IoColorPaletteOutline /> Design</NavLink>
                                <NavLink to={`/create-new-ai-tool?aid=${curElem.agencyId}&id=${curElem.id}&t=third`}><IoExtensionPuzzleOutline /> Integration</NavLink>
                                <NavLink to={`/create-new-ai-tool?aid=${curElem.agencyId}&id=${curElem.id}&t=forth`}><IoShareSocialOutline /> Share</NavLink>
                                <NavLink to={`/create-new-ai-tool?aid=${curElem.agencyId}&id=${curElem.id}&t=fifth`}><FiUsers /> Results</NavLink>
                                {auth.user.isClientAccount === "1" ? "" : <><a style={{ cursor: "pointer" }} onClick={handleCopy}>{loader.copy ? <FaSpinner className='fa-spin' /> : <LuCopy />} Duplicate</a>
                                    <a style={{ cursor: "pointer" }} onClick={onDelete}><BsTrash color='red' /> Delete</a></>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={`Are you sure you want to delete this tool?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </div>

    )
}

export default ToolRows