import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { generatePrompt, generateResult } from '../../../../Redux/Actions/CommonActions';

const Section = ({ title, question, data, type, setCompleteData, enableButton }) => {
  const location = useLocation();
  const id = queryString.parse(location.search).id;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({
    fetchPrompt: false,
    fetchResult: false
  })

  if (data.result) {
    enableButton(true)
  }

  const handleGeneratePrompt = () => {
    setLoader({
      ...loader,
      fetchPrompt: true
    })
    const userData = { input: data.inputText, id, heading: type, title };
    dispatch(generatePrompt(userData, data, loader, setLoader))
  };

  const handleGenerateResult = () => {
    setLoader({
      ...loader,
      fetchResult: true
    })
    const userData = { prompt: data.prompt, id, heading: type, title };
    dispatch(generateResult(userData, data, loader, setLoader, enableButton))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompleteData(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: value,
        title: title,
      }
    }));
  };

  return (
    <div className="common_wrapper_campaign">
      <div className="create_content_head">
        <h4>{title}</h4>
        <hr className="break_line" />
      </div>
      <div className="create_content_middle">
        <div className="question-container">
          <label htmlFor="product-benefit" className="question-label">{question}</label>
          <textarea
            type="text"
            className="common-input inst"
            style={{ resize: 'none' }}
            rows="5"
            name='inputText'
            value={data.inputText}
            onChange={(e) => handleChange(e)}
            placeholder="Enter your answer here..."
          />
          <div className="text-end">
            <button onClick={handleGeneratePrompt}
              disabled={!data?.inputText || loader.fetchPrompt}
              style={{ cursor: data?.inputText ? "" : "not-allowed", background: data?.inputText ? "" : "gray", border: data?.inputText ? "" : "gray" }}
              className={`site-link ${!data?.inputText ? "disabled" : ""} mt-4`}
            >
              <span>{loader.fetchPrompt ? <> Generating Prompt<i className="fa fa-spinner fa-spin mx-1 loader-log" /></> : "Generate Prompt"}</span>
            </button>
          </div>

          <div className="prompt_wrapper mt-4">
            <h5>Generated Prompt :</h5>
            <textarea
              type="text"
              className="common-input inst"
              style={{ resize: 'none' }}
              rows="5"
              name='prompt'
              value={data.prompt}
              onChange={handleChange}
            />
            <div className="text-end">
              <button onClick={handleGenerateResult}
                disabled={!data?.prompt || loader.fetchResult}
                style={{ cursor: data?.prompt ? "" : "not-allowed", background: data?.prompt ? "" : "gray", border: data?.prompt ? "" : "gray" }}
                className={`site-link ${!data?.prompt ? "disabled" : ""} mt-4`}
              >
                <span>
                  {loader.fetchResult ? <> Generating Result<i className="fa fa-spinner fa-spin mx-1 loader-log" /></> : "Generate Result"}
                </span>
              </button>
            </div>
          </div>

          <div className="prompt_wrapper mt-4">
            <h5 style={{ fontWeight: 'bold' }}>Result:</h5>
            <textarea
              type="text"
              className="common-input inst generate-content"
              rows="5"
              style={{ resize: 'none', fontWeight: 'bold', fontSize: '16px' }}
              name='result'
              value={data.result}
              onChange={handleChange}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
