import React, { useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { editElementIndex, onDeleteFields, onHandleChangeForm, onHandleChangeFormRequired, onHandleNewField, onHandleShuffleField, onHandleToolFields, onToggleMain } from '../../../Redux/Actions/CreateToolActions'
import Swal from 'sweetalert2'
import { onUploadMedia } from '../../../Redux/Actions/CommonActions'
import { themeColor } from '../../../Global/Global'

const AddedElements = ({ curElem, index, handleDrop, isChild, onHover, pollyLanguages, pollyVoices, setFindArtist, findArtist }) => {
    const dispatch = useDispatch()
    const inputFileds = ["text", "color", "pdf", "url", "checkbox", "email", "number", "image"]

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        create: false
    })

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "fields",
        item: { ...curElem, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const [collectedPropsTop, dropTop] = useDrop(() => ({
        accept: "fields",
        canDrop: () => true,
        hover: () => {
            if (!collectedPropsTop.isOver) {
                onHover("child");
            }
        },
        drop: (item) => {
            if (item.isCustom) {
                let ty = isChild && "child"
                handleDrop(item, ty, index, "addTop")
            } else {
                handleShuffleField(item, index, "addTop", "child")
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        }),
    }))

    const [collectedPropsBot, dropBottom] = useDrop(() => ({
        accept: "fields",
        canDrop: () => true,
        hover: () => {
            if (!collectedPropsBot.isOver) {
                onHover("child");
            }
        },
        drop: (item) => {
            if (item.isCustom) {
                let ty = isChild && "child"
                handleDrop(item, ty, index, "addBot")
            } else {
                handleShuffleField(item, index, "addBot", "child")
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        }),

    }))


    const onCompeleteUpload = (url) => {
        dispatch(onHandleChangeForm(false, url, false, index))
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleShuffleField = (item, dropIndex, pos) => {
        dispatch(onHandleShuffleField(item, dropIndex, pos))
    }

    const deleteField = () => {
        dispatch(onDeleteFields(index))
    }

    const handleEdit = () => {
        dispatch(editElementIndex(index))
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        let objData
        if (name === "artist") {
            objData = pollyVoices.find(({ id }) => +id === +value)
        }
        if (curElem.isVoice) {
            setFindArtist(!findArtist)
        }
        dispatch(onHandleChangeForm(name, value, checked, index, objData))
    }

    const handleRequired = (e) => {
        const { checked } = e.target
        dispatch(onHandleChangeFormRequired(checked, index))
    }

    const opacity = isDragging ? 0.4 : 1


    return (
        <>
            <div className={`drag-element  ${index === 0 ? "mt-0" : ""}`} style={{ position: "relative" }}>
                {
                    curElem.type.toLowerCase() === "title" || curElem.type.toLowerCase() === "subtitle" ?
                        <div className="row align-items-center" ref={drag} style={{ opacity }}>
                            <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                            </div>
                            <div className="col-auto">
                                <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                            </div>
                            <div className="col">
                                <div className="drag-cont text-center">
                                    <div className="drag-cont-head">
                                        <div></div>
                                        <div className="drag-action" style={{ display: isDragging ? "none" : "" }}>
                                            <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                            <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                        </div>
                                    </div>
                                    <p>{curElem.type}</p>
                                    <h5>{curElem.text}</h5>
                                </div>
                            </div>
                        </div>
                        :

                        curElem.type.toLowerCase() === "textarea" ?
                            <div className="row align-items-center" style={{ opacity }} ref={drag}>
                                <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                    <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                    <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                </div>
                                <div className="col-auto">
                                    <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                                </div>
                                <div className="col ps-0">
                                    <div className="drag-cont">
                                        <div className="drag-cont-head">
                                            {/* <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                            <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div> */}
                                            <label className="label" htmlFor="">{curElem.label}</label>
                                            <div className="drag-action">
                                                <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                                <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                            </div>
                                        </div>
                                        <textarea
                                            className="input"
                                            placeholder={curElem.placeholder}
                                            style={{ position: "relative", zIndex: isDragging ? 1 : 2 }}
                                            name={curElem.name}
                                            value={curElem.value}
                                            required={/^true$/i.test(curElem.required)}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex justify-content-between align-items-center mt-1'>
                                            <p className="pt-1 form-info"><small>{`{{${curElem.name}}}`}</small></p>
                                            <div style={{ zIndex: 2 }} className='d-flex justify-content-between align-items-center'>

                                                <p>Required field</p>
                                                <label className="switch ms-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={/^true$/i.test(curElem.required)}
                                                        onChange={handleRequired}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* <p className="pt-1 form-info"><small>{`{{${curElem.name}}}`}</small></p> */}
                                    </div>
                                </div>
                            </div> :

                            curElem.type.toLowerCase() === "paragraph" ?
                                <div className="row align-items-center" ref={drag} style={{ opacity }}>
                                    <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                        <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                        <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                                    </div>
                                    <div className="col">
                                        <div className="drag-cont ">
                                            <div className="drag-cont-head">
                                                {/* <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div> */}
                                                <div></div>
                                                <div className="drag-action" style={{ display: isDragging ? "none" : "" }}>
                                                    <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                                    <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                                </div>
                                            </div>
                                            <label>{curElem.type}</label>
                                            <p style={{ fontSize: 18 }}>{curElem.text}</p>
                                        </div>
                                    </div>
                                </div>

                                :
                                inputFileds.find((curVal) => curElem.type.toLowerCase().includes(curVal)) ?

                                    <div className="row align-items-center" ref={drag} style={{ opacity }} >
                                        <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                            <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                            <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                                        </div>
                                        <div className="col ps-0">
                                            <div className="drag-cont">
                                                <div className="drag-cont-head">
                                                    {/* <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                    <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div> */}
                                                    <label className="label" htmlFor="">{curElem.label}</label>
                                                    <div className="drag-action" style={{ display: isDragging ? "none" : "" }}>
                                                        <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                                        <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                                    </div>
                                                </div>
                                                {curElem.type.toLowerCase().includes("image") || curElem.type.toLowerCase().includes("pdf") ?
                                                    <input
                                                        className="input"
                                                        style={{ position: "relative", zIndex: isDragging ? 1 : 2 }}
                                                        type={"file"}
                                                        accept={curElem.type.toLowerCase().includes("image") ? "image/jpeg,image/png,image/webp" : ".pdf"}
                                                        placeholder={curElem.placeholder}
                                                        name={curElem.name}
                                                        required={/^true$/i.test(curElem.required)}
                                                        onChange={onInputImage}
                                                    />
                                                    :
                                                    <input
                                                        className="input"
                                                        style={{ position: "relative", zIndex: isDragging ? 1 : 2 }}
                                                        name={curElem.name}
                                                        required={/^true$/i.test(curElem.required)}
                                                        onChange={handleChange}
                                                        value={curElem.value}
                                                        type={
                                                            curElem.type.toLowerCase().includes("text") ? "text" :
                                                                curElem.type.toLowerCase().includes("color") ? "color" :
                                                                    curElem.type.toLowerCase().includes("image") || curElem.type.toLowerCase().includes("pdf") ? "file" :
                                                                        curElem.type.toLowerCase().includes("url") ? "url" :
                                                                            curElem.type.toLowerCase().includes("checkbox") ? "checkbox" :
                                                                                curElem.type.toLowerCase().includes("email") ? 'email' :
                                                                                    curElem.type.toLowerCase().includes("number") ? "number" :

                                                                                        "hidden"
                                                        }
                                                        placeholder={curElem.placeholder}
                                                    />}
                                                <div className='d-flex justify-content-between align-items-center mt-1'>
                                                    <p className="pt-1 form-info"><small>{`{{${curElem.name}}}`}</small></p>
                                                    <div style={{ zIndex: 2 }} className='d-flex justify-content-between align-items-center'>

                                                        <p>Required field</p>
                                                        <label className="switch ms-2">
                                                            <input
                                                                type="checkbox"
                                                                checked={/^true$/i.test(curElem.required)}
                                                                onChange={handleRequired}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <p className="pt-1 form-info"><small>{`{{${curElem.name}}}`}</small></p> */}
                                            </div>
                                        </div>
                                    </div> :
                                    curElem.type.toLowerCase() === "dropdown" || curElem.type.toLowerCase() === "select" ?
                                        <div className="row align-items-center" ref={drag} style={{ opacity }}>
                                            <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                                <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                                            </div>
                                            <div className="col ps-0">
                                                <div className="drag-cont">
                                                    <div className="drag-cont-head">
                                                        {/* <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                        <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div> */}
                                                        <label className="label" htmlFor="">{curElem.label}</label>
                                                        <div className="drag-action">
                                                            {curElem.isVoice || curElem.isArtist ? null :
                                                                <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                                            }
                                                            <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                                        </div>
                                                    </div>
                                                    <select
                                                        className="input"
                                                        name={curElem.name}
                                                        required={/^true$/i.test(curElem.required)}
                                                        onChange={handleChange}
                                                        value={curElem.value}
                                                        style={{ position: "relative", zIndex: isDragging ? 1 : 2 }}
                                                    >
                                                        <option value="" selected>{curElem.placeholder}</option>
                                                        {curElem.isVoice ?
                                                            pollyLanguages.length > 0 ?
                                                                pollyLanguages.map((curD, index) => {
                                                                    return (
                                                                        <option value={curD.code} key={index}>{curD.name}</option>
                                                                    )
                                                                })
                                                                : ""
                                                            : ""}
                                                        {curElem.isArtist ?
                                                            pollyVoices.length > 0 ?
                                                                pollyVoices.map((curD, index) => {
                                                                    return (
                                                                        <option value={curD.id} key={index}>{curD.voice_id}({curD.gender}), {curD.type}</option>
                                                                    )
                                                                })
                                                                : ""
                                                            : ""}
                                                        {curElem.options && !curElem.isVoice && !curElem.isArtist ?
                                                            curElem.options.map((op, ind) => {
                                                                return (
                                                                    <option value={op.value} key={ind}>{op.text}</option>
                                                                )
                                                            })
                                                            : ""}
                                                    </select>
                                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                                        <p className="pt-1 form-info"><small>{`{{${curElem.name}}}`}</small></p>
                                                        <div style={{ zIndex: 2 }} className='d-flex justify-content-between align-items-center'>
                                                            <p>Required field</p>
                                                            <label className="switch ms-2">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={/^true$/i.test(curElem.required)}
                                                                    onChange={handleRequired}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        curElem.type.toLowerCase() === "separator" ?
                                            <div className="row align-items-center" style={{ opacity }} ref={drag}>
                                                <div style={{ position: "absolute", zIndex: 1, height: "100%", width: "100%" }}>
                                                    <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                    <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="drag-icon" style={{ cursor: "pointer" }}><MdOutlineDragIndicator /></div>
                                                </div>
                                                <div className="col ps-0">
                                                    <div className="drag-cont">
                                                        <div className="drag-cont-head">
                                                            {/* <div className='h-1/2 w-full top-0 start-0 absolute' style={{ borderTop: collectedPropsTop.isOver ? "3px solid #000" : "" }} ref={dropTop}></div>
                                                            <div className='h-1/2 w-full bottom-0  start-0 absolute' style={{ borderBottom: collectedPropsBot.isOver ? "3px solid #000" : "" }} ref={dropBottom}></div>
                                                            <label className="label" htmlFor="">{curElem.label}</label> */}
                                                            <div></div>
                                                            <div className="drag-action">
                                                                <button type='button' className="site-link smaller" onClick={handleEdit}><span>Edit</span></button>
                                                                <button type='button' className="site-link smaller red" onClick={deleteField}><span>Delete</span></button>
                                                            </div>
                                                        </div>
                                                        <h5 className='pb-2'>Separator</h5>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                }
            </div>
        </>
    )
}

export default AddedElements