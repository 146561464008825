import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import SweetAlert from "react-bootstrap-sweetalert";
import waveIcon from "../../../../images/wave.svg";
import { useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa";
import { onDeleteMedia } from "../../../../Redux/Actions/CommonActions";
import { onPlayEditorMedia } from "../../../../Redux/Actions/EditorActions";
import CommonAlert from "../../../Common/CommonAlert";
// import { addBgMusic, removeBgAudioFromSlides, removeTtsFromSlides, setTtsData } from "../../actions/chapterAction";

let clearPause, audio = false
const AudioFiles = (props) => {

    const dispatch = useDispatch()
    const editor = useSelector(state => state.editor)
    const [audioDuration, setAudioDuration] = useState(0)
    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: props.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        const formData = new FormData();
        formData.append('id', sweet.id);
        dispatch(onDeleteMedia(formData, props.fetchAudios, setSweet))
    }


    const playAudio = (e) => {
        e.stopPropagation()
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(props.url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            dispatch(onPlayEditorMedia(props.url))
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio()
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const handleSelect = () => {
        props.onSelectAudio(props.url, audioDuration)
    }

    useEffect(() => {
        if (props.url) {
            let au = document.createElement('audio');
            au.src = props.url;

            au.addEventListener('loadedmetadata', function () {
                if (au.duration !== Infinity && au.duration !== 0) {
                    setAudioDuration(Number((au.duration).toFixed(1)));
                    au = null
                }
                else {
                    var getDuration = function (url, next) {
                        var _player = new Audio(url);
                        _player.load();
                        _player.currentTime = 24 * 60 * 60; // fake big time
                        _player.volume = 0;
                        _player.muted = false;
                        _player.play();

                        _player.addEventListener("durationchange", function (e) {
                            if (this.duration != Infinity) {
                                var duration = this.duration
                                _player.remove();
                                next(duration);
                            };
                        }, false);

                        // Wait for a user gesture (e.g., a click) before loading and playing the audio.
                        document.addEventListener("click", function () {
                            _player.load();
                            _player.currentTime = 24 * 60 * 60; // fake big time
                            _player.volume = 0;
                            _player.muted = false;
                            _player.play();
                        }, { once: true }); // Remove the event listener after it's triggered once.
                    };
                    if (au.src) {
                        getDuration(au.src, function (duration) {
                            setAudioDuration(Number((duration).toFixed(1)));
                            au = null
                        });
                    }
                }
            }, false);
        }
    }, [props.url])

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    return (
        <>
            <li className="cursor-pointer col-md-6"  >
                <div className={`audioList-single card ${props.selectedAudio === props.url ? 'active-audio' : ''}`}>
                    <div className="active-audio-wrapper cursor-pointer" >
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="audioList-top" onClick={() => handleSelect()}>

                        {
                            props.type === "uploaded" ?
                                <div className="card-icon-delete" onClick={(e) => onDelete(e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </div>
                                : ''
                        }
                        <img className="waveIcon" alt="" src={waveIcon} />
                        <span className="audio-time text-light">{`${audioDuration} sec`}</span>
                    </div>
                    <div className="audioList-bottom">
                        {
                            play && editor.audio.currentUrl === props.url ?
                                <i className="fa fa-pause-circle" aria-hidden="true" onClick={(e) => pauseAudio(e)} />
                                :
                                <i className={`${loader ? 'fa fa-spinner fa-spin' : 'fas fa-play-circle'} `} onClick={(e) => playAudio(e)} />
                        }
                        <p className="mx-1 text-light">{props.name}</p>
                    </div>
                </div>

                <CommonAlert
                    show={sweet.enable}
                    message={"Are you sure you want to delete this audio?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    alertLoader={sweet.loader}
                    performDelete={performDelete}
                    icon={<FaTrash />}
                />
            </li>



        </>
    )
}

export default AudioFiles;