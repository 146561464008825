import React, { useEffect } from 'react'
import { AbsoluteFill, Audio, prefetch } from "remotion";


const AudioLayer = ({ src, volume = 1, loop = true }) => {

    useEffect(() => {
        if (src) {
            const { free, waitUntilDone } = prefetch(src, {
                method: 'base64',
            });

            waitUntilDone().then(() => {

            }).catch((error) => {
                console.log(error, "Error")
            })
        }
    }, [src]);

    return (
        <AbsoluteFill>
            {src !== "" ?
                <Audio
                    src={src}
                    volume={volume}
                    loop={loop}
                    onError={(event) => {
                        // Handle image loading error here
                        console.log("error")
                    }}
                />
                : ""
            }
        </AbsoluteFill>
    )
}

export default AudioLayer
