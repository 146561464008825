import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'
import TitleBar from '../../Common/TitleBar'
import SidePanel from '../../Common/SidePanel'
import AccountNav from '../Account-Nav/AccountNav'
import { fetchArticles, fetchVideos } from '../../../Redux/Actions/HelpActions'
import { useDispatch } from 'react-redux'
import HelpContent from './HelpContent'
import HelpVideos from './HelpVideos'
import CustomerSupport from './CustomerSupport'

const Help = () => {
    const dispatch = useDispatch()

    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        fetchHelpData()
    }, [])
    return (
        <>
            <TitleBar title="Help & Support" />
            <Header />

            <div className="page-wrap">
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right large'>
                            <HelpContent loadArticles={loader.article} />
                            <HelpVideos loadVideos={loader.video} />
                            <CustomerSupport />
                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default Help
