import React from 'react'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { IoClose } from "react-icons/io5";
import { MdOutlineElectricBolt } from "react-icons/md";

function FifthTab() {
  return (
    <>
      <div className="row justify-content-between">
        <div className="col-sm-6">
          <div className="card-wrap">
            <h5>Payment Type</h5>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              
              <Nav variant="v" className="nav-style-4 mt-3">
                <Nav.Item>
                  <Nav.Link eventKey="first">One-Time Purchase</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Membership Plan</Nav.Link>
                </Nav.Item>
              </Nav>
            
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className='mt-4'>
                    <label htmlFor="" className="label">Plan Name</label>
                    <input className="input small fill" type="text" placeholder='e.g., "Standard Plan"' />
                  </div>
                  <div className='mt-4'>
                    <label htmlFor="" className="label">Price</label>
                    <input className="input small fill" type="text" placeholder='e.g., $49.99' />
                  </div>
                  <div className='mt-4'>
                    <label htmlFor="" className="label">Subtitle</label>
                    <input className="input small fill" type="text" placeholder='e.g., "Our Most Popular Plan"' />
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="" className="label p-0">Membership Plan Benefits</label>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Access to all basic features</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Basic reporting and analytics</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Up to 10 individual users</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-add mt-2">
                       Click to add more
                    </div>
                  </div>


                  <div className='mt-4'>
                    <label htmlFor="" className="label">Button Text</label>
                    <input className="input small fill" type="text" placeholder='e.g., "Get started"' />
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="" className="label">Button Link</label>
                    <input className="input small fill" type="text" placeholder='https://buy.stripe.com/test_abcdefgh123456' />
                  </div>

                  <div className='mt-4'>
                    <button className='site-link'><span>Save and Continue</span></button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className='mt-4'>
                    <label htmlFor="" className="label">Choose the billing cycle for your membership plan</label>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='d-flex align-items-center gap-2'>
                        <label className="custom-radio">
                          <div className='custom-radio-in'>
                            <input type="radio" name='1' />
                            <span className='radio-mark'></span>
                          </div>
                        </label>
                        Monthly
                      </div>
                      <div className='d-flex align-items-center gap-2'>
                        <label className="custom-radio">
                          <div className='custom-radio-in'>
                            <input type="radio" name='1' />
                            <span className='radio-mark'></span>
                          </div>
                        </label>
                        Quarterly
                      </div>
                      <div className='d-flex align-items-center gap-2'>
                        <label className="custom-radio">
                          <div className='custom-radio-in'>
                            <input type="radio" name='1' />
                            <span className='radio-mark'></span>
                          </div>
                        </label>
                        Yearly
                      </div>
                    </div>
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="" className="label">Price ($)</label>
                    <div className="row">
                      <div className="col-auto pe-0">
                        <select name="" id="" className="input small fill">
                          <option value="">Monthly</option>
                        </select>
                      </div>
                      <div className="col"><input className="input small fill" type="text" placeholder='10' /></div>
                    </div>
                  </div>


                  <div className='mt-4'>
                    <label htmlFor="" className="label">Subtitle</label>
                    <input className="input small fill" type="text" placeholder='e.g., "Our Most Popular Plan"' />
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="" className="label p-0">Membership Plan Benefits</label>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Access to all basic features</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Basic reporting and analytics</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-card mt-2 d-flex align-items-center">
                      <div className="col">Up to 10 individual users</div>
                      <div className="col-auto" style={{cursor: 'pointer'}}><IoClose /></div>
                    </div>
                    <div className="benefits-add mt-2">
                       Click to add more
                    </div>
                  </div>


                  <div className='mt-4'>
                    <label htmlFor="" className="label">Button Text</label>
                    <input className="input small fill" type="text" placeholder='e.g., "Get started"' />
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="" className="label">Button Link</label>
                    <input className="input small fill" type="text" placeholder='https://buy.stripe.com/test_abcdefgh123456' />
                  </div>

                  <div className='mt-4'>
                    <button className='site-link'><span>Save and Continue</span></button>
                  </div>


                </Tab.Pane>
              </Tab.Content>
              
            </Tab.Container>
          </div>
        </div>
        <div className="col-sm-5">
          <h5 className='text-center'>Modal Preview</h5>
          <div className="card-wrap modalPrev mt-3">
            <div className="modalPrev-cont">
              <div className="text-center"><div className='modalPrev-icon'><MdOutlineElectricBolt /></div></div>
              <h5 className='text-center pt-4'>Standard plan</h5>
              <h3 className='text-center py-3'>$49.99</h3>
              <p className='text-center'>Our most popular plan.</p>
              <ul>
                <li>Access to all basic features</li>
                <li>Basic reporting and analytics</li>
                <li>Up to 10 individual users</li>
              </ul>
            </div>
            <div className="modalPrev-bottom">
             <button className='site-link'><span>Get started</span></button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FifthTab