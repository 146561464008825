import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { onUpdateUser } from '../../../Redux/Actions/AuthActions';
import { FaSpinner } from 'react-icons/fa';
import { themeColor } from '../../../Global/Global';


const AccountInfo = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState({
        user: false
    });
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, user: true })
        if (user.name === auth.user.name) {
            setLoader({ ...loader, user: false });
            Swal.fire({
                icon: 'info',
                title: 'No Changes Detected',
                text: 'Your name is the same as the current one. No updates were made.',
                confirmButtonColor: themeColor
            });
            return;
        }
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }


    useEffect(() => {
        if (auth.user) {
            setUser({ name: auth.user.name, email: auth.user.email })
        }
    }, [auth])

    return (
        <div className="dashboard-block">
            <h2 className='account-title'>Personal Information</h2>
            <form onSubmit={handleSubmit}>
                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>Name</label> 
                    <input className='input'
                        tyep="text"
                        placeholder="Enter your name"
                        onChange={(e) => handleChange(e)}
                        required
                        name="name"
                        value={user.name} />
                </div>

                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>Email</label>
                    <input className='input' type="email" placeholder="example@gmail.com"
                        value={user.email}
                        readOnly />
                </div>

                <div className='input-wrap text-end mt-4'><button className='site-link blue' type="submit"><span>
                    {loader.user ? <>Updating  <i className="fa fa-spinner fa-spin mr-2" /></> : 'Update'}
                </span></button></div>
            </form>
        </div>
    )
}

export default AccountInfo