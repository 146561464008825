import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VideoRows from "./VideoRows";
import { FaSpinner } from "react-icons/fa";
import CreateModalAssets from "./CreateModalAssets";
import { fetchData } from "../../../Redux/Actions/CommonActions";

const VideoData = ({
  createData,
  setTotalCourses,
  cid,
  searchKey,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [videoLoader, setVideoLoader] = useState({
    fetch: false,
  });
  const [sortHeader, setSortHeader] = useState({
    name: "",
    created: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(10);

  const fetchAllVideos = () => {
    let data = { cId: cid };
    setVideoLoader({
      ...videoLoader,
      fetch: true,
    });
    dispatch(
      fetchData("fetch-all-video", data, setVideos, videoLoader, setVideoLoader)
    );
  };

  const last = currentPage * videosPerPage;
  const first = last - videosPerPage;
  const currentVideos = videos.slice(first, last);

  const totalPages = Math.ceil(videos.length / videosPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSort = (type) => {
    let sortedData = [...currentVideos];

    if (type === "name") {
      sortedData.sort((a, b) => {
        if (sortHeader.name) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        } else {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
        }
      });
      setSortHeader({ ...sortHeader, name: !sortHeader.name });
    } else if (type === "created") {
      sortedData.sort((a, b) => {
        if (sortHeader.created) {
          return a.created.toLowerCase() > b.created.toLowerCase() ? 1 : -1;
        } else {
          return a.created.toLowerCase() < b.created.toLowerCase() ? 1 : -1;
        }
      });
      setSortHeader({ ...sortHeader, created: !sortHeader.created });
    }

    const updatedImages = [...videos];
    updatedImages.splice(first, videosPerPage, ...sortedData);
    setVideos(updatedImages);
  };
  useEffect(() => {
    if (setTotalCourses) {
      setTotalCourses(videos.length);
    }
  }, [videos]);
  useEffect(() => {
    fetchAllVideos();
  }, []);
  return (
    <>
      {/* 
            <div className='cont-box'>
                <div className="container-fluid mb-2 pt-4 pb-5">
                    <div className="row">

                        {videos.length > 0 ?
                            currentVideos.map((curElem, index) => (

                                <div className="col-xl-3 col-md-4 col-sm-6 col-12 mt-2" key={index}>

                                    <VideoRows
                                        curElem={curElem}
                                        index={index}
                                        videos={videos}
                                        setVideos={setVideos}
                                    />
                                </div >
                            )) :
                            <div>
                                <div colSpan={5} className="text-center load-icon-help mt-3">
                                    {videoLoader.fetch ?
                                        <FaSpinner className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#32cd32" }} /> :
                                        <p style={{ fontSize: 15 }}>You do not have videos yet!</p>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div> */}
      {/* {videos.length > 0 && (
                <div className="pagination d-flex justify-content-end mt-4 align-items-center">
                    <button
                        className="btn btn-secondary"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`btn btn-outline-secondary mx-2 ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageClick(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        className="btn btn-secondary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            )} */}

      <div className="cont-box p-0">
        <div className="container-fluid mb-2">
          <div className="row">
            {videos.length > 0 ? (
              videos.filter((item) => {
                if (searchKey) {
                  return item.name
                    .toLowerCase()
                    .includes(searchKey.toLowerCase());
                } else {
                  return item;
                }
              }).length > 0 ? (
                videos
                  .filter((item) => {
                    if (searchKey) {
                      return item.name
                        .toLowerCase()
                        .includes(searchKey.toLowerCase());
                    } else {
                      return item;
                    }
                  })
                  .map((curElem, index) => {
                    return (
                      <div className="col-xxl-3 col-lg-4 col-sm-6 col-12 mt-2">
                        <VideoRows
                          curElem={curElem}
                          index={index}
                          videos={videos}
                          setVideos={setVideos}
                        />
                      </div>
                    );
                  })
              ) : (
                <div>
                  <div colSpan={5} className="text-center load-icon-help mt-3">
                    {videoLoader.fetch ? (
                      <FaSpinner
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 25, color: "#32cd32" }}
                      />
                    ) : (
                      <p style={{ fontSize: 15 }}>No Data Found</p>
                    )}
                  </div>
                </div>
              )
            ) : videoLoader.fetch ? (
              <FaSpinner
                className="fa fa-spinner fa-spin"
                style={{ fontSize: 25, color: "#32cd32" }}
              />
            ) : (
              <div className="text-center">
                <p style={{ fontSize: 15 }}>You do not have videos yet!</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {
                    loader.fetch ?
                        <div className="col-md-12 text-center" >
                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: "28px" }} />
                        </div> : ''
                } */}

      <CreateModalAssets
        show={createData?.show && createData?.type === "video"}
        handleClose={handleClose}
        type={createData?.type}
        dimension={createData?.dimension}
        fetchData={fetchAllVideos}
        cid={cid}
      />
    </>
  );
};

export default VideoData;
