import React, { useState } from 'react'
import { Link } from 'react-router'
import { forgetPassword } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'

import { appName } from "../../Global/Global";
import TitleBar from '../Common/TitleBar';

import Footer from '../Common/Footer';
import LoginHeader from '../Common/LoginHeader';


function ForgetPassword() {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(forgetPassword(data, setLoader))
    }
    return (
        <>
            <TitleBar title="Forgot Password" />

            <div className="login-header"><LoginHeader /></div>

            <div className='login-container'>
                <div className='container'>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5">
                            <div className="login-left">
                                {/* <div className="logo mb-4"><img src={require("../../images/logo.svg").default} alt="" /></div> */}
                                <h3>Welcome to <span className='gradient-txt'>{appName}</span></h3>
                                <p className='pt-3'>
                                    Ready to create and sell your own AI tools? Log in to access a platform that empowers you to build, customize, and white-label cutting-edge AI solutions. Start turning your ideas into profitable tools you can brand as your own and sell to your audience with ease!</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={handleSubmit}>
                                <div className="login-right">
                                    <div className='login-card'>
                                        <h2>Enter Your Email Address</h2>
                                        <div className="input-wrap mt-4">
                                            <label className='label' htmlFor="">Email:</label>
                                            <input
                                                type="email"
                                                className="input"
                                                placeholder="Enter Your Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-wrap mt-4">

                                            <button type="submit" className="site-link lg full mt-1" disabled={loader}>
                                                <span>{loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit</span>
                                            </button>
                                        </div>
                                        {/* <p className='text-center pt-4'>Don't have account? <Link className='txt-orange' to="/register">Register</Link></p> */}
                                        <p className='text-center pt-2'>Remember Password? <Link className='txt-orange' to="/">Login</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default ForgetPassword