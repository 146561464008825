import React from 'react'
import { Link } from 'react-router'

const HelpSubContent = (props) => {
    return (
        <div className='col-6 col-lg-4 col-xl-3'>
            <Link to={props.url}>
                <div className='support-single'>
                    <div className='support-icon'><img src={props.image} alt="" /></div>
                    <p>{props.name}</p>
                </div>
            </Link>
        </div>
    )
}

export default HelpSubContent
