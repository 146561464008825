import React from 'react'
import { FiUpload } from "react-icons/fi";

function FirstTab() {
  return (
    <>
      <div className='row'>
        <div className="col-sm-6">
            <div className="card-wrap h-100">
               <div>
                <label className='label' htmlFor="">Title</label>
                <input className='input' type="text" placeholder='Enter portal title' /> 
               </div>

               <div className='mt-3'>
                <label className='label' htmlFor="">Description</label>
                <textarea style={{height: 100}} className='input' name="" id="" placeholder='Enter portal title'></textarea>
               </div>

               <div className='mt-3'>
                <label className='label' htmlFor="">Slug (subdomain)</label>
                <div className="row">
                    <div className="col">
                        <input className='input' type="text" placeholder='Enter portal title' />
                    </div>
                    <div className="col-auto">
                        <button className='site-link h-100'><span>Check Availability</span></button>
                    </div>
                </div>
                <p className='pt-1'><small>Your Portal will be available on <strong>xxx</strong>.aiwhitelabel.com</small></p>
               </div>

            </div>
        </div>
        <div className="col-sm-6">
            <div className='card-wrap h-100'>
                <div className='row mt-3'>
                    <div className='col-6'>
                        <label className='label' htmlFor="">Primary color</label>
                        <div className='colorBox' style={{background: '#293844'}}></div>
                    </div>
                    <div className='col-6'>
                        <label className='label' htmlFor="">Secondary Color</label>
                        <div className='colorBox' style={{background: '#929BA9'}}></div>
                    </div>
                </div>

                <div className='mt-3'>
                   <label className='label' htmlFor="">Social preview image</label>
                    <div className='prev-upload'>
                        <FiUpload />
                        <p><small><strong>Drag and Drop files here</strong></small></p>
                        <p style={{opacity: 0.5}}><small>File format: PDF/Image/Scan</small></p>
                        <button className='site-link sm'><span>Browse</span></button>
                        <p style={{opacity: 0.5}}><small>Maximum size: 5MB</small></p>
                        <input type="file" />
                    </div>
                </div>

                <div className='mt-3'>
                   <div className="row">
                      <div className="col-6">
                        <label className='label' htmlFor="">Logo</label>
                        <div className='prev-upload'>
                          <FiUpload />
                          <p><small><strong>Drag and Drop files here</strong></small></p>
                          <p style={{opacity: 0.5}}><small>File format: PDF/Image/Scan</small></p>
                          <button className='site-link sm'><span>Browse</span></button>
                          <p style={{opacity: 0.5}}><small>Maximum size: 5MB</small></p>
                          <input type="file" />
                        </div>
                      </div>
                      <div className="col-6">
                        <label className='label' htmlFor="">Icon</label>
                        <div className='prev-upload'>
                          <FiUpload />
                          <p><small><strong>Drag and Drop files here</strong></small></p>
                          <p style={{opacity: 0.5}}><small>File format: PDF/Image/Scan</small></p>
                          <button className='site-link sm'><span>Browse</span></button>
                          <p style={{opacity: 0.5}}><small>Maximum size: 5MB</small></p>
                          <input type="file" />
                        </div>
                      </div>
                   </div>
                </div>

            </div>
        </div>
      </div>
    </>
  )
}

export default FirstTab