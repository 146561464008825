import React from 'react'
import Header from '../Common/Header'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { FiEdit, FiUpload } from "react-icons/fi";
import { AiOutlineLink } from "react-icons/ai";

function ProjectScript() {
  return (
    <>
      <Header />
      <div className='pro-script'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className='pro-script-tab'>
                <div className='pro-script-tab-in'>
                    <Nav variant="b" className="nav-style-2">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Content</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Settings & Cast</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Breakdown</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        
            <div className="input-idea">
                <h2>Input your idea</h2>
                <Tab.Content className='pt-4'>
                    <Tab.Pane eventKey="first">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="b" className="nav-style-3">
                            <Nav.Item>
                               <Nav.Link eventKey="first"><FiEdit /> Text Input</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                               <Nav.Link eventKey="second"><AiOutlineLink /> Website Link</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                               <Nav.Link eventKey="third"><FiUpload /> Upload Document</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className='pt-4'>
                            <Tab.Pane eventKey="first">
                                <textarea className='input alt h-500' name="" id="" placeholder='Input anything from a ful script, a few scenes, or a story...'></textarea>
                                <div className="txt-length">0 / 12,000</div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <input className='input alt' type="text" placeholder='Enter Website URL' />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className='upload-box'>
                                    <FiUpload />
                                    <p>Upload Document</p>
                                    <input type="file" />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">Settings & Cast</Tab.Pane>
                    <Tab.Pane eventKey="third">Breakdown</Tab.Pane>
                </Tab.Content>
            </div>
        </Tab.Container>
        <div className="next-bar">
            <button className='site-link'><span>Next</span></button>
        </div>
      </div>
    </>
  )
}

export default ProjectScript