import React, { useEffect, useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import CreateLandingModal from './CreateLanding/CreateLandingModal';
import { useDispatch } from 'react-redux';
import { themeColor } from '../../../Global/Global';
import LandingPageCard from './LandingPageCard';
import { useSelector } from 'react-redux';
import Header from '../../Common/Header';
import SidePanel from '../../Common/SidePanel';
import TitleBar from '../../Common/TitleBar';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import Footer from '../../Common/Footer';
import { IoSearchOutline } from 'react-icons/io5';

const SiteHero = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [q, setQ] = useState("")
    const [landingPage, setLandingPage] = useState([]);
    const [loader, setLoader] = useState({
        fetch: true
    });


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchAllLandingPage = () => {
        dispatch(fetchData("fetch-landing-all", {}, setLandingPage, loader, setLoader))
    }

    useEffect(() => {
        fetchAllLandingPage();
    }, [])

    return (
        <>
            <Header />
            <TitleBar title={"Page Builder"} />
            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>

                <div className="page-container pt-0 pe-0 site-hero-wrap">
                    <div className="cont_area">
                        <div className="d-flex project-mob-wrap projectTitle-wrap gap-4 justify-content-center justify-content-md-between align-items-center">
                            <h4 className='text-nowrap'>All Landing Pages | <span>{landingPage.length} Pages</span> </h4>
                            <div className="projectTitle justify-content-center justify-content-md-start justify-content-xl-end row">
                                <div className="col-lg-6 col-6">
                                    {/* <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Landing Page"
                                                value={q}
                                                onChange={(e) => setQ(e.target.value)}
                                            />
                                        </div>
                                    </div> */}
                                    <div className='tabSearch site-tab-search'>
                                        <input class="custom-search" type="text" placeholder="Search" value="" />
                                        <span className='inp-icon'>
                                            <IoSearchOutline fontSize={23} />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        {
                                            auth.user.isClientAccount === "1" ? "" :
                                                <button onClick={handleShow} className="site-link"><BsStars /><span> Create New Landing Page</span></button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container-fluid pt-0">
                            <div class="videoProject-top mt-4">
                                {/* <h2><span>Total Landing Pages ({landingPage.length})</span></h2> */}
                            </div>
                            <div className="forproject m-4">
                                <div className="row mb-5">

                                    {landingPage.length > 0 ?
                                        landingPage.filter((curElem) => {
                                            return curElem.name.toLowerCase().includes(q.toLowerCase())
                                        }).length > 0 ?
                                            landingPage.filter((curElem) => {
                                                return curElem.name.toLowerCase().includes(q.toLowerCase())
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <LandingPageCard
                                                            curElem={curElem}
                                                            landingPage={landingPage}
                                                            setLandingPage={setLandingPage}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <div className='col-md-12 text-center'>
                                                <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                    <h4 style={{ fontWeight: "500" }}>
                                                        No landing page available!
                                                    </h4>
                                                </div>
                                            </div>
                                        :
                                        <div className='col-md-12 text-center'>
                                            <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "60vh" }}>
                                                    {loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: "40px" }} /> :
                                                        <h5 style={{ fontSize: "15px", color: "#141414" }}>
                                                            You haven't created any landing page in your account yet.
                                                        </h5>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <CreateLandingModal
                show={show}
                handleClose={handleClose}
            />

            <Footer />
        </>
    )
}

export default SiteHero