import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { addInnerStyle, applyToAllSlide, onFontFamily, onFontSize, onTextAlign, onTextBold, onTextCapitalize, onTextItalic, onTextLineHeight, onTextLower, onTextStrike, onTextUnderline, onTextUpper, removeInnerStyle, textLayerAdd, textLayerUpdate } from '../../../../../Redux/Actions/VideoActions';
import sanitizeHtml from 'sanitize-html';
import { onFetchFont } from '../../../../../Redux/Actions/CommonActions';
import { disableDelete, enableDelete } from '../../../../../Redux/Actions/EditorActions';
import FontColor from './FontColor';


const Text = () => {
  const dispatch = useDispatch()
  const layerTextRef = useRef(null)
  const campaign = useSelector(state => state?.video?.data)
  const selectedSlide = campaign?.slides?.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign?.slides?.findIndex(({ isSelected }) => isSelected === "1")
  const textIndex = selectedSlide?.layers?.findIndex(({ isSelected }) => isSelected === true)
  const selectedText = selectedSlide?.layers?.find(({ isSelected }) => isSelected === true)

  const [layerText, setLayerText] = useState("")
  const [slideAll, setSlideAll] = useState(false);
  const [fonts, setFonts] = useState([])
  const [isGradient, setIsGradient] = useState(false)
  const [restriction, setRestriction] = useState(true);
  const [dataHigh, setDataHigh] = useState({
    text: "",
    color: "",
    background: ""
  })
  const [styleState, setStyleState] = useState({
    color: "#000000",
    fontFamily: "",
    background: "",
    fontSize: "36",
    textAlign: "center",
    fontWeight: false,
    fontStyle: false,
    textTransform: "lowercase",
    textDecoration: "none",
    lineHeight: 1,
    opacity: 1,
  })

  const addTextLayer = () => {
    dispatch(textLayerAdd(selectedSlideIndex))
  }

  const onLayerTextChange = (text) => {
    if (selectedText.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
    }
  }

  const onTextStyle = (type) => {
    setSlideAll(false)
    if (type === 'bold') {
      if (styleState.fontWeight === false || styleState.fontWeight === "") {
        dispatch(onTextBold("bold", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextBold(false, selectedSlideIndex, textIndex));
      }
    } else if (type === 'italic') {
      if (styleState.fontStyle === false || styleState.fontStyle === "") {
        dispatch(onTextItalic("italic", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextItalic(false, selectedSlideIndex, textIndex));
      }
    } else if (type === 'uppercase') {
      if (styleState.textTransform === "uppercase") {
        dispatch(onTextUpper("none", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextUpper("uppercase", selectedSlideIndex, textIndex));
      }
    }
    else if (type === 'capitalize') {
      if (styleState.textTransform === "capitalize") {
        dispatch(onTextCapitalize("none", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextCapitalize("capitalize", selectedSlideIndex, textIndex));
      }
    }

    else if (type === 'lowercase') {
      if (styleState.textTransform === "lowercase") {
        dispatch(onTextLower("none", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextLower("lowercase", selectedSlideIndex, textIndex));
      }
    } else if (type === 'underline') {
      if (styleState.textDecoration === "underline") {
        dispatch(onTextUnderline("none", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextUnderline("underline", selectedSlideIndex, textIndex));
      }
    } else if (type === 'line-through') {
      if (styleState.textDecoration === "line-through") {
        dispatch(onTextStrike("none", selectedSlideIndex, textIndex));
      } else {
        dispatch(onTextStrike("line-through", selectedSlideIndex, textIndex));
      }
    }
  }

  const onAlign = (align) => {
    setSlideAll(false);
    dispatch(onTextAlign(align, selectedSlideIndex, textIndex));
  }


  const onInputChange = (e, type) => {
    setSlideAll(false);
    if (type === 'size') {
      dispatch(onFontSize(e.target.value, selectedSlideIndex, textIndex));
    } else if (type === 'font_family') {
      dispatch(onFontFamily(e.target.value, selectedSlideIndex, textIndex));
    }
  }

  const onChangeLineHeight = (e) => {
    setSlideAll(false);
    dispatch(onTextLineHeight(e.target.value, selectedSlideIndex, textIndex))
  }

  const fetchFonts = () => {
    dispatch(onFetchFont(setFonts, false, true))
  }

  const applyChanges = () => {
    if (slideAll === false) {
      setSlideAll(true);
      dispatch(applyToAllSlide(styleState, selectedText.innerCss));
    }
  }

  const handleChecked = (e) => {
    if (selectedText.type === "text") {
      setSlideAll(false);
      if (e.target.checked) {
        dispatch(addInnerStyle(selectedSlideIndex, textIndex, styleState.background))
      } else {
        dispatch(removeInnerStyle(selectedSlideIndex, textIndex))
      }
    }
  }


  const handleClick = (event) => {
    let text = event.currentTarget.innerText;
    setRestriction(false)
    if (selectedText.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
    }
  }

  const handleBlur = () => {
    dispatch(enableDelete())
    // setRestriction(true)
  }


  const handleHighlight = (e) => {
    const selObj = window.getSelection().toString();

    if (selObj !== "") {
      setDataHigh({
        ...dataHigh,
        text: selObj
      })
    }
  }

  const handleOutsideText = () => {
    dispatch(disableDelete())

  }

  useEffect(() => {
    if (selectedText) {
      setStyleState({
        ...selectedText.style
      })
      if (selectedText.style.background.includes("linear-gradient")) {
        setIsGradient(true)
      } else {
        setIsGradient(false)
      }
      setLayerText(selectedText.src)
    }
  }, [selectedText])

  useEffect(() => {
    fetchFonts()
  }, [])



  return (
    <div className="tabInner">

      <div className="textarea-block-wrap mb-4 mt-0">
        <div className="textarea-block-head d-flex justify-content-between align-items-center">
          <h2>Text</h2>
          <button
            className='textarea-btn'
            onClick={addTextLayer}
          >
            Add New Text Layer
          </button>
        </div>
        <div className="textarea-wrapper">
          <div class="">
            {selectedText ?
              selectedText.type === "text" ?
                <>
                  <ContentEditable
                    className="form-control mt-3"
                    ref={layerTextRef}
                    html={layerText}
                    disabled={false}
                    onBlur={handleBlur}
                    onClick={(e) => handleClick(e)}
                    onMouseUp={() => handleHighlight()}
                    onFocus={() => handleOutsideText()}
                    onChange={(e) => onLayerTextChange(e.target.value)}
                    style={{ minHeight: 120 }}
                  />
                </>
                : "" : ""}
          </div>
        </div>

      </div>

      <div className="txt-format mt-2">
        {selectedText ?
          selectedText.type === "text" ? <>
            <h2>Font Style</h2>
            <div className="fontStyle">
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-8">
                    <select
                      id="sel-font"
                      name="font_family"
                      style={{ fontFamily: styleState.fontFamily }}
                      className='cursor-pointer text-hover-effect'
                      onChange={(e) => onInputChange(e, 'font_family')}
                    >
                      <option value="" selected>Select Font</option>
                      {fonts.length > 0 ?
                        fonts.map((font, index) => {
                          return (
                            <option
                              style={{ fontFamily: font }}
                              selected={font === styleState.fontFamily}
                              value={font}
                              key={index}
                            >
                              {font}
                            </option>
                          )
                        })
                        : ''
                      }
                    </select>
                  </div>


                  <div className="col-4">
                    <input
                      id="f-size"
                      type="number"
                      className="form-control edit-input-font-size text-hover-effect"
                      name="f-size"
                      min="10"
                      value={styleState.fontSize}
                      onChange={(e) => onInputChange(e, 'size')}
                    />
                  </div>
                </div>
              </div>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-2">
                    <div
                      className={`${styleState.fontWeight === "bold" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect `}
                      onClick={() => onTextStyle('bold')}
                      data-toggle="tooltip"
                      title="Text Bold"
                    ><span className="font-weight-bold">T</span>
                    </div>

                  </div>
                  <div className="col-2">

                    <div
                      className={`${styleState.fontStyle === "italic" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('italic')}
                      data-toggle="tooltip"
                      title="Text Italic"
                    ><span className="font-italic">T</span>
                    </div>

                  </div>
                  <div className="col-2">

                    <div
                      className={`${styleState.textTransform === "uppercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('uppercase')}
                      data-toggle="tooltip"
                      title="Text Uppercase"
                    ><span>TT</span></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textTransform === "lowercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('lowercase')}
                      data-toggle="tooltip"
                      title="Text Lowercase"
                    ><span>tt</span></div>
                  </div>

                  <div className="col-2">
                    <div
                      className={`${styleState.textDecoration === "underline" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('underline')}
                      data-toggle="tooltip"
                      title="Text Underline"
                    ><span className="text-decoration-underline">T</span></div>
                  </div>
                  <div className="col-2">
                    <div
                      className={`${styleState.textDecoration === "line-through" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('line-through')}
                      data-toggle="tooltip"
                      title="Text Strike"
                    ><span className="text-decoration-line-through">T</span></div>
                  </div>
                </div>
              </div>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'left' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onAlign('left')}
                      data-toggle="tooltip"
                      title="Text Left"
                    ><i className="fa fa-align-left" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'center' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onAlign('center')}
                      data-toggle="tooltip"
                      title="Text Center"
                    ><i className="fa fa-align-center" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'right' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onAlign('right')}
                      data-toggle="tooltip"
                      title="Text Right"
                    ><i className="fa fa-align-right" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'justify' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onAlign('justify')}
                      data-toggle="tooltip"
                      title="Text Justify"
                    ><i className="fa fa-align-justify" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-4">

                    <div
                      className={'form-format'}
                      data-toggle="tooltip"
                      title="Text Line Height"
                    >
                      <input
                        id="t-lineheight"
                        className="edit-input-lineheight text-hover-effect"
                        type="number"
                        name="Lineheight"
                        value={selectedText.innerCss ? selectedText.innerCss.lineHeight : styleState.lineHeight}
                        min="1"
                        step="0.1"
                        onChange={(e) => onChangeLineHeight(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </> : "" : ""}


        {selectedText ?
          selectedText.type === "text" || selectedText.type === "shape" ?
            <>
              <FontColor
                type="text"
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
                color={styleState.color}
                isHighlight={false}
                setSlideAll={setSlideAll}
              />

              <FontColor
                type="text background"
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
                color={selectedText.innerCss ? selectedText.innerCss.background : styleState.background}
                setSlideAll={setSlideAll}
                isHighlight={false}
              />
            </>
            : "" : ""}

        {selectedText ?
          selectedText.type === "text" ?
            <div className="txt-format">
              <FontColor
                type="highlight"
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
                isHighlight={true}
                dataHigh={dataHigh}
                selectedText={selectedText}
                setDataHigh={setDataHigh}
                setSlideAll={setSlideAll}
              />

              <FontColor
                type="highlight background"
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
                isHighlight={true}
                dataHigh={dataHigh}
                setDataHigh={setDataHigh}
                selectedText={selectedText}
                setSlideAll={setSlideAll}
              />
            </div>
            : "" : ""}

        {selectedText ? selectedText.type === "text" ?
          <>
            <div className="fontStyle-block-wrap">

              <div className="itemDisp">
                <div className="itemDisp-left">
                  Add space between text
                </div>
                <div className="itemDisp-right">
                  <div className="switch-single">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id='addTtsAll'
                        checked={selectedText.innerCss && selectedText.type === "text"}
                        onChange={(e) => handleChecked(e)}
                      // style={{ width: '28px', height: "19px" }}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="opt-select mt-4">
              <span></span>
              <label className="switch">
                <input
                  type="checkbox"
                  id='addTtsAll'
                  checked={selectedText.innerCss && selectedText.type === "text"}
                  onChange={(e) => handleChecked(e)}
                // style={{ width: '28px', height: "19px" }}
                />
                <span className="slider round"></span>
              </label>
            </div> */}

            <div className="fontStyle-block-wrap">


              <div className="itemDisp">
                <div className="itemDisp-left">
                  Apply to all slides
                </div>
                <div className="itemDisp-right">
                  <div className="switch-single">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={slideAll}
                        disabled={slideAll}
                        onChange={(e) => applyChanges()}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
          : "" : ""}
      </div>
    </div>
  );
};

export default Text;
