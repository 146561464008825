import React from 'react'
import { Link } from 'react-router'

import Footer from '../Common/Footer';
import LoginHeader from '../Common/LoginHeader';

function Register() {
    return (
        <>

            <div className="login-header"><LoginHeader /></div>

            <div className='login-container'>
                <div className='container'>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5">
                            <div className="login-left">
                                {/* <div className="logo mb-4"><img src={require("../../images/logo.svg").default} alt="" /></div> */}
                                <h3>Welcome to <span className='gradient-txt'>AiClients</span></h3>
                                <p className='pt-3'>
                                    Ready to create and sell your own AI tools? Log in to access a platform that empowers you to build, customize, and white-label cutting-edge AI solutions. Start turning your ideas into profitable tools you can brand as your own and sell to your audience with ease!</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-right">
                                <div className='login-card'>
                                    <div className="input-wrap">
                                        <label className='label' htmlFor="">Name:</label>
                                        <input className='input' type="text" placeholder='Enter Your Name' />
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <label className='label' htmlFor="">Email:</label>
                                        <input className='input' type="text" placeholder='Enter Your Email' />
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <label className='label' htmlFor="">Password:</label>
                                        <input className='input' type="password" placeholder='Enter Your Password' />
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <button className='site-link lg full mt-1'><span>Register</span></button>
                                    </div>
                                    <p className='text-center pt-4'>Alraedy have account? <Link className='txt-orange' to="/">Login</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default Register