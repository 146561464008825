import React from 'react'

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab'; 

function HelpPost() {
  return (
    <>

        <Header />

        <div className='page-wrap'>
        <div className="side-nav">
            <SidePanel />
        </div>
        <div className="page-container">

        <div className='container-area'>

<div className='account-wrap pb-5'>
    <div className='titleBar'>
        <div className='titleBar-left'>
        <h2>Help</h2>
        <p>Select from Popular Topics</p>
        </div>
    </div>
</div>

<Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <div className='account-wrap for-post'>
    <div className='account-left'>
        <div className='upgrade-nav'>
            <Nav variant="y" className="">
                <Nav.Item>
                   <Nav.Link eventKey="first">Account Info & Privacy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="second">Create Custom Agent/Staff</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="third">Integrations / WhiteLabel</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="fourth">Manage Team Member</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="fifth">Add Departments & Project Knowledge Base</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="sixth">Setting Up & Managing Company Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="seventh">Manage Hire/Fire Staff</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                   <Nav.Link eventKey="eighth">Video Training</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    </div>

    <div className='account-right large'>
        <Tab.Content>
            <Tab.Pane eventKey="first">
                <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
                <div className='dashboard-block'>
                    <h5>Change Account Password</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
               <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
                <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
               <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
                <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="sixth">
               <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="seventh">
                <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="eighth">
                <div className='dashboard-block mt-0'>
                    <h5>Manage Profile</h5>
                    <hr />
                    <div className='img-block'>
                        <img src="https://backend-centralized.aisuites.io/assets/images/article/1727435545_1.png" alt="" />
                    </div>
                </div>
            </Tab.Pane>
        </Tab.Content>
    </div>
    </div>
</Tab.Container>



</div>

        </div>
        </div>

        <Footer />

    </>
  )
}

export default HelpPost