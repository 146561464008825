import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import TitleBar from '../Common/TitleBar';
import { fetchDataRedux } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import AgencyRows from './AgencyRows';
import CreateAgencyModal from './CreateAgencyModal';

const Agency = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const agency = useSelector(state => state.agency)


    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchAgency = () => {
        dispatch(fetchDataRedux("fetch-all-agency", {}, "FETCH_AGENCY_LIST", loader, setLoader))
    }

    useEffect(() => {
        fetchAgency()
    }, [])

    return (
        <>
            <Header />
            <TitleBar title="Agency" />
            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="titleFlex">
                                <div className="titleFlex-left">
                                    <h5>Choose Agency</h5>
                                    <p>Create and Manage Agencies</p>
                                </div>
                                <div className="titleFlex-right">
                                    <button className='site-link' onClick={handleShow}><span>Create New Agency</span></button>
                                </div>
                            </div>

                            {loader.fetch ?
                                <div className="text-center mt-4">
                                    <FaSpinner className="fa-spin" size={25} />
                                </div>
                                :
                                agency.list.length > 0 ?
                                    agency.list.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <AgencyRows
                                                    curElem={curElem}
                                                    fetchAgency={fetchAgency}
                                                />
                                            </React.Fragment>
                                        )
                                    }) :
                                    <div className='text-center mt-4'>
                                        <h6>No Agency Create yet, <br /> Create your agency to get started!</h6>
                                    </div>
                            }

                            {/* <div className="titleFlex mt-5">
                                <div className="titleFlex-left">
                                    <h5>Check Resources</h5>
                                    <p>We added Webnair and Facebook Group</p>
                                </div>
                            </div> */}
                            {/* <div className='companyCard d-block py-4 mt-4'>
                                <div className="row">
                                    <div className="col-sm-5"></div>
                                    <div className="col-sm-7">
                                        <h5>Checkout Free Webnair</h5>
                                        <p className='pt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                                        <p className='pt-2'>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                                        <a className='site-link mt-3' href=""><span>Learn More</span></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>

            <Footer />

            <CreateAgencyModal
                show={show}
                handleClose={handleClose}
                fetchAgency={fetchAgency}
            />
        </>
    )
}

export default Agency