import { React } from 'react'
import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import AccountNav from '../Account-Nav/AccountNav'
import PrivacyEmail from './PrivacyEmail'
import PrivacyGdpr from './PrivacyGdpr'
import TitleBar from '../../Common/TitleBar'

const Privacy = () => {

    return (
        <>
            <TitleBar title="Privacy" />
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Privacy</h2>
                                    <p>Create and Manage Account</p>
                                </div>
                            </div>
                            <PrivacyEmail />
                            <PrivacyGdpr />
                        </div>
                    </div>

                </div>
            </div>

            <Footer />




        </>
    )
}

export default Privacy