import { produce } from "immer"
const initialState = {
    data: false
}
export const RebrandingReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'ADD_REBRAND':
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case 'REMOVE_ADD_REBRAND':
            return produce(state, (draft) => {
                draft.data = false
            })

        default:
            return state

    }
}