import React from 'react'
import Background from './Background'
import ImageLayer from './ImageLayer'
import TextLayer from './TextLayer'
import VideoLayer from './VideoLayer'
import { useDispatch } from "react-redux"
import Watermark from './Watermark'
import Logo from './Logo'
import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../../../Redux/Actions/VideoActions'
import TalkingHead from './TalkingHead'

const PreviewMain = (props) => {
    const dispatch = useDispatch()

    const handleSelectLayer = (index) => {
        const layerIndex = props?.selectedSlide?.layers.findIndex(({ isSelected }) => isSelected === true)
        if (index !== layerIndex) {
            dispatch(updateSelectedLayer(index, props?.selectedSlideIndex))
        }
    }

    const handleResize = (width, height, x, y, layerIndex) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))

        dispatch(resizeLayer(width, height, x, y, props?.selectedSlideIndex, layerIndex))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, props?.selectedSlideIndex, layerIndex))
    }

    return (
        <>
            <Background
                background={props?.selectedSlide?.background}
                selectedSlideIndex={props?.selectedSlideIndex}
                selectedSlide={props?.selectedSlide}
                dimension={props?.dimension}
            />
            <Logo />
            <Watermark />
            <TalkingHead />

            {props?.selectedSlide?.layers.map((layer, index) => {
                if (layer.type === "text") {
                    return (
                        <React.Fragment key={index}>
                            <TextLayer
                                layer={layer}
                                selectedSlide={props?.selectedSlide}
                                selectedSlideIndex={props?.selectedSlideIndex}
                                layerIndex={index}
                                handleSelectLayer={handleSelectLayer}
                                handleResize={handleResize}
                                handleRePosition={handleRePosition}
                                dimension={props?.dimension}
                            />
                        </React.Fragment>
                    )
                }
                if (layer.type === "image") {
                    return (
                        <React.Fragment key={index}>
                            <ImageLayer
                                layer={layer}
                                selectedSlide={props?.selectedSlide}
                                selectedSlideIndex={props?.selectedSlideIndex}
                                layerIndex={index}
                                handleSelectLayer={handleSelectLayer}
                                handleResize={handleResize}
                                dimension={props?.dimension}
                                handleRePosition={handleRePosition}
                            />
                        </React.Fragment>
                    )
                }
                if (layer.type === "video") {
                    return (
                        <React.Fragment key={index}>
                            <VideoLayer
                                layer={layer}
                                selectedSlide={props?.selectedSlide}
                                selectedSlideIndex={props?.selectedSlideIndex}
                                layerIndex={index}
                                handleSelectLayer={handleSelectLayer}
                                handleResize={handleResize}
                                dimension={props?.dimension}
                                handleRePosition={handleRePosition}
                            />
                        </React.Fragment>
                    )
                }
            })}
        </>
    )
}

export default PreviewMain