import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { appName } from "../../../Global/Global";
import { DocumentDownload, StopCircle, Trash } from "iconsax-react";
import CommonAlert from '../../Common/CommonAlert';
import { gdprAction } from '../../../Redux/Actions/AuthActions';

const PrivacyGdpr = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "",
        text: "",
        ep: "",
        loader: false,
        icon: <DocumentDownload />
    })

    const modalData = [
        {
            message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking on Download button.`
        },
        {
            message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        }

    ]
    const performAction = () => {
        setSweet({
            ...sweet,
            loader: true
        })
        dispatch(gdprAction({}, sweet, setSweet))
    }

    const handleClick = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index,
            text: modalData[index].message,
            confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
            ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account",
            icon: index === 0 ? <DocumentDownload /> : index === 1 ? <StopCircle /> : <Trash />
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            ep: ""
        })
    }

    return (
        <div className='dashboard-block'>
            <h2 className='account-title'>Manage Your Account Data</h2>
            <div className='data-block'>
                <h6>Download My Data</h6>
                <p className='pt-2'>{rebrand ? rebrand.name : appName} your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that by clicking "Download My Data".</p>
                <button className='site-link mt-3' onClick={() => handleClick(0)}><span><DocumentDownload /> Download My Data</span></button>
            </div>

            <div className='data-block'>
                <h6>Stop Processing My Data</h6>
                <p className='pt-2'>If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName} you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. <a >Please Proceed With Caution</a>.</p>
                <button className='site-link orange mt-3' onClick={() => handleClick(1)}><span><StopCircle /> Stop Processing My Data</span></button>
            </div>

            <div className='data-block'>
                <h6>Delete My Data</h6>
                <p className='pt-2'>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use  {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use  {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. <a>Please Proceed With Caution</a>.</p>
                <button className='site-link red mt-3' onClick={() => handleClick(2)}><span><Trash />  Delete My Data</span></button>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={sweet.text}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performAction}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </div>

    )
}

export default PrivacyGdpr