import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { onConnectInte } from '../../../Redux/Actions/SocialActions'
import { setAlert } from '../../../Redux/Actions/AlertActions'

const ConnectModal = ({ shortType, url, name, show, handleClose }) => {
    const FormDataJson = require('form-data-json-convert')
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false,
        fetch: false
    })
    const [eventIds, setEventIds] = useState([])
    const [state, setState] = useState({
        name: ""
    })

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     setState({
    //         ...state,
    //         [name]: value
    //     })
    // }
    const handleSubmit = (e) => {
        e.preventDefault()

        let data = { ...state }
        if (shortType === "AR") {

            delete data.apiKey
            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            formData.postUrl = form[0].action
            data = {
                ...data,
                name: data.name,
                code: {...data, formData},
            }
        }
        if (shortType === "twilio") {
            data.type = "twilio"
        }
        setLoader({
            ...loader,
            submit: true
        })

        dispatch(onConnectInte(url, data, handleClose, loader, setLoader))
    }
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'toNumber') {
            if (!value || /^[0-9]*$/.test(value)) {
                setState((prevState) => ({
                    ...prevState,
                    [name]: value
                }));
            } else {
                dispatch(setAlert("Please enter a valid number.", "danger"));
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleFetchEvents = async (e) => {
        if (state.apiKey) {
            let urlVal = `https://api.cal.com/v1/event-types?apiKey=${state.apiKey}`
            setLoader({
                ...loader,
                fetch: true
            })
            await axios({
                method: "GET",
                url: urlVal
            }).then((res) => {
                if (res.data.event_types.length > 0) {
                    setEventIds(res.data.event_types.map((curElem) => {
                        return {
                            id: curElem.id,
                            title: curElem.title
                        }
                    }))
                }
                setLoader({
                    ...loader,
                    fetch: false
                })
            }).catch((err) => {
                dispatch(setAlert("Please provide a valid API key.", "danger"))
                console.log(err)
                setLoader({
                    ...loader,
                    fetch: false
                })
            })
        } else {
            dispatch(setAlert("Please provide API Key to get events!", "danger"))
        }
    }


    return (
        <Modal className="VideoModal " show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2 > Connect new <span style={{ textTransform: "lowercase" }}>{name}</span> account</h2>
                        </div>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>

                {shortType === "domain" ? <>
                    <form onSubmit={handleSubmit}>
                        <div className='input-wrap mt-4'>
                            <label htmlFor="" className='label'>Enter Domain</label>
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-8'>
                                    <div className="inp-outer">
                                        <input
                                            className="input fill"
                                            type="text"
                                            name='domainName'
                                            placeholder='Enter Name Domain'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                </div>
                                <div className='col-4'>
                                    <button className="site-link blue" disabled={loader.submit}><span>{loader.submit ? <>Submiting<FaSpinner className="spin" /></> : "Check & Add Domain"}</span></button>
                                </div>
                            </div>

                            {/* <div> */}
                            <ul className="white-list-item mt-3 mt-md-4" style={{ listStyle: "disc", paddingLeft: "25px" }}>
                                <li>Please use top-level domains only (sub-domains won’t work).</li>
                                <li>Please enter your domain above without any www, http or https (domain.com for example).</li>
                                <li>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.</li>
                                <li>Copy this IP address for the A Record: 72.52.158.199</li>
                                <li>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the </li>
                                <li>Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".</li>
                                <li>Select your type as "A Record".</li>
                                <li>Set Host as "@".</li>
                                <li>Paste the copied IP Address from step 4 into the value field.</li>
                                <li>Leave TTL as Automatic or 14400.</li>
                                <li>Click Save and you're done.</li>
                                <li>Note: It can take up to 48-72 hours to reflect.</li>
                            </ul>
                            {/* </div> */}
                        </div>
                    </form>
                </>
                    :
                    <form onSubmit={handleSubmit}>
                        <div className="input-wrap mt-4">
                            <label htmlFor="" className='label'>Enter Name</label>
                            <div className="inp-outer">
                                <input
                                    className="input fill"
                                    type="text"
                                    name='name'
                                    placeholder='Enter Name'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        {shortType === "AR" ?
                            <div className="input-wrap mt-4">
                                <label htmlFor="" className='label'>Paste Raw HTML Optin Code</label>
                                <div className="inp-outer">
                                    <textarea
                                        className="input fill"
                                        type="text"
                                        name='code'
                                        rows={5}
                                        placeholder='Enter Code Here'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            :
                            shortType === "EL" ?
                                <>
                                    <div className="input-wrap mt-4">
                                        <label htmlFor="" className='label'>Enter API Key</label>
                                        <div className="inp-outer">
                                            <input
                                                className="input fill"
                                                type="text"
                                                name='apiKey'
                                                placeholder='Enter API Key'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </>

                                : shortType === "stripe" ?
                                    <div className="input-wrap mt-4">
                                        <label htmlFor="" className='label'>Secret Key</label>
                                        <div className="inp-outer">
                                            <input
                                                className="input fill"
                                                type="text"
                                                name='secretKey'
                                                placeholder='Enter Secret Key'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    :
                                    shortType === "openAi" ?
                                        <>
                                            <div className="input-wrap mt-4">
                                                <label htmlFor="" className='label'>OpenAi key</label>
                                                <div className="inp-outer">
                                                    <input
                                                        className="input fill"
                                                        type="text"
                                                        name='apiKey'
                                                        placeholder='Enter openAi key'
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="input-wrap mt-4">
                                            <label htmlFor="" className='label'>Secret Id</label>
                                            <div className="inp-outer">
                                                <input
                                                    className="input fill"
                                                    type="text"
                                                    name='secretId'
                                                    placeholder='Enter Secret ID'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div> */}
                                        </>
                                        :
                                        shortType === "twilio" ?
                                            <>
                                                <div className="input-wrap mt-4">
                                                    <label htmlFor="" className='label'>Account SID</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="input fill"
                                                            type="text"
                                                            placeholder="77adaqe3asn a23xxxq3232342asdnsajoq23jo23ljasej213412"
                                                            name='accountSid'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap mt-4">
                                                    <label htmlFor="" className='label'>Auth Token</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="input fill"
                                                            type="text"
                                                            placeholder="Enter Auth Token"
                                                            onChange={handleChange}
                                                            name='authToken'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap mt-4">
                                                    <label htmlFor="" className='label'>From Number</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="input fill"
                                                            type="text"
                                                            placeholder="Enter Twilio Number"
                                                            onChange={handleChange}
                                                            name='twilioNumber'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap mt-4">
                                                    <label htmlFor="" className='label'>To Number (Need for testing)</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="input fill"
                                                            type="text"
                                                            placeholder="Enter To Number"
                                                            onChange={handleChange}
                                                            name='toNumber'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </>

                                            : shortType === "smtp" ?
                                                <>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>Enter Host</label>
                                                        <div className="inp-outer">
                                                            <input
                                                                className="input fill"
                                                                type="text"
                                                                placeholder="Enter Host"
                                                                name='host'
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>Enter Port</label>
                                                        <div className="inp-outer">
                                                            <input
                                                                className="input fill"
                                                                type="number"
                                                                placeholder="Enter Port"
                                                                name='port'
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>Enter Username</label>
                                                        <div className="inp-outer">
                                                            <input
                                                                className="input fill"
                                                                type="text"
                                                                placeholder="Enter Username"
                                                                onChange={handleChange}
                                                                name='username'
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>Enter Password</label>
                                                        <div className="inp-outer">
                                                            <input
                                                                className="input fill"
                                                                type="password"
                                                                placeholder="Enter Password"
                                                                onChange={handleChange}
                                                                name='password'
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>Enter Email</label>
                                                        <div className="inp-outer">
                                                            <input
                                                                className="input fill"
                                                                type="email"
                                                                placeholder="Enter Email"
                                                                onChange={handleChange}
                                                                name='from'
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap mt-4">
                                                        <label htmlFor="" className='label'>SSL</label>
                                                        <div className="inp-outer">
                                                            <select
                                                                className="input fill"
                                                                onChange={handleChange}
                                                                name='ssl'
                                                                required
                                                            >
                                                                <option value="">Select Option</option>
                                                                <option value="ssl">Yes</option>
                                                                <option value="tls">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                                : shortType === "CC" ?
                                                    <>
                                                        <div className="input-wrap mt-4">
                                                            <label htmlFor="" className='label'>Enter API Key</label>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className="inp-outer" style={{ width: "80%" }}>
                                                                    <input
                                                                        className="input fill"
                                                                        type="text"
                                                                        name='apiKey'
                                                                        placeholder='Enter API Key'
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                </div>
                                                                <button className='site-link' type='button' onClick={handleFetchEvents}><span>Events {loader.fetch ? <FaSpinner className='spin' /> : ""}</span></button>
                                                            </div>
                                                        </div>
                                                        <div className="input-wrap mt-4">
                                                            <label htmlFor="" className='label'>Event Type ID</label>
                                                            <div className="inp-outer" >
                                                                <select
                                                                    className='input fill'
                                                                    name='eventId'
                                                                    placeholder='Enter Event ID'
                                                                    onChange={handleChange}
                                                                    required
                                                                >
                                                                    <option value={""}>Select Event ID</option>
                                                                    {eventIds.length > 0 ?
                                                                        eventIds.map((curElem, index) => {
                                                                            return (
                                                                                <option key={index} value={curElem.id}>{curElem.title}</option>
                                                                            )
                                                                        }) : ""}

                                                                </select>
                                                            </div>
                                                        </div>


                                                    </>

                                                    : ""


                        }
                        <div className="input-wrap text-center mt-2">
                            <button className="site-link blue" disabled={loader.submit}><span>{loader.submit ? <>Connecting <FaSpinner className="fa-psin" /></> : "Connect"}</span></button>
                        </div>
                    </form>
                }

                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: state.code }} />
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal