import React from 'react'

import UpgradeMenu from './UpgradeMenu'
import UpgradeContent from './UpgradeContent'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import TitleBar from '../Common/TitleBar'
import Footer from '../Common/Footer'

const Upgrades = () => {
    return (
        <>
            <TitleBar title="Upgrades" />
            <Header />
            <div className="page-wrap">
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className='account-wrap'>

                        <UpgradeMenu />
                        {/* <div className='account-right large'> */}
                        <UpgradeContent />
                        {/* </div> */}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Upgrades