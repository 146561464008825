import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import { useSelector } from 'react-redux'
import { setAlert } from '../../Redux/Actions/AlertActions'
import getCroppedImg from './CropImage'
import { useDispatch } from 'react-redux'
// import { onGenerateFile, onVideoCrop } from '../../Redux/Actions/VideoActions'
import { AiOutlineClose } from "react-icons/ai";
import { onGenerateFile, onVideoCrop } from '../../Redux/Actions/VideoActions'

const CropModal = ({ show, handleClose, type, cropData, setCropData, handleSelectImage }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        load: false,
        crop: false
    })
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)



    const handleUse = () => {
        let data
        if (type === "images") {
            data = {
                url: cropData.cropped,
                type: "cropImage"
            }
        } else {
            data = {
                url: cropData.cropped,
                thumbnail: cropData.thumbnail
            }
        }
        handleSelectImage(data)
        handleClose()
    }

    const onCropImage = useCallback(async () => {
        setLoader({
            ...loader,
            crop: true
        })
        try {
            const croppedImage = await getCroppedImg(
                cropData.current,
                croppedAreaPixels,
                0
            )
            downloadFiles(croppedImage);
        } catch (e) {
            dispatch(setAlert(e, "danger"))
            console.error(e)
        }
    })

    const onCropVideo = () => {
        setLoader({
            ...loader,
            crop: true
        })
        const formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', cropData.current);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        dispatch(onVideoCrop(formData, loader, setLoader, cropData, setCropData))
    }


    const downloadFiles = (imageData) => {
        let ext = cropData.current.match(/\.([^\./\?]+)($|\?)/)[1];

        let data = {
            data: imageData,
            format: ext
        }
        dispatch(onGenerateFile(data, loader, setLoader, cropData, setCropData))
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(() => {
        if (!show) {
            setCropData({
                ...cropData,
                current: false,
                cropped: false
            })
        }
        return () => {
            setCroppedAreaPixels(null)

        }
    }, [show])

    return (
        <Modal className='theme-modal xl crop-modal' show={show} onHide={handleClose} centered scrollable style={{zIndex:9999}}>
            <span className='modalClose text-end' style={{ cursor: "pointer" }} onClick={handleClose}><AiOutlineClose fontSize={20} /></span>

            <Modal.Header className="crop-header">
                <Modal.Title className="d-flex justify-content-between align-items-center w-100">
                    <h3>Crop {type === "images" ? "image" : type}</h3>
                </Modal.Title>

            </Modal.Header>
            <Modal.Body style={{ padding: "20px 40px", position: "relative", height: "50vh" }} >
                <div className='info-tab h-100' >

                    <div className='row h-100'>
                        <div className='col-sm-12' >
                            {type === "images" ?
                                <Cropper
                                    image={cropData.current}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={campaign.dimension === "16x9" ? 16 / 9 : 9 / 16}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    objectFit="contain"
                                /> :
                                <Cropper
                                    video={cropData.current}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={campaign.dimension === "16x9" ? 16 / 9 : 9 / 16}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />

                            }
                            {
                                croppedAreaPixels === null ?
                                    <div className="crop-icon-center" style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                    }}>
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#00D0B0", fontSize: '25px' }} />
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='common-input-wrap'>
                    <button
                        className='site-link   '
                        onClick={type === "images" ? onCropImage : onCropVideo}
                    >
                        <span>{loader.crop ? <>Cropping <i className="fa fa-spinner fa-spin mx-1" /></> : "Crop"}</span>
                    </button>
                    <button
                        className='site-link    ms-1'
                        onClick={handleUse}
                        style={{ background: cropData.cropped ? "" : "gray" }}
                        disabled={!cropData.cropped}
                    >
                        <span>Use</span>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default CropModal