import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import All from './All';
import Marketing from './Marketing';
import ContentGenerator from './ContentGenerator';
import SmallBusiness from './SmallBusiness';
import Sales from './Sales';
import Entertainment from './Entertainment';
import Others from './Others';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import TitleBar from '../Common/TitleBar';


function ExploreAITools() {
   // url, data, setState, loader, setLoader, limit, isShort
   const dispatch = useDispatch()
   const [state, setState] = useState([])
   const [loader, setLoader] = useState({
      fetch: false
   })
   const fetchToolsData = () => {
      setLoader({
         ...loader,
         fetch: true
      })
      dispatch(fetchData("fetch-all-tools", {}, setState, loader, setLoader))
   }
   useEffect(() => {
      fetchToolsData()
   }, [])

   return (
      <>
         <TitleBar title="AI Tools" />
         <Header />

         <div className='page-wrap'>
            <div className="side-nav">
               <SidePanel />
            </div>

            <div className="page-container">
               <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="v" className="nav-1">
                     <Nav.Item>
                        <Nav.Link eventKey="first">All</Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="second">Marketing</Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="third">Content Generator</Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="fourth">Small Business</Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="fifth">Sales</Nav.Link>
                     </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="sixth">Entertainment</Nav.Link>
                     </Nav.Item>
                     {/* <Nav.Item>
                        <Nav.Link eventKey="seventh">Others</Nav.Link>
                     </Nav.Item> */}
                  </Nav>

                  <Tab.Content className='pt-4'>
                     <Tab.Pane eventKey="first"><All data={state} loader={loader} /></Tab.Pane>
                     <Tab.Pane eventKey="second"><Marketing data={state} loader={loader} /></Tab.Pane>
                     <Tab.Pane eventKey="third"><ContentGenerator data={state} loader={loader} /></Tab.Pane>
                     <Tab.Pane eventKey="fourth"><SmallBusiness data={state} loader={loader} /></Tab.Pane>
                     <Tab.Pane eventKey="fifth"><Sales data={state} loader={loader} /></Tab.Pane>
                     <Tab.Pane eventKey="sixth"><Entertainment data={state} loader={loader} /></Tab.Pane>
                     {/* <Tab.Pane eventKey="seventh"><Others data={state} loader={loader} /></Tab.Pane> */}
                  </Tab.Content>
               </Tab.Container>
            </div>
         </div>

         <Footer />
      </>
   )
}

export default ExploreAITools