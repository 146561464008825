import React, { useState } from 'react'
import Rebranding from './Rebranding';
import wordpress from "../../images/round-icon-wordpress.png";
import download from "../../images/round-icon-download.png";
import importIcon from "../../images/round-icon-import.png"
import customize from "../../images/round-icon-customize.png";
import youtubeimg from '../../images/youtubeimg.jpg';
import modalCloseIcon from '../../images/modal-close.png';
import YoutubeKeySearch from './YoutubeKeySearch';
import { appName } from '../../Global/Global';
import { Modal, Tab } from 'react-bootstrap';
import Week1 from './Week1';
import Week2 from './Week2';
import Week3 from './Week3';
import Week4 from './Week4';
import { CloseCircle } from 'iconsax-react';

const UpgradeContent = () => {

    const [playing, setPlaying] = useState(false);
    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setPlaying(true);
        setFrameUrl('https://player.vimeo.com/video/514577280?');
    }

    const closePop = () => {
        setPlaying(false);
        setFrameUrl('');
    }
    return (
        <>
            <div className="account-right">
                <div className="tabCont-right">
                    <div className="tab-content" id="v-pills-tabContent">

                        <div className="tab-pane fade show active">
                            <div className="tabepaneIn">
                                {/* <h2 className="tabTitle">Welcome</h2> */}
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Welcome</h3>
                                        <p>To access your upgrades, click any of the available options on the left side menu</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-wlrebranding" role="tabpanel" aria-labelledby="v-pills-wlrebranding-tab" >
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Rebranding</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <Rebranding />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-fbtemplate" role="tabpanel" aria-labelledby="v-pills-fbtemplate-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">FB Templates</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h5>Facebook Ads Templates</h5>
                                        <div className="upgrade-para pt-2">
                                            <p className="">
                                                Download this done-for-you Facebook and Instagram ad creatives
                                                and ad copy templates that can easily be customized to be used
                                                for any niche
                                            </p>
                                        </div>
                                    </div>


                                    <div className="tabepaneIn-cont mt-4">
                                        <h5 className='pb-3'>Download your Facebook Ads Templates here</h5>

                                        <a
                                            href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                            target="_blank"
                                        >
                                            <button className="site-link btn-block mt-0 w-100"><span>Download the Templates</span></button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tab-pane fade"
                            id="v-pills-vidoeflix"
                            role="tabpanel"
                            aria-labelledby="v-pills-vidoeflix-tab"
                        >
                            <h5 className="text-white">Video Flix</h5>
                            <div className="upgrade-content">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white  ">
                                            <div className="upgrade-heading">
                                                <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                                            </div>
                                            <div className="upgrade-para">
                                                <p className="text-center ">
                                                    VideoFLIX is a subscription-based video content management app
                                                    built on cloud. Using VideoFLIX you will be able to create
                                                    your own video content sharing website similar to NetFlix,
                                                    HBO-Go, DisneyPLUS etc; sharing your own or others unique
                                                    video content as channels or series. You will be able to
                                                    create free, one-time or monthly payment plans for your
                                                    customers to access your video content and build your video
                                                    content subscription business!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download VideoFlix</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download Tutorial Docs</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-udamy" role="tabpanel" aria-labelledby="v-pills-udamy-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Acadeable</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Acadeable</h3> */}
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download Udemy Style Website to showcase your courses. Instant
                                                Access to Udemy Style Website Builder to Sell Courses
                                            </p>
                                        </div>

                                        <div className='text-center pt-3'>
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                                target="_blank"
                                            >
                                                <button className="site-link btn-block"><span>Download Setup</span></button>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="tab-pane fade"
                            id="v-pills-dfycourse"
                            role="tabpanel"
                            aria-labelledby="v-pills-dfycourse-tab"
                        >
                            <h5 className="text-white">DFY Course</h5>
                            <div className="upgrade-content">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white  ">
                                            <div className="upgrade-heading">
                                                <h6 className="m-0">DFY Courses</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 1</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 11</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 2</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 12</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 3</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 13</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 4</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 14</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 5</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 15</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 6</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 16</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 7</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 17</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Download DFY Course 8</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 18</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 9</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 19</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 10</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center p-3 text-white">
                                            <a
                                                href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download DFY Course 20</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Client Contract</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Client Contract</h3> */}
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download our "Client Contract" template. This is a docx file,
                                                works best with MS Word. Edit the doc and add your business
                                                name, address and other details. Fill the empty blank space
                                                with your service, eg Video Marketing, Local Reputation
                                                Management, Social Media Marketing, Design &amp; Creatives,
                                                Ads Management and so on.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="tabepaneIn-cont-alt">
                                        <div className="upgrade-content">
                                            <div className="row">
                                                <div className="col-xl-4 mb-2">
                                                    <div className="tabepaneIn-cont">
                                                        <div className="upgrade-heading">
                                                            <h6>Brief Pitch Template</h6>
                                                        </div>
                                                        <div className="upgrade-para mt-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="site-link btn-block mt-0 w-100"><span>Download</span></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-2">
                                                    <div className="tabepaneIn-cont">
                                                        <div className="upgrade-heading">
                                                            <h6>Service Agreement</h6>
                                                        </div>
                                                        <div className="upgrade-para mt-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="site-link btn-block mt-0 w-100"><span>Download</span></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4  mb-2">
                                                    <div className="tabepaneIn-cont">
                                                        <div className="upgrade-heading">
                                                            <h6>Meeting Setup Template</h6>
                                                        </div>
                                                        <div className="upgrade-para mt-3">
                                                            <a
                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                                target="_blank"
                                                            >
                                                                <button className="site-link btn-block mt-0 w-100"><span>Download</span></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-reseller" role="tabpanel" aria-labelledby="v-pills-reseller-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Reseller License</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Reseller License</h3> */}
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Go to JVZoo Dashboard and request your affiliate link. Once
                                                you've requested, submit a ticket with your {appName} Business
                                                transaction ID, affiliate ID and email address for our team to
                                                activate your reseller license. Please allow upto 2 working
                                                days.
                                            </p>
                                        </div>
                                        <div className="row   mt-5">
                                            <div className="col-md-6">
                                                <div className="text-center  text-white">
                                                    <div className="">
                                                        <a
                                                            href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/413443"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100">
                                                                <span>Request Affiliate Link</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-center  text-white">
                                                    <div className="">
                                                        <a href="https://support.vineasx.com/" target="_blank">
                                                            <button className="site-link btn-block mt-0 w-100"><span>Raise Support Ticket</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-webhosting" role="tabpanel" aria-labelledby="v-pills-webhosting-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Web Hosting</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Web Hosting</h3> */}
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Please raise a support ticket with your  {appName} purchase
                                                transaction ID, your domain name that you currently own and
                                                wish to use with the hosting, email address, your full name
                                                and a note about how you would be using the webhosting for
                                                your business.
                                            </p>
                                        </div>

                                        <div className='text-center mt-4'>
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="site-link btn-block"><span>Raise a Ticket</span></button>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-videocommercial" role="tabpanel" aria-labelledby="v-pills-videocommercial-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Video Commercial</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Video Commercial</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                6X Stunning &amp; Compelling Video Commercials
                                            </p>
                                        </div>
                                        <a
                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                                            target="_blank"
                                        >
                                            <button className="site-link btn-block"><span>Download Videos</span></button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-videopromo" role="tabpanel" aria-labelledby="v-pills-videopromo-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Video Promos</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Video Promos</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Open a ticket with the following details per video:
                                            </p>
                                            <ol>
                                                <li>Your {appName} credentials</li>
                                                <li>Permission to access your account</li>
                                                <li>
                                                    What is the purpose of this video that you wish to create?
                                                </li>
                                                <li>Name of the template you wish to use</li>
                                                <li>Number of the template you wish to use</li>
                                                <li>Screenshot of the template you wish to use</li>
                                                <li>
                                                    Content for the video. Please click on the EDIT option for
                                                    the template you wish to use and share with us all the text
                                                    content, logo, music file or Text-to-speech content along
                                                    with images/video clips you wish to use in your videos.
                                                </li>
                                            </ol>
                                        </div>
                                        <a href="https://support.vineasx.com/" target="_blank">
                                            {" "}
                                            <button className="site-link btn-block"><span>Raise a Ticket</span></button>
                                        </a>
                                    </div>
                                    <div className="tabepaneIn-cont mt-4">
                                        <h3>Note</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                we have the rights to deny a request if it does not match with
                                                the video template requirements. Video creation can take up to
                                                5-6 working days. The video will be available inside your
                                                {appName} Account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="v-pills-dfylead" role="tabpanel" aria-labelledby="v-pills-dfylead-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Lead Magnets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3 className="text-white">DFY Lead Magnets</h3> */}
                                        <a
                                            href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                            target="_blank"
                                        >
                                            <button className="site-link btn-block mt-0 w-100" type="button">
                                                <span>Download</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-agency" role="tabpanel" aria-labelledby="v-pills-agency-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Agency Website</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Agency</h3> */}
                                        <div className="row">
                                            <div className="col-md-12 mx-auto">
                                                <div className="upgrade-box-content p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={wordpress} alt="wprdpress" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Fresh Install and configure wordpress at your server. If
                                                                    you need help in installing and configuring wordpress,
                                                                    Click here to find the tutorial.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={download} alt="download" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Once wordpress is installed at your server. Download the
                                                                    site content by clicking the "Download site button". This
                                                                    will download a wpress file which you will be needing to
                                                                    import in your wordpress site.
                                                                </p>
                                                                <div className="row  d-flex justify-content-center">
                                                                    <div className="col-md-12">
                                                                        <div className="upgrade-box-content text-center  text-white">
                                                                            <div className="upgrade-para p-3">
                                                                                <a
                                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                                                    target="_blank"
                                                                                >
                                                                                    <button className="site-link btn-block mt-0">
                                                                                        <span>Download the site</span>
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={importIcon} alt="import" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Import the xml file in to your wordpress site. If you need
                                                                    help importing the xml file in wordpress, Click here to
                                                                    find the tutorial.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                    <div className="row">
                                                        <div className="col-md-2 pr-0 text-center ">
                                                            <img src={customize} alt="customize" />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="upgrade">
                                                                <p className="m-0">
                                                                    Customize the site as according to your need. If you need
                                                                    help customizing the site. Click here to find the tutorial
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <h5 className='pt-5'>Tutorial</h5>

                                    <div className="tabepaneIn-cont mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="upload-box-content">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="tutorial-content">
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={wordpress} alt="wordpress" />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a className="btn-block"
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button
                                                                                    title="Install WordPress From cPanel"
                                                                                    className="site-link btn-block mt-0"
                                                                                >
                                                                                    <span>Install WordPress From cPanel</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="row mt-4 align-items-center">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={importIcon} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="site-link btn-block mt-0">
                                                                                    <span>Import Website</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="row mt-4 align-items-center">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={customize} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="site-link btn-block mt-0">
                                                                                    <span>Customize website</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="row mt-4 align-items-center">
                                                                    <div className="col-md-2 text-center">
                                                                        <img src={download} />
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="upgrade ml-lg-4">
                                                                            <a
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                                                target="_blank"
                                                                            >
                                                                                <button
                                                                                    className="site-link btn-block mt-0"
                                                                                    title="Upload Video and Usage in Website"
                                                                                >
                                                                                    <span>Upload Video and Usage in Website</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <h5 className='pt-5'>How to Install the Agency Website</h5>

                                    <div className="tabepaneIn-cont mt-3">

                                        <div className="row justify-content-center">
                                            <div className="col-md-12">
                                                <div className="card fb-card border-0">
                                                    <div
                                                        className="card-header text-center"
                                                        style={{ background: "rgb(80, 243, 202)" }}
                                                    >
                                                        <h6 className="text-white Account-Management-headings">
                                                            How to Install the Agency Website
                                                        </h6>
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <img src={youtubeimg} alt="" className="img-fluid" />
                                                        <span className="youtubeicon" onClick={(e) => addFrameUrl()} style={{ cursor: "pointer", color: '#007bff' }} data-toggle="modal" data-target="#MyModal" ><i class="fas fa-play-circle"></i></span>
                                                    </div>
                                                    <div >

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Social Media Assets</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        <h3>Social Media Assets</h3>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download your plug &amp; play social media assets below
                                            </p>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0"><span>Social Media Calendar</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0"><span>Social Media Captions</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0">
                                                                <span>Social Media Video Posts</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0">
                                                                <span>Inspirational, Motivational Quotes Images</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  d-flex justify-content-center  mt-5">
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0">
                                                                <span>Social Media Image Posts</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="upgrade-box-content text-center  text-white">
                                                    <div className="upgrade-para p-3">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0">
                                                                <span>Library of Quotes for social Media{" "}</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="v-pills-dfyarticles" role="tabpanel" aria-labelledby="v-pills-dfyarticles-tab">

                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Articles</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>DFY Articles</h3> */}

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 1</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 6</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 2</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 7</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 3</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 8</span> </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 4</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                            target="_blank"
                                                        >
                                                            {" "}
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 8 </span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 5</span></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="text-center  text-white">
                                                    <div className="upgrade-para p-2">
                                                        <a
                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="site-link btn-block mt-0 w-100"><span>Article Pack 10</span> </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                        <div
                            className="tab-pane fade"
                            id="v-pills-affiliate"
                            role="tabpanel"
                            aria-labelledby="v-pills-affiliate-tab"
                        >
                            <h5 className="text-white">Afftrack Affiliate Approval</h5>
                            <div className="upgrade-content mt-2">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white  ">
                                            <div className="upgrade-heading">
                                                <h6>Afftrack Affiliate</h6>
                                            </div>
                                            <div className="upgrade-para">
                                                <p className="text-center ">
                                                    If you are not approved within 24 hours, please open a ticket at
                                                    support@vineasx.com
                                                    with your username and email on Paykickstart to get
                                                    approved. We can not approve you if you have a refund rate over 10%
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/10875"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 1
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/10909"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 2
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/11286"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 3
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/11303"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 4
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/11304"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 5
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/11676"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 6
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">

                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/12253"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 7
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/13339"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 8
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/13399"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 9
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/14240"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 10
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/14352"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 11
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/15185"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 12
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/15252"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 13
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/15582"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 14
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/15646"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 15
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/16233"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 16
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/16368"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 17
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/17055"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 18
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/17297"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 19
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/17810"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 20
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/18279"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 21
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/18673"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 22
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/19039"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 23
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/19262"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 24
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex text-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/19269"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 25
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a
                                                    href="https://paykstrt.com/request/19415"
                                                    target="_blank"
                                                >
                                                    <button className="btn-change7">
                                                        Link 26
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tab-pane fade"
                            id="v-pills-youtube"
                            role="tabpanel"
                            aria-labelledby="v-pills-youtube-tab"
                        >

                            <h5 className="text-white mb-3">YouTube Keyword Research</h5>
                            <YoutubeKeySearch />
                            {/* <iframe
                    src="https://app.rankreel.io/?redirect=%2Fkeyword-suggestion"
                    style={{ width: "100%", height: "90vh" }}
                /> */}
                            {/* <div className="upgrade-content">
                    <div className="row">
                        <div className="col-md-12">
                            <iframe />
                        </div>
                    </div>
                </div> */}
                        </div>

                        <div
                            className="tab-pane fade"
                            id="v-pills-intro-outro"
                            role="tabpanel"
                            aria-labelledby="v-pills-intro-outro-tab"
                        >
                            <h5 className="text-white">Intro/Outro Creater</h5>
                            <div className="upgrade-content">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content  text-white">
                                            <div className="upgrade-heading">
                                                <h6>Intro/Outro Creater</h6>
                                            </div>
                                            <div className="upgrade-para">
                                                <p className="text-center ">
                                                    Please raise a support ticket with your {appName} purchase
                                                    transaction ID and a note about how you would be using the Intro/Outro Creater for
                                                    your business.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  d-flex justify-content-center  mt-5">
                                    <div className="col-md-6">
                                        <div className="upgrade-box-content text-center  text-white">
                                            <div className="upgrade-para p-3">
                                                <a href="https://support.vineasx.com/" target="_blank">
                                                    <button className="btn-change7">Raise a Ticket</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-Social-Traffic-Generator" role="tabpanel" aria-labelledby="v-pills-Social-Traffic-Generator-tab">
                            <h5 className="text-white">Social Traffic Generator</h5>
                            <div className="upgrade-content">
                                <div className="row  mt-5">
                                    <div className="col-md-8 mx-auto">
                                        <div className="upgrade-box-content text-center p-3   text-white  ">
                                            <div className="upgrade-heading"></div>
                                            <div className="upgrade-para">
                                                <p className="text-center ">
                                                    Download Social Traffic Generator
                                                </p>
                                            </div>
                                            <a
                                                href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-business" role="tabpanel" aria-labelledby="v-pills-business-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">Business Finder</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>Business Finder</h3> */}
                                        <iframe
                                            src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                            style={{ width: "100%", height: "100vh" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Week1 />
                        <Week2 />
                        <Week3 />
                        <Week4 />


                        <div className="tab-pane fade" id="v-pills-dfy-course" role="tabpanel" aria-labelledby="v-pills-dfy-course-tab">
                            <div className="tabepaneIn">
                                <h2 className="tabTitle">DFY Course</h2>
                                <div className="tabepaneIn-main">
                                    <div className="tabepaneIn-cont">
                                        {/* <h3>DFY Course</h3> */}
                                        <div className="">
                                            <div className="">
                                                <div className="mb-4">
                                                    <div className="title-area">
                                                        <h5>Done For You Courses </h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="row text-center DFY_Course_scroll" style={{

                                                }}>
                                                    <div className="col-md-6 ">
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 1</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip" target="_blank" className="btn btn-3 w-100 mb-2" >Download DFY Course 2</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 3</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 4</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 5</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 6</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/ProvenAffiliateMarketingConcepts.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 7</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 8</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 9</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 10</a>
                                                        </div>


                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 11</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 12</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 13</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 14</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 15</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 16</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 17</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 18</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 19</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 20</a>
                                                        </div>

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/LevelUpLeadership.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 21</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/BeingWhole.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 22</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/BetterHabits.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 23</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/LifeOfContentment.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 24</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/MetaverseMadeSimple.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 25</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/UnlockYourFullPotential.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 26</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmbraceYourWeirdness.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 27</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/FindingBalance.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 28</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/SimpleProductivity.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 29</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/SpeakWithConfidence.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 30</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/StartWithClarityVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 31</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/VirtualNetworkingSuccess.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 32</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Focus.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 33</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyHabits_Videos.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 34</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/OvercomeImposterSyndrome.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 35</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/OvercomePhoneAddiction.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 36</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/PowerOfVisualisation_up.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 37</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/SolopreneurSuccess.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 38</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href=" https://acadeable.s3.amazonaws.com/dfycoursex196/SuperiorBrainHealth_VideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 39</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/TheMorningRitual.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 40</a>
                                                        </div>



                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/How-to-Stop-Shopping-Cart-Abdonment.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 41</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Why+Hate+Mail+Is+A+GOOD+Thing+And+What+You+Should+Do+About+It.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 42</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Internet+Marketing+Lifestyle+Upsell+Sales+Video.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 43</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Tips-For-Increasing-Your-Holiday-Sales-This-Season.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 44</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Sell-More-With-These-Content-Writing-Tips.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 45</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Killer+Ideas+On+How+To+Increase+Sales+Using+Affiliate+Newsletters.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 46</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/VSL+Instagram+stories+Upsell.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 47</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/How-to-Add-Hundreds-of-Subscribers-Weekly.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 48</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Doubling+Your+Sales+With+These+Tricks.zip " target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 49</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Generation-Z-and-what-it-means-for-your-marketing.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 50</a>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/HIIT+It+Hard+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 51</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/IM+for+Newbies+Upsell+Sales+Video.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 52</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Doubling+Your+Sales+With+These+Tricks.mp4.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 53</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Cautionary+Email+Marketing+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 54</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Free+Report+Money+Train+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 55</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/List+Building+Kickstart+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 56</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Setup+Your+Own+Search+Engine+Video.mp4.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 57</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/CopyCat+Buster.mp4.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 58</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Niche+Membership+Site+Carving+Tips+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 59</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Finding+The+Money+Keywords.zip" target="_blank" className="btn btn-2 w-100 mb-3" >Download DFY Course 60</a>
                                                        </div>



                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/OvercomeObstacles.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 61</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/TotalMentalResilience_UP.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 62</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/MagicOfStartingOver_UP.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 63</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/InstagramGuides.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 64</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/InfluencerSecretsVideouP.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 65</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/easyKeto.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 66</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/SocialMediaMkt.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 67</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/ABeginnersGuideToVisualisation_UP.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 68</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Mindfulness.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 69</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/FreeFBTrafficStrategies.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 70</a>

                                                        </div>


                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/UltimateHomeWorkout.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 71</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/HowToStartaFreelanceBusiness.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 72</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/TheWinningMindsetFormula_VideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 73</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/DisconnectToReconnectVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 74</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/Resilience.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 75</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/ZoomMasterVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 76</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/BoostYourImmuneSystemVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 77</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/PersonalBrandingBlueprint.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 78</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 79</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/RelentlessDriveVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 80</a>
                                                        </div>



                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Boost+Your+Online+Sales.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 81</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/The+Real+Law+Of+Attraction+Code.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 82</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/The+Organized+Life+Video+2.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 83</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Peaceful+Chaos.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 84</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Bitcoin+Breakthrough.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 85</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/The+Traffic+Handbook+Video+Course.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 86</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Become+The+Best+Version+Of+Yourself.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 87</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/TikTok+Marketing+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 88</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Virtual+Summit+Secrets+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 89</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Get+What+You+Really+Want+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 90</a>
                                                        </div>


                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Intermittent+Fasting+Formula+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 91</a>

                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Supercharge+Your+Body+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 92</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Social+Messaging+Apps+For+Marketers+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 93</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Work+From+Home+Productivity+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 94</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/How+To+Stop+Worrying+What+Other+People+Think+Of+You.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 95</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Peak+Productivity+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 96</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Unplug+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 97</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Carb+Cycling+for+Weight+Loss.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 98</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/The+Abundance+Mindset.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 99</a>
                                                        </div>
                                                        <div className="btn-wrapper">
                                                            <a href="https://reeelapps-app.s3.us-west-2.amazonaws.com/CoursereelAI/DFY+Course/Instagram+Ads+Success+Video+Upgrade.zip" target="_blank" className="btn btn-2 w-100 mb-2" >Download DFY Course 100</a>
                                                        </div>


                                                    </div>

                                                </div>





                                                <div className="col-md-12 text-center mt-3">
                                                    <hr />
                                                    <div className="btn-wrapper">
                                                        <a href="https://support.vineasx.com" target="_blank" className="btn btn-3 pricing-btn" >Request Custom Course</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <Modal className='VideoModal md-modal' show={playing} onHide={closePop} centered>
                <div className='titleBar'>
                    <div className='titleBar-left'>
                    </div>
                    <span className='modalClose' onClick={closePop}><CloseCircle /></span>
                </div>
                <Modal.Body>
                    <div style={{ width: "100%", height: "360px" }}>
                        <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} style={{ width: "100%", height: "100%" }} allowFullScreen=""></iframe>
                    </div>
                </Modal.Body>
            </Modal >



        </>
    )
}

export default UpgradeContent