import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Profile2User, Edit, Trash, UserSquare, CloseCircle } from 'iconsax-react'
import { useDispatch } from 'react-redux';
import { addAccData } from '../../../Redux/Actions/AccountManagements';

const CreateAccountModal = ({ show, handleClose, fetchMembers }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false,
    });
    const [user, setUser] = useState({
        type: 'team_member',
        email: "",
        name: "",
        password: "",
    });


    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false,
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (user.password) {
            if (pattern.test(user.password)) {
                setLoader({
                    ...loader,
                    create: true,
                });
                dispatch(addAccData(user, fetchMembers, loader, setLoader, setUser, handleClose));
                setPasswordMsg({ ...passwordMsg, validation: false, msg: "" });
            } else {
                setPasswordMsg({
                    ...passwordMsg,
                    msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.",
                    validation: true,
                });
            }
        }
    };

    useEffect(() => {
        if (!show) {
            setUser({
                type: 'team_member',
                email: "",
                name: "",
                password: "",
            })
            setPasswordMsg({
                msg: "",
                validation: false
            })
        }
    }, [show])
    return (
        <Modal className="VideoModal sm-modal" show={show} onHide={handleClose} centered>
            <Modal.Body className='p-4'>
                <div className='titleBar'>
                    <div className='titleBar-left'>
                        <h2>Create Team Member Account.</h2>
                        <p>Manage memebers account</p>
                    </div>
                    <span className='modalClose' onClick={handleClose}><CloseCircle /></span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-wrap mt-4">
                        <label className="label">Account Type</label>
                        <select className="input fill" name="type" onChange={handleChange}>
                            <option value="team_member">Team Member</option>
                            {/* <option value="client">Client</option> */}
                        </select>
                    </div>
                    <div className='input-wrap mt-4'>
                        <label className='label' htmlFor="">Account Name</label>
                        <input className='input fill' type="text" placeholder="Enter Account Name"

                            name="name"
                            value={user.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='input-wrap mt-4'>
                        <label className='label' htmlFor="">Account Email</label>
                        <input className='input fill' type="email" placeholder='Enter Email Here'

                            required
                            name="email"
                            value={user.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-wrap mt-4'>
                        <label className='label' htmlFor="">Password</label>
                        <input className='input fill' type="password" placeholder="********"
                            required
                            name="password"
                            value={user.password}
                            onChange={handleChange} />
                    </div>
                    {passwordMsg.validation ?
                        <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                    <div className='input-wrap mt-4 text-end'>
                        <button type="submit" className="site-link blue" disabled={loader.create}>
                            <span> {loader.create ? <>Creating  <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Create'}
                            </span>
                        </button>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateAccountModal