import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router';
import queryString from "query-string";
import TrainingTitles from './TrainingTitles';
import SidePanel from '../../Common/SidePanel';
import Header from '../../Common/Header';

const TrainingArticles = () => {

    const location = useLocation()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.help);
    const articleData2 = useSelector(state => state.help.article);

    const filterArticle = articleData2.filter(article => article.id === id)[0];
    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];

    return (
        <>
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    {/* <div className='container-area'> */}

                    <div className='account-wrap pb-5'>
                        {/* <div className='titleBar'> */}
                        <div className='account-left'>
                            {/* <h2>Help</h2>
                                    <p>Select from Popular Topics</p> */}
                            <TrainingTitles id={id} supportData={articleData} name={filterName} />
                        </div>
                        <div className="account-right large">
                            <h2 className="tabTitle">
                                <Link className="vidoebtn" to="/help-and-support"><span className="mr-1">
                                    <i className="fas fa-arrow-left"></i></span> Back to  Training
                                </Link>
                            </h2>

                            <div className="tab-content mt-4" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                    {
                                        filterArticle.items.length > 0 ?
                                            filterArticle.items.map((item, index) => {
                                                return (
                                                    item.id == art_id ?
                                                        <>
                                                            <div className="tabepaneIn-main">
                                                                <div className="dashboard-block tabepaneIn-cont-alt">

                                                                    <div className=""
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                        : ''
                                                )
                                            })
                                            : ''
                                    }

                                </div>

                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default TrainingArticles