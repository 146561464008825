import React, { useState } from "react";
import footerLogo from "../../images/aisuits_logo.png"
const Footer = () => {

  return (
    <>
      <footer className="site-footer">
        <div className="site-footer-in">
          <div className="footer-link">
            <ul>
              <li><a target="_blank" href="https://aiwhitelabels.io/terms.html">TERMS</a></li>
              <li><a target="_blank" href="https://aiwhitelabels.io/privacy.html">PRIVACY</a></li>
              <li><a target="_blank" href="http://support.vineasx.com/">SUPPORT</a></li>
            </ul>
          </div>
          <div className="footer-copy">
            <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied. <br /> © 2024, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by <a target="_balnk" href="https://www.vega6.com">Vega6</a></p>
          </div>
          {/* <div className="footer-logo"><img src={require("../../images/aisuits_logo_light.png")} alt="" /></div> */}
          <div className="footer-logo"><a href="https://aisuites.io/" target="_blank"><img src={footerLogo} alt="" /></a></div>
        </div>
      </footer>
    </>
  )
}

export default Footer;