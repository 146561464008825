import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        description: "",
        email: "",
        password: "",
        image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/AIwhitelabels1/1736590571_fTegUBMkr9XJPSysPy3HdoAIwhitelabels1.png"
    },
    activeAgency: false
}

export const AgencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_AGENCY_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_AGENCY":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })

        case "ACTIVE_AGENCY":
            return produce(state, (draft) => {
                draft.activeAgency = action.payload
            })
        case "DEACTIVE_AGENCY":
            return produce(state, (draft) => {
                draft.activeAgency = false
            })


        case "EDIT_AGENCY":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "DELETE_AGENCY":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "UNMOUNT_CREATE_AGENCY":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })
        default:
            return state
    }
}