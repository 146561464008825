import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Profile2User, Edit, Trash, UserSquare, CloseCircle } from 'iconsax-react'
import { useDispatch } from 'react-redux';
import { onEditClient } from '../../../Redux/Actions/AccountManagements';
const EditAccountManagent = ({ show, handleClose, curElem, type, fetchMembers }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({
        type: 'team_member',
        email: "",
        name: "",
        password: "",
    });
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedUser = { ...user };
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (user.password && !pattern.test(user.password)) {
            setPasswordMsg({
                msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and be at least 8 characters long.",
                validation: true,
            });
            return;
        }
        setLoader(true);
        dispatch(onEditClient(updatedUser, fetchMembers, handleClose, setLoader));
    };

    useEffect(() => {
        if (curElem) {
            setUser({
                ...user,
                type: type,
                name: curElem.name,
                email: curElem.email,
                id: curElem.id
            })
        }
    }, [curElem])

    return (
        <Modal className="VideoModal sm-modal" show={show} onHide={handleClose} centered>
            <Modal.Body className='p-4'>
                <div className='titleBar'>
                    <div className='titleBar-left'>
                        <h2>Edit Accounts</h2>
                        <p>Manage memebers account</p>
                    </div>
                    <span className='modalClose' onClick={handleClose}><CloseCircle /></span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='input-wrap mt-4'>

                        <label className='label' htmlFor="">Account Type</label>
                        <input className='input fill' type="text"

                            required
                            value={user.type}
                            readOnly
                            disabled
                        />
                    </div>
                    <div className='input-wrap mt-4'>

                        <label className='label' htmlFor="">Account Name</label>
                        <input className='input fill' type="text" placeholder='Enter Name Here'
                            name="name"
                            value={user.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-wrap mt-4'>
                        <label className='label' htmlFor="">Account Email</label>
                        <input className='input fill' type="email" placeholder='Enter Email Here'
                            required
                            readOnly
                            name="email"
                            value={user.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-wrap mt-4'>
                        <label className='label' htmlFor="">Password</label>
                        <input className='input fill' type="password"
                            placeholder="********"
                            name="password"
                            value={user.password}
                            onChange={handleChange}
                        />
                        {passwordMsg.validation ? <small className="text-danger">{passwordMsg.msg}</small> : ""}
                    </div>
                    <div className='input-wrap mt-4 text-end'>
                        {/* <button className='site-link blue'><span>Update Member</span></button> */}
                        <button type="submit" className="site-link blue"><span>{loader ? <>Updating  <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Update'}</span></button>
                    </div>
                </form>
            </Modal.Body> 
        </Modal>

    )
}

export default EditAccountManagent