import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router'
import { resetPassword } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import queryString from "query-string";
import { appName } from "../../Global/Global";
import TitleBar from '../Common/TitleBar';

import Footer from '../Common/Footer';
import LoginHeader from '../Common/LoginHeader';

function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let location = useLocation()
  const [view1, setView1] = useState(false)
  const [view2, setView2] = useState(false)

  const [loader, setLoader] = useState(false)
  const [loginInfo, setLoginInfo] = useState({
    password: null,
    confirm_password: null,
    password_token: queryString.parse(location.search).token
  })

  const [passwordMsg, setPasswordMsg] = useState({
    msg: "",
    validation: false
  })


  const handleChange = (e) => {
    const { name, value } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
    if (loginInfo.password) {
      if (pattern.test(loginInfo.password)) {
        if ((loginInfo.password === loginInfo.confirm_password)) {
          setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
          setLoader(true);
          dispatch(resetPassword(loginInfo, setLoader, navigate));
        } else {
          setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
        }
      } else {
        setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
      }
    }
  }
  return (
    <>
      <TitleBar title="Forgot Password" />

      <div className="login-header"><LoginHeader /></div>

      <div className='login-container'>
        <div className='container'>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="login-left">
                {/* <div className="logo mb-4"><img src={require("../../images/logo.svg").default} alt="" /></div> */}
                <h3>Welcome to <span className='gradient-txt'>{appName}</span></h3>
                <p className='pt-3'>
                  Ready to create and sell your own AI tools? Log in to access a platform that empowers you to build, customize, and white-label cutting-edge AI solutions. Start turning your ideas into profitable tools you can brand as your own and sell to your audience with ease!</p>

              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                <div className="login-right">
                  <div className='login-card'>
                    <h2><span className='gradient-txt'>Reset</span> Password</h2>
                    <div className="input-wrap mt-4">
                      <label className='label' htmlFor="">Password:</label>
                      <input
                        type="password"
                        name="password"
                        className="input"
                        placeholder="************"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrap mt-4">
                      <label className='label' htmlFor="">Confirm Password:</label>

                      <input
                        type="password"
                        name="confirm_password"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="************"
                        required
                      />
                    </div>
                    <div className="input-wrap mt-4">

                      <button type="submit" className="site-link lg full mt-1" disabled={loader}>
                        <span>{loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password</span>
                      </button>
                    </div>
                    {/* <p className='text-center pt-4'>Don't have account? <Link className='txt-orange' to="/register">Register</Link></p> */}
                    <p className='text-center pt-2'>Remember Password? <Link className='txt-orange' to="/">Login</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="login-footer"><Footer /></div>
    </>
  )
}

export default ResetPassword