import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router";
import { appName } from "../../Global/Global";
import { IoIosArrowDropright } from "react-icons/io";
import { TbBriefcaseFilled } from "react-icons/tb";
import { BsPlayCircle } from "react-icons/bs";
import CusstomToolModel from "../Common/CusstomToolModel";
import TeamplateModel from "../Common/TeamplateModel";
import { Tooltip } from "react-tooltip";

const DashboardHead = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrandData = useSelector(state => state.rebrand.data);
    const [showPopup, setShowPopup] = useState(false);
    const [showClose, setShowClose] = useState(false)

    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [toolId, setToolId] = useState("0")

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);

    const [memberShip, setMemberShip] = useState([])

    const colorObj = {
        color: "#000000",
        position: "absolute",
        top: 30,
        left: "-141px",
        fontSize: 10,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: 5,
        borderRadius: 5,
        cursor: "pointer"
    }

    const handleCreateTool = () => {
        if (agencyId) {
            handleShow1()
        }
    }


    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <section className="hero-banner">
                <div className="hero-video">
                    <iframe src="https://player.vimeo.com/video/428018128?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                </div>
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    <h3>{rebrandData ? rebrandData.name : appName}</h3>
                                    <p>
                                        Here, you can design, customize, and manage your AI tools with powerful features. Whitelabel your solutions, track progress, and get ready to sell under your own brand. Whether for business or clients, everything you need to create market-ready AI tools is right here.
                                    </p>
                                    <a onClick={handleShow} className="site-link mt-4"><span><i class="fa fa-play" aria-hidden="true"></i> Watch Demo Video</span></a>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="hero-banner-right">
                                    {!agencyId ?
                                        <Tooltip
                                            id={'my-tooltip-custom-tool-create'}
                                            style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                                            place="top"
                                            content={"Please create an agency first to create new tool."}
                                        /> : null}

                                    {+ auth.user.isClientAccount !== 1 ? <a
                                        onClick={handleCreateTool}
                                        className="bannerLink"
                                        style={agencyId ? { cursor: "pointer" } : { background: "gray", cursor: "not-allowed", color: "white" }}
                                        data-tooltip-id="my-tooltip-custom-tool-create"
                                    >
                                        <div className="bannerLink-left">
                                            {/* <img src={iconUplod} /> */}
                                            <div className="bannerLink-left-icon icon-second"><TbBriefcaseFilled /></div>
                                            Create New Tool
                                        </div>
                                        <div className="bannerLink-right"><IoIosArrowDropright /></div>
                                    </a> : ""
                                    }

                                    <Link to={`/my-tools?aid=${agencyId}`} className="bannerLink">
                                        <div className="bannerLink-left">
                                            {/* <img src={iconMic} /> */}
                                            <div className="bannerLink-left-icon icon-third"><BsPlayCircle /></div>
                                            View Tools
                                        </div>
                                        <div className="bannerLink-right"><IoIosArrowDropright /></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CusstomToolModel show={show} handleClose={handleClose} toolId={toolId} type="custom" agencyId={agencyId} />
            <TeamplateModel show={show1} handleClose={handleClose1} />


            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/1046733221?badge=0&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" allowfullscreen="" allow="autoplay,fullscreen,picture-in-picture" title="Player for CourseReel Intro" data-ready="true" tabindex="-1"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="VideoModal" show={showPopup} onHide={handelClose2} centered>
                <Modal.Body>
                    <div onClick={() => setShowClose(true)} className="vidClose" >
                        <div style={{ position: "relative" }}>
                            <img alt="" src={modalCloseIcon} />
                            {showClose ?
                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                        </div>

                    </div>
                    <div className="modalVidFb">
                        <div className="para_ai h-100 p-5">
                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                <p style={{
                                    fontSize: "3rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: "3.2rem",
                                    color: "#fff"
                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                <button className="btn btn-danger mt-4 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "1.5rem" }}> Click Here</button>
                            </a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}

export default DashboardHead;