import React, { useState } from 'react'
import { BsExclamationCircle } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onChangeIntegrationAutoResponder, onChangeIntegrationAutoResponderEnable, onChangeIntegrationDomain, onChangeIntegrationDomainEnable, onChangeIntegrationOpenAi, onChangeIntegrationOpenAiEnable, onChangeIntegrationSmtp, onChangeIntegrationSmtpEnable, onChangeIntegrationStripe, onChangeIntegrationStripeEnable } from '../../Redux/Actions/CreateToolActions';
import CommonWysiwyg from '../Common/CommonWysiwyg';
import { Link, useNavigate } from 'react-router';

function BuilderIntegration() {

    const integration = useSelector(state => state.createTool.data.integrations);
    const socials = useSelector(state => state.social)
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const handleChecked = (e, type) => {
        const { name, checked } = e.target
        if (type === "smtp") {
            dispatch(onChangeIntegrationSmtpEnable(name, checked))
        } else if (type === "stripe") {
            dispatch(onChangeIntegrationStripeEnable(name, checked))
        } else if (type === "autoresponder") {
            dispatch(onChangeIntegrationAutoResponderEnable(name, checked))
        } else if (type === "domain") {
            dispatch(onChangeIntegrationDomainEnable(name, checked))
        } else {
            dispatch(onChangeIntegrationOpenAiEnable(name, checked))
        }
    }

    const handleChange = (e, type) => {
        const { name, value } = e.target
        if (type === "stripe") {
            dispatch(onChangeIntegrationStripe(name, value))
        } else if (type === "autoresponder") {
            dispatch(onChangeIntegrationAutoResponder(name, value))
        } else if (type === "domain") {
            dispatch(onChangeIntegrationDomain(name, value))
        } else {
            dispatch(onChangeIntegrationOpenAi(name, value))
        }
    }

    const handleChangeSmtp = (e) => {
        const { name, value } = e.target
        dispatch(onChangeIntegrationSmtp(name, value))
    }

    const dataFunction = (value, name) => {
        dispatch(onChangeIntegrationSmtp(name, value))
    }

    return (
        <>
            <div className='builder-area left-align'>
                <div className='design-left scrollHeight hideScrollBar'>
                    <div className='builder-block'>
                        <div className="row flex-row">
                            <div className="col-12 col-sm-auto">
                                <div className='builder-block-icon'></div>
                            </div>
                            <div className="col-12 col-sm py-3 p-sm-0">
                                <h6>Collect payments (Stripe)</h6>
                                <p>Connect your stripe account to collect payments from your customers.</p>
                                {integration.stripe.enable ?
                                    <>
                                        <select
                                            className='input sm mt-3 fill'
                                            onChange={(e) => handleChange(e, "stripe")}
                                            name='stripeId'
                                            value={integration.stripe.stripeId}
                                            disabled={auth.user.isClientAccount === "1"}
                                        >
                                            <option value="">Select Account</option>
                                            {socials.stripe.length > 0 ?
                                                socials.stripe.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                    )
                                                })
                                                : null}
                                        </select>

                                        <div className='mt-3'>
                                            <label className='label' htmlFor="">Pay amount ($)</label>
                                            <input
                                                className='input fill small'
                                                type="number"
                                                name='amount'
                                                onChange={(e) => handleChange(e, "stripe")}
                                                value={integration.stripe.amount}
                                                placeholder='Enter pay amount'
                                                onKeyDown={(e) => {
                                                    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                readOnly={auth.user.isClientAccount === "1"}
                                            />
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                            <div className="col-12 col-sm-auto">
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "stripe")}
                                        name="enable"
                                        checked={integration.stripe.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                        </div>
                        {socials.stripe.length > 0 ? ""
                            :
                            <div className="mt-2 ms-2">
                                <div class="alert alert-info" role="alert">
                                    You have not connected Stripe. Please go to Integrations and connect Stripe.{auth.user.isClientAccount === "1" ? "" : <Link to={"/integrations"} >Click Here</Link>}
                                </div>
                            </div>
                        }
                    </div>

                    <div className='builder-block mt-3'>
                        <div className="row flex-row">
                            <div className="col-12 col-sm-auto">
                                <div className='builder-block-icon'></div>
                            </div>
                            <div className="col-12 col-sm py-3 p-sm-0">
                                <h6>Send automated email</h6>
                                <p>Send an automated email to your tool users (must collecting emails).</p>
                            </div>
                            <div className="col-12 col-sm-auto">
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "smtp")}
                                        name="enable"
                                        checked={integration.smtp.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                        </div>
                        {socials.smtp.length > 0 ? ""
                            :
                            <div className="mt-2 ms-2">
                                <div class="alert alert-info" role="alert">
                                    You have not connected SMTP. Please go to Integrations and connect SMTP.{auth.user.isClientAccount === "1" ? "" : <Link to={"/integrations"} >Click Here</Link>}
                                </div>
                            </div>
                        }

                        {integration.smtp.enable ?
                            <>
                                <div className='mt-3'>
                                    <label className='label' htmlFor="">Select your SMTP</label>
                                    <select
                                        className='input sm fill'
                                        onChange={(e) => handleChangeSmtp(e)}
                                        name='smtpId'
                                        value={integration.smtp.smtpId}
                                        disabled={auth.user.isClientAccount === "1"}
                                    >
                                        <option value="">Select Account</option>
                                        {socials.smtp.length > 0 ?
                                            socials.smtp.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : null}
                                    </select>

                                </div>

                                <div className='mt-3'>
                                    <label className='label' htmlFor="">Subject <BsExclamationCircle /></label>
                                    <input
                                        className='input fill small'
                                        type="text"
                                        name='subject'
                                        onChange={handleChangeSmtp}
                                        value={integration.smtp.subject}
                                        placeholder='Thank you'
                                    />
                                </div>

                                <div className='mt-3'>
                                    <label className='label' htmlFor="">Sender name: <BsExclamationCircle /></label>
                                    <input
                                        className='input fill small'
                                        type="text"
                                        name='name'
                                        onChange={handleChangeSmtp}
                                        value={integration.smtp.name}
                                        placeholder='Ivan'
                                    />
                                </div>

                                <div className='mt-3'>
                                    <label className='label' htmlFor="">Reply To: <BsExclamationCircle /></label>
                                    <input
                                        className='input fill small'
                                        type="text"
                                        name='replyTo'
                                        onChange={handleChangeSmtp}
                                        value={integration.smtp.replyTo}
                                        placeholder='Ivan@aiwhitelabel.com'
                                    />
                                </div>

                                <div className='mt-3'>
                                    <CommonWysiwyg
                                        text={integration?.smtp?.email?.replaceAll("\n", "<br>")}
                                        name={"email"}
                                        dataFunction={dataFunction}
                                    />
                                </div>
                            </>
                            : ""
                        }

                    </div>

                </div>

                <div className='design-left scrollHeight hideScrollBar'>
                    <div className='builder-block mt-3 mt-md-0'>
                        <div className="row flex-row">
                            <div className="col-12 col-sm-auto">
                                <div className='builder-block-icon'></div>
                            </div>
                            <div className="col-12 col-sm py-3 p-sm-0">
                                <h6>Open AI</h6>
                                <p>Connect your Open AI account for your customers.</p>
                                {integration.openai.enable ?
                                    <select
                                        className='input sm mt-3 fill'
                                        onChange={(e) => handleChange(e, "openai")}
                                        name='openAiId'
                                        value={integration.openai?.openAiId}
                                    >
                                        <option value="">Select Account</option>
                                        {socials.openai.length > 0 ?
                                            socials.openai.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                    : ""
                                }
                            </div>
                            <div className="col-12 col-sm-auto">
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "openai")}
                                        name="enable"
                                        checked={integration.openai.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                        </div>
                        {socials.openai.length > 0 ? ""
                            :
                            <div className="mt-2 ms-2">
                                <div class="alert alert-info" role="alert">
                                    You have not connected Open AI. Please go to Integrations and connect Open AI.{auth.user.isClientAccount === "1" ? "" : <Link to={"/integrations"} >Click Here</Link>}
                                </div>
                            </div>
                        }
                    </div>

                    <div className='builder-block mt-3'>
                        <div className="row flex-row">
                            <div className="col-12 col-sm-auto">
                                <div className='builder-block-icon'></div>
                            </div>
                            <div className="col-12 col-sm py-3 p-sm-0">
                                <h6>Autoresponder</h6>
                                <p>Connect your Autoresponder account.</p>
                                {integration.autoresponder.enable ?
                                    <select
                                        className='input sm mt-3 fill'
                                        onChange={(e) => handleChange(e, "autoresponder")}
                                        name='autoresponderId'
                                        value={integration.autoresponder.autoresponderId}
                                        disabled={auth.user.isClientAccount === "1"}
                                    >
                                        <option value="">Select Account</option>
                                        {socials.autoresponder.length > 0 ?
                                            socials.autoresponder.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                    : ""
                                }
                            </div>
                            <div className="col-12 col-sm-auto">
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "autoresponder")}
                                        name="enable"
                                        checked={integration.autoresponder.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                        </div>
                        {socials.autoresponder.length > 0 ? ""
                            :
                            <div className="mt-2 ms-2">
                                <div class="alert alert-info" role="alert">
                                    You have not connected Auto responder. Please go to Integrations and connect Auto responder.{auth.user.isClientAccount === "1" ? "" : <Link to={"/integrations"} >Click Here</Link>}
                                </div>
                            </div>
                        }
                    </div>

                    <div className='builder-block mt-3'>
                        <div className="row flex-row">
                            <div className="col-12 col-sm-auto">
                                <div className='builder-block-icon'></div>
                            </div>
                            <div className="col-12 col-sm py-3 p-sm-0">
                                <h6>Domain</h6>
                                <p>Connect your domain account.</p>
                                {integration.domain.enable ?
                                    <select
                                        className='input sm mt-3 fill'
                                        onChange={(e) => handleChange(e, "domain")}
                                        name='domain'
                                        value={integration.domain.domain}
                                    >
                                        <option value="">Select Account</option>
                                        {socials.domain.length > 0 ?
                                            socials.domain.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.domain}>{curElem.domain}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                    : ""
                                }
                            </div>
                            <div className="col-12 col-sm-auto">
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "domain")}
                                        name="enable"
                                        checked={integration.domain.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                            {socials.domain.length > 0 ? ""
                                :
                                <div className="mt-2 ms-2">
                                    <div class="alert alert-info" role="alert">
                                        You have not connected Domain. Please go to Integrations and connect Domain.{auth.user.isClientAccount === "1" ? "" : <Link to={"/integrations"} >Click Here</Link>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuilderIntegration