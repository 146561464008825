import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Profile2User, Edit, Trash, UserSquare, CloseCircle } from 'iconsax-react'
import { useSearchParams } from 'react-router';
import { onDeleteAcc } from '../../../Redux/Actions/AccountManagements';
import { useDispatch } from 'react-redux';
const DeleteAccountMangaemant = ({ show, handleClose, curElem, fetchMembers }) => {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const handleDelete = () => {
        setLoader(true)
        let data = {
            client_id: curElem.id,
        }

        dispatch(onDeleteAcc(data, fetchMembers, setLoader, handleClose));
    }
    return (
        <Modal className="VideoModal sm-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='text-center'>
                    <div className='modalIcon'><Trash /></div>
                    <p className='pt-4'>Are you sure you want to delete this member?</p>
                    <div className='btnGroup mt-4 mb-4 text-center'>
                        <button className='site-link blue' onClick={handleClose}><span>Cancel</span></button>
                        {/* <button className='site-link red'><span>Delete</span></button> */}
                        <button className="site-link blue" onClick={handleDelete}><span>{loader ? <>Deleteing  <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Delete'}</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteAccountMangaemant