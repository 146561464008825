import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onUpdatePassword } from '../../../Redux/Actions/AuthActions'


const AccountPassword = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        password: false,
    })

    const [password, setPassword] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handlePassword = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }

    const updatePassword = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (password.new_password) {
            if (pattern.test(password.new_password)) {
                if (password.new_password === password.confirm_password) {
                    setLoader({ ...loader, password: true })
                    dispatch(onUpdatePassword(password, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Passwords do not match!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and be at least 8 characters long.", validation: true })
            }
        }
    }

    return (
        <div className='dashboard-block'>

            <h2 className='account-title'>Update Password</h2>
            <form onSubmit={updatePassword}>
                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>Current Password</label>
                    <input className='input' type="password" placeholder="**********"

                        name="password"
                        onChange={handlePassword}
                        required
                    />
                </div>
                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>New Password</label>
                    <input className='input' type="password" placeholder="**********"
                        name="new_password"
                        onChange={handlePassword}
                        required
                    />
                </div>
                <div className='input-wrap mt-4'>
                    <label htmlFor="" className='label'>Re-type New Password</label>
                    <input className='input' type="password" placeholder="**********"
                        name="confirm_password"
                        onChange={handlePassword}
                        required
                    />
                </div>
                {passwordMsg.validation ?
                    <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                <div className='alert alert-primary mt-4'>Updating your password will log you out for re-login.</div>

                <div className='input-wrap text-end mt-4'>


                    <button type="submit" className="site-link blue" disabled={loader.password}> <span> {loader.password ? <>Updating <i className="fa fa-spinner fa-spin mr-2" /></> : 'Update'} </span></button>
                </div >
            </form>
        </div>
    )
}

export default AccountPassword
