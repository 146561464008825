import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FaBox } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { MdChevronLeft } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';
import CusstomToolModel from './CusstomToolModel';
import appIcon from "../../images/appicon.jpg"
const BroserModel = ({ show2, handleClose2 }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState([])
    const [search, setSearch] = useState("")
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [selectedCategory, setSelectedCategory] = useState("");
    const [templates, setTemplates] = useState([]);
    const fetchToolsData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-all-tools", {}, setState, loader, setLoader))
    }
    useEffect(() => {
        fetchToolsData()
    }, [])
    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [toolId, setToolId] = useState({})
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleChange = (curEem) => {
        setToolId(curEem)
    }
    const handleOpenModel = () => {
        setShow6(true);
    }
    const handleCategoryChange = (e) => {

        const category = e.target.value;
        setSelectedCategory(category);
    };
    useEffect(() => {

        if (selectedCategory === "") {
            setTemplates(state)
        } else {

            const filteredTemplates = state.filter(
                (template) => template.category === selectedCategory
            );
            setTemplates(filteredTemplates);
        }
    }, [selectedCategory, state])

    return (
        <>
            <Modal className="VideoModal large" show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <div className='p-3'>
                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex align-items-center gap-2'>
                                <h4 style={{ cursor: 'pointer' }} onClick={handleClose2}><MdChevronLeft /></h4>
                                <h4>Browse templates</h4>
                            </div>
                            <div className='search-temp'>
                                <input type="text" placeholder='Search templates' name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <select name="category" id="category" onChange={handleCategoryChange}>

                                    <option value="">All Categories</option>
                                    {[...new Set(state.map((tool) => tool.category))].map((category, index) => (
                                        <option key={index} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                                <button className='site-link ms-3'><span ><FiSearch /></span></button>
                            </div>
                        </div>
                        <div className='mt-3' style={{ height: "calc(100vh - 25rem)", overflowX: "hidden", overflowY: "auto", scrollbarWidth: "thin" }}>
                            <div className='row px-12'>
                                {loader.fetch ? <div className="col-12 mt-4">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : templates.length > 0 ? templates.filter((curElem) => {
                                    return curElem.name.toLowerCase().includes(search.toLowerCase())
                                }).length > 0 ? templates.filter((curElem) => {
                                    return curElem.name.toLowerCase().includes(search.toLowerCase())
                                }).map((curElem, index) => {
                                    return (
                                        <div className='col-lg-4' key={index}>
                                            <div className={`temp sm ${toolId.id === curElem.id ? "active" : ""}`} onClick={() => handleChange(curElem)} style={{ cursor: "pointer" }}>
                                                <div className='temp-img'><img src={curElem.icon ? curElem.icon : appIcon} alt="" /></div>
                                                <div className='temp-txt'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <h6>{curElem.name}</h6>
                                                        <img src={require("../../images/badge.svg").default} alt="" />
                                                    </div>
                                                    <p>{curElem.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <div className='d-flex align-items-center justify-content-center'>No tool available.</div> : <div className='d-flex align-items-center justify-content-center'> You do not have  templates yet!</div>}


                            </div>
                        </div>
                        <div className='text-end'>
                            <button className='site-link full mt-4' type="button" onClick={handleOpenModel} style={{ width: "100px" }}><span>Use this</span></button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <CusstomToolModel
                show={show6}
                handleClose={handleClose6}
                toolId={toolId}
                type="tool"
                agencyId={agencyId}
                browserClose={handleClose2}

            />
        </>
    )
}

export default BroserModel