import React, { useEffect, useState } from 'react'
import { AbsoluteFill, Video, Img, Sequence, prefetch } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';
import { themeColor } from '../../../../../../Global/Global';

const VideoLayer = ({ src, layer, multiplyNum, mute }) => {

    const [loader, setLoader] = useState(false)

    const increaseSize = (size) => {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    useEffect(() => {
        if (layer.type === "video" && src) {
            setLoader(true)

            const { free, waitUntilDone } = prefetch(src, {
                method: 'base64',
            });

            waitUntilDone().then(() => {
                // setLoader(false)
            }).catch((error) => {
                console.log(error, "Error")
            })
        }
    }, [layer.type]);

    return (
        src ?
            <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
                <AbsoluteFill>
                    {
                        loader ?
                            <div className='loader-center-temp1' style={{ position: "absolute", left: `${layer.position.left}%`, top: `${layer.position.top}%`, height: `${layer.size.height}%`, width: `${layer.size.width}%` }} >
                                <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} />
                            </div> : ""
                    }
                    <Animation style={{ position: "absolute", height: `${layer.size.height}%`, width: `${layer.size.width}%`, left: `${layer.position.left}%`, top: `${layer.position.top}%` }} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                        {layer.type === "video" ?
                            <Video
                                key={src}
                                style={{
                                    objectFit: "contain", height: "100%", width: "100%",
                                    borderRadius: layer.style.borderRadius ? `${layer.style.borderRadius}${layer.style.borderRadius.includes("%") ? "" : "%"}` : "",
                                    border: `${layer.style.border}`,
                                    borderWidth: `${increaseSize(layer.style.border)}`
                                }}
                                src={src}
                                volume={1}
                                muted={mute === false ? false : true}
                                loop={true}
                                onError={(event) => {
                                    // Handle image loading error here
                                    console.log("error")
                                }}
                                onLoadedData={() => setLoader(false)}
                            />

                            :
                            <Img
                                key={src}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: layer.style.borderRadius ? `${layer.style.borderRadius}${layer.style.borderRadius.includes("%") ? "" : "%"}` : "",
                                    border: `${layer.style.border}`,
                                    borderWidth: `${increaseSize(layer.style.border)}`
                                }}
                                src={src}
                                onError={(event) => {
                                    // Handle image loading error here
                                    console.log("error")
                                }}
                            />
                        }
                    </Animation>
                </AbsoluteFill >
            </Sequence >
            : ""
    )

}

export default VideoLayer
