
import React, { useCallback, useEffect, useState } from 'react';
import { Edit } from 'react-iconly'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { TbTextSize } from 'react-icons/tb';
import { FaBorderStyle, FaQrcode } from "react-icons/fa"
import { PiSelectionBackgroundFill } from 'react-icons/pi';
import { MdPermMedia, MdOutlineQrCode2 } from 'react-icons/md';
import { BsFillLayersFill, BsGlobe, BsPaintBucket } from 'react-icons/bs';
import { BiSolidComponent } from 'react-icons/bi'
import MyCanvas from './MyCanvas';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { onCreateQrImage, onFetchQr, onSaveCanvas, onUnmountQR } from '../../../../Redux/Actions/WidgetActions';
import ImageText from './EditorTabs/Text/ImageText';
import Background from './EditorTabs/Background/Background';
import Media from './EditorTabs/Media';
import Elements from './EditorTabs/Elements';
import Border from './EditorTabs/Border';
import Layers from './EditorTabs/Layers';
import QrCreator from './EditorTabs/QrCreator';
import { onAutoPostFetchDesign, onCreateBaseImage, onFetchDesign, onSavePostCanvas, onUnmountDesign } from '../../../../Redux/Actions/ImageAction';
import Meta from './EditorTabs/Meta';
import { IoIosArrowBack } from "react-icons/io";
import { themeColor } from '../../../../Global/Global';
import TitleBar from '../../../Common/TitleBar';
import Header from '../../../Common/Header';
import Footer from '../../../Common/Footer';
import { fetchData } from '../../../../Redux/Actions/CommonActions';


// Canva.propTypes = {canvas: PropTypes.bool};
const ImageEditor = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, type } = queryString.parse(location.search)
    const { dimension, name, noi } = location.state ? location.state : { dimension: null, name: null, noi: null }


    const qrData = useSelector(state => state.widget.qrData)
    const image = useSelector(state => state.image)

    const [pageLoader, setPageLoader] = useState(false)
    const [selectedImg, setSelectedImg] = useState(false)
    const [canvas, setCanvas] = useState(false);
    const [pastHistory, setPastHistory] = useState([canvas])

    const [futureHistory, setFutureHistory] = useState([])
    const [images, setImages] = useState([])
    const [canvasState, setCanvasState] = useState(canvas);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [loader1, setLoader1] = useState({
        fetch: false,
    })
    const [canvasJson, setCanvasJson] = useState({
        data: []
    });
    const [imgUrl, setImgUrl] = useState(false)
    const [qrUrl, setQrUrl] = useState({
        redirectUrl: "",
        text: ""
    })

    const [text, setText] = useState({
        destiantionURL: "",
        caption: ""
    })


    const initCanvas = () => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            // width: kapil === "321x189" ? 321 : kapil === "559x397" ? 559 : kapil === "794x1122" ? 561 : kapil === "1920x1080" ? 640 : 360,
            // height: kapil === "321x189" ? 189 : kapil === "559x397" ? 397 : kapil === "794x1122" ? 397 : kapil === "1920x1080" ? 360 : 640,
            width: image?.imageSize?.height === 1920 ? 360 : 640,
            height: image?.imageSize?.height === 1920 ? 640 : 360,

            // width: image ? image.dimension === "1920x1080" ? 640 : 360 : qrData ? +qrData.size.split("x")[0] === 794 ? 397 : +qrData.size.split("x")[0] === 803 ? 803 : +qrData.size.split("x")[0] === 893 ? 893 : +qrData.size.split("x")[0] / +qrData.multiplier : 640,
            // height: image ? image.dimension === "1920x1080" ? 360 : 640 : qrData ? +qrData.size.split("x")[1] === 1122 ? 561 : +qrData.size.split("x")[1] === 473 ? 473 : +qrData.size.split("x")[1] === 596 ? 596 : +qrData.size.split("x")[1] / +qrData.multiplier : 360
        })
    )

    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: themeColor,
            cornerColor: themeColor,
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }

    const saveJson = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setPastHistory(history => [...history, newCanvasState])
        setFutureHistory([])
    }


    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            setPastHistory(history => [...history, newCanvasState])
            setFutureHistory([])
        },
        [setCanvasState, setPastHistory]
    );


    const onUndo = () => {
        let undoData = pastHistory.pop()
        if (undoData) {
            setFutureHistory(future => [...future, undoData])
            canvas.loadFromJSON(undoData)
        }
    }

    const onRedo = () => {
        let redoData = futureHistory.pop()
        if (redoData) {
            setPastHistory(history => [...history, redoData])
            canvas.loadFromJSON(redoData)
        }
    }

    const downloadCanvas = () => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = canvas.toDataURL({
            format: 'png',
            multiplier: image ? 3 : +qrData.multiplier
        }
        );
        link.click();
    }

    const fetchImgMedia = () => {
        const data = {
            type: "images"
        }
        setLoader1({
            ...loader1,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setImages, loader1, setLoader1))
    }

    const saveCanvas = () => {
        if (qrData) {
            let img = canvas.toDataURL({
                format: 'png',
                multiplier: +qrData.multiplier
            });
            setCanvasLoader(true)
            let baseObj = {
                type: "image",
                data: img
            }
            dispatch(onCreateQrImage(baseObj, setImgUrl))
        } else {
            let img = canvas.toDataURL({
                format: 'png',
                multiplier: 3
            });
            setCanvasLoader(true)
            let baseObj = {
                type: "image",
                data: img
            }
            dispatch(onCreateBaseImage(baseObj, setImgUrl))
        }
    }

    useEffect(() => {
        fetchImgMedia()
    }, [])

    useEffect(() => {
        if (imgUrl) {
            let canvasData = JSON.stringify(canvas.toJSON())
            if (qrData) {
                let data = JSON.parse(JSON.stringify(qrData))
                data.data = canvasData
                data.imageUrl = imgUrl
                data.redirectUrl = qrUrl.redirectUrl
                data.aiQr.text = qrUrl.text

                dispatch(onSaveCanvas(data, navigate, setCanvasLoader, setImgUrl))
            } else {
                let data = JSON.parse(JSON.stringify(image))
                if (type === "auto-post") {
                    data.destiantionURL = text.destiantionURL
                    data.caption = text.caption
                    data.thumbnail = imgUrl
                    data.url = imgUrl
                    data.data = canvasData
                } else {
                    data.imageUrl = imgUrl
                    // data.data[0].url = imgUrl
                    data.data[0] = canvasData
                }
                dispatch(onSavePostCanvas(data, navigate, setCanvasLoader, setImgUrl, type, name, noi))
            }
        }
    }, [imgUrl, qrData, image])



    useEffect(() => {
        if (canvas) {
            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);
        }
    }, [canvas, onObjectModified]);

    useEffect(() => {
        if (selectedImg) {
            canvas.loadFromJSON(selectedImg, function () {
                if (!canvas.background) {
                    canvas.background = 'white';
                }
                if (canvas.backgroundImage) {
                    const bgImage = new Image();
                    bgImage.src = canvas.backgroundImage.src;
                    bgImage.onload = function () {
                        let h1 = this.height
                        let w1 = this.width
                        let scaleRatio = Math.min(canvas.width / w1, canvas.height / h1);
                        canvas.backgroundImage.scaleX = scaleRatio
                        canvas.backgroundImage.scaleY = scaleRatio
                        canvas.backgroundImage.width = w1
                        canvas.backgroundImage.height = h1
                        canvas.backgroundImage.left = (canvas.width - w1 * scaleRatio) / 2;
                        canvas.backgroundImage.top = (canvas.height - h1 * scaleRatio) / 2;
                        canvas.renderAll();
                    }
                }
                canvas.renderAll();
                setLoader(false)
                // setFutureHistory([])
                // setPastHistory([])
            })
        }
    }, [selectedImg])
    const fetchDesign = () => {
        let data = { id }
        if (location.pathname === "/marketing/image-editor") {
            dispatch(onFetchDesign(data, setPageLoader))
        }
    }


    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])


    useEffect(() => {
        if (canvas) {
            if (id === undefined) {
                canvas.dispose();
                setCanvas(initCanvas);
                initCanvasStyle();
            }
        }
    }, [location])

    const onSetInitialCanvas = () => {
        canvas.dispose();
    }
    useEffect(() => {
        if (image) {
            if (image.data.length > 0) {
                initCanvasStyle()
                setCanvas(initCanvas)
                setSelectedImg(image.data[0])
            }
        }
    }, [image])


    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                // setPastHistory([canvas.toJSON()])
            }, 2000)
        }
    }, [loader])

    useEffect(() => {
        if (id) {
            fetchDesign()
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch(onUnmountDesign())
            dispatch(onUnmountQR())
        }
    }, [])

    return (
        !pageLoader ?
            <>
                <TitleBar title="Image Editor" />
                <div className="kap">
                    <Header />
                </div>
                <section className='site-wrap' style={{ paddingTop: "70px" }}>

                    <div className='site-container white_label_image_marketing  p-3 px-md-5'>
                        <div className='title-block'>
                            <div className='container-fluid'>
                                <div className="content_image_top">
                                    <div className='title-block-in mob-title-wrap d-flex justify-content-between align-items-center w-100'>
                                        <button className='site-link  ps-3 mt-0' onClick={() => navigate(-1)}> <span><IoIosArrowBack />
                                            Back</span></button>
                                        {/* <h3 className='page-title text-capitalize'>Create {type}</h3> */}
                                        <div className='edit-title d-flex align-items-center'>
                                            <div className='me-3 d-flex'>
                                                <p style={{ fontSize: "16px" }}>{qrData.name ? qrData.name : image.name}</p>
                                                {/* <span
                                                    onClick={() => { console.log(JSON.stringify(canvas.toJSON())) }}
                                                ><Edit /></span> */}
                                            </div>

                                            <div className='render-bar mt-0'>
                                                <button className='site-link  mt-0 me-2' onClick={downloadCanvas}><span>Download</span></button>
                                                <button className='site-link  mt-0' onClick={saveCanvas}><span>{canvasLoader ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save"}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='content-editor-wrap alt'>
                                <div className='content-editor-left'>
                                    <div className='content-editor-left-in'>
                                        <div className='content-editor-tab' style={{ height: "630px" }}>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey={location.pathname === "/review/image-editor" ? "qr" : "text"}>
                                                <Nav variant="tab-side-nav" className="flex-column editor-tab-left">
                                                    {location.pathname === "/review/image-editor" ?
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="qr"><span><MdOutlineQrCode2 /></span> QR Code</Nav.Link>
                                                        </Nav.Item> : null}

                                                    <Nav.Item>
                                                        <Nav.Link eventKey="text"><span><TbTextSize /></span> Text</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="background"><span><PiSelectionBackgroundFill /></span> Background</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="media"><span><MdPermMedia /></span> Media</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="elements"><span><BiSolidComponent /></span> Elements</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="border"><span><FaBorderStyle /></span> Borders</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="layer"><span><BsFillLayersFill /></span> Layers</Nav.Link>
                                                    </Nav.Item>
                                                    {type ? type.toLowerCase() === "post" || type === "auto-post" ?
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="article"><span><BsGlobe /></span> Article</Nav.Link>
                                                        </Nav.Item>
                                                        : null : null}

                                                </Nav>

                                                <Tab.Content>
                                                    <Tab.Pane eventKey="text">
                                                        <ImageText
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="background">
                                                        <Background
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                            image={images}
                                                            fetchImgMedia={fetchImgMedia}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="media">
                                                        <Media
                                                            saveJson={saveJson}
                                                            canvas={canvas}
                                                            canvasJson={canvasJson}
                                                            setCanvasJson={setCanvasJson}
                                                            image={images}
                                                            fetchImgMedia={fetchImgMedia}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="elements">
                                                        <Elements
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                            canvasJson={canvasJson}
                                                            setCanvasJson={setCanvasJson}
                                                            image={images}
                                                            fetchImgMedia={fetchImgMedia}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="border">
                                                        <div className='text-picker'>
                                                            <Border
                                                                canvas={canvas}
                                                                saveJson={saveJson}
                                                                canvasJson={canvasJson}
                                                                setCanvasJson={setCanvasJson}
                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="layer">
                                                        <div className='text-picker'>
                                                            <Layers
                                                                canvas={canvas}
                                                                saveJson={saveJson}
                                                                canvasJson={canvasJson}
                                                                setCanvasJson={setCanvasJson}
                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                    {type ? type.toLowerCase() === "post" || type === "auto-post" ?
                                                        <Tab.Pane eventKey="article">
                                                            <Meta
                                                                text={text}
                                                                setText={setText}
                                                            />
                                                        </Tab.Pane>
                                                        : null : null}
                                                    <Tab.Pane eventKey="qr">
                                                        <QrCreator
                                                            canvas={canvas}
                                                            canvasJson={canvasJson}
                                                            setCanvasJson={setCanvasJson}
                                                            qrUrl={qrUrl}
                                                            setQrUrl={setQrUrl}
                                                        />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                                <div className='content-editor-right'>
                                    <div className='content-editor-area'>
                                        <MyCanvas
                                            undo={onUndo}
                                            redo={onRedo}
                                            canvas={canvas}
                                            canvasState={canvasState}
                                            saveJson={saveJson}
                                            cvLoader={loader}
                                            dimension={qrData.dimension}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='kap'>
                    <Footer />
                </div>


            </> :
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
    )

}

export default ImageEditor;