import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import queryString from "query-string";
import { Link } from 'react-router';


const TrainingContents = ({ location }) => {
    const supportData = useSelector(state => state.help);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.article);

    return (
        <>
            <div className="tabRight" style={{ paddingTop: "0" }}>
                <div className="tabCont-right">
                    <div className="tabepaneIn forTraining">

                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                {
                                    articles.length > 0 ?
                                        articles.map((article, index) => {
                                            return (
                                                article.id === articleId ?
                                                    article.items.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="tabepaneIn-main" key={index}>
                                                                    <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>

                                                                        <div className="dashboard-block">
                                                                            <h5 key={index} className="text-dark">
                                                                                {item.title}
                                                                            </h5>
                                                                            <hr />
                                                                            <div key={index} className="mt-4 img-block">
                                                                                <img src={item.feature_url} className="img-fluid" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                    : ''
                                            )
                                        })
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingContents