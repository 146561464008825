import { produce } from "immer"

const initialState = {
    data: {
        id: "",
        industryId: "",
        templateId: "",
        liveLink: "",
        domain: "",
        isPublish: "",
        aiCoach: "",
        headers: {
            enable: false,
            heading: "",
            bannerImage: "",
            logoImage: "",
            buttonText: "",
            buttonAction: "",
            customeUrl: "",
            bannerDescription: ""
        },
        videos: {
            enable: false,
            heading: "",
            source: "",
            url: ""
        },
        benefits: {
            enable: false,
            heading: "",
            benefits: [{
                heading: "",
                icon: "",
                description: ""
            }]
        },
        about: {
            enable: false,
            heading: "",
            image: "",
            paragraphs: [{
                text: ""
            }]
        },
        bonus: {
            enable: false,
            heading: "",
            members: [{
                image: "",
                name: "",
                role: "",
                description: ""
            }]
        },
        contact: {
            enable: false,
            logo: "",
            heading: "",
            daysOpen: {
                fromDay: "Monday",
                fromTime: 9,
                fromMeridiem: "AM",
                toDay: "Friday",
                toTime: 9,
                toMeridiem: "PM"
            }
            ,
            workingHours: 12,
            address: "",
            emailTitle: "",
            email: "",
            phoneTitle: "",
            phone: "",
            autoResponder: "",
            arrListId: ""
        },
        footer: {
            enable: false,
            tosText: "",
            privacyText: "",
            tosUrl: "",
            privacyUrl: "",
            copyrightText: ""
        },

    }
}

export const LandingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_GET_LANDING":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "ON_CHECKED_TOGGLE":
            return produce(state, (draft) => {
                if (action.payload.name === "header") {
                    draft.data.headers.enable = action.payload.checked
                } else if (action.payload.name === "style") {
                    draft.data.style.enable = action.payload.checked
                } else if (action.payload.name === "videos") {
                    draft.data.videos.enable = action.payload.checked
                } else if (action.payload.name === "benefits") {
                    draft.data.benefits.enable = action.payload.checked
                } else if (action.payload.name === "bonus") {
                    draft.data.bonus.enable = action.payload.checked
                } else if (action.payload.name === "thankyou") {
                    draft.data.thankyou.enable = action.payload.checked
                } else if (action.payload.name === "footer") {
                    draft.data.footer.enable = action.payload.checked
                }
            })

        case "ON_CHANGE_HEADER":
            return {
                ...state,
                data: {
                    ...state.data,
                    headers: {
                        ...state.data.headers,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "ON_CHANGE_HEADER_BG_TYPE":
            return produce(state, (draft) => {
                draft.data.headers.bgStyle.type = action.payload
                draft.data.headers.bgStyle.src = ""
                draft.data.headers.bgStyle.thumb = ""
            })

        case "ON_CHANGE_HEADER_BG_DATA":
            return produce(state, (draft) => {
                if (draft.data.headers.bgStyle.type === "video") {
                    draft.data.headers.bgStyle.src = action.payload.src
                    draft.data.headers.bgStyle.thumb = action.payload.thumb
                } else {
                    draft.data.headers.bgStyle.src = action.payload
                }
            })

        case "ON_CHANGE_VIDEO":
            return {
                ...state,
                data: {
                    ...state.data,
                    videos: {
                        ...state.data.videos,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "ON_CHANGE_BENEFIT":
            return produce(state, (draft) => {
                if (action.payload.index !== undefined) {
                    draft.data.benefits.benefits[action.payload.index] = {
                        ...draft.data.benefits.benefits[action.payload.index],
                        [action.payload.name]: action.payload.value
                    }
                } else {
                    draft.data.benefits[action.payload.name] = action.payload.value
                }
            })

        case "ON_ADD_BENEFIT":
            return produce(state, (draft) => {
                draft.data.benefits.benefits.push({
                    heading: "",
                    icon: "",
                    description: ""
                })
            })

        case "ON_CANCLE_BENEFIT":
            return produce(state, (draft) => {
                draft.data.benefits.benefits.splice(action.payload, 1)
            })


        case "ON_CHANGE_TEAM":
            return produce(state, (draft) => {
                if (action.payload.index !== undefined) {
                    draft.data.bonus.products[action.payload.index] = {
                        ...draft.data.bonus.products[action.payload.index],
                        [action.payload.name]: action.payload.value
                    }
                } else {
                    draft.data.bonus.heading = action.payload.value
                }
            })

        case "ON_UPDATE_BONUS_COLOR":
            return produce(state, (draft) => {
                if (action.payload.name === "bg") {
                    draft.data.bonus.products[action.payload.index].color.bg = action.payload.val
                } else {
                    draft.data.bonus.products[action.payload.index].color.font = action.payload.val
                }
            })

        case "ON_ADD_MEMBER":
            return produce(state, (draft) => {
                const { bonusUrl, description, downloadUrl, name } = action.payload
                draft.data.bonus.products.push({
                    icon: bonusUrl,
                    name: name,
                    heading: "",
                    description: description,
                    downloadUrl: downloadUrl,
                    color: {
                        bg: "linear-gradient(to right, #799F0C, #FFE000)",
                        font: "#fff"
                    }
                })
            })

        case "ON_UPDATE_MEMBER":
            return produce(state, (draft) => {
                const { bonusUrl, description, downloadUrl, name } = action.payload.data
                draft.data.bonus.products[action.payload.index] = {
                    ...draft.data.bonus.products[action.payload.index],
                    icon: bonusUrl,
                    name: name,
                    heading: "",
                    description: description,
                    downloadUrl: downloadUrl,
                }
            })
        case "ON_CANCEL_MEMBER":
            return produce(state, (draft) => {
                draft.data.bonus.products.splice(action.payload, 1)
            })

        case "ON_CHANGE_FOOTER":
            return {
                ...state,
                data: {
                    ...state.data,
                    footer: {
                        ...state.data.footer,
                        [action.payload.name]: action.payload.value
                    }
                }
            }
        case "ON_UPDATE_FONT_FAMILY":
            return produce(state, (draft) => {
                draft.data.style.fontfamily = action.payload
            })

        case "ON_UPDATE_PRIMARY_COLOR":
            return produce(state, (draft) => {
                draft.data.style.primaryBackground.mainBgColor = action.payload
            })

        case "ON_UPDATE_SECONDARY_COLOR":
            return produce(state, (draft) => {
                draft.data.style.secondaryColor = {
                    ...draft.data.style.secondaryColor,
                    [action.payload.name]: action.payload.val

                }
            })

        case "ON_UPDATE_BUTTON_COLOR":
            return produce(state, (draft) => {
                draft.data.style.buttons = {
                    ...draft.data.style.buttons,
                    [action.payload.name]: action.payload.val

                }
            })

        case "ON_CHANGE_BONUS_COACH":
            return produce(state, (draft) => {
                draft.data.aiCoach = action.payload.value
            })

        case "ON_UPDATE_THANKYOU":
            return produce(state, (draft) => {
                draft.data.thankyou = {
                    ...draft.data.thankyou,
                    [action.payload.name]: action.payload.value

                }
            })
        default:
            return state
    }
}