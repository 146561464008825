import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import dummyImage from "../../../images/dummy.jpg"
import InstructionModal from './InstructionModal';
import { useDispatch } from 'react-redux';
import { RiInformation2Fill } from "react-icons/ri";
import VoiceClone from './VoiceClone/VoiceClone';

import Swal from 'sweetalert2';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { useImmer } from "use-immer"
import { useSelector } from 'react-redux';
import { themeColor } from '../../../Global/Global';
import { onSaveVideoChapter } from '../../../Redux/Actions/ProjectActions';
import { Link, useNavigate } from 'react-router';
const WriteDataModal = ({ show, handleClose, videoData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show1, setShow1] = useState(false)
    const [percent, setPercent] = useState(0)
    const elevenLabs = useSelector(state => state.social.elevenlabs)

    const [state, setState] = useImmer({
        mediaType: "stock",
        dimension: "16x9",
        faceNarration: {
            enable: false,
            media: {
                url: "",
                pos: "bottom-right",
                height: 125,
                width: 125,
                shape: "circle"
            },
            coverage: 10,
            headMovement: false
        },
        voiceClone: {
            enable: false,
            cloneId: "",
        }
    })
    const [loader, setLoader] = useState({
        generate: false,
        upload: false
    })

    const handleShow1 = () => setShow1(true)
    const handleClose1 = () => setShow1(false)

    const onChangeTaking = (e) => {
        let { name, checked } = e.target
        if (name === "enable") {
            setState((draft) => {
                draft.faceNarration.enable = checked
                if (!checked) {
                    draft.faceNarration.headMovement = false
                    draft.faceNarration.media.url = ""
                }
            })
        } else if (name === "headMovement") {
            setState((draft) => {
                draft.faceNarration.headMovement = checked
            })
        }
    }

    const onChangeVoiceClone = (e) => {
        let { name, checked } = e.target
        if (name === "enable") {
            setState((draft) => {
                draft.voiceClone.enable = checked
                if (!checked) {
                    draft.voiceClone.cloneId = ""
                }
            })
        }
    }
    const onCompeleteUpload = (url) => {
        setState((draft) => {
            draft.faceNarration.media.url = url
        })
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }
    const handleSubmit = () => {
        let data = {
            id: videoData.id,
            text: videoData.text,
            script: videoData.script,
            buttonType: "generate",
            createdFrom: videoData.createdFrom,
            voiceClone: state.voiceClone,
            faceNarration: state.faceNarration,
            mediaType: state.mediaType,
            dimension: state.dimension
        }
        setLoader({
            ...loader,
            generate: true
        })
        dispatch(onSaveVideoChapter(data, navigate, loader, setLoader))
    }
    return (
        <Modal className="VideoModal white convertVideoModal" show={show} onHide={handleClose} centered>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
            <Modal.Body className='custom-scrolls'>
                <div className="formSec p-3">
                    <h5 className='mb-4 modal-title h4'>Convert To Video</h5>
                    <div className="col-12">
                        <div className='common-input-wrap'>
                            <div className='question-type '>
                                <h6>Choose Media Type</h6>
                                <hr />
                                <ul className=''>
                                    <li>
                                        <div className='question-type-single'>
                                            <div className='d-flex'>
                                                <div className='col-auto'>
                                                    <div className='radio-style'></div>
                                                </div>
                                                <div className='col'>
                                                    <h6>AI Image</h6>
                                                </div>
                                            </div>
                                            <input
                                                type="radio"
                                                name='mediaType'
                                                checked={state.mediaType === "ai"}
                                                onChange={(e) => setState({ ...state, mediaType: "ai" })}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='question-type-single'>
                                            <div className='d-flex'>
                                                <div className='col-auto'>
                                                    <div className='radio-style'></div>
                                                </div>
                                                <div className='col'>
                                                    <h6>Stock Image</h6>
                                                </div>
                                            </div>
                                            <input
                                                type="radio"
                                                name='mediaType'
                                                checked={state.mediaType === "stock"}
                                                onChange={(e) => setState({ ...state, mediaType: "stock" })}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div className='common-input-wrap'>
                            <div className='question-type '>
                                <h6>Choose Video Dimension</h6>
                                <hr />
                                <ul className=''>
                                    <li>
                                        <div className='question-type-single'>
                                            <div className='d-flex align-items-center'>
                                                <div className='col-auto'>
                                                    <div className='radio-style'></div>
                                                </div>
                                                <div className='col'>
                                                    <h6>16:9</h6>
                                                </div>
                                            </div>
                                            <input
                                                type="radio"
                                                name='dimension'
                                                checked={state.dimension === "16x9"}
                                                onChange={(e) => setState({ ...state, dimension: "16x9" })}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='question-type-single'>
                                            <div className='d-flex align-items-center'>
                                                <div className='col-auto'>
                                                    <div className='radio-style'></div>
                                                </div>
                                                <div className='col'>
                                                    <h6>9:16</h6>
                                                </div>
                                            </div>
                                            <input
                                                type="radio"
                                                name='dimension'
                                                checked={state.dimension === "9x16"}
                                                onChange={(e) => setState({ ...state, dimension: "9x16" })}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='common-input-wrap'>
                            <div className="add-block-title">
                                <h6>Talking Head </h6>
                                <div className="switch-single">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='enable'
                                            checked={state.faceNarration.enable}
                                            onChange={(e) => onChangeTaking(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <hr />
                            {state.faceNarration.enable ?
                                <div>
                                    <div
                                        className="fileUpload mt-4"
                                        style={{ height: 120 }}
                                    >
                                        <div className='d-flex justify-content-between align-items-center' style={{ height: "100%", width: "100%" }}>
                                            <div className='text-center ps-5'>
                                                {loader.upload ?
                                                    <i className="fa fa-spinner fa-spin" />
                                                    :
                                                    <>
                                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                                        <div>
                                                            Upload Image Here
                                                        </div>
                                                    </>}
                                            </div>
                                            <img
                                                src={state.faceNarration.media.url ? state.faceNarration.media.url : dummyImage}
                                                style={{ height: "100%", objectFit: "contain" }}
                                            />
                                        </div>
                                        <input
                                            type="file"
                                            className='cursor-pointer'
                                            onChange={onInputImage}
                                        />
                                    </div>
                                    <p className='label mt-3' style={{ color: "#32cd32", cursor: "pointer" }} onClick={handleShow1}>
                                        <RiInformation2Fill /> Instruction to choose image ?
                                    </p>

                                    <div className='mt-4'>
                                        <label className="pb-2 d-flex " htmlFor="headMovement" style={{ cursor: "pointer" }}>
                                            <input
                                                type='checkbox'
                                                name='headMovement'
                                                id='headMovement'
                                                onChange={onChangeTaking}
                                                className='mx-2'
                                                checked={state.faceNarration.headMovement}
                                                style={{ height: 20, width: 20 }}
                                            />
                                            Include head movement.
                                        </label>
                                    </div>
                                </div> : ""}
                        </div>
                        <div className='common-input-wrap'>
                            <div className="add-block-title">
                                <h6>Clone Your Own Voice</h6>
                                {elevenLabs.length > 0 ?
                                    <div className="switch-single">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name='enable'
                                                checked={state.voiceClone.enable}
                                                onChange={(e) => onChangeVoiceClone(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div> : ""}
                            </div>
                            <hr />
                            {elevenLabs.length > 0 ?
                                state.voiceClone.enable ?
                                    <div>
                                        <VoiceClone
                                            setState={setState}
                                            state={state}
                                        />
                                    </div>
                                    : ""
                                :
                                <div className="alert alert-warning p-3 mt-4" role="alert">
                                    Please go to the integrations tab to connect your ElevenLabs account. <Link to="/integrations" style={{ color: themeColor }}>Click Here</Link>
                                </div>
                            }
                        </div>
                        <div className='text-center'>
                        <button type="button" className="site-link mt-3 create-modal-btn" onClick={handleClose}><span>Cancel</span></button>
                        <button
                            type="button"
                            className="site-link mt-3 create-modal-btn ms-2"
                            onClick={handleSubmit}
                        >
                            <span>Generate Video {loader.generate ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</span>
                        </button>
                    </div>
                    </div>
                </div>
            </Modal.Body>

            <InstructionModal
                show={show1}
                handleClose={handleClose1}
            />
        </Modal>
    )
}

export default WriteDataModal