import React from 'react';

export const Dissolve = ({
    progress,
    exitingElement = null,
    enteringElement = null,
}) => {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                {exitingElement}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    opacity: progress,
                }}
            >
                {enteringElement}
            </div>
        </>
    )
}

export default Dissolve
