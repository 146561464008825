import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onChangeDesignFont, onChangeDesignLogo, onChangeToolData, onCheckedDesignFont, onCheckedDesignLogo, onCheckedToolToggle } from '../../Redux/Actions/CreateToolActions';
import { onFetchFont } from '../../Redux/Actions/CommonActions';
import LivePreview from './LivePreview';

function Design() {
    const dispatch = useDispatch();
    const design = useSelector(state => state.createTool.data.design);
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])

    const [colorShow, setColorShow] = useState({
        backgroundColor: false,
        textColor: false,
        buttonBackgroundColor: false,
        buttonFontColor: false,
    });

    const [fonts, setFonts] = useState(false);

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts, false, true))
    }

    const handleChecked = (e, type) => {
        const { name, checked } = e.target
        if (type === "font") {
            if (checked === false) {
                dispatch(onChangeDesignFont("fontFamily", "Open Sans"))
            }
            dispatch(onCheckedDesignFont(name, checked))
        } else if (type === "logo") {
            dispatch(onCheckedDesignLogo(name, checked))
        } else {
            dispatch(onCheckedToolToggle(name, checked))
        }
    }

    const handleChange = (e, type) => {
        const { name, value } = e.target
        if (type === "font") {
            dispatch(onChangeDesignFont(name, value))
        } else if (type === "logo") {
            dispatch(onChangeDesignLogo(name, value))
        } else {
            dispatch(onChangeToolData(name, value))
        }
    }

    const handleChangeColor = (e, name) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        dispatch(onChangeToolData(name, hex))
    }

    const handleToggleActive = (name) => {
        setColorShow({
            ...colorShow,
            [name]: true
        })
    }
    const handleToggleDeactivate = (name) => {
        setColorShow({
            ...colorShow,
            [name]: false
        })
    }



    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        fetchFonts()
    }, [])

    return (
        <>
            <div className='builder-area'>
                <div className='design-left'>
                    <div className='design-block scrollHeight hideScrollBar'>
                        <h5>Form Styling</h5>
                        <div className='mt-3'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <label className='label p-0' htmlFor="">Custom Font</label>
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "font")}
                                        name="enable"
                                        checked={design.font.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                            {design.font.enable ?
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Fonts</span>
                                    <select
                                        className='site-common-inp'
                                        onChange={(e) => handleChange(e, "font")}
                                        name='fontFamily'
                                        value={design.fontFamily}
                                        style={{ fontFamily: design.fontFamily }}
                                    >
                                        {fonts.length > 0 ?
                                            fonts.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem} style={{ fontFamily: curElem }}>{curElem}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>
                                : ""
                            }
                        </div>
                        <div className='row mt-3'>
                            <div className="col-6">
                                <label className='label' htmlFor="">Background Color</label>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: design.backgroundColor }}
                                        onClick={() => handleToggleActive("backgroundColor")}
                                    ></span>
                                    <span className='colorVal'>{design.backgroundColor}</span>
                                </div>
                                {colorShow.backgroundColor ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactivate("backgroundColor")}
                                        />
                                        <div className="colorBox">
                                            <SketchPicker
                                                color={design.backgroundColor}
                                                onChange={(e) => handleChangeColor(e, "backgroundColor")}
                                            />
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                            <div className="col-6">
                                <label className='label' htmlFor="">Text Color</label>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: design.textColor }}
                                        onClick={() => handleToggleActive("textColor")}
                                    ></span>
                                    <span className='colorVal'>{design.textColor}</span>
                                </div>
                                {colorShow.textColor ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactivate("textColor")}
                                        />
                                        <div className="colorBox">
                                            <SketchPicker
                                                color={design.textColor}
                                                onChange={(e) => handleChangeColor(e, "textColor")}
                                            />
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                        </div>

                        <h5 className='pt-4'>Button Styling</h5>
                        <div className="row">
                            <div className="col-sm-12 mt-3">
                                <label className='label' htmlFor="">Button Text</label>
                                <input
                                    className='input small'
                                    type="text"
                                    name='buttonText'
                                    onChange={handleChange}
                                    value={design.buttonText}
                                    placeholder='Enter button text'
                                    readOnly={auth.user.isClientAccount === "1"}
                                />
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className="col-6">
                                <label className='label' htmlFor="">Background Color</label>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: design.buttonBackgroundColor }}
                                        onClick={() => handleToggleActive("buttonBackgroundColor")}
                                    ></span>
                                    <span className='colorVal'>{design.buttonBackgroundColor}</span>
                                </div>
                                {colorShow.buttonBackgroundColor ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactivate("buttonBackgroundColor")}
                                        />
                                        <div className="colorBox">
                                            <SketchPicker
                                                color={design.buttonBackgroundColor}
                                                onChange={(e) => handleChangeColor(e, "buttonBackgroundColor")}
                                            />
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                            <div className="col-6">
                                <label className='label' htmlFor="">Font Color</label>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: design.buttonFontColor }}
                                        onClick={() => handleToggleActive("buttonFontColor")}
                                    ></span>
                                    <span className='colorVal'>{design.buttonFontColor}</span>
                                </div>
                                {colorShow.buttonFontColor ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactivate("buttonFontColor")}
                                        />
                                        <div className="colorBox">
                                            <SketchPicker
                                                color={design.buttonFontColor}
                                                onChange={(e) => handleChangeColor(e, "buttonFontColor")}
                                            />
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                        </div>
                        {memberShip.includes("premium") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") || memberShip.includes("elite") ?
                            <div className='d-flex align-items-center justify-content-between gap-2 mt-4'>
                                <h6><small>AIWhiteLabel Logo</small></h6>
                                {auth.user.isClientAccount === "1" ? "" : <label className="switch mt-1">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChecked(e, "logo")}
                                        name="enable"
                                        checked={design.logo.enable}
                                    />
                                    <span className="slider round"></span>
                                </label>}
                            </div>
                            : null}
                        <div className='mt-4 d-flex align-items-center justify-content-between'>
                            <h6 className='pb-3'>Custom CSS Code</h6>
                        </div>
                        <textarea
                            className='input'
                            name="customCss"
                            onChange={handleChange}
                            value={design.customCss}
                            placeholder='Add Custom CSS'
                            readOnly={auth.user.isClientAccount === "1"}
                        >
                        </textarea>
                    </div>
                </div>
                <LivePreview previewType="design" />
            </div>
        </>
    )
}

export default Design