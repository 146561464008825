import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PreviewMain from './PreviewMain';
import { RiArrowGoBackLine, RiArrowGoForwardLine} from 'react-icons/ri'

import { useSelector } from 'react-redux';
import { layerDuplicate, onRedo, onUndo, removeLayer, saveVideo, updateLastAction, updateLayerPos, updatePresent } from '../../../../../Redux/Actions/VideoActions';
import SlidePlayPause from './SlidePlayPause';
import Remotion from '../Remotion/Remotion';

const Preview = ({ preLoader, showPreview, handleClosePreviewModal }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const editor = useSelector(state => state?.editor)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    let actionArr = ["UNDO", "REDO", "ADD_VIDEO_DATA", "UPDATE_SLIDES_DATA", "END_ANIMATION", "ONPLAY_SLIDE", "UPDATE_WATERMARK_HW", "UPDATE_LOGO_HW"]
    const [allowCall, setAllowCall] = useState(true)
    const [playStatus, setPlayStatus] = useState(false)
    const [undoActive, setUndoActive] = useState(false)
    const [redoActive, setRedoActive] = useState(false)
    const [second, setSecond] = useState(0)

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })


    const deleteLayer = () => {
        dispatch(removeLayer(selectedSlide, selectedSlideIndex))
    }


    const duplicateLayer = () => {
        if (selectedSlide?.layers?.length > 0) {
            let layerData = selectedSlide?.layers?.find(({ isSelected }) => isSelected === true)
            const layerDataIndex = selectedSlide?.layers?.findIndex(({ isSelected }) => isSelected === true)
            if (layerDataIndex !== -1) {
                const val = { ...layerData }
                dispatch(layerDuplicate(selectedSlideIndex, val, layerDataIndex))
            }
        }
    }
    const handleLayerUpDown = (type) => {
        const layerDataIndex = selectedSlide?.layers?.findIndex(({ isSelected }) => isSelected === true)
        if (layerDataIndex !== -1) {
            if (type === "down") {
                if (layerDataIndex > 0) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "up"))
                }
            } else {
                if (selectedSlide?.layers?.length - 1 > layerDataIndex) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "down"))
                }
            }
        }
    }

    const handleUndo = () => {
        let id = selectedSlide.id
        if (!undoActive) {
            dispatch(onUndo(id))
        }
    }
    const handleRedo = () => {
        let id = selectedSlide.id
        if (!redoActive) {
            dispatch(onRedo(id))
        }
    }



    useEffect(() => {
        const handleKey = (event) => {
            if (event.keyCode === 46) {
                if (editor.layer.canDelete) {
                    deleteLayer()
                }
            }
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
                handleUndo()
            }
            if (event.keyCode === 89 && (event.ctrlKey || event.metaKey)) {
                handleRedo()
            }
        }
        document.addEventListener("keydown", handleKey)
        return () => document.removeEventListener("keydown", handleKey)
    }, [selectedSlide])

    useEffect(() => {
        if (campaign?.undoRedo?.length > 0) {
            const undoRedoObj = campaign?.undoRedo.find(({ slideId }) => parseInt(slideId) === parseInt(selectedSlide.id))
            if (undoRedoObj) {
                if (undoRedoObj.past.length > 0) {
                    setUndoActive(false)
                }
                else {
                    setUndoActive(true)
                }
                if (undoRedoObj.future.length > 0) {
                    setRedoActive(false)
                }
                else {
                    setRedoActive(true)
                }
            }
        }
    }, [campaign.undoRedo, selectedSlideIndex])

    useEffect(() => {
        if (actionArr.includes(campaign.lastAction)) {
            dispatch(updateLastAction(selectedSlide))
        } else {
            dispatch(updatePresent(selectedSlide))
        }
    }, [selectedSlide])

    useEffect(() => {
        if (selectedSlide) {
            const data = selectedSlide?.duration ? selectedSlide?.duration : 0
            setTime({
                ...time,
                s: parseInt(data % 60),
                m: Math.floor(data % 3600 / 60),
                h: parseInt(data / 3600)
            })
        }
    }, [selectedSlide?.duration])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])


    //Save Slide 

    useEffect(() => {
        if (campaign && allowCall) {
            setAllowCall(false)
            dispatch(saveVideo(campaign, setAllowCall))
        }
    }, [campaign])

    return (
        // <div className="{`${campaign.dimension === "9x16" ? "canvasEditor-main_9x16 " : "canvasEditor-main_16x9 "} canvasEditor-main`}">
        <div className="canvasEditor" style={campaign.dimension === "9x16" ? { maxWidth: "360px ", transform: " scale(0.95)" } : { maxWidth: "640px" }}>
            <ul className="controls d-flex flex-wrap justify-content-center align-items-center">

                <li className="control-btn">
                    <a
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Undo"
                        onClick={() => handleUndo()}
                    >
                        <RiArrowGoBackLine className='whiteFont' />
                    </a>
                </li>
                <li className="control-btn">
                    <a
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Redo"
                        onClick={() => handleRedo()}
                    >
                        <RiArrowGoForwardLine className='whiteFont' />
                    </a>
                </li>


                <li className="control-btn">
                    <a
                        id="layer-back"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Layer Back"
                        onClick={() => handleLayerUpDown('up')}
                    >
                        <img src={require('../../../../../images/editor/up.svg').default} alt='' />
                    </a>
                </li>
                <li className="control-btn">
                    <a
                        id="layer-front"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Layer Front"
                        onClick={() => handleLayerUpDown('down')}

                    >
                        <img src={require('../../../../../images/editor/down.svg').default} alt='' />
                    </a>
                </li>
                <li className="control-btn" >
                    <a
                        id="duplicate"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Duplicate"
                        onClick={duplicateLayer}
                    >
                        <img src={require('../../../../../images/editor/copy.svg').default} alt='' />
                    </a>
                </li>
                <li className="control-btn" >
                    <a
                        id="delete"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Delete"
                        onClick={deleteLayer}
                    >
                        <img src={require('../../../../../images/editor/delete.svg').default} alt='' />
                    </a>
                </li>
            </ul>
            <div className={`${campaign.dimension === "9x16" ? "canvasEditor-main_9x16 " : "canvasEditor-main_16x9 "} canvasEditor-main`}>
                {showPreview ?
                    <Remotion
                        show={showPreview}
                        handleClose={() => handleClosePreviewModal}
                        previewType={"screenPreview"}
                    />
                    :
                    <PreviewMain
                        slideData={campaign.slides}
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        preLoader={preLoader}
                        promo={campaign.promo}
                        dimension={campaign.dimension}
                    />
                }
            </div>
            {showPreview ? null :
                <div className={campaign?.dimension === "9x16" ? "videoControll_9x16 " : "videoControll_16x9"} >
                    <div className="row">
                        <div className="col-4">
                            <div className="videoControll-txt">
                                <span>{selectedSlideIndex + 1} / {campaign?.slides?.length}</span>

                            </div>
                        </div>
                        <div className="col-4">
                            <div className="videoControll-nav">
                                <SlidePlayPause
                                    setSecond={setSecond}
                                    setPlayStatus={setPlayStatus}
                                    playStatus={playStatus}
                                    timer={timer}
                                    setTimer={setTimer}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="videoControll-right">
                                {playStatus ?
                                    <span>
                                        {
                                            timer.h < 10 ? '0' + timer.h : timer.h
                                        }
                                        :
                                        {
                                            timer.m < 10 ? '0' + timer.m : timer.m
                                        }
                                        :
                                        {
                                            second < 10 ? '0' + second : second
                                        }
                                    </span> :
                                    <span>{time.h > 9 ? "" : "0"}{time.h}:{time.m > 9 ? "" : "0"}{time.m}:{time.s > 9 ? "" : "0"}{time.s}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>}
        </div>

    );
};




export default Preview;
