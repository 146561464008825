import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import AddedElements from './AddedElements';
import { RiDragMove2Fill } from "react-icons/ri";
import { onFetchToolLanguage, onFetchToolVoice, onHandleFieldContainer, onHandleNewField, onSetFrameHeight } from '../../../Redux/Actions/CreateToolActions';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';

const MiddleBuilder = ({ first }) => {
    const dispatch = useDispatch()
    const heightRef = useRef(null)
    const createTool = useSelector(state => state.createTool)
    const [pollyLanguages, setPollyLanguages] = useState([]);
    const [pollyVoices, setPollyVoices] = useState([]);

    const [findArtist, setFindArtist] = useState(false)


    const [dropStates, setDropStates] = useState({
        parent: true,
        child: true,
    });
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "fields",
        canDrop: () => dropStates.parent,
        drop: (item, monitor) => {
            if (!monitor.isOver({ shallow: true })) {
                return;
            }
            if (item.isCustom) {
                let type = dropStates.parent && "parent"
                handleDrop(item, type)
            }
        },
        hover: () => {
            if (isOver) {
                onHover("parent")
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        })
    }))

    const onHover = (container) => {
        // setDropStates((prevStates) => {
        //     const newStates =
        //         container === "parent"
        //             ? { parent: true, child: false }
        //             : { parent: false, child: true };

        //     if (JSON.stringify(prevStates) !== JSON.stringify(newStates)) {
        //         return newStates;
        //     }
        //     return prevStates;
        // });
    }

    const handleDrop = (item, container, dropIndex, pos) => {
        if (container === "parent") {
            dispatch(onHandleFieldContainer(item))
        } else if (container === "child") {
            dispatch(onHandleNewField(item, dropIndex, pos))
        }
    }

    useEffect(() => {
        if (heightRef.current && first) {

            dispatch(onSetFrameHeight(heightRef.current.scrollHeight))
        }
    }, [heightRef, createTool.data.forms])



    const fetchPollyLanguages = () => {
        dispatch(onFetchToolLanguage(setPollyLanguages))
    }

    const fetchPollyArtist = (value) => {
        dispatch(onFetchToolVoice(value, setPollyVoices))
    }



    useEffect(() => {
        if (pollyLanguages.length > 0 && createTool.data.forms.length > 0) {
            let objD = createTool.data.forms.find(({ isVoice }) => isVoice === true)
            if (objD) {
                let vId = pollyLanguages.find(({ code }) => code === objD.value)
                if (vId) {
                    fetchPollyArtist(vId.id)
                }
            }
        }
    }, [findArtist, pollyLanguages])

    useEffect(() => {
        fetchPollyLanguages()
    }, [])


    return (
        <div className="builder-mid hideScrollBar" ref={drop} >
            <div style={{ height: "100%" }} ref={heightRef}>
                {createTool.data.forms.length > 0 ?
                    createTool.data.forms.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <AddedElements
                                    curElem={curElem}
                                    index={index}
                                    handleDrop={handleDrop}
                                    isChild={dropStates.child}
                                    onHover={onHover}
                                    pollyLanguages={pollyLanguages}
                                    pollyVoices={pollyVoices}
                                    setFindArtist={setFindArtist}
                                    findArtist={findArtist}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='d-flex align-items-center justify-content-center' style={{ height: "100%" }}>
                        <div className='text-center'>
                            <RiDragMove2Fill size={25} />
                            <h4>Drag & drop elements here to start building</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MiddleBuilder