import { useEffect, useState } from 'react';
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'
import { FiPlus } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";
import { onDeleteCollection, onFetchAllTools } from '../../Redux/Actions/MyCollection';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CollectionModal from './CollectionModal';
import CommonAlert from '../Common/CommonAlert';
import { Trash } from 'iconsax-react'
import EditCollectionModel from './EditCollectionModel';
import TitleBar from '../Common/TitleBar';
import { Tooltip } from 'react-tooltip';

const MyCollection = () => {
    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [allCollection, setAllCollection] = useState([])
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [editData, setEditData] = useState({})
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const onfetchAll = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "agencyId": agencyId
        }
        dispatch(onFetchAllTools(formData, setAllCollection, loader, setLoader))
    }
    useEffect(() => {
        if (agencyId) {
            onfetchAll()
        }
    }, [agencyId])
    const onDelete = (curElem) => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCollection(data, setSweet, onfetchAll))
    }
    const onEdithanlde = (curElem) => {
        setShow1(true)
        setEditData(curElem)
    }
    return (
        <>
            <TitleBar title="My Collection" />
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className="pageTitle">
                        <div className='pageTitle-left'>
                            <h4>My Collections</h4>
                            <p>You can group your tools in to Collections. Manage or update them with ease.</p>
                        </div>
                        <div className='pageTitle-right'>
                            {!agencyId ?
                                <Tooltip
                                    id={'my-tooltip-custom-collection-create'}
                                    style={{ backgroundColor: "#15161d", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                                    place="top"
                                    content={"Please create an agency first to create a collection."}
                                /> : null}
                            {auth.user.isClientAccount === "1" ? "" : <button
                                className='site-link white'
                                onClick={handleShow}
                                style={agencyId ? {} : { background: "gray", cursor: "not-allowed" }}
                                disabled={!agencyId}
                                data-tooltip-id="my-tooltip-custom-collection-create"
                            >
                                <span><FiPlus /> Create Collection</span>
                            </button>}
                        </div>
                    </div>

                    <div className='row'>
                        {

                            loader.fetch ?
                                <div className="col-12 mt-4">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div>

                                :
                                allCollection.length > 0 ? allCollection.map((curElem, index) => {
                                    return (
                                        <div className='col-lg-6 mt-4' key={index}>
                                            <div className="collection-card">
                                                <div className='row align-items-center'>
                                                    <div className="col">
                                                        <h5>{curElem.name}</h5>
                                                        <p><small>{curElem.description}</small></p>
                                                    </div>
                                                    {auth.user.isClientAccount === "1" ? "" : <div className="col-auto d-flex align-items-center gap-2">
                                                        <button className='iconButton' onClick={() => onEdithanlde(curElem)}><MdOutlineModeEdit /></button>
                                                        <button className='iconButton red' onClick={() => onDelete(curElem)}><FiTrash2 /></button>
                                                    </div>}
                                                </div>
                                                <div className='row'>
                                                    {
                                                        curElem.userTools?.length > 0 ?
                                                            curElem.userTools?.map((item, i) => {
                                                                return (
                                                                    <div className="col-sm-6 mt-3" key={i}>
                                                                        <div className='collection-single'>
                                                                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                                                                            <h6>{item.name}</h6>
                                                                            <p>{item.description}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className='col-sm-12 text-center'>
                                                                <h6>You do not have tools in this collection.</h6>
                                                            </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) : <div className='col-sm-12 text-center mt-5'>
                                    <h6>You do not have a collection created yet!</h6></div>

                        }


                    </div>
                </div>
            </div>

            <Footer />

            <CollectionModal show={show} handleClose={handleClose} onfetchAll={onfetchAll} />
            <EditCollectionModel show={show1} handleClose={handleClose1} onfetchAll={onfetchAll} editData={editData} />
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this collection?"}
                confirmButtonColor={""}
                icon={<Trash />}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default MyCollection