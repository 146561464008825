import React from 'react'
import { useDrag } from 'react-dnd'

const DragElements = (props) => {
    
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "fields",
        item: { name: props.name, isCustom: true, data: props.data },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const opacity = isDragging ? 0.4 : 1

    return (
        <li>
            <div className="layout-elements-single" ref={drag} style={{ opacity }}>
                {props.icon}
                <p>{props.fieldName}</p>
            </div>
        </li>
    )
}

export default DragElements