import { produce } from "immer"

const initialState = {
    data: {
        forms: [],
        design: {
            textColor: "#000000",
            backgroundColor: "#E3E3E3",
            buttonBackgroundColor: "#E3E3E3",
            buttonFontColor: "#FFFFFF",
            buttonText: "Generate",
            font: {
                enable: false,
                fontFamily: "Open Sans"
            },
            logo: {
                enable: true
            }
        },
        model: "",
        integrations: {
            smtp: {
                enable: false,
                email: "",
            },
            stripe: {
                enable: false
            },
            openai: {
                enable: false
            },
            autoresponder: {
                enable: false
            },
            domain: {
                enable: false
            }
        }
    },
    mainActive: true,
    editElementIndex: false,
}

export const CreateToolReducer = (state = initialState, action) => {
    switch (action.type) {

        case "SET_TOOL_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                obj.forms = obj.forms ? obj.forms : []
                obj.design = obj.design ? obj.design : {
                    textColor: "#000000",
                    backgroundColor: "#E3E3E3",
                    buttonBackgroundColor: "#18bd15",
                    buttonFontColor: "#FFFFFF",
                    buttonText: "Generate",
                    font: {
                        enable: false,
                        fontFamily: "Open Sans"
                    },
                    logo: {
                        enable: true
                    }
                }
                obj.integrations = obj.integrations ? obj.integrations : {
                    smtp: {
                        enable: false,
                        email: ""
                    },
                    stripe: {
                        enable: false
                    },
                    openai: {
                        enable: false
                    },
                    autoresponder: {
                        enable: false
                    },
                    domain: {
                        enable: false
                    }

                }
                draft.data = obj
            })
        case "ADD_TOOL_FIELD_CONTAINER":
            return produce(state, (draft) => {
                draft.data.forms.push(action.payload.data)
            })

        case "ADD_TOOL_FIELD":
            return produce(state, (draft) => {
                const { item, dropIndex, pos } = action.payload
                if (pos === "addTop") {
                    if (draft.data.length === 0) {
                        draft.data.forms.push(item.data)
                    } else {
                        draft.data.forms.splice(dropIndex, 0, item.data)
                    }
                } else if (pos === "addBot") {
                    if (draft.data.forms.length === 0) {
                        draft.data.forms.push(item.data)
                    } else {
                        draft.data.forms.splice(dropIndex + 1, 0, item.data)
                    }
                } else {
                    draft.data.forms.splice(dropIndex, 0, item.data)
                }
            })

        case "SHUFFLE_TOOL_FIELD":
            return produce(state, (draft) => {
                const { item, dropIndex, pos } = action.payload
                if (pos === "addTop") {
                    if (item.index < 0 || item.index >= draft.data.forms.length || dropIndex < 0 || dropIndex >= draft.data.forms.length) {

                    } else {
                        const element = draft.data.forms.splice(item.index, 1)[0];
                        draft.data.forms.splice(dropIndex, 0, element);
                    }
                } else {
                    if (item.index < 0 || item.index >= draft.data.forms.length || dropIndex < 0 || dropIndex >= draft.data.forms.length) {
                    } else {
                        const element = draft.data.forms.splice(item.index, 1)[0];
                        draft.data.forms.splice(dropIndex + 1, 0, element);
                    }
                }
            })

        case "DELETE_FIELD":
            return produce(state, (draft) => {
                draft.data.forms.splice(action.payload, 1)
            })

        case "TOOGLE_TYPE":
            return produce(state, (draft) => {
                draft.mainActive = action.payload
            })

        case "EDIT_ELEMENT_INDEX":
            return produce(state, (draft) => {
                draft.editElementIndex = action.payload
            })
        case "CHANGE_TOOL_FIELD":
            return produce(state, (draft) => {
                draft.data.forms[draft.editElementIndex] = {
                    ...draft.data.forms[draft.editElementIndex],
                    [action.payload.name]: action.payload.value
                }
            })

        case "CHANGE_FIELD_TEXT":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })


        case "CHANGE_TOOL_DATA_ENABLE":
            return produce(state, (draft) => {
                draft.data.design[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_DATA":
            return produce(state, (draft) => {
                draft.data.design[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_DESIGN_FONT_ENABLE":
            return produce(state, (draft) => {
                draft.data.design.font[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_DESIGN_FONT":
            return produce(state, (draft) => {
                draft.data.design.font[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_DESIGN_LOGO_ENABLE":
            return produce(state, (draft) => {
                draft.data.design.logo[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_DESIGN_LOGO":
            return produce(state, (draft) => {
                draft.data.design.logo[action.payload.name] = action.payload.value
            })


        case "CHANGE_TOOL_INTEGRATION_AUTO_RESPONDER_ENABLE":
            return produce(state, (draft) => {
                draft.data.integrations.autoresponder[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_AUTO_RESPONDER":
            return produce(state, (draft) => {
                draft.data.integrations.autoresponder[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_STRIPE_ENABLE":
            return produce(state, (draft) => {
                draft.data.integrations.stripe[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_STRIPE":
            return produce(state, (draft) => {
                draft.data.integrations.stripe[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_OPENAI_ENABLE":
            return produce(state, (draft) => {
                draft.data.integrations.openai[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_OPENAI":
            return produce(state, (draft) => {
                draft.data.integrations.openai[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_SMTP_ENABLE":
            return produce(state, (draft) => {
                draft.data.integrations.smtp[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_SMTP":
            return produce(state, (draft) => {
                draft.data.integrations.smtp[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_DOMAIN_ENABLE":
            return produce(state, (draft) => {
                draft.data.integrations.domain[action.payload.name] = action.payload.value
            })

        case "CHANGE_TOOL_INTEGRATION_DOMAIN":
            return produce(state, (draft) => {
                draft.data.integrations.domain[action.payload.name] = action.payload.value
            })

        case "ON_CHANGE_FORM_DATA":
            return produce(state, (draft) => {
                draft.data.forms[action.payload.index] = {
                    ...draft.data.forms[action.payload.index],
                    value: action.payload.value
                }
                if (action.payload.name === "artist") {
                    draft.data.forms[action.payload.index].artistData = action.payload.objData
                }
                if (draft.data.forms[action.payload.index].isVoice) {
                    let objIndex = draft.data.forms.findIndex(({ isArtist }) => isArtist === true)
                    if (objIndex !== -1) {
                        draft.data.forms[objIndex].value = ""
                    }
                }
            })


        case "ON_ADD_OPTION":
            return produce(state, (draft) => {
                draft.data.forms[draft.editElementIndex].options.push({ text: "", value: "" })
            })

        case "ON_REMOVE_OPTION":
            return produce(state, (draft) => {
                draft.data.forms[draft.editElementIndex].options.splice(action.payload, 1)
            })

        case "ON_CHANGE_FIELD_OPTIONS":
            return produce(state, (draft) => {
                draft.data.forms[draft.editElementIndex].options[action.payload.index][action.payload.name] = action.payload.value
            })

        case "ON_SET_FRAME_HEIGHT":
            return produce(state, (draft) => {
                draft.data.frameHeight = action.payload
            })

        case "ON_CHECKED_FIELD":
            return produce(state, (draft) => {
                draft.data.forms[action.payload.index] = {
                    ...draft.data.forms[action.payload.index],
                    required: action.payload.checked
                }
            })
        default:
            return state
    }
}

