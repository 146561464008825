import React from "react";
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import mac4 from "../../images/comp-4.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 order-1">
                                    <div className="featureSec-left">
                                        {/* <h6>GET STARTED WITHOUT A PLAN</h6> */}
                                        <h2> AI Tool Builder:</h2>
                                        <p>Design advanced AI tools effortlessly with our intuitive builder. Choose from a range of pre-configured models and functionalities tailored to different industries and use cases. Customize every detail, from features to branding, ensuring your tool is unique and ready for your audience.</p>
                                        {/* <div className="textL">See how you can use {rebrand? rebrand.name : appName } to <span>create your first course</span></div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 order-2">
                                    <div className="featureSec-right"><img src={mac} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-left">
                                        {/* <h6>Polish your videos effortlessly</h6> */}
                                        <h2>White-Labeling and Custom Branding:</h2>
                                        <p>Create a brand of your own with seamless white-labeling options. Add your logo, colors, and custom designs to AI tools, making them fully branded and ready to sell. Stand out in the market with professional, tailor-made solutions under your unique identity.</p>
                                        {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-right"><img src={mac2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 order-1">
                                    <div className="featureSec-left">
                                        {/* <h6>TAKE A BACKSEAT</h6> */}
                                        <h2>Sales and Deployment Features:</h2>
                                        {/* <p>With {rebrand? rebrand.name : appName } Professional, share access with teams to create videos and courses for you. Provide reviews and comments to ensure everyone is on the same page.</p> */}
                                        <p>Sell your AI tools with ease using built-in deployment and sales options. Publish tools directly to your website, integrate payment gateways, and manage client access. Get detailed insights into usage and revenue, empowering you to scale your AI business effortlessly.</p>

                                        {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 order-2">
                                    <div className="featureSec-right"><img src={mac3} /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;