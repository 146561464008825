import React from 'react'
import DashboardHead from './DashboardHead'
import DashboardContent from './DashboardContent'
import DashboardAbout from './DashboardAbout'
import Webinar from './Webinar'
import TitleBar from '../Common/TitleBar'
import Footer from '../Common/Footer'
import SidePanel from '../Common/SidePanel' 
import Header from '../Common/Header'

const Dashboard = () => { 
    return (
        <>
            <TitleBar title="Dashboard" />  
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container alt">

                <DashboardHead />
                <DashboardContent /> 
                <DashboardAbout />
                <Webinar /> 

                </div>
            </div>

            

            <Footer />
        </>
    )
}

export default Dashboard