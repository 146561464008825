import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { BsTrash } from 'react-icons/bs'
import { FaRegEdit } from "react-icons/fa"
import { Link } from 'react-router'
import icon from "../../../images/apps-icons.png"
import { useSelector } from 'react-redux'
import { BiEdit } from 'react-icons/bi'
import CommonAlert from '../../Common/CommonAlert'

const ProjectRows = ({ curElem, camp, setCamp }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (campId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: campId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false,
            id: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            loader: true,
            confirmButtonName: "Deleting"
        })
        let data = {
            id: sweet.id,
        }
        dispatch(onDeleteCampaign("delete-user-campaign", data, camp, setCamp, setSweet))
    }

    let prodName = curElem?.productName?.split("-")

    return (
        <tr>
            <td>
                <span className='vid-thumb' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80px', height: '80px' }}>
                    <img src={icon} alt="" style={{ objectFit: "contain", width: "50px", height: "50px" }} />
                </span>
            </td>
            <td className='text-capitalize'>{curElem.campaignName}</td>
            <td className='text-capitalize'>{prodName ? prodName[0] : ""}</td>
            <td>{curElem.created}</td>
            <td>
                {auth.user.isClientAccount === "1" ? "" :
                    <>
                        <div className='d-flex align-items-center gap-1'>
                            <Link className='link-icon' title='Edit' to={`/marketing/sales-copywriter/project-content?id=${curElem.id}`}><BiEdit fontSize={24} style={{ color: '#838383' }} /></Link>
                            <a className='link-icon' onClick={() => onDelete(curElem.id)}><BsTrash fontSize={24} style={{ color: '#838383' }} /></a>
                        </div>
                    </>}
            </td>
            <CommonAlert
                show={sweet.enable}
                icon={<div className='kap_wrap'><BsTrash size={35} /> </div>}
                message={"Are you sure you want to delete this campaign?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default ProjectRows