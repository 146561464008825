import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { onUpdateThankyou } from '../../../../Redux/Actions/ProjectActions';

const ThankyouSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.landingPage.data)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onUpdateThankyou(name, value))
    }

    return (

        <>
            <div className='boxStyle'>
                <div className="row">
                    <div className='col-md-6 mb-3'>
                        <div className="inpLabelWrap mt-0">
                            <span className="labelTxt">Thank You Page Headline 1</span>
                            <div className="inpLabel">
                                <label htmlFor="bannerDescription">Thank You Page Headline 1</label>
                                <input
                                    className="inpLabel-inp"
                                    name="headingOne"
                                    placeholder="Thank You Page Headline 1"
                                    value={landingData.thankyou.headingOne}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="inpLabelWrap mt-0">
                            <span className="labelTxt">Thank You Page Headline 2</span>
                            <div className="inpLabel">
                                <label htmlFor="bannerDescription">Thank You Page Headline 2</label>
                                <input
                                    className="inpLabel-inp"
                                    name="headingTwo"
                                    placeholder="Thank You Page Headline 2"
                                    value={landingData.thankyou.headingTwo}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Thank You Page Description</span>
                            <div className="inpLabel">
                                <label htmlFor="bannerDescription">Thank You Page Description</label>
                                <textarea
                                    className="inpLabel-inp"
                                    name="description"
                                    placeholder="Thank You Page Description"
                                    value={landingData.thankyou.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>





    )
}

export default ThankyouSection