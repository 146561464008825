import { React, useEffect, useState } from 'react'

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import AccountNav from '../Account-Nav/AccountNav'
import { Profile2User, Edit, Trash, UserSquare } from 'iconsax-react'
import CreateAccountModal from './CreateAccountModal'
import EditAccountManagent from './EditAccountManagent'
import DeleteAccountMangaemant from './DeleteAccountMangaemant'
import { themeColor } from '../../../Global/Global'
import { useDispatch } from 'react-redux'
import { fetchAccData } from '../../../Redux/Actions/AccountManagements'
import TitleBar from '../../Common/TitleBar'

const AccountManagement = () => {
    const dispatch = useDispatch()
    const [editData, setEditData] = useState({})
    const [AccountType, setAccountType] = useState("")
    const [deleteData, setDeleteData] = useState({})
    const [teamMember, setTeamMember] = useState([])
    const [clients, setClients] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);



    const fetchMembers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
    }

   
    const handleEdit = (curElem, type) => {
        handleShow2()
        setEditData(curElem)
        setAccountType(type)
    }
    const handleDeleteshow = (curElem) => {
        handleShow3()
        setDeleteData(curElem)
    }

    useEffect(() => {
        fetchMembers()
    }, [])

    return (
        <>
            <TitleBar title="Account Management" />
            <Header />
            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right large'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Account Management</h2>
                                    <p>Create and Manage Account</p>
                                </div>
                                <div className='titleBar-right'>
                                    <button className='site-link blue' onClick={handleShow}><span><Profile2User /> Add Member</span></button>
                                </div>
                            </div>

                            {/* <h6 className='pt-5'>Clients</h6> */}
                            <div className='manage-block d-none'>
                                {/* <div className='manage-block-single'> */}
                                {
                                    clients.length > 0 ?
                                        clients.map((curElem, index) => {
                                            return (
                                                < div className='manage-block-single' key={index}>
                                                    <div className='manage-block-left'>
                                                        <div className='manage-block-icon'><UserSquare /> </div>
                                                        <div className='manage-block-txt'>
                                                            <h6>{curElem.name}</h6>
                                                            <p>{curElem.email} . {curElem.created}</p>
                                                        </div>
                                                    </div>
                                                    <div className='manage-block-right'>
                                                        <div className='group-icon'>
                                                            <span onClick={() => handleEdit(curElem, "Client")}><Edit /></span>
                                                            <span onClick={() => handleDeleteshow(curElem)}><Trash /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <div >
                                                {loader.fetch ?
                                                    <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> :
                                                    <>
                                                    <h6 className='text-left'>You do not have any client yet!</h6>
                                                    </>}
                                            </div>
                                        </div>
                                }
                                {/* </div> */}
                            </div>


                            <h6 className='pt-5'>Team Members</h6>
                            {
                                teamMember.length > 0 ?
                                    teamMember.map((curElem, index) => {
                                        return (
                                            <>

                                                <div className='manage-block' key={index}>
                                                    <div className='manage-block-single'>
                                                        <div className='manage-block-left'>
                                                            <div className='manage-block-icon'><UserSquare /> </div>
                                                            <div className='manage-block-txt'>
                                                                <h6>{curElem.name}</h6>
                                                                <p>{curElem.email} {curElem.created}</p>
                                                            </div>
                                                        </div>
                                                        <div className='manage-block-right'>
                                                            <div className='group-icon'>
                                                                <span onClick={() => handleEdit(curElem, "Team Member")}><Edit /></span>
                                                                <span onClick={() => handleDeleteshow(curElem)}><Trash /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    :
                                    <div className='d-flex align-items-center justify-content-center mt-5'>
                                        <div>
                                            {loader.fetch ?
                                                <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> :
                                                <><h6 className='text-left'>You do not have any team members yet!</h6></>}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />



            <CreateAccountModal show={show} handleClose={handleClose} fetchMembers={fetchMembers} />

            <EditAccountManagent show={show2} handleClose={handleClose2} curElem={editData} type={AccountType} fetchMembers={fetchMembers} />

            <DeleteAccountMangaemant show={show3} handleClose={handleClose3} curElem={deleteData} fetchMembers={fetchMembers} />

        </>
    )
}

export default AccountManagement