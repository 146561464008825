import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreate = (url, data, setLoader , handleClose,fetchAllVideos , navigate) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                navigate('/marketing/image-maker')
                fetchAllVideos()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}



export const onFetchDesign = (data, setLoader) => (dispatch, getState) => {
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    commonAxios("fetch-custom-image", data, dispatch, getState().auth.token)
        .then(async (res) => {
            if (res.status) {
                let obj = res.data[0]
            
            if (!obj.data) {
                const img = new Image();
                img.onload = function () {
                    let w, h

                    if (obj.dimension === "1920x1080") {
                        w = 640
                        h = 360
                    } else {
                        w = 360
                        h = 640
                    }
                    let scaleFactor = Math.min(w / this.width, h / this.height);
                    let initial = [{
                        "version": "5.3.0",
                        "objects": [],
                        "background": "white",
                        "backgroundImage": {
                            "type": "image",
                            "version": "5.3.0",
                            "originX": "left",
                            "originY": "top",
                            "left": (w - this.width * scaleFactor) / 2,
                            "top": (h - this.height * scaleFactor) / 2,
                            "width": this.width,
                            "height": this.height,
                            "fill": "rgb(0,0,0)",
                            "stroke": null,
                            "strokeWidth": 0,
                            "strokeDashArray": null,
                            "strokeLineCap": "butt",
                            "strokeDashOffset": 0,
                            "strokeLineJoin": "miter",
                            "strokeUniform": false,
                            "strokeMiterLimit": 4,
                            "scaleX": scaleFactor,
                            "scaleY": scaleFactor,
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "opacity": 1,
                            "shadow": null,
                            "visible": true,
                            "backgroundColor": "",
                            "fillRule": "nonzero",
                            "paintFirst": "fill",
                            "globalCompositeOperation": "source-over",
                            "skewX": 0,
                            "skewY": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "src": obj.imageUrl,
                            "crossOrigin": "*",
                            "filters": []
                        }
                    }]

                    initial.forEach((curElem) => {
                        // curElem = JSON.stringify(curElem)
                        // curElem = curElem.replaceAll(`"crossOrigin":null`, `"crossOrigin" :"*"`)
                        // curElem = JSON.parse(curElem)
                        if (curElem.backgroundImage) {
                            if (!curElem.backgroundImage.src.includes("not-from-cache-please")) {
                                curElem.backgroundImage.src = curElem.backgroundImage.src + `?${time}not-from-cache-please`
                            } else {
                                let placeIndex = curElem.backgroundImage.src.indexOf("not-from-cache-please")
                                curElem.backgroundImage.src = curElem.backgroundImage.src.slice(0, placeIndex - 8) + time + curElem.backgroundImage.src.slice(placeIndex, 1)
                            }
                            curElem.objects.forEach((layer) => {
                                if (layer.type === "image") {
                                    if (!layer.src.includes("not-from-cache-please")) {
                                        layer.src = layer.src + `?${time}not-from-cache-please`
                                    } else {
                                        let placeIndexL = layer.src.indexOf("not-from-cache-please")
                                        layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                                    }
                                }
                            })
                        }
                    })
                    obj.data = initial
                    dispatch({ type: "ADD_IMAGE_DATA", payload: obj })
                    setLoader(false)
                }
                img.src = await obj.imageUrl
            }
            else {
                obj.data.forEach((curElem) => {
                    curElem = JSON.stringify(curElem)
                    curElem = curElem.replaceAll(`"crossOrigin":anonymous`, `"crossOrigin" :"*"`)
                    curElem = JSON.parse(curElem)
                    if (curElem.backgroundImage) {
                        if (!curElem.backgroundImage.src.includes("not-from-cache-please")) {
                            curElem.backgroundImage.src = curElem.backgroundImage.src + `?${time}not-from-cache-please`
                        } else {
                            let placeIndex = curElem.backgroundImage.src.indexOf("not-from-cache-please")
                            curElem.backgroundImage.src = curElem.backgroundImage.src.slice(0, placeIndex - 8) + time + curElem.backgroundImage.src.slice(placeIndex, 1)
                        }
                        curElem.objects.forEach((layer) => {
                            if (layer.type === "image") {
                                if (!layer.src.includes("not-from-cache-please")) {
                                    layer.src = layer.src + `?${time}not-from-cache-please`
                                } else {
                                    let placeIndexL = layer.src.indexOf("not-from-cache-please")
                                    layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                                }
                            }
                        })
                    }
                })
                dispatch({ type: "ADD_IMAGE_DATA", payload: obj })
                setLoader(false)
            }
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onCheckImageStatus = (data, common, setCommon, interval) => (dispatch, getState) => {

    commonAxios("fetch-custom-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data[0].status
                if (statusNum === 2) {
                    clearInterval(interval)
                    let text = ""
                    let redirectUrl = ""
                    let bgColor = "#117899"
                    switch (statusNum) {
                        case 2:
                            text = "Generated Successfully"
                            redirectUrl = `/marketing/image-editor?id=${data.id}`
                            bgColor = "#0f9628"
                            break;
                        default:
                            text = ""
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        status: statusNum,
                        isEditabel: true,
                        isDownloadable: res.data[0].imageUrl,
                        showRendering: false,
                        urlToRedirect: redirectUrl,
                        showProgAnimation: false,
                        thumbUrl: res.data[0].imageUrl,
                        bgColor: bgColor,
                        failedData: res.data[0].log_file ? JSON.parse(res.data[0].log_file) : false
                    })
                }
                else if (statusNum === 1) {
                    setCommon({
                        ...common,
                        statusText: "Generating Image...",
                        status: statusNum,
                        showRendering: false,
                        progress: res.data[0].renderProgress,
                        isEditabel: false,
                        isDownloadable: res.data[0].imageUrl,
                        thumbUrl: res.data[0].thumbnail,
                        bgColor: "#9a15c2",
                        urlToRedirect: `/marketing/image-editor?id=${data.id}`
                    })
                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}


export const onSavePostCanvas = (newData, navigate, setCanvasLoader, setSave, type, name, noi) => (dispatch, getState) => {
    setSave(false)
    let url = "update-canvas"
    if (type === "auto-post") {
        url = "update-auto-post"
    }
    commonAxios(url, newData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "auto-post") {
                    navigate(`/post/auto-inner-post?id=${newData.imageId}`, {
                        state: {
                            name: name,
                            dimension: newData.dimension,
                            noi: noi
                        }
                    }
                    )
                } else {
                    navigate('/marketing/image-maker')
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCanvasLoader(false)
        }).catch((err) => {
            console.log(err)
            setCanvasLoader(false)
        })
}

export const onGenerateText = (data, setText, setLoader) => (dispatch, getState) => {
    commonAxios("generate-content", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setText(res.data)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onTemplateChange = (data, fetchDesign) => (dispatch, getState) => {
    commonAxios("change-template-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchDesign()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCreateText = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/my-content/text-set?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onFetchText = (data, setText, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data[0] }
                obj.text = obj.text ? obj.text : obj.script.replaceAll("?", "")
                setText(obj)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onSaveText = (data, setLoader) => (dispatch, getState) => {
    commonAxios("save-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onCreateBaseImage = (obj, setImgUrl) => (dispatch, getState) => {
    commonAxios("save-base", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let url = res.data
                setImgUrl(url)
                // if (setIsDownload) {
                //     dispatch(onSaveCanvasJson(selectedIndex, data, setIsDownload, url, index))
                // } else {
                //     dispatch(onChangeTemplate(selectedIndex, index, data, url, text))
                // }

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onRegenerateMeta = (data, index, setLoader) => (dispatch, getState) => {
    commonAxios("regenerate-meta", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    text: res.data,
                    index
                }
                dispatch({ type: "REGENERATE_META", payload: obj })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

//No Axios

export const onUnmountDesign = () => (dispatch) => {
    dispatch({ type: "ON_LEAVE_IMAGE_EDITOR" })
}

export const onChangeTemplate = (activeIndex, index, obj, url, text) => (dispatch) => {
    let data = { activeIndex, index, obj, url, text }
    dispatch({ type: "CHANGE_TEMPLATE", payload: data })
}

export const onSaveCanvasJson = (index, data, setSave, url, text) => (dispatch) => {
    dispatch({ type: "SAVE_CANVAS", payload: { index, data, url, text } })
    setSave(true)
}

export const onUpadteMetaData = (index, data) => (dispatch) => {
    dispatch({ type: "UPADTE_METADATA", payload: { index, data } })
}