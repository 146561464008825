
import { commonAxios } from "../../Global/CommonAxios"
import { themeColor } from "../../Global/Global"
import { setAlert } from "./AlertActions"

export const onCreateAiTools = (url, data, navigate, loader, setLoader, fetchTool, handleClose) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (data.id) {
                    fetchTool()
                    handleClose()
                }
                else {
                    navigate(`/create-new-ai-tool?aid=${data.agencyId}&id=${res.data}`)
                }
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
        })
}


export const onFetchToolData = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-tool-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_TOOL_DATA", payload: res.data })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}
export const onFetchPaymentData = (data, response, setResponse, myInterval) => (dispatch, getState) => {
    commonAxios("fetch-payment", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data.status === 2) {
                    setResponse({
                        ...response,
                        payment: false,
                        paymentId: false
                    })
                    clearInterval(myInterval)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchResults = (data, setResult, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-results", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setResult(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

export const onExportUserCsv = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("export-csv", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let fileDownload = document.createElement("a");
                fileDownload.href = res.data;
                fileDownload.download = "results.csv";
                fileDownload.click();
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                export: false
            });
        }).catch((err) => {
            console.log(err.message);
            dispatch(setAlert(err.message, "danger"));
            setLoader({
                ...loader,
                export: false
            });
        });
}

export const onPreviewSubmit = (data, response, setResponse, loader, setLoader, toolData, previewType) => (dispatch, getState) => {
    commonAxios("generate-response", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                res.data = res.data
                    .replaceAll(/</g, "&lt;")
                    // .replaceAll(/>/g, "&gt")
                    .replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                    .replaceAll("```", "</pre>")
                    .replaceAll("\n", "<br />")
                    .replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                    .replaceAll("```\n", "</pre>")
                    .replaceAll("<br />", "<br>")

                if (toolData.integrations.stripe.enable && toolData.integrations.stripe.stripeId && toolData.integrations.stripe.amount && previewType === "live") {
                    setResponse({
                        ...response,
                        payment: true,
                        isResponse: true,
                        data: res.data
                    })
                } else {
                    setResponse({
                        ...response,
                        isResponse: true,
                        payment: false,
                        data: res.data
                    })
                }
            }

            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onSubmitToolData = (data, handleTabNext, loader, setLoader, redirect) => (dispatch, getState) => {
    commonAxios("update-user-tool", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (handleTabNext) {
                    dispatch(setAlert(res.msg, "success"))
                    if (redirect) {
                        redirect(`/my-tools?aid=${data.agencyId}`)
                    } else {
                        handleTabNext()
                    }
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onGenerateToolFromAI = (data, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-ai-tool", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch({ type: "SET_TOOL_DATA", payload: res.data })
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
        })
}

export const onTestPrompt = (data, setData, loader, setLoader, type) => (dispatch, getState) => {
    commonAxios("generate-ai-response", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                res.data = res.data
                    .replaceAll(/</g, "&lt;")
                    // .replaceAll(/>/g, "&gt")
                    .replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                    .replaceAll("```", "</pre>")
                    .replaceAll("\n", "<br />")
                    .replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                    .replaceAll("```\n", "</pre>")
                    .replaceAll("<br />", "<br>")

                setData(res.data)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                [type]: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                [type]: false
            })
        })
}

export const onDeleteAiTool = (data, navigate, setSweet) => (dispatch, getState) => {
    commonAxios("delete-user-tool", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/explore-ai-tools?aid=${getState().agency.activeAgency.id}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onSaveTemplate = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-tool-template", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onCopyTool = (data, aid, fetchTool, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("copy-tool", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (aid && navigate) {
                    navigate(`/create-new-ai-tool?aid=${aid}&id=${res.data}`)
                }
                fetchTool(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                copy: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                copy: false
            })
        })
}
export const onMakePayment = (data, setResponse) => (dispatch, getState) => {
    commonAxios("create-order", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setResponse((pre) => {
                    return {
                        ...pre,
                        paymentId: res.data.payment_id,
                        paymentUrl: res.data.payment_url
                    }
                })
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onAddLoadCount = (data) => (dispatch, getState) => {
    commonAxios("load-count", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const onFetchUserDetails = (data, handleClose, handleShow) => (dispatch, getState) => {
    commonAxios("fetch-payment-detail", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data.status === 2) {
                    handleClose();
                } else {
                    handleShow();
                }
            } else {
                handleShow();
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onFetchToolLanguage = (setPollyLanguages) => (dispatch, getState) => {
    commonAxios("get-tool-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                setPollyLanguages(arr);
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchToolVoice = (value, setArtist) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('lang_code', value);
    let id = ["96"]
    commonAxios("get-tool-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = [...res.data]
                obj = obj.filter((curElem) => {
                    return !id.includes(curElem.id)
                })
                setArtist(obj)
            }
        }).catch((err) => {
            console.log(err)
        })
}



//No Axios Here


export const onHandleFieldContainer = (item) => (dispatch) => {
    dispatch({ type: "ADD_TOOL_FIELD_CONTAINER", payload: item })
}
export const onHandleNewField = (item, dropIndex, pos) => (dispatch) => {
    dispatch({ type: "ADD_TOOL_FIELD", payload: { item, dropIndex, pos } })
}

export const onHandleShuffleField = (item, dropIndex, pos) => (dispatch) => {
    dispatch({ type: "SHUFFLE_TOOL_FIELD", payload: { item, dropIndex, pos } })
}

export const onDeleteFields = (index) => (dispatch) => {
    dispatch({ type: "DELETE_FIELD", payload: index })
}
export const onToggleMain = (val) => (dispatch) => {
    dispatch({ type: "TOOGLE_TYPE", payload: val })
}

export const editElementIndex = (index) => (dispatch) => {
    dispatch({ type: "EDIT_ELEMENT_INDEX", payload: index })
}

export const onChangeToolFileds = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_FIELD", payload: { name, value } })
}

export const onChangeFieldsText = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_FIELD_TEXT", payload: { name, value } })
}

export const onCheckedToolToggle = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DATA_ENABLE", payload: { name, value } })
}
export const onChangeToolData = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DATA", payload: { name, value } })
}
export const onCheckedDesignFont = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DESIGN_FONT_ENABLE", payload: { name, value } })
}
export const onChangeDesignFont = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DESIGN_FONT", payload: { name, value } })
}
export const onCheckedDesignLogo = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DESIGN_LOGO_ENABLE", payload: { name, value } })
}
export const onChangeDesignLogo = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_DESIGN_LOGO", payload: { name, value } })
}

export const onChangeIntegrationEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_ENABLE", payload: { name, value } })
}
export const onChangeIntegration = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION", payload: { name, value } })
}

export const onChangeIntegrationSmtpEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_SMTP_ENABLE", payload: { name, value } })
}
export const onChangeIntegrationSmtp = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_SMTP", payload: { name, value } })
}
export const onChangeIntegrationStripeEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_STRIPE_ENABLE", payload: { name, value } })
}
export const onChangeIntegrationStripe = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_STRIPE", payload: { name, value } })
}
export const onChangeIntegrationOpenAiEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_OPENAI_ENABLE", payload: { name, value } })
}
export const onChangeIntegrationOpenAi = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_OPENAI", payload: { name, value } })
}
export const onChangeIntegrationAutoResponderEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_AUTO_RESPONDER_ENABLE", payload: { name, value } })
}
export const onChangeIntegrationAutoResponder = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_AUTO_RESPONDER", payload: { name, value } })
}

export const onHandleChangeForm = (name, value, checked, index, objData) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_FORM_DATA", payload: { name, value, checked, index, objData } })
}

export const onChangeIntegrationDomainEnable = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_DOMAIN_ENABLE", payload: { name, value } })
}
export const onChangeIntegrationDomain = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TOOL_INTEGRATION_DOMAIN", payload: { name, value } })
}


export const onAddOption = () => (dispatch) => {
    dispatch({ type: "ON_ADD_OPTION" })
}

export const onRemoveOption = (ind) => (dispatch) => {
    dispatch({ type: "ON_REMOVE_OPTION", payload: ind })
}

export const onChangeToolFiledsOptions = (name, value, index) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_FIELD_OPTIONS", payload: { name, value, index } })
}

export const onSetFrameHeight = (height) => (dispatch) => {
    dispatch({ type: "ON_SET_FRAME_HEIGHT", payload: height })
}

export const onHandleChangeFormRequired = (checked, index) => (dispatch) => {
    dispatch({ type: "ON_CHECKED_FIELD", payload: { checked, index } })
}