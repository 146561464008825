import React from 'react'
import { onAddOption, onChangeToolFileds, onChangeToolFiledsOptions, onRemoveOption } from '../../../Redux/Actions/CreateToolActions'
import { useDispatch } from 'react-redux'
import { FaMinus, FaPlus } from "react-icons/fa"
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";

const EditToolFields = ({ curElem }) => {
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeToolFileds(name, value))
    }

    const handleChangeOptions = (e, ind) => {
        const { name, value } = e.target
        dispatch(onChangeToolFiledsOptions(name, value, ind))
    }

    const handleAddOptions = () => {
        dispatch(onAddOption())
    }

    const handleRemoveOptions = (ind) => {
        dispatch(onRemoveOption(ind))
    }
    return (
        <>
            {curElem.text === "" || curElem.text ?
                <div className="mt-3">
                    <label className="label" htmlFor="">{curElem.type}</label>
                    <input
                        className="input"
                        type="text"
                        name="text"
                        value={curElem.text}
                        onChange={handleChange}
                    />
                    <p className="pt-1 form-info">
                        <small>
                            {curElem.type === "Title" ? "The main title of your app." : curElem.type === "Subtitle" ? "The subtitle of your app." : "The text to display to users."}
                        </small>
                    </p>
                </div> : null}
            {curElem.label === "" || curElem.label ?
                <div className="mt-3">
                    <label className="label" htmlFor="">Label</label>
                    <input
                        className="input"
                        type="text"
                        name="label"
                        value={curElem.label}
                        onChange={handleChange}
                    />
                    <p className="pt-1 form-info"><small>The field label, displayed above the field.</small></p>
                </div> : null}
            {curElem.options === "" || curElem.options ?
                <div className="mt-3">
                    <div className='d-flex align-items-center justify-content-between'>
                        <label className="label" htmlFor="">Options</label>
                        <FiPlusSquare className='me-2' style={{cursor:"pointer"}} onClick={handleAddOptions} />
                    </div>
                    {curElem.options.length > 0 ?
                        curElem.options.map((curElem, oInd) => {
                            return (
                                <div className='my-1' key={oInd}>

                                    <div className='d-flex align-items-center justify-content-between'>
                                        <label className="label" htmlFor="" style={{ fontSize: 12 }}>Option {oInd + 1}</label>
                                        <FiMinusSquare className='me-2' style={{cursor:"pointer"}} onClick={() => handleRemoveOptions(oInd)} />
                                    </div>
                                    <label className="label" htmlFor="" >Enter name here</label>
                                    <input
                                        className="input mb-1"
                                        type="text"
                                        name="text"
                                        value={curElem.text}
                                        onChange={(e) => handleChangeOptions(e, oInd)}
                                    />
                                    <label className="label" htmlFor="" >Enter value here</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name="value"
                                        value={curElem.value}
                                        onChange={(e) => handleChangeOptions(e, oInd)}
                                    />
                                </div>
                            )
                        })
                        : ""}

                    <p className="pt-1 form-info"><small>Add and remove option here.</small></p>
                </div > : null
            }
            {
                curElem.placeholder === "" || curElem.placeholder ?
                    <div className="mt-3">
                        <label className="label" htmlFor="">Placeholder</label>
                        <input
                            className="input"
                            type="text"
                            name="placeholder"
                            value={curElem.placeholder}
                            onChange={handleChange}
                        />
                        <p className="pt-1 form-info"><small>The placeholder text. It will be displayed when the field is empty</small></p>
                    </div> : null
            }
            {
                curElem.name === "" || curElem.name ?
                    <div className="mt-3">
                        <label className="label" htmlFor="">Variable name</label>
                        <input
                            className="input"
                            type="text"
                            name='name'
                            value={curElem.name}
                            onChange={handleChange}
                        />
                        <p className="pt-1 form-info"><small>The variable, used for the prompt.</small></p>
                    </div> : null
            }

            {
                curElem.type === "separator" ?
                    <div className='text-center mt-4'>
                        No properties for this type.
                    </div>
                    : ""
            }
        </>
    )
}

export default EditToolFields