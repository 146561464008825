import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteSocialAccounts } from '../../../Redux/Actions/SocialActions'
import CommonAlert from '../../Common/CommonAlert'
import { BsTrash } from 'react-icons/bs'

const ConnectRows = ({ curElem }) => {
    const dispatch = useDispatch(0)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteSocialAccounts("delete-social-network", data, setSweet))
    }

    return (
        <div className="integrations-in">
            <div className="integration-left">
                <div className="integration-img"></div>
                <div className="integration-txt">
                    <h6>{curElem.type === "domain" ? curElem.domain : curElem.name}</h6>
                    <p>Added on - {curElem.created}</p>
                </div>
            </div>
            <div className="integration-right">
                <button className="site-link red sm" onClick={onDelete}><span>Disconnect</span></button>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to disconnect this connection?"}
                confirmButtonColor={""}
                icon={<BsTrash />}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default ConnectRows