import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";
import { fetchArticles, fetchVideos } from "./HelpActions";
import { fetchRebrandData } from "./RebrandingAction";
import { fetchSocialAccounts } from "./SocialActions";

export const loadUser = () => (dispatch) => {
    let token = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).auth.token : false
    if (token) {
        commonAxios("fetch-user", {}, dispatch, token)
            .then((res) => {
                if (res.status) {
                    dispatch({ type: 'LOAD_USER_DATA', payload: res.data });
                    dispatch(fetchVideos(false))
                    dispatch(fetchArticles(false))
                    dispatch(fetchRebrandData())
                    dispatch(fetchSocialAccounts())
                }
            }).catch((err) => {
                console.log(err)
            })
    }
}

export const masterLoginUser = (user, setLoader, navigate) => (dispatch) => {
    commonAxios("master-login", user, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())
                navigate('/dashboard');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const loginUser = (data, setLoader) => (dispatch) => {
    localStorage.removeItem("state");
    commonAxios("login", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}



export const forgetPassword = (data, setLoader) => (dispatch) => {
    commonAxios("forgot-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg.replace('if', 'If'), "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const resetPassword = (data, setLoader, navigate) => (dispatch) => {
    commonAxios("reset-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                navigate('/');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const updateName = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_NAME', payload: data.name });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}


export const updatePassword = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}




export const gdprAction = (data, sweet, setSweet) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}${sweet.ep}`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            if (sweet.ep === 'download-user-data') {
                window.location.href = res.data.file;
            } else {
                dispatch(logoutUser());
            }
            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "",
            text: "",
            ep: "",
            loader: false
        })
    }).catch(error => {
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "",
            text: "",
            ep: "",
            loader: false
        })
        dispatch(setAlert('Getting Internal Server Error', "danger"));
    })
}

// NEW ACTIONS


export const onUpdateUser = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data === 1) {
                    dispatch(logoutUser())
                }
                dispatch({ type: "CHANGE_USER_NAME", payload: data })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                user: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                user: false
            })
        })
}

export const onChangeEmail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("change-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_EMAIL', payload: data.email });
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const onUpdatePassword = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-user-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                password: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                password: false
            })
        })
}

export const logoutUser = () => (dispatch, getState) => {
    commonAxios("logout", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGOUT' });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const changeProfile = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-profile-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                image: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                image: false
            })
        })
}

export const onGenerateKey = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-api-key", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "UPDATE_API_KEY", payload: res.data })
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                create: false
            })
        })
}


export const onFetchWhitelabel = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-whitelabel-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch({ type: "ADD_WL_DATA", payload: res.data[0] })
                dispatch({ type: 'ADD_REBRAND', payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
} 