import React, { useEffect, useState } from 'react'
import { Modal, Nav, Tab } from 'react-bootstrap'
import { BsCheckCircle, BsFillTrashFill, BsUpload } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { onFecthUploadImage, onfetchImages } from '../../../../../Redux/Actions/ProjectActions';
import { AiOutlineClose } from 'react-icons/ai';
import HoverVideoPlayer from "react-hover-video-player";
import Swal from 'sweetalert2';
import { onUploadMedia } from '../../../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../../../Global/Global';
import CommonAlert from '../../../../Common/CommonAlert';


const VideoModal = (props) => {
    const dispatch = useDispatch()
    const [videoList, setVideoList] = useState([])
    const [uploadList, setUploadList] = useState([])
    const [video, setVideo] = useState({
        keyword: "",
        page_number: 1,
        search_type: "video"
    })
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false,
        upload: false

    })
    const [msg, setMsg] = useState("")
    const [percent, setPercent] = useState(0)

    const [selected, setSelected] = useState({
        data: false,
        index: false
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        type: "",
        loader: false
    })


    const handleSearch = (val) => {
        setVideo({
            ...video,
            keyword: val
        })
        if (videoList.length > 0) {
            setVideoList([])
        }
        if (msg !== "") {
            setMsg("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchImages()
    }

    const fetchImages = (page = 1) => {
        let data = { ...video }
        data.page_number = page
        setLoader({
            ...loader,
            fetchLoader: true
        })
        setVideo({
            ...video,
            page_number: page,
        })
        dispatch(onfetchImages(data, videoList, setVideoList, setMsg, loader, setLoader))
    }

    const onChooseVideo = (url, thumb, index) => {
        setSelected({
            ...selected,
            data: {
                src: url,
                thumb: thumb
            },
            index: index
        })
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            if (props.iconIndex !== undefined) {
                props.callbackFun(selected.data, props.iconIndex)
            } else {
                props.callbackFun(selected.data)
            }
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
            setVideoList([])
            setVideo({
                ...video,
                keyword: "",
                page_number: 1,
                search_type: "video"
            })
        }, 500);
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (['video/mp4'].includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 200000000) {
                    formData.append('upload_type', "video")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fecthUploadList, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 200MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }



    const handleDelete = (e, id) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            id: id,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
        })
    }

    const performDelete = () => {
        const formData = new FormData()
        formData.append("id", sweet.id)
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        // dispatch(onDeleteUserMedia(formData, fecthUploadList, setSweet))
    }

    const fecthUploadList = () => {
        let data = {
            type: "video",
        }

        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFecthUploadImage(data, setUploadList, loader, setLoader))
    }

    useEffect(() => {
        fecthUploadList()
    }, [])


    return (
        <Modal className="theme-modal video-modal xl" show={props.show} centered>
            <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
            <Modal.Body>
                <div className="imgUp">
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="h">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Library</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Upload</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="imgUp-main">
                                    <form className="img-search" onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Search Video"
                                            value={video.keyword}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </form>
                                    <InfiniteScroll
                                        dataLength={videoList.length} //This is important field to render the next data
                                        next={() => fetchImages(video.page_number + 1)}
                                        hasMore={true}
                                        scrollableTarget={`scrollableVideoModal`}
                                    >
                                        <div className="img-search-scroll" style={{ minHeight: "350px" }} id='scrollableVideoModal'>
                                            <ul >
                                                {videoList.length > 0 ?
                                                    videoList.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div
                                                                    className="photo-single"
                                                                    style={{ border: selected.index === index ? "2px solid #3f429b" : "", cursor: "pointer" }}
                                                                    onClick={() => onChooseVideo(curElem.url, curElem.thumbnail, index)}
                                                                >
                                                                    <HoverVideoPlayer
                                                                        className="hover-video-player"
                                                                        style={{ height: '100%' }}
                                                                        videoSrc={curElem.url}
                                                                        pausedOverlay={
                                                                            <img
                                                                                src={curElem.thumbnail}
                                                                                alt={``}
                                                                                style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                                        }
                                                                        loadingOverlay={
                                                                            <div style={{ color: "#3f429b", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                                <i className="fa fa-spinner fa-spin hover-loader-center" />
                                                                            </div>
                                                                        }
                                                                    />
                                                                    <div className="imgStat"><BsCheckCircle /></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    : <div className='text-center pt-3' style={{ margin: "auto" }}>
                                                        {msg}
                                                    </div>}
                                            </ul>
                                            {loader.fetchLoader ?
                                                <div className='text-center mt-3'>
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#32cd32" }} />
                                                </div>
                                                : ""}

                                        </div>
                                    </InfiniteScroll>

                                </div>
                                <div className="btnTab">
                                    <button className="site-link bdr" onClick={props.handleClose}><span>Cancel</span></button>
                                    <button className="site-link ms-1" onClick={handleSave}><span> {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /></> : "Use"}</span></button>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <>
                                    <div className="imgUp-main " >
                                        <div className="style-upload-box">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                onChange={(e) => onInputChange(e)}
                                            />
                                            <div>
                                                {loader.upload ?
                                                    <div className='percent-loader-box'>
                                                        <span className='loader-percent'>{percent}%</span>
                                                    </div>
                                                    : <>
                                                        <BsUpload />
                                                        <h6>Upload your video</h6>
                                                    </>}
                                            </div>
                                        </div>

                                        <div className="img-search-scroll">
                                            <ul>
                                                {uploadList.length > 0 ?
                                                    uploadList.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div
                                                                    className="photo-single "
                                                                    onClick={() => onChooseVideo(curElem.url, curElem.poster, index)}
                                                                    style={{ cursor: "pointer", border: +selected.index === index ? "2px solid #3f429b" : "" }}
                                                                >
                                                                    <HoverVideoPlayer
                                                                        className="hover-video-player"
                                                                        style={{ height: '100%' }}
                                                                        videoSrc={curElem.url}
                                                                        pausedOverlay={
                                                                            <img
                                                                                src={curElem.poster}
                                                                                alt={``}
                                                                                style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                                        }
                                                                        loadingOverlay={
                                                                            <div style={{ color: "#3f429b", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                                <i className="fa fa-spinner fa-spin hover-loader-center" />
                                                                            </div>
                                                                        }
                                                                    />
                                                                    <div className='list-delete' style={{ zIndex: 2 }} onClick={(e) => handleDelete(e, curElem.id)}>
                                                                        <BsFillTrashFill />
                                                                    </div>
                                                                    {/* <div className="imgStat"><BsCheckCircle /></div> */}
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    : ""}
                                            </ul>
                                            {loader.fetchLoader ?
                                                <div className='text-center mt-3'>
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#3f429b" }} />
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className="btnTab">
                                        <button className="site-link bdr" onClick={props.handleClose}><span>Cancel</span></button>
                                        <button className="site-link ms-1" onClick={handleSave}><span>{!loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin" /> </> : "Use"}</span></button>
                                    </div>



                                </>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this video?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </Modal>
    )
}

export default VideoModal