import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FaBox } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { MdChevronLeft } from "react-icons/md";
import { useSelector } from 'react-redux';
import CusstomToolModel from './CusstomToolModel';
import BroserModel from './BroserModel';
const TeamplateModel = ({ show, handleClose }) => {
    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [toolId, setToolId] = useState({
        id: "0"
    })
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleOpenModel = () => {

        setShow6(true);

    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return (
        <>
            <Modal className='VideoModal large' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='p-4'>
                        <h4 className='text-center'>Let's build a new AI tool with AIWhitelabels </h4>
                        <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>Choose how you want to create new AI tool from the options below</h6>
                        <div className="row pt-3">
                            <div className="col-lg-6 mt-4" >
                                <div className='gen-card'>
                                    <div className="gen-icon"><FaBox /></div>
                                    <h5 className='pt-3'>Use a Template</h5>
                                    <p className='pt-3'>100+ ready-made templates you can copy and use freely.</p>
                                    <button className='site-link full mt-4' type="button" onClick={handleShow2} ><span>Browse Templates</span></button>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <div className='gen-card' >
                                    <div className="gen-icon"><BsStars /></div>
                                    <h5 className='pt-3'>Create custom tool</h5>
                                    <p className='pt-3'>
                                        Create a totally custom tool in our tool builder</p>
                                    <button className='site-link full mt-4' type="button" onClick={handleOpenModel} ><span>Generate</span></button>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 mt-4">
                            <div className='gen-card'>
                                <div className="gen-icon"><HiLightBulb /></div>
                                <h5 className='pt-3'>Get Unique ideas</h5>
                                <p className='pt-3'>Find the best ideas based on content of your website.</p>
                                <button className='site-link full mt-4'><span>Get Ideas</span></button>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <CusstomToolModel show={show6} handleClose={handleClose6} toolId={toolId} type="custom" agencyId={agencyId} />
            <BroserModel show2={show2} handleClose2={handleClose2} />
        </>
    )
}

export default TeamplateModel