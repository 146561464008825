import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { Search } from 'react-iconly'
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import ProjectRows from './ProjectRows';
// import Pagination from '../../CommonComponent/Pagination';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import CreateProject from './CreateProject';
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Pagination from '../../Common/Pagination';

const Projects = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [type, setType] = useState(1)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [campLoader, setCampLoader] = useState({
        fetch: true
    })
    const [sortHeader, setSortHeader] = useState({
        campaignName: "",
        created: "",
        productName: ""
    })

    const [campaignsData, setCampaignsData] = useState([])
    const [camp, setCamp] = useState([])
    const [filterObj, setFilterObj] = useState({
        searchKey: ""
    })
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;

    const currentTodo =
        filterObj.searchKey !== "" ? campaignsData : campaignsData.slice(indexofFirstTodo, indexofLastTodo);

    const handleSort = (type) => {
        const data = [...camp]
        if (type === "name") {
            if (sortHeader.campaignName) {
                data.sort((a, b) => {
                    if (a.campaignName.toLowerCase() > b.campaignName.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    campaignName: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.campaignName.toLowerCase() < b.campaignName.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    campaignName: true,
                });
            }
        }
        else if (type === "created") {
            if (sortHeader.created) {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() > b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() < b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: true,
                });
            }
        }
        else if (type === "product") {
            if (sortHeader.productName) {
                data.sort((a, b) => {
                    if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    productName: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    productName: true,
                });
            }
        }
        setCamp(data)
    }

    const handleChange = (e) => {
        const { value } = e.target
        setFilterObj({
            ...filterObj,
            searchKey: value
        })
    }

    const createCampaign = () => {
        handleShow()
    }

    const fetchCamp = () => {
        dispatch(fetchData("fetch-all-campaign", {}, setCamp, campLoader, setCampLoader))
    }

    useEffect(() => {
        if (filterObj.searchKey === "") {
            setCampaignsData(camp)
        }
        else {
            let dataArr = camp.filter((item1) => {
                return (item1.campaignName.toLowerCase().includes(filterObj.searchKey.toLowerCase()) || item1.productName.toLowerCase().includes(filterObj.searchKey.toLowerCase()))
            })
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 10
            })
            setCampaignsData(dataArr)
        }

    }, [filterObj, camp])

    useEffect(() => {
        setPagination({
            ...pagination,
            currentPage: 1,
            totalItemOnPage: 10
        })

        setCamp([])
        fetchCamp()
    }, [])

    return (
        <>
            <TitleBar title="Campaigns" />
            <Header />

            <section className='page-wrap' style={{ paddingTop: "0" }}>

                <div className='container-fluid'>
                    <div className='d-flex mob-margin align-items-center justify-content-between flex-wrap gap-3'>
                        <h1 className='page-title'>My Campaigns</h1>
                        {auth.user.isClientAccount === "1" ? "" :
                            <span className='site-link bdr' onClick={createCampaign}><span>+ Create Campaign</span></span>}
                    </div>
                    <div className='cont-box' style={{ padding: 20 }}>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search Campaign'
                                        onChange={handleChange}
                                    // readOnly={camp.length === 0}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-lg-2">
                                <div className="input-wrap with-icon">
                                    <div className="inp-wrap">
                                        <select
                                            className="inp"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <option value={1}>Projects</option>
                                            <option value={2}>Reelmerge Projects</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {+type === 1 ?
                            <div className='video-table'>
                                <div className='table-responsive campaigns-data-table'>
                                    <table className='table'>
                                        <tr>
                                            <th></th>
                                            <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>Campaign Name<span> {sortHeader.campaignName === "" ? <FaSort /> : sortHeader.campaignName ? <FaSortDown /> : <FaSortUp />}</span></th>
                                            <th onClick={() => handleSort("product")} style={{ cursor: "pointer" }}>Product Name<span> {sortHeader.productName === "" ? <FaSort /> : sortHeader.productName ? <FaSortDown /> : <FaSortUp />}</span></th>
                                            <th onClick={() => handleSort("created")} style={{ cursor: "pointer" }}>Created<span  > {sortHeader.created === "" ? <FaSort /> : sortHeader.created ? <FaSortDown /> : <FaSortUp />}</span></th>
                                            {/* <th>Name</th>
                                         <th>Created</th> */}
                                            <th style={{ cursor: "pointer" }}>Action</th>
                                        </tr>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ProjectRows
                                                            curElem={curElem}
                                                            camp={camp}
                                                            setCamp={setCamp}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            camp.length === 0 ?
                                                <tr>
                                                    <td colSpan={5} className='text-center'>
                                                        {
                                                            campLoader.fetch ?
                                                                <i className="fa fa-spinner fa-spin " style={{ fontSize: 25 }} />
                                                                :
                                                                <p style={{ fontSize: 14 }}>
                                                                    You do not have any "Campaigns" in your account.
                                                                </p>
                                                        }
                                                    </td>
                                                </tr>
                                                : ""
                                        }

                                    </table>
                                </div>
                                {(!campLoader.fetch) && <Pagination
                                    listData={campaignsData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={camp}
                                    loader={campLoader.fetch}
                                />}
                            </div>
                            : ""
                            // <MergeProjects
                            //     filterObj={filterObj}
                            // />
                        }

                    </div>
                </div>
            </section>
            <CreateProject
                show3={show}
                handleClose3={handleClose}
            />
            {/* <Footer /> */}
        </>
    )

}

export default Projects;