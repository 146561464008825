import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';

const HelpVideos = (props) => {
    const supportData = useSelector(state => state.help);

    const [videosData, setVideosDara] = useState(supportData.videos);
    const [url, setUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        setVideosDara(supportData.videos)
    }, [supportData.videos])

    return (
        <>
            <div className='titleBar pt-5'>
                <div className='titleBar-left'>
                    <h2>Popular Video Tutorial</h2>
                </div>
            </div>


            <div className='support-vid'>
                <div className='row'>

                    {
                        videosData.length > 0 ?
                            videosData.map((video, index) => {
                                return (
                                    <div className='col-sm-6 mt-4' key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => playVideo(video.url)}>
                                        <div className='support-vid-single'>
                                            <div className='support-video-thumb iconx'><img src={require("../../../images/appicon.jpg")} alt="" /></div>
                                            <div className='support-txt'>{video.title}</div>
                                        </div>
                                    </div>

                                )
                            })
                            : ''
                    }
                    {
                        props.loadVideos ?
                            <div className="col-12">
                                <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                            </div>
                            : ''
                    }
                </div>
            </div>


            <Modal className='VideoModal' show={showModal} onHide={(e) => closeModal()} centered>
                <Modal.Body>
                    <div className='vidClose' onClick={(e) => closeModal()}><IoMdClose /></div>
                    <div className='modalVidWrap'>
                        <div className='modalVid'><iframe src={url} title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default HelpVideos
