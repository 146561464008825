import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { MdChevronLeft } from "react-icons/md";
import { FaSpinner } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { themeColor } from '../../Global/Global'
import { useDispatch } from 'react-redux';
import { onUploadMedia } from '../../Redux/Actions/CommonActions';
import { onCreateAiTools } from '../../Redux/Actions/CreateToolActions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const CusstomToolModel = ({ show, handleClose, toolId, type, id, editData, fetchTool }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const agencyId = useSelector(state => state.agency.activeAgency.id)
    const [percent, setPercent] = useState(0)
    const [toolData, setToolData] = useState({
        "agencyId": false,
        "type": type,
        "name": "",
        "description": "",
        "image": ""

    })
    const [loader, setLoader] = useState({
        upload: false,
        create: false
    })
    const onCompeleteUpload = (url) => {
        setToolData({ ...toolData, "image": url })

    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setToolData({ ...toolData, [name]: value })
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let data = {
            ...toolData,
            "toolId": toolId.id || "0",
            agencyId,
            id
        }
        let url = id ? "update-user-tool" : "create-tool"
        dispatch(onCreateAiTools(url, data, navigate, loader, setLoader, fetchTool, handleClose))
    }

    useEffect(() => {
        if (editData) {
            setToolData(editData)
        }
    }, [editData])

    useEffect(() => {
        if (show) {
            if (editData) {
                setToolData(editData)
            }
        } else {
            setToolData({
                "agencyId": false,
                "type": type,
                "name": "",
                "description": "",
                "image": ""
            })
        }
    }, [show])

    return (
        <Modal className='VideoModal md-modal' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='p-4'>
                    <h4 className='text-center'>{toolId.name || `${id ? "Update" : "Create"} custom tools`}</h4>
                    <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>{toolId.description || "Description of the template"}</h6>
                    <form onSubmit={handleSubmit}>
                        <div className='mt-3'>
                            <label className='label' htmlFor="">Give your tool a name</label>
                            <input className='input fill' type="text" placeholder='Enter tool name' name="name" value={toolData.name} onChange={handleChange} required />
                        </div>

                        <div className='mt-3'>
                            <label className='label' htmlFor=""> Give your tool a description</label>
                            <textarea style={{ height: 140 }} className='input fill' id="" placeholder='Enter your description' name="description" value={toolData.description} onChange={handleChange} />
                        </div>
                        <div className="mt-3">
                            <div className="file-field upload-box d-flex align-items-center justify-content-center">

                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                                        {loader.upload ?
                                            <FaSpinner className="fa-spin" size={25} color={themeColor} />
                                            :
                                            <img alt="" src={toolData.image} style={{ maxHeight: "100%" }} />
                                        }
                                    </div>
                                    <div>
                                        <h6>Upload a logo for your tool</h6>
                                        <p>JPG, PNG, JPEG upto 5MB is allowed</p>
                                    </div>
                                </div>
                                {loader.upload ? null :
                                    <input
                                        type="file"
                                        onChange={onInputImage}
                                        accept="image/jpg, image/png, image/jpeg"
                                    />
                                }
                            </div>
                        </div>

                        <div className='text-center pt-4 d-flex align-items-center justify-content-center gap-2'>
                            <button className='site-link grey' type="button" onClick={handleClose}><span><MdChevronLeft /> Back</span></button>
                            <button className='site-link' type='submit' ><span> {loader.create ? <>{id ? "Updating" : "Creating"} <FaSpinner className="fa-spin" /> </> : id ? "Update Tool" : "Create new tool"}</span></button>
                        </div>
                    </form>

                </div>
            </Modal.Body>
        </Modal >
    )
}

export default CusstomToolModel