import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { onUpdateLogoInfo } from '../../../../../Redux/Actions/VideoActions'

const Logo = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const logoRef = useRef(null)

    useEffect(() => {
        if (campaign?.logo?.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateLogoInfo(logoRef?.current?.clientHeight, logoRef?.current?.clientWidth))
            }, 2000)
        } else {
            dispatch(onUpdateLogoInfo(0, 0))
        }
    }, [campaign?.logo?.src])

    return (

        campaign?.logo?.src !== "" ?
            <div className={`logo-${campaign?.logo?.pos} logo-style `}>
                <img
                    src={campaign?.logo?.src}
                    alt="logo"
                    ref={logoRef}
                />
            </div>
            : ''
    )
}

export default Logo