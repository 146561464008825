import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import { onChangeFieldsText, onSaveTemplate } from '../../../Redux/Actions/CreateToolActions'
import { FaSpinner } from 'react-icons/fa'
import { IoArrowBackOutline } from 'react-icons/io5'
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { setAlert } from '../../../Redux/Actions/AlertActions'

// let audio = false
const LeftBuilder = ({ testData, setTestData, loaderTest }) => {
    const dispatch = useDispatch()
    const textareaRef = useRef(null);
    const createTool = useSelector(state => state.createTool)
    const [filedData, setFieldData] = useState([])
    // const [play, setPlay] = useState(false)

    const [loader, setLoader] = useState({
        save: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeFieldsText(name, value))
    }

    // const handleAddKey = (insertedText) => {
    //     const textarea = textareaRef.current;

    //     if (textarea) {
    //         const start = textarea.selectionStart;
    //         const end = textarea.selectionEnd;

    //         const updatedText =
    //             createTool.data.prompts.slice(0, start) + `{{${insertedText}}}` + createTool.data.prompts.slice(end);
    //         dispatch(onChangeFieldsText("prompts", updatedText))

    //         setTimeout(() => {
    //             textarea.setSelectionRange(
    //                 start + insertedText.length,
    //                 start + insertedText.length
    //             );
    //         }, 0);
    //     }
    // };

    const handleSave = () => {
        let obj = { ...createTool.data }
        setLoader({
            ...loader,
            save: true
        })
        dispatch(onSaveTemplate(obj, loader, setLoader))
    }

    // const playTts = (url) => {
    //     if (audio !== false) {
    //         audio.pause();
    //         setPlay(false)
    //     }
    //     else {
    //         audio = new Audio(url);
    //         audio.play();
    //         setPlay(true)
    //         audio.onended = function () {
    //             audio = false
    //             setPlay(false)
    //         }
    //     }
    // }

    // const downloadVoice = async (url) => {
    //     try {
    //         const response = await fetch(url);
    //         if (!response.ok) throw new Error('Network response was not ok.');
    //         const blob = await response.blob();
    //         const blobUrl = window.URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = blobUrl;
    //         link.download = 'voice_file.mp3';
    //         document.body.appendChild(link);
    //         link.click();
    //         window.URL.revokeObjectURL(blobUrl);
    //         document.body.removeChild(link);
    //     } catch (error) {
    //         console.error('Failed to download the voice file:', error);
    //     }
    // };



    const copyUrl = () => {
        navigator.clipboard.writeText(testData);
        dispatch(setAlert("URL copied successfully", "success"))
    }

    const fetchFiledData = () => {
        dispatch(fetchData("fetch-field-data", {}, setFieldData))
    }

    const outputData = filedData.filter((curData) => curData.category === "outputType")
    const providerData = filedData.filter((curData) => curData.category === "provider")
    const modalData = filedData.filter((curData) => curData.category === "models")

    useEffect(() => {
        fetchFiledData()
    }, [])

    return (
        <div className="builder-left hideScrollBar">
            {testData ?
                <div>
                    <button className="site-link smaller my-1" onClick={() => setTestData("")}><span><IoArrowBackOutline /> Back</span></button>
                    <div className='p-2 mt-2' style={{ border: "1px solid #cdcdcd", borderRadius: '0.6rem' }}>

                        <div className='text-end mb-2'><button className="site-link smaller" type='submit' ><span>{loaderTest.regenerate ? <>Regenerating <FaSpinner className='fa-spin' /> </> : "Regenerate"}</span></button></div>
                        {+createTool.data.toolId === 17 ?
                            <>
                                <audio src={testData} controls></audio>
                                {/* <p className='mb-2 kap_url'>{testData}</p> */}
                                <div className='d-flex align-items-center justify-content-end'>
                                    {/* <div className='kap_play'>
                                        {play ?
                                            <FaStop title='Pause' style={{ cursor: "pointer" }} onClick={() => playTts(testData)} />
                                            :
                                            <FaPlay title='Play' style={{ cursor: "pointer", position: "relative", left: "2px" }} onClick={() => playTts(testData)} />
                                        }
                                    </div>
                                    <div className='kap_play'>
                                        <MdOutlineFileDownload style={{ cursor: "pointer" }} onClick={() => downloadVoice(testData)} />
                                    </div> */}
                                    <button type='button' className='site-link smaller' onClick={copyUrl}><span>Copy URL</span></button>
                                </div>
                            </>

                            : testData.startsWith('https') && /\.(jpg|jpeg|png|gif)$/i.test(testData) ?
                                <img src={testData} alt="" />
                                :
                                <p className='p-2'>
                                    <ReactMarkdown children={testData} rehypePlugins={[rehypeRaw]} />
                                </p>

                        }
                    </div>
                </div>
                :
                <>
                    <div>
                        <h6>AI Prompt</h6>
                        <textarea
                            className="input fill mt-2"
                            style={{ height: 206 }}
                            name="prompts"
                            value={createTool.data.prompts}
                            onChange={handleChange}
                            ref={textareaRef}
                        />
                        <p><small>You will see variables from form elements here.</small></p>
                        <div className="prompt-var">
                            <ul>
                                {createTool.data.forms.length > 0 ?
                                    createTool.data.forms.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {curElem.name || curElem.name === "" ?
                                                    <li
                                                    >{`{{${curElem.name}}}`}</li> : ""}
                                            </React.Fragment>
                                        )
                                    })
                                    : ""}
                            </ul>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h6>System Prompt</h6>
                        <textarea
                            className="input fill mt-2"
                            name="systemPrompt"
                            placeholder="You are an experienced fitness coach skilled in meal planning"
                            value={createTool.data.systemPrompt}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6 mt-4">
                            <div>
                                <h6>Output Type</h6>
                                <select
                                    className="input mt-2"
                                    name='outputType'
                                    onChange={handleChange}
                                    required
                                    value={createTool.data.outputType}
                                >
                                    {+createTool.data.toolId === 17 ?
                                        <>
                                            <option value={""}>Select an option</option>
                                            <option value={"mp3"}>Mp3</option>
                                        </>
                                        :
                                        outputData.length > 0 ?
                                            outputData.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                </select>
                            </div>

                        </div>
                        <div className="col-6 mt-4">
                            <div>
                                <h6>Provider</h6>
                                <select
                                    className="input mt-2"
                                    onChange={handleChange}
                                    name='provider'
                                    value={createTool.data.outputType}
                                >
                                    {providerData.length > 0 ?
                                        providerData.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                            </div>
                        </div>
                        {(+createTool.data.toolId === 74 || +createTool.data.toolId === 18 || +createTool.data.toolId === 20) && createTool.data.outputType.toLowerCase() !== "image" ?
                            <div className='col-12 mt-2'>
                                <div class="alert alert-info" role="alert">
                                    This tool generate image. and you are providing output type {createTool.data.outputType}.
                                </div>
                            </div> : null}
                    </div>

                    <div className="radio-group mt-4">
                        {modalData.length > 0 ?
                            modalData.map((curElem, index) => {
                                return (
                                    <div className="radio-group-single" key={index}>
                                        <span className="radio-box"></span>
                                        <input
                                            type="radio"
                                            name="model"
                                            checked={curElem.name.toLowerCase() === createTool.data.model.toLowerCase()}
                                            value={curElem.name}
                                            onChange={handleChange}
                                        />
                                        {curElem.name}
                                    </div>
                                )
                            })
                            : ""}
                    </div>



                    {/* <div className="card-box mt-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h6>API Usage</h6>
                    <button className="site-link small"><span>Manage API Keys</span></button>
                </div>
                <div className="d-flex align-items-center justify-content-between pt-2">
                    <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-1"><img src={require("../../../images/ai.svg").default} alt="" /> OpenAI</div>
                        <div className="d-flex align-items-center gap-1"><img src={require("../../../images/ai.svg").default} alt="" /> Anthropic</div>
                    </div>
                    <div>Free credits: <span style={{ color: 'var(--link)' }}>1</span></div>
                </div>
            </div> */}

                    <div className="row mt-4">
                        <div className="col"><button className="site-link grey full" type='button' onClick={() => handleSave()}><span>{loader.save ? <>Saving <FaSpinner className='fa-spin' /> </> : "Save as template"}</span></button></div>
                        <div className="col">
                            <button
                                className="site-link full"
                                type='submit'
                            >
                                <span>{loaderTest.test ? <>Test Prompt <FaSpinner className='fa-spin' /> </> : "Test Prompt"}</span>
                            </button>
                        </div>
                    </div>
                </>
            }
        </div >
    )
}

export default LeftBuilder