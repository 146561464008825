import { produce } from "immer"
const initialState = {
    data: {
        toggleMenu: false,
        rephraseData: false,
        isKnowledgeData: true,
        isWhiteLabel: false
    }
}

export const ExtraReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TOOGLE_SIDE_MENU":
            return produce(state, (draft) => {
                draft.data.toggleMenu = action.payload
            })

        case "ADD_REPHRASE_DATA":
            return produce(state, (draft) => {
                draft.data.rephraseData = action.payload
            })

        case "ON_CHANGE_REPHRASE":
            return produce(state, (draft) => {
                draft.data.rephraseData.rePhrased = action.payload
            })

        case "UNMOUNT_REPHRASE":
            return produce(state, (draft) => {
                draft.data.rephraseData = false
            })

        case "UPDATE_KNOWLEDGE_STATUS":
            return produce(state, (draft) => {
                draft.data.isKnowledgeData = action.payload
            })

        case "WHITELABEL_STATUS_CHANGE":
            return produce(state, (draft) => {
                draft.data.isWhiteLabel = action.payload
            })
        default:
            return state
    }
}