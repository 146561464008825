import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { onAddMember, onUpdateMember } from '../../../../../Redux/Actions/ProjectActions'
import { useDispatch } from 'react-redux'
import { FaCheck } from "react-icons/fa";
import modalCloseIcon from '../../../../../images/modal-close.png';

const BonusModal = (props) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(false)

  const handleSubmit = () => {
    if (selected) {
      if (props.bonusIndex !== undefined) {
        dispatch(onUpdateMember(selected, props.bonusIndex))
      } else {
        dispatch(onAddMember(selected))
      }
      props.handleClose()
    }
  }

  return (
    <Modal className='theme-modal VideoModal xl' show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        {/* <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div> */}
        <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
        <h3 className='text-capitalize gradientTxt'><span>Choose Bonus</span></h3>
        <div className="p-4 Choose_Bonus_wrapper">
          <div className="row">

            {props.data.length > 0 ?
              props.data.map((curElem, index) => {
                return (
                  <div className='col-md-4 mb-4' key={index}>
                    <div className={`Choose_Bonus ${selected.id === curElem.id ? "selected_bonus" : ""}`} style={{ cursor: "pointer" }} onClick={() => setSelected(curElem)}>
                      <div className='Choose_Bonus-img'> <img className="" src={curElem.bonusUrl} alt='' /></div>

                      <p className='fs-6 text-center'>{curElem.name}</p>
                      {selected.id === curElem.id ?
                        <div className="checkicon text-center">
                          <FaCheck style={{ color: "#18BD15", fontSize: "14px" }} />
                        </div> : ""}
                    </div>
                  </div>
                )
              })
              : ""}

          </div>

        </div>
        <div className='d-flex align-items-center justify-content-end gap-2 pt-4'>
          <button className='site-link m-0' type='button' onClick={props.handleClose}><span>Cancel</span></button>
          <button className='site-link m-0' type='button' onClick={handleSubmit}><span>Add</span></button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BonusModal