import React from 'react'

function SecondTab() {
  return (
    <>
      <div className='row' style={{marginTop: '-1.5rem'}}>
        <div className='col-lg-6 mt-4'>
            <div className="collection-card">
                <div className='row align-items-center'>
                    <div className="col">
                        <h5>Dummy Collection</h5>
                        <p><small>Collection description</small></p>
                    </div>
                    <div className="col-auto d-flex align-items-center gap-2">
                        <button className='iconButton auto'>Select</button>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-lg-6 mt-4'>
            <div className="collection-card">
                <div className='row align-items-center'>
                    <div className="col">
                        <h5>Dummy Collection</h5>
                        <p><small>Collection description</small></p>
                    </div>
                    <div className="col-auto d-flex align-items-center gap-2">
                        <button className='iconButton auto'>Select</button>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-lg-6 mt-4'>
            <div className="collection-card">
                <div className='row align-items-center'>
                    <div className="col">
                        <h5>Dummy Collection</h5>
                        <p><small>Collection description</small></p>
                    </div>
                    <div className="col-auto d-flex align-items-center gap-2">
                        <button className='iconButton auto'>Select</button>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-lg-6 mt-4'>
            <div className="collection-card">
                <div className='row align-items-center'>
                    <div className="col">
                        <h5>Dummy Collection</h5>
                        <p><small>Collection description</small></p>
                    </div>
                    <div className="col-auto d-flex align-items-center gap-2">
                        <button className='iconButton auto'>Select</button>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <div className='collection-single'>
                            <div className='mb-2'><img src={require("../../images/monitor.svg").default} alt="" /></div>
                            <h6>Social AI for Engaging Content</h6>
                            <p>Creating Customized Content for Social Media content Creating Customized Content for Social Media content for Social Media</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default SecondTab