const initialState = {
    google: [],
    facebook: [],
    youtube: [],
    scriptreel: [],
    livereel: [],
    trimreel: [],
    syvid: [],
    sonority: [],
    videoreel: [],
    elevenlabs: [],
    autoresponder: [],
    cal: [],
    instagram: [],
    smtp: [],
    twilio: [],
    paypal: [],
    stripe: [],
    openai: [],
    domain: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    google: action.payload.data.google ? action.payload.data.google : [],
                    facebook: action.payload.data.facebook ? action.payload.data.facebook : [],
                    youtube: action.payload.data.youtube ? action.payload.data.youtube : [],
                    scriptreel: action.payload.data.scriptreel ? action.payload.data.scriptreel : [],
                    livereel: action.payload.data.livereel ? action.payload.data.livereel : [],
                    trimreel: action.payload.data.trimreel ? action.payload.data.trimreel : [],
                    syvid: action.payload.data.syvid ? action.payload.data.syvid : [],
                    sonority: action.payload.data.sonority ? action.payload.data.sonority : [],
                    videoreel: action.payload.data.videoreel ? action.payload.data.videoreel : [],
                    elevenlabs: action.payload.data.elevenlabs ? action.payload.data.elevenlabs : [],
                    cal: action.payload.cal ? action.payload.cal : [],
                    instagram: action.payload.instagram ? action.payload.instagram : [],
                    autoresponder: action.payload.data.autoresponder ? action.payload.data.autoresponder : [],
                    smtp: action.payload.data.smtp ? action.payload.data.smtp : [],
                    twilio: action.payload.data.twilio ? action.payload.data.twilio : [],
                    paypal: action.payload.paypal ? action.payload.paypal : [],
                    stripe: action.payload.data.stripe ? action.payload.data.stripe : [],
                    openai: action.payload.data.openai ? action.payload.data.openai : [],
                    domain: action.payload.data.domain ? action.payload.data.domain : []
                }
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}