import { React, useState } from 'react'

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'

import TitleBar from '../../Common/TitleBar'
import IntegrationAcc from './IntegrationAcc'
import { useSelector } from 'react-redux'

const Integrations = () => {
    const socials = useSelector(state => state.social)

    return (
        <>
            <TitleBar title="Integrations" />
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">
                    <div className='container'>
                        <div className='account-right ' style={{ width: "100%" }}>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Integrations</h2>
                                </div>
                            </div>


                            {/* <IntegrationAcc
                                name="Google Calendar"
                                desc="Booking your events in Google Calendar"
                                image={require('../../../images/google.png')}
                                type="GC"
                                data={socials.google}
                            /> */}


                            {/* <IntegrationAcc
                                name="Facebook"
                                type="FB"
                                desc="Post contents in Facebook"
                                image={require('../../../images/facebook-logo.svg').default}
                                data={socials.facebook}
                            /> */}

                            <IntegrationAcc
                                name="Domain"
                                type="domain"
                                url="add-domain"
                                desc="Add your custom domain to the tools"
                                image={require('../../../images/globe.png')}
                                data={socials.domain}
                            />

                            <IntegrationAcc
                                name="ElevenLabs"
                                desc="Give a unique voice and clone your own voice "
                                type="EL"
                                url="create-elevenlabs"
                                image={require('../../../images/elevenlabs.png')}
                                data={socials.elevenlabs}
                            />

                            {/* <IntegrationAcc
                                        name="Instagram"
                                        desc="Messaging to teams"
                                        type="IG"
                                        image={require('../../../images/instagram.svg').default}
                                        data={socials.instagram}
                                    /> */}

                            <IntegrationAcc
                                name="Autoresponder"
                                desc="Connect your Autoresponder to save leads"
                                type="AR"
                                url="connect-autoresponder"
                                image={require('../../../images/autoresponder.png')}
                                data={socials.autoresponder}
                            />

                            <IntegrationAcc
                                name="SMTP"
                                desc="Connect SMTP to send emails"
                                type="smtp"
                                url="smtp-add"
                                image={require('../../../images/smtp.svg').default}
                                data={socials.smtp}
                            />

                            {/* <IntegrationAcc
                                name="Twilio"
                                desc="Connect Twilio for sending SMS"
                                type="twilio"
                                url="connect-twilio"
                                image={require('../../../images/twilio.svg').default}
                                data={socials.twilio}
                            /> */}

                            <IntegrationAcc
                                name="OpenAI"
                                desc="Connect OpenAI for yout tools to use AI models"
                                type="openAi"
                                url="connect-openai"
                                image={require('../../../images/openai-icon.png')}
                                data={socials.openai}
                            />

                            <IntegrationAcc
                                name="Stripe"
                                desc="Connect Stripe to receive payments for your tools"
                                type="stripe"
                                url="stripe-connect"
                                image={require('../../../images/stripe.svg').default}
                                data={socials.stripe}
                            />


                            {/* <div className='dashboard-block'>
                                <div className='integrations-single'>
                                    <div className='integrations-in'>
                                        <div className='integration-left'>
                                            <div className='integration-img'><img src={require("../../../images/google.png")} alt="" /></div>
                                            <div className='integration-txt'>
                                                <h6>Google Calendar</h6>
                                                <p>Booking your events in Google Calendar</p>
                                            </div>
                                        </div>
                                        <div className='integration-right'>
                                            <button className='site-link sm'><span>Connect</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dashboard-block'>
                                <div className='integrations-single'>
                                    <div className='integrations-in'>
                                        <div className='integration-left'>
                                            <div className='integration-img'><img src={require("../../../images/slack.png")} alt="" /></div>
                                            <div className='integration-txt'>
                                                <h6>Slack</h6>
                                                <p>Booking your events in Google Calendar</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='integrations-in'>
                                        <div className='integration-left'>
                                            <div className='integration-img'></div>
                                            <div className='integration-txt'>
                                                <h6>Tapas</h6>
                                                <p>Added on - 2024-09-30 03:25:33</p>
                                            </div>
                                        </div>
                                        <div className='integration-right'>
                                            <button className='site-link red sm'><span>Disconnect</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dashboard-block'>
                                <div className='integrations-single'>
                                    <div className='integrations-in'>
                                        <div className='integration-left'>
                                            <div className='integration-img'><img src={require("../../../images/twitter.png")} alt="" /></div>
                                            <div className='integration-txt'>
                                                <h6>Twitter</h6>
                                                <p>Booking your events in Google Calendar</p>
                                            </div>
                                        </div>
                                        <div className='integration-right'>
                                            <button className='site-link sm'><span>Connect</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div></div>
            </div>

            <Footer />

        </>
    )
}

export default Integrations