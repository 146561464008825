import React, { useEffect, useState } from 'react'
import HelpSubContent from './HelpSubContent';
import { useSelector } from 'react-redux';
import { FaRegEnvelope } from 'react-icons/fa';

const HelpContent = (props) => {
    const supportData = useSelector(state => state.help);
    const [articlesData, setArticlesData] = useState(supportData.article);
    const [q, setQ] = useState('');

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }
    useEffect(() => {
        setArticlesData(supportData.article)
    }, [supportData.article])
    return (
        <>
            <div className='input-wrap'>
                <div className='titleBar'>
                    <div className='titleBar-left'>
                        <h2>Help and Support</h2>
                        <p>You can find answers and get direct support here.</p>
                    </div>
                    <div className='titleBar-right'>

                        <a target="_blank" className="site-link blue mt-4" href="https://support.vineasx.com/"><span><FaRegEnvelope /> Email Support</span></a>
                    </div>
                </div>
                <div className='input-with-icon mt-4'>
                    <input className='input' type="text" placeholder="Search"
                        onChange={(e) => onSearch(e)} />
                </div>
                <div className='support-list'>
                    <div className='row'>
                        {search(articlesData).length > 0 ?
                            <div className="row">

                                {
                                    articlesData.length > 0 ?
                                        search(articlesData).map((article, index) => {
                                            return (
                                                <HelpSubContent
                                                    name={article.name}
                                                    image={article.logo}
                                                    url={`/training?id=${article.id}`}
                                                    key={index}
                                                />
                                            )
                                        })
                                        : ""
                                }

                            </div> :
                            props.loadArticles ?
                                <div className="col-12 mt-4">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : <div style={{ display: "flex", justifyContent: "center", marginTop: "12px" }}>No Data Available</div>

                        }

                    </div></div>
            </div>
        </>
    )
}

export default HelpContent
