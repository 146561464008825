import { Trash } from 'iconsax-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addRebrandData, onDeleteRebrand } from '../../Redux/Actions/RebrandingAction';
import Swal from 'sweetalert2';
import CommonAlert from '../Common/CommonAlert';


const Rebranding = () => {
    const dispatch = useDispatch();
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState({
        name: "",
        img: "",
        id: false,
        isRebrand: false
    });
    const [uploadImg, setUploadImg] = useState(false)
    const [loader, setLoader] = useState(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Remove",
        loader: false,
        icon: <Trash />
    })

    const onConfirm = () => {
        let data = { id: sweet.id }
        setSweet({
            ...sweet,
            confirmButtonName: "Removing",
            loader: true
        })
        dispatch(onDeleteRebrand(data, setSweet, setRebrand))
    }

    const onInputFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files[0]
            if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
                if (fileData.size < 5000000) {
                    setRebrand({
                        ...rebrand,
                        img: fileData
                    })
                    setUploadImg(URL.createObjectURL(fileData))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max uploaded size for image is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let formData = new FormData();
        formData.append('name', rebrand.name);
        formData.append('logo', rebrand.img);
        dispatch(addRebrandData(formData, setLoader));
    }

    const deleteRebrand = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: rebrand.id
        })
    }


    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Remove",
            loader: false
        })
    }

    useEffect(() => {
        if (rebrandData.data) {
            const { name, id, logo } = rebrandData.data
            setRebrand({
                ...rebrand,
                name: name,
                id: id,
                img: logo,
                isRebrand: true
            });
        }
    }, [rebrandData])

    return (
        <div className="upgrade-content">
            {/* <h3>Rebranding</h3> */}
            <div className="bg-table justify-content-center align-items-center">
                <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="">
                        <div style={{ height: "100%", width: "100%", cursor: "pointer" }} >
                            <div className="upload-btn-wrapper">
                                <div className="d-flex mt-4 justify-content-center align-items-center flex-column upload-box py-4">

                                    <p>Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended</p>
                                    <i
                                        className="fas fa-cloud-upload-alt"
                                        aria-hidden="true"
                                        style={{
                                            display: "block",
                                            fontSize: "2rem",
                                            paddingTop: 10
                                        }}
                                    />

                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="customFile"
                                        name="file"
                                        style={{
                                            padding: 0,
                                            cursor: "pointer"
                                        }}
                                        onChange={(e) => onInputFile(e)}
                                    />


                                    {rebrand.img ?
                                        <img src={uploadImg ? uploadImg : rebrand.img} alt='' style={{ marginTop: 10, width: 210 }} /> : null}
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4 p-1">
                        <div className="form-group form-group-mb">
                            <label className="input-label">Brand Name </label>
                            <input
                                //style={{ backgroundColor: "rgb(54, 56, 57)", color: "white" }}
                                className="input"
                                type="text"
                                name="firstName"
                                placeholder="Enter your brand Name"
                                required
                                value={rebrand.name}
                                onChange={(e) => setRebrand({ ...rebrand, name: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="col col-md-12 text-center mt-3">
                        {rebrand.isRebrand === false ?
                            <button
                                type="submit"
                                className="demoLink-2 mt-0"
                                disabled={loader}
                            >
                                {loader ? <>Saving <i className="fa fa-spinner fa-spin ms-2" /></> : 'Save'}
                            </button>
                            :
                            <button
                                type="button" onClick={deleteRebrand}
                                className="demoLink-2 mt-0"
                            >
                                Remove Rebranding
                            </button>
                        }
                    </div>

                </form>
            </div>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to remove this rebranding?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                alertLoader={sweet.loader}
                icon={<Trash />}

            />
        </div>
    )
}

export default Rebranding