import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { FaRegEdit, FaSort, FaSortDown, FaSortUp, FaSpinner } from 'react-icons/fa'
import CreateModal from "../ImageData/CreateModal"
import ImageRows from "../ImageData/ImageRows"

const ImageData = ({ createData, handleCreate, searchKey, handleClose, setTotalCourses }) => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [videoLoader, setVideoLoader] = useState({
        fetch: false
    })
    const [sortHeader, setSortHeader] = useState({
        name: "",
        created: "",
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [videosPerPage] = useState(6)

    const fetchAllVideos = () => {
        let data = {}
        setVideoLoader({
            ...videoLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-custom-image-all", data, setVideos, videoLoader, setVideoLoader))
    }

    useEffect(() => {
        fetchAllVideos()
    }, [])


    const last = currentPage * videosPerPage
    const first = last - videosPerPage
    const currentVideos = videos.slice(first, last)

    const totalPages = Math.ceil(videos.length / videosPerPage)

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    const handleSort = (type) => {
        let sortedData = [...currentVideos];

        if (type === "name") {
            sortedData.sort((a, b) => {
                if (sortHeader.name) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                } else {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
                }
            });
            setSortHeader({ ...sortHeader, name: !sortHeader.name });
        } else if (type === "created") {
            sortedData.sort((a, b) => {
                if (sortHeader.created) {
                    return a.created.toLowerCase() > b.created.toLowerCase() ? 1 : -1;
                } else {
                    return a.created.toLowerCase() < b.created.toLowerCase() ? 1 : -1;
                }
            });
            setSortHeader({ ...sortHeader, created: !sortHeader.created });
        }

        const updatedImages = [...videos];
        updatedImages.splice(first, videosPerPage, ...sortedData);
        setVideos(updatedImages);
    }

    useEffect(() => {
        if (setTotalCourses) {
            setTotalCourses(videos.length)
        }
    }, [videos])

    return (
        <>
            <div className='cont-box'>
                <div className="container-fluid mb-2 pb-4">
                    <div className="row g-4">

                        {videos.length > 0 ?
                            videos.filter((item) => {
                                if (searchKey) {
                                    return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                }
                                else {
                                    return item
                                }
                            }).length > 0 ?
                                videos.filter((item) => {
                                    if (searchKey) {
                                        return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                    }
                                    else {
                                        return item
                                    }
                                }).map((curElem, index) => (

                                    <div className="col-xxl-3 col-lg-4 col-md-6 col-12 " key={index}>

                                        <ImageRows
                                            curElem={curElem}
                                            index={index}
                                            videos={videos}
                                            setVideos={setVideos}
                                        />
                                    </div >
                                )) :
                                <div>
                                    <div colSpan={5} className="text-center load-icon-help mt-3">
                                        {videoLoader.fetch ?
                                            <FaSpinner className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#32cd32" }} /> :

                                            <p style={{ fontSize: 15 }}>No Image Found</p>

                                        }
                                    </div>
                                </div>
                            : videoLoader.fetch ?
                                <FaSpinner className="fa fa-spinner fa-spin mt-3" style={{ fontSize: 25, color: "#32cd32" }} /> :
                                <div className='text-center'>

                                    <p style={{ fontSize: 15 }}>You do not have images yet!</p>
                                </div>
                        }
                    </div >
                </div>
            </div>

            <CreateModal
                show={createData?.show && createData?.type === "video"}
                handleClose={handleClose}
                type={createData?.type}
                dimension={createData?.dimension}
                fetchData1={fetchAllVideos}
            />
        </>
    )
}

export default ImageData
