import React from 'react'
import { PiSealCheckDuotone } from "react-icons/pi";

function ThirdTab() {
  return (
    <>
    <div className="row justify-content-center">
      <div className="col-lg-8">
         <div className='card-wrap'>
            <h5>Custom Domain</h5>
            <hr />
            <div className="row">
              <div className="col-auto">
                <label className='custom-radio' htmlFor="">
                  <div className='custom-radio-in'>
                    <input type="radio" name='1' />
                    <span className='radio-mark'></span>
                  </div>
                </label>
              </div>
              <div className="col ps-0">
                <h6>Get a free Framer subdomain</h6>
                <p className='colorGrey'><small>instantly connect a customized domain for free</small></p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <input className='input small' type="text" placeholder='My Website' />
              </div>
                <div className='col-auto ps-0'>
                    <select className='input small' name="" id="">
                      <option value="">.aiwhitelabel.com</option>
                    </select>
                </div>
              <div className="col-auto ps-0">
                <button className='site-link grey small h-100'><span>Confirm</span></button>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-auto">
                <label className='custom-radio' htmlFor="">
                  <div className='custom-radio-in'>
                    <input type="radio" name='1' />
                    <span className='radio-mark'></span>
                  </div>
                </label>
              </div>
              <div className="col ps-0">
                <h6>Connect a domain you own</h6>
                <p className='colorGrey'><small>Connect a domain purchased through a web hosting service</small></p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <input className='input small' type="text" placeholder='My Website' />
              </div>
              <div className="col-auto ps-0">
                <button className='site-link grey small h-100'><span>Confirm</span></button>
              </div>
            </div>
         </div>

         <div className='card-wrap mt-4'>
          <div className="d-flex align-items-center justify-content-between">
            <h5>Base Domain</h5>
            <h6 className='d-flex align-items-center gap-1' style={{color: 'var(--link)'}}>Optimized <PiSealCheckDuotone /></h6>
          </div>
          <hr />
          <div className='table-responsive'>
            <table className='table no-bdr'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>https://yourwebsite.aiwhitelabel.com</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Website is published and optimized</td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>Latest . Updated 2h ago by Jenifar</td>
                </tr>
              </tbody>
            </table>
          </div>
         </div>
      </div>
    </div>
    </>
  )
}

export default ThirdTab