import React, { useState } from 'react';
import Slider from "react-slick";

import Modal from 'react-bootstrap/Modal';
import { FaBox } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { MdChevronLeft } from "react-icons/md";
import AiToolsCard from './AiToolsCard';
import { FaSpinner } from 'react-icons/fa';
import CusstomToolModel from '../Common/CusstomToolModel';
import { useSelector } from 'react-redux';

function All({ data, loader }) {
  const agencyId = useSelector(state => state.agency.activeAgency.id)
  const [toolId, setToolId] = useState({})

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleOpenModel = (curElem) => {

    setShow6(true);
    setToolId(curElem)
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    rows: 2,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2.2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  var settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2.2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>

      <div>
        <h4 className='pb-3'>Featured Templates</h4>

        {loader.fetch ? <div className="col-12 mt-4">
          <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
        </div> : <div className='slider-container'>
          {data?.length > 0 ?

            <Slider {...settings}>
              {data?.map((curElem, index) => {
                return (
                  <React.Fragment key={index}>
                    <AiToolsCard curElem={curElem} handleShow={handleOpenModel} />
                  </React.Fragment>

                )
              })
              }

            </Slider>
            : <div className='d-flex align-items-center justify-content-center'> You do not have featured templates yet!</div>}




        </div>}

        <h4 className='pb-3 mt-5'>Recent Templates</h4>
        {loader.fetch ? <div className="col-12 mt-4">
          <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
        </div> : <div className='slider-container'>
          {data?.length > 0 ?

            <Slider {...settings2}>
              {data?.slice(0, 6).map((curElem, index) => {
                return (
                  <React.Fragment key={index}>
                    <AiToolsCard curElem={curElem} handleShow={handleOpenModel} />
                  </React.Fragment>
                )
              })

              }
            </Slider>
            : <div className='d-flex align-items-center justify-content-center'> You do not have recent templates yet!</div>}

        </div>
        }
      </div>



      <Modal className='VideoModal large' show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className='p-4'>
            <h4 className='text-center'>Let’s build a new lead with AIWhiteLabel</h4>
            <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>Choose how you want to create new lead magnet tools from the options below</h6>
            <div className="row pt-3">
              <div className="col-lg-4 mt-4">
                <div className='gen-card'>
                  <div className="gen-icon"><FaBox /></div>
                  <h5 className='pt-3'>Use a Template</h5>
                  <p className='pt-3'>200+ ready-made templates you can copy and use freely.</p>
                  <button className='site-link full mt-4' onClick={handleShow2}><span>Browse Templates</span></button>
                </div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className='gen-card'>
                  <div className="gen-icon"><BsStars /></div>
                  <h5 className='pt-3'>Generate with AI</h5>
                  <p className='pt-3'>Describe what you want to build and let AI do the job.</p>
                  <button className='site-link full mt-4' onClick={handleShow3}><span>Generate</span></button>
                </div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className='gen-card'>
                  <div className="gen-icon"><HiLightBulb /></div>
                  <h5 className='pt-3'>Get Unique ideas</h5>
                  <p className='pt-3'>Find the best ideas based on content of your website.</p>
                  <button className='site-link full mt-4' onClick={handleShow4}><span>Get Ideas</span></button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className="VideoModal large" show={show2} onHide={handleClose2} centered>
        <Modal.Body>
          <div className='p-3'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <h4 style={{ cursor: 'pointer' }} onClick={handleClose2}><MdChevronLeft /></h4>
                <h4>Browse templates</h4>
              </div>
              <div className='search-temp'>
                <input type="text" placeholder='Search templates' />
                <select name="" id="">
                  <option value="">All Categories</option>
                </select>
                <button className='site-link ms-3'><span><FiSearch /></span></button>
              </div>
            </div>

            <div className='row pt-4 px-12'>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='temp sm'>
                  <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                  <div className='temp-txt'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6>AI Membership Plan Builder</h6>
                      <img src={require("../../images/badge.svg").default} alt="" />
                    </div>
                    <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='temp sm'>
                    <div className='temp-img'><img src={require("../../images/prev-img.svg").default} alt="" /></div>
                    <div className='temp-txt'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h6>AI Membership Plan Builder</h6>
                        <img src={require("../../images/badge.svg").default} alt="" />
                      </div>
                      <p>An AI app that writes 100% unique and human-like An AI app that writes 100% unique and human-like</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className='VideoModal md-modal' show={show3} onHide={handleClose3} centered>
        <Modal.Body>
          <div className='p-4'>
            <h4 className='text-center'>Generate Tool with AI</h4>
            <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>Describe what data you want to collect, what the tool <br /> should do and let AI build it for you.</h6>

            <div className='pt-5'>
              <label className='label' htmlFor="">Describe a tool you want to build</label>
              <textarea style={{ height: 150 }} className='input fill' name="" id="" placeholder='I want to create a meal plan generator ...'></textarea>
            </div>

            <div className='text-center pt-4 d-flex align-items-center justify-content-center gap-2'>
              <button className='site-link grey' onClick={handleClose3}><span><MdChevronLeft /> Back</span></button>
              <button className='site-link'><span>Generate Lead</span></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className='VideoModal md-modal' show={show4} onHide={handleClose4} centered>
        <Modal.Body>
          <div className='p-4'>
            <h4 className='text-center'>Get personalized Tool Ideas</h4>
            <h6 className='text-center pt-1' style={{ fontWeight: '400', opacity: '0.5' }}>Enter your website URL or describe what you do, and <br /> we suggest ideas for your lead app.</h6>

            <div className='pt-5'>
              <label className='label' htmlFor="">Your website URL:</label>
              <input className='input fill' type="text" placeholder='www.example.com' />
            </div>

            <div className='text-center pt-4 d-flex align-items-center justify-content-center gap-2'>
              <button className='site-link grey' onClick={handleClose4}><span><MdChevronLeft /> Back</span></button>
              <button className='site-link' onClick={handleShow5}><span>Get Unique Ideas</span></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className='VideoModal md-modal' show={show5} onHide={handleClose5} centered>
        <Modal.Body>
          <div className='p-4'>

            <div className=''>
              <label className='label' htmlFor="">Tool Name</label>
              <input className='input fill' type="text" placeholder='Social Media Manager' />
            </div>

            <div className='mt-3'>
              <label className='label' htmlFor="">Subtitle</label>
              <input className='input fill' type="text" placeholder='Generate a social media post for a specific platform.' />
            </div>

            <div className='mt-3'>
              <label className='label' htmlFor="">Description</label>
              <textarea style={{ height: 140 }} className='input fill' name="" id="" placeholder='Generate a social media post effortlessly!'></textarea>
            </div>

            <div className='mt-3'>
              <label className='label' htmlFor="">Category</label>
              <select className='input fill' name="" id="">
                <option value="">Marketing</option>
              </select>
            </div>

            <div className='mt-3'>
              <label className='label' htmlFor="">Logo/Icon</label>
              <div className='upField'>
                <p>Click to replace</p>
                <div className='upField-img mt-2'><img src={require("../../images/up.png")} alt="" /></div>
                <input type="file" />
              </div>
            </div>

            <div className='text-center pt-4 d-flex align-items-center justify-content-end gap-2'>
              <button className='site-link'><span>Confirm</span></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CusstomToolModel show={show6} handleClose={handleClose6} toolId={toolId} type="tool" agencyId={agencyId} />

    </>
  )
}

export default All