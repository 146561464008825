import React from 'react'

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'

import AccountNav from '../Account-Nav/AccountNav'
import { FiUploadCloud } from "react-icons/fi";
import TitleBar from '../../Common/TitleBar'
import AccountImage from './AccountImage'
import AccountInfo from './AccountInfo'
import AccountPassword from './AccountPassword'

function MyAccount() {
    return (
        <>
            <TitleBar title="My Account" />
            <Header />

            <div className='page-wrap'>
                <div className="side-nav">
                    <SidePanel />
                </div>
                <div className="page-container">

                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>My Account</h2>
                                    <p>Create and Manage Account</p>
                                </div>
                            </div>
                            <AccountImage />
                            <AccountInfo />
                            <AccountPassword />
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default MyAccount