import React, { useEffect, useState } from 'react'
import { Link, NavLink } from "react-router";

import { LuUser2, LuKeyRound, LuShare2, LuUserCog, LuHeadphones, LuLogOut } from "react-icons/lu";

import { IoKey, IoShareSocial } from "react-icons/io5";
import { useSelector } from 'react-redux';

const AccountNav = () => {
  const auth = useSelector(state => state.auth);
  const [memberShip, setMemberShip] = useState([])

  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  return (
    <>
      <div className='account-left'>
        <div className='account-nav'>
          <ul>
            <li><NavLink to="/my-account"><span><LuUser2 /></span> My Account</NavLink></li>
            <li><NavLink to="/privacy"><span><LuKeyRound /></span> Privacy</NavLink></li>
            {/* <li><NavLink to="/integrations"><span><LuShare2 /></span> Integrations</NavLink></li> */}
            {auth.user.isClientAccount === "1" ? "" : <> {
              memberShip.includes("enterprise") || memberShip.includes("diamond") || memberShip.includes("diamond_vip") ?
                <li><NavLink to="/account-management"><span><LuUserCog /></span> Account Management</NavLink></li> : null
            }</>}
            {/* <li><NavLink to="/upgrades"><span><LuHeadphones /></span>Upgrades</NavLink></li> */}
            <li><NavLink
              // to="/help-support"
              to="/help-and-support"
            ><span><LuHeadphones /></span> Help and Support</NavLink></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AccountNav