import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeBackgroundInterval, onPlay, onRemovePlaySlide, updateSelectedTiles } from '../../../../../Redux/Actions/VideoActions'


let voice = false, music = false, clearPlay = false, newIntervalId = false, bgSlideInterval = false
const SlidePlayPause = ({ setSecond, playStatus, setPlayStatus, timer, setTimer }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const selectedSlideIndex = campaign?.slides?.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = campaign?.slides?.find(({ isSelected }) => isSelected === "1")

    const [playLoader, setPlayLoader] = useState(false)

    const handleForwardSlide = () => {
        if (campaign.slides.length - 2 >= selectedSlideIndex) {
            dispatch(updateSelectedTiles(selectedSlideIndex + 1))
        }
    }

    const handleBackwardSlide = () => {
        if (selectedSlideIndex > 0) {
            dispatch(updateSelectedTiles(selectedSlideIndex - 1))
        }
    }

    const handlePlay = () => {
        const bgMusic = campaign.audio
        const voiceData = selectedSlide.voiceOver
        if (voiceData.enable) {
            setPlayLoader(true)
            setPlayStatus(true)
            voice = new Audio(voiceData.src)
            voice.volume = voiceData.volume / 100
            voice.play().then(() => {
                setPlayLoader(false)
                if (bgMusic.enable) {
                    music = new Audio(bgMusic.source)
                    music.volume = bgMusic.volume / 100
                    music.play()
                }
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            })
        }
        else {
            if (bgMusic.enable) {
                setPlayLoader(true)
                setPlayStatus(true)
                music = new Audio(bgMusic.source)
                music.volume = bgMusic.volume / 100
                music.play().then(() => {
                    setPlayLoader(false)
                    let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                    newIntervalId = setInterval(() => {
                        setSecond(prevSec => prevSec + 1)
                    }, 1000);

                    clearPlay = setTimeout(() => {
                        handlePause()
                    }, durToPlay * 1000)
                    dispatch(onPlay(selectedSlideIndex))
                })
            }
            else {
                setPlayStatus(true)
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            }

        }
        bgSlideInterval = setInterval(() => {
            dispatch(onChangeBackgroundInterval(selectedSlideIndex))
        }, 7000)
    }

    const handlePause = () => {
        setPlayLoader(false)
        setSecond(0)
        setTimer({
            ...timer,
            h: 0,
            m: 0
        })
        if (voice) {
            voice.pause()
            voice = false
        }
        if (music) {
            music.pause()
            music = false
        }
        clearTimeout(clearPlay)
        clearInterval(newIntervalId)
        clearInterval(bgSlideInterval)
        setPlayStatus(false)
        dispatch(onRemovePlaySlide(selectedSlideIndex))
    }

    useEffect(() => {
        if (playStatus) {
            handlePause()
        }
    }, [selectedSlideIndex])

    useEffect(() => {
        return () => {
            if (voice) {
                voice.pause()
                voice = false
            }
            if (music) {
                music.pause()
                music = false
            }
            clearTimeout(clearPlay)
            clearInterval(newIntervalId)
            clearInterval(bgSlideInterval)
        }
    }, [])

    return (

        <ul>
            <li onClick={handleBackwardSlide} title='Previous Slide'>
                <i class="fa fa-step-backward" aria-hidden="true"></i>
            </li>
            {
                playStatus ?
                    playLoader ?
                        <i className="fa fa-spinner fa-spin"
                            style={{
                                fontSize: "20px",
                                marginBottom: "3px",
                                color: "#32cd32"
                            }} />
                        :
                        <li onClick={handlePause} title='Stop Slide Play'><i class="fa fa-solid fa-pause" ></i></li>
                    :
                    <li onClick={handlePlay} title='Play Slide'><i class="fa fa-solid fa-play"></i></li>
            }



            <li onClick={handleForwardSlide} title='Next Slide'>
                <i class="fa fa-step-forward" aria-hidden="true"></i>
            </li>
        </ul>
    )
}

export default SlidePlayPause