import React from 'react'
import { AbsoluteFill } from "remotion";

const Promo = ({ src, position, multiplyNum }) => {

    let mystyle = {
        position: "absolute",
        fontSize: 16 * multiplyNum,
    }

    if (position === "top-left") {
        mystyle.position = "absolute";
        mystyle.top = 0;
        mystyle.left = 0;
        mystyle.marginTop = multiplyNum * 10;
        mystyle.marginLeft = multiplyNum * 10;
    } else if (position === "top-center") {
        mystyle.position = "absolute";
        mystyle.top = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)";
        mystyle.marginTop = multiplyNum * 10;
        // mystyle.width = `${300 * multiplyNum}px`;
        // mystyle.height = `${42 * multiplyNum}px`;
    } else if (position === "top-right") {
        mystyle.position = "absolute";
        mystyle.top = 0;
        mystyle.right = 0;
        mystyle.marginTop = multiplyNum * 10;
        mystyle.marginRight = multiplyNum * 10;
    }
    else if (position === "bottom-center") {
        mystyle.position = "absolute";
        mystyle.bottom = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)";
        mystyle.marginBottom = multiplyNum * 10
        // mystyle.width = `${300 * multiplyNum}px`;
        // mystyle.height = `${42 * multiplyNum}px`;
    } else if (position === "bottom-right") {
        mystyle.position = "absolute";
        mystyle.bottom = 0;
        mystyle.right = 0;
        mystyle.marginBottom = multiplyNum * 10;
        mystyle.marginRight = multiplyNum * 10;
    } else {
        mystyle.position = "absolute";
        mystyle.bottom = 0;
        mystyle.left = 0;
        mystyle.marginBottom = multiplyNum * 10;
        mystyle.marginLeft = multiplyNum * 10;
    }


    return (
        <AbsoluteFill>
            <div style={mystyle} dangerouslySetInnerHTML={{ __html: src }}>
            </div>
        </AbsoluteFill>
    )

}

export default Promo
