import React, { useEffect, useState } from 'react'
import { BsCopy } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../Redux/Actions/AlertActions';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    // PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

function Share() {

    const auth = useSelector(state => state.auth);
    const toolData = useSelector(state => state.createTool.data);
    const dispatch = useDispatch();

    const [copyState, setCopyState] = useState({
        copyLink: false,
        copyCode: false
    })

    const { protocol, hostname, port } = window.location;
    const [shareURL, setShareURL] = useState("");
    const [iframeValue, setIframeValue] = useState("");

    const handleCopy = async (copyValue, name) => {
        try {
            await navigator.clipboard.writeText(copyValue);
            dispatch(setAlert("URL copied successfully", "success"))
        } catch (err) {
            dispatch(setAlert(err, "danger"))
        }
        setCopyState({
            ...copyState,
            [name]: true
        })
        setTimeout(() => {
            setCopyState({
                copyLink: false,
                copyCode: false
            })
        }, 5000)
    }

    useEffect(() => {
        let url = toolData.integrations.domain.enable && toolData.integrations.domain.domain ?
            `https://${toolData.integrations.domain.domain}/share?userId=${auth.user.id}&id=${toolData.id}`
            :
            `${protocol}//${hostname}${port ? `:${port}` : ''}/share?userId=${auth.user.id}&id=${toolData.id}`

        setShareURL(url)
        setIframeValue(`<iframe src="${url}"
        width="400" height="${+toolData.frameHeight + 50}"
        style="border-radius:0.5rem; border:none; loading='lazy'"
        referrerpolicy="unsafe-url" allow="clipboard-read; clipboard-write" id="prev-frame-id">
</iframe>`)

    }, [protocol, hostname, port, toolData.integrations.domain, toolData.frameHeight])

    return (
        <>
            <div className='builder-area d-block'>
                <div className='builder-block'>
                    <h4>Copy & Paste HTML on your website</h4>
                    <p>Embed the code anywhere you want to diplay Social AI for Engaging Content.</p>
                    <div className='codeBlock mt-4'>
                        {<span style={{ color: "white" }}>{iframeValue}</span>}
                    </div>
                    <div className='d-flex align-items-center justify-content-end gap-2 mt-3'>
                        <button className='site-link' onClick={() => handleCopy(iframeValue, "copyCode")}><span>{copyState.copyCode ? "Copied Code" : "Copy Code"} <BsCopy /></span></button>
                    </div>
                </div>

                <div className='or mt-4 mb-4'><span>OR</span></div>

                <div className='builder-block'>
                    <div className="row">
                        <div className="col-sm-6">
                            <h4>Share the link to your lead magnet</h4>
                            <p>You can share the URL link to your lead magnet with your audience, easily.</p>
                            <div className="sc-links mt-4">
                                <ul>
                                    {
                                        <>
                                            <FacebookShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <FacebookIcon />
                                            </FacebookShareButton>

                                            <EmailShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <EmailIcon />
                                            </EmailShareButton>

                                            <LinkedinShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <LinkedinIcon />
                                            </LinkedinShareButton>

                                            <RedditShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <RedditIcon />
                                            </RedditShareButton>

                                            <TelegramShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <TelegramIcon />
                                            </TelegramShareButton>

                                            <PocketShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <PocketIcon />
                                            </PocketShareButton>

                                            <TwitterShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <TwitterIcon />
                                            </TwitterShareButton>

                                            <WhatsappShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <WhatsappIcon />
                                            </WhatsappShareButton>

                                            <TumblrShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <TumblrIcon />
                                            </TumblrShareButton>

                                            <InstapaperShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <InstapaperIcon />
                                            </InstapaperShareButton>

                                            <ViberShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <ViberIcon />
                                            </ViberShareButton>

                                            <VKShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <VKIcon />
                                            </VKShareButton>

                                            <WorkplaceShareButton
                                                url={shareURL}
                                                quote={shareURL}
                                            >
                                                <WorkplaceIcon />
                                            </WorkplaceShareButton>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='linkCopy'>
                                {shareURL}
                            </div>
                            <div className='d-flex align-items-center justify-content-end gap-2 mt-3'>
                                <button className='site-link' onClick={() => handleCopy(shareURL, "copyLink")}><span>{copyState.copyLink ? "Copied Link" : "Copy Link"} <BsCopy /></span></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Share