import React from 'react'
import lessonImg from '../../images/lesson-img.png';
import { useSelector } from 'react-redux';
import { appName } from '../../Global/Global';
// const fnc = () => {
//     setTimeout(window.scrollTo(400, 1600), 5000);
// };


const Webinar = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="lessonLink"> 
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="lessonLink-left"><img src={lessonImg} /></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lessonLink-right">
                                <h6><span> {rebrand ? rebrand.name : appName}</span> LESSON #1</h6>
                                <h2>Join Us for our LIVE Webinar.</h2>
                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. </p>
                                {/* <a className="demoLink" href="https://coursereel.io/training" target="_blanck"><i class="fa fa-play" aria-hidden="true"></i> </a> */}
                                <a href='https://www.abhineos.com/training' className="site-link mt-4" target="_blank"><span><i class="fa fa-play" aria-hidden="true"></i>Join The Training Now</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Webinar
