import { current, produce } from "immer"
const initialState = {
    data: false,
}

export const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_CHAPTER_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "ON_CHANGE_WRITE_SCRIPT":
            return produce(state, (draft) => {
                draft.data.createdFrom.text = action.payload.value
            })

        case "ADD_VIDEO_DATA":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                draft.data = data
                draft.data = {
                    ...draft.data,
                    undoRedo: []
                }
                if ((data.slides.findIndex(({ isSelected }) => +isSelected === 1)) === -1) {
                    data.slides[0].isSelected = "1"
                    data.slides[0].layers[0].isSelected = true
                    data.slides[0].background.isSelected = 1
                }
                data.slides.forEach((curElem) => {
                    // curElem.faceNarration = JSON.parse(curElem.faceNarration)
                    if (curElem.layers) {
                        curElem.layers.forEach((layer) => {
                            if (!layer.textToHighlight && layer.type === "text") {
                                layer.textToHighlight = []
                            }
                            if (layer.type === "video" && layer.mute === undefined) {
                                layer.mute = true
                            }
                            if (layer.type === "text") {
                                if (layer.style.lineHeight === "") {
                                    layer.style.lineHeight = 1.4
                                }
                            }
                        })
                    } else {
                        curElem.layers = []
                    }
                    draft.data.undoRedo.push({
                        slideId: curElem.id,
                        past: [],
                        present: curElem,
                        future: []
                    })
                })

            })



        case 'UPDATE_SLIDES_DATA':
            return produce(state, (draft) => {
                draft.data.lastAction = "UPDATE_SLIDES_DATA"
                draft.data.slides[action.payload.index].isSelected = "1"
                draft.data.slides[action.payload.slideIndex].isSelected = "0"
                if (draft.data.slides[action.payload.index].layers) {
                    draft.data.slides[action.payload.index].layers.forEach((curElem) => {
                        curElem.animation.playAnimation = true
                    })
                }

            })
        case "REARRANGE TILES":
            return produce(state, (draft) => {
                draft.data.slides = action.payload
            })

        case "ON_TEXT_FONTSIZE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontSize = action.payload.data
            })
        case "ON_TEXT_FONTSTYLE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontFamily = action.payload.data
            })
        case "ON_TEXT_FONTBOLD":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontWeight = action.payload.data
            })
        case "ON_TEXT_FONTITALIC":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontStyle = action.payload.data
            })
        case "ON_TEXT_FONTTRANSFORM":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textTransform = action.payload.data
            })
        case "ON_TEXT_FONTDECO":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textDecoration = action.payload.data
            })
        case "ON_TEXT_FONTALIGN":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textAlign = action.payload.data
            })
        case "ON_TEXT_FONTLINEHEIGHT":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.lineHeight = action.payload.data
                } else {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.lineHeight = action.payload.data
                }

            })

        case "ON_TEXT_FONTCOLOR":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.color = action.payload.data
            })

        case "ON_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.background = action.payload.data
                } else {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.background = action.payload.data
                }
            })

        case "REMOVE_FONTBGCOLOR":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.background = ""
                } else {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.background = ""
                }

            })

        case "APPLY_TO_ALL_LAYERS":
            return produce(state, (draft) => {
                draft.data.slides.forEach((slide) => {
                    slide.layers.forEach((layer) => {
                        if (layer.type === "text") {
                            layer.style = action.payload.style
                            layer.innerCss = action.payload.innerCss
                        }
                    })
                })
            })


        case 'UPDATE_BG_IMAGE':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.data.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].type = "image"
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file.url
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.url
                }
            })

        case 'UPDATE_BG_VIDEO':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.data.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].type = "video"
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file.url
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.poster
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].bgDuration = action.payload.file.bgDuration
                }
            })

        case 'UPDATE_BG_COLOR':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.data.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].type = "color"
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file
                    draft.data.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file
                }
            })



        case "RESIZE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size = {
                    width: action.payload.w,
                    height: action.payload.h
                }
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.x,
                    top: action.payload.y
                }
            })

        case "REPOSITION_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.l,
                    top: action.payload.t
                }
            })

        case "UPDATE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((curElem, index) => {
                    if (index === action.payload.index) {
                        curElem.isSelected = true
                    } else {
                        curElem.isSelected = false
                    }
                })
            })

        case "UNSELECT_LAYERS":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
            })

        case "ADD_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.objData)

            })

        case "UPDATE_LAYER_IMAGE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "image"
            })

        case "UPDATE_LAYER_VIDEO":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val.url
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "video"
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case 'ADD_BGAUDIO_DATA':
            return produce(state, (draft) => {
                draft.data.audio = {
                    ...draft.data.audio,
                    enable: true,
                    type: "mp3",
                    source: action.payload.url,
                    duration: action.payload.dur,
                }
            })

        case 'REMOVE_BGAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.data.audio = {
                    ...draft.data.audio,
                    enable: false,
                    type: "",
                    source: "",
                    duration: 0,
                }
            })

        case 'UPDATE_TTS1_TEXT':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].voiceOver.meta.text = action.payload.text
            })

        case 'UPDATE_GENERATED_TTS':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].voiceOver.enable = true
                draft.data.slides[action.payload.index].voiceOver.type = "mp3"
                draft.data.slides[action.payload.index].voiceOver.duration = action.payload.val.duration
                draft.data.slides[action.payload.index].voiceOver.src = action.payload.val.url
                draft.data.slides[action.payload.index].voiceOver.meta.languageId = action.payload.langData.lang
                draft.data.slides[action.payload.index].voiceOver.meta.voiceId = action.payload.langData.voice
                draft.data.slides[action.payload.index].faceNarration.status = 1

            })

        case 'ADD_TTS_DATA':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].voiceOver.enable = action.payload.enable
                draft.data.slides[action.payload.slideIndex].voiceOver.type = "mp3"
                draft.data.slides[action.payload.slideIndex].voiceOver.duration = action.payload.meta.duration
                draft.data.slides[action.payload.slideIndex].voiceOver.src = action.payload.meta.src
                draft.data.slides[action.payload.slideIndex].voiceOver.meta = action.payload.meta.meta
                draft.data.slides[action.payload.slideIndex].faceNarration.status = 1

            })

        case 'REMOVE_TTSAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].voiceOver.enable = false
                draft.data.slides[action.payload.slideIndex].voiceOver.type = ""
                draft.data.slides[action.payload.slideIndex].voiceOver.duration = 0
                draft.data.slides[action.payload.slideIndex].voiceOver.src = ''
                draft.data.slides[action.payload.slideIndex].voiceOver.meta = action.payload.tts

            })

        case 'UPDATE_SLIDE_DURATION':
            return produce(state, (draft) => {
                const selectedTile = draft.data.slides.findIndex(({ isSelected }) => isSelected === "1")
                draft.data.slides[selectedTile].duration = parseInt(action.payload)
            })

        case 'ADD_TEXT_LAYER':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_LAYER_TEXT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.text
            })

        case "REMOVE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.splice(action.payload.layerIndex, 1)
                if (draft.data.slides[action.payload.slideIndex].layers.length > 0) {
                    draft.data.slides[action.payload.slideIndex].layers[0].isSelected = true
                }
            })

        case "COPY_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].isSelected = false
                if (!action.payload.layerData.layerType) {
                    action.payload.layerData = {
                        ...action.payload.layerData,
                        layerType: "custom"
                    }
                }
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.layerData)

            })

        case "LAYER_DOWN":
            return produce(state, (draft) => {
                let temp = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1] = temp
            })

        case "LAYER_UP":
            return produce(state, (draft) => {
                let temp = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1] = temp
            })

        case "DELETE_SLIDE":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload].isSelected === "0") {
                    draft.data.slides.splice(action.payload, 1)
                } else {
                    if (action.payload === 0) {
                        draft.data.slides[action.payload + 1].isSelected = "1"
                        draft.data.slides.splice(action.payload, 1)
                    }
                    else {
                        draft.data.slides[action.payload - 1].isSelected = "1"
                        draft.data.slides.splice(action.payload, 1)
                    }
                }
            })

        case 'UPDATE_SELECTED_TILES':
            return produce(state, (draft) => {
                let index = draft.data.slides.findIndex(({ isSelected }) => isSelected === "1");
                draft.data.slides[index].isSelected = "0";
                draft.data.slides[action.payload].isSelected = "1";
            })

        case 'UPDATE_STATUS_L&W':
            return produce(state, (draft) => {
                if (action.payload.type === 'logo') {
                    draft.data.logo.enable = action.payload.enable
                }
                else if (action.payload.type === 'watermark') {
                    draft.data.watermark.enable = action.payload.enable
                }
            })


        case 'ADD_LOGO':
            return produce(state, (draft) => {
                draft.data.logo.src = action.payload.data
                if (!draft.data.logo.pos) {
                    draft.data.logo.pos = "top-left"
                }
            })

        case 'ADD_WATERMARK':
            return produce(state, (draft) => {
                draft.data.watermark.src = action.payload.data
                if (!draft.data.watermark.pos) {
                    draft.data.watermark.pos = "top-left"
                }
            })

        case 'REMOVE_LOGO_LAYER':
            return produce(state, (draft) => {
                draft.data.logo.src = ''
                draft.data.logo.pos = 'top-left'
            })

        case 'REMOVE_WATERMARK_LAYER':
            return produce(state, (draft) => {
                draft.data.watermark.src = ''
                draft.data.watermark.pos = 'top-left'
            })

        case 'UPDATE_LOGO_POSITION':
            return produce(state, (draft) => {
                draft.data.logo.pos = action.payload.data
            })

        case 'UPDATE_WATERMARK_POSITION':
            return produce(state, (draft) => {
                draft.data.watermark.pos = action.payload.data
            })

        case 'CHANGE_BG_VOLUME':
            return produce(state, (draft) => {
                draft.data.audio.volume = parseInt(action.payload)
            })

        case 'CHANGE_VOICE_VOLUME':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.selectedSlideIndex].voiceOver.volume = parseInt(action.payload.value)
            })
        case "UPDATE_LAST_ACTION":
            return produce(state, (draft) => {
                draft.data.lastAction = ""
            })
        case "UNDO":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.data.undoRedo[slideIndex].past.length > 0) {
                    let obj = draft.data.undoRedo[slideIndex].past.pop()
                    draft.data.undoRedo[slideIndex].future.push(draft.data.undoRedo[slideIndex].present)
                    draft.data.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.data.slides.findIndex(({ id }) => id === obj.id)
                    draft.data.slides[slideIndForSlide] = obj
                    draft.data.lastAction = "UNDO"
                }
            })

        case "REDO":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.data.undoRedo[slideIndex].future.length > 0) {
                    let obj = draft.data.undoRedo[slideIndex].future.pop()
                    draft.data.undoRedo[slideIndex].past.push(draft.data.undoRedo[slideIndex].present)
                    draft.data.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.data.slides.findIndex(({ id }) => id === obj.id)
                    draft.data.slides[slideIndForSlide] = obj
                    draft.data.lastAction = "REDO"
                }
            })

        case "SET_PRESENT":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload.id)
                if (slideIndex !== -1) {
                    let temp = draft.data.undoRedo[slideIndex].present
                    temp.isSelected = "1"
                    draft.data.undoRedo[slideIndex].present = action.payload
                    draft.data.undoRedo[slideIndex].past.push(temp)
                    if (draft.data.undoRedo[slideIndex].future.length > 0) {
                        draft.data.undoRedo[slideIndex].future = []
                    }
                    draft.data.lastAction = ""
                }
                else {
                    draft.data.undoRedo.push({
                        slideId: action.payload.id,
                        past: [],
                        present: action.payload,
                        future: [],
                    })
                }
                draft.data.lastAction = ""
            })

        case 'SET_INTRO_STATUS':
            return produce(state, (draft) => {
                draft.data.intro.enable = action.payload
            })

        case 'SET_OUTRO_STATUS':
            return produce(state, (draft) => {
                draft.data.outro.enable = action.payload
            })

        case 'REMOVE_INTRO':
            return produce(state, (draft) => {
                draft.data.intro.src = ""
                draft.data.intro.thumbnail = ""
                draft.data.intro.name = ""
                draft.data.intro.duration = 0
            })

        case 'REMOVE_OUTRO':
            return produce(state, (draft) => {
                draft.data.outro.src = ""
                draft.data.outro.thumbnail = ""
                draft.data.outro.name = ""
                draft.data.outro.duration = 0
            })

        case 'UPDATE_INTRO':
            return produce(state, (draft) => {
                draft.data.intro.src = action.payload.url
                draft.data.intro.thumbnail = action.payload.thumbData
                draft.data.intro.name = action.payload.nameData
                draft.data.intro.duration = action.payload.duration
            })


        case 'UPDATE_OUTRO':
            return produce(state, (draft) => {
                draft.data.outro.src = action.payload.url
                draft.data.outro.thumbnail = action.payload.thumbData
                draft.data.outro.name = action.payload.nameData
                draft.data.outro.duration = action.payload.duration
            })


        case "UPDATE_TEXT_TRANSLATE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].voiceOver.meta.translateText = action.payload.newText
            })

        case "REVERT_TRANSLATE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].audio.meta.translateText = ""
            })

        case "UPDATE_ANIMTION":
            return produce(state, (draft) => {
                if (action.payload.name === "animation") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.name = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "delay") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.delay = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "duration") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.duration = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                }
            })

        case "ONPLAY_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].layers.forEach(curElem => {
                    curElem.animation.playAnimation = true
                })
                draft.data.slides[action.payload].isPlaying = true
                draft.data.lastAction = "ONPLAY_SLIDE"
            })
        case "STOP_PLAYING_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].isPlaying = false
            })

        case "END_ANIMATION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = false
                draft.data.lastAction = "END_ANIMATION"
            })


        case "CHANGE_VIDEO_MUTE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case "CHANGE_VIDEO_UNMUTE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = false
            })

        case "ADD_NEW_SLIDE":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                // data.audio = JSON.parse(data.audio)
                // data.background = JSON.parse(data.background)
                // data.faceNarration = JSON.parse(data.faceNarration)
                // data.layers = JSON.parse(data.layers)
                data.layers.forEach(curElem => {
                    if (curElem.type === "text") {
                        if (!curElem.textToHighlight) {
                            curElem.textToHighlight = []
                        }
                    }
                    if (curElem.type === "video") {
                        curElem.mute = true
                    }
                })
                draft.data.slides.push(data)
                draft.data.undoRedo.push({
                    slideId: data.id,
                    past: [],
                    present: data,
                    future: []
                })
            })
        case "ADD_TRANSITION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].transitionName = action.payload.name
            })

        case "UPDATE_LOGO_HW":
            return produce(state, (draft) => {
                draft.data.logo.height = action.payload.height + "px"
                draft.data.logo.width = action.payload.width + "px"
                draft.data.lastAction = "UPDATE_LOGO_HW"
            })

        case "UPDATE_WATERMARK_HW":
            return produce(state, (draft) => {
                draft.data.watermark.height = action.payload.height + "px"
                draft.data.watermark.width = action.payload.width + "px"
                draft.data.lastAction = "UPDATE_WATERMARK_HW"
            })

        case "ADD_TTS_IN_ALL":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].voiceOver.enable = true
                draft.data.slides[action.payload.slideIndex].voiceOver.type = "mp3"
                draft.data.slides[action.payload.slideIndex].voiceOver.duration = action.payload.duration
                draft.data.slides[action.payload.slideIndex].voiceOver.src = action.payload.url
                draft.data.slides[action.payload.slideIndex].duration = action.payload.duration

                draft.data.slides[action.payload.slideIndex].voiceOver.meta.languageId = action.payload.ttsData.language_id
                draft.data.slides[action.payload.slideIndex].voiceOver.meta.voiceId = action.payload.ttsData.voice_id
                draft.data.slides[action.payload.slideIndex].voiceOver.meta.duration = action.payload.duration

            })

        case "REVERT_TEXT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].audio.meta.translateText = ""
            })

        case "EDIT_SCRIPT":
            return produce(state, (draft) => {
                draft.data.originalScript = action.payload
            })
        case "UPDATE_TEXT_HEIGHT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size.height = action.payload.height
            })

        case "ADD_INNER_STYLE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss = action.payload.innerCss
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = ""
            })
        case "REMOVE_INNER_STYLE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss.background
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss = false
            })

        case "REPLACE_TEXT_WITH_STYLE":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.includes(action.payload.data.text)) {
                    let obj = {
                        text: action.payload.data.text,
                        color: action.payload.data.color,
                        background: action.payload.data.background
                    }
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight.push(obj)

                }
            })

        case "UPDATE_HIGHLIGHT":
            return produce(state, (draft) => {
                if (action.payload.type === "highlight") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.index].color = action.payload.color
                } else {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.index].background = action.payload.color
                }
            })

        case "REMOVE_HIGHLIGHT":
            return produce(state, (draft) => {
                if (action.payload.type === "highlight") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.arrIndex].color = ""
                } else {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.arrIndex].background = ""
                }
            })

        case "REMOVE_TRANSITION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].transitionName = ""
            })


        case "CHANGE_VARIATION_DATA":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex] = action.payload.obj
            })

        case "UPDATE_ORIGINAL_SCRIPT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.i].scene = action.payload.text
            })

        case "UPDATE_LAYER_ORIGINAL_SCRIPT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.i].layers[action.payload.indexVal].src = action.payload.text
            })

        case "ENABLE_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.enable = action.payload
            })

        case "UPDATE_POSITION_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.position = action.payload
            })


        case "UPDATE_CTA_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.data = action.payload
            })

        case "ON_SELECT_INNERBACK":
            return produce(state, (draft) => {
                let selectedBack = draft.data.slides[action.payload.slideIndex].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBack !== -1) {
                    draft.data.slides[action.payload.slideIndex].background[selectedBack].isSelected = 0
                    draft.data.slides[action.payload.slideIndex].background[action.payload.backgroundIndex].isSelected = 1
                }
            })

        case "ON_DELETE_BACK":
            return produce(state, (draft) => {
                if (+draft.data.slides[action.payload.slideIndex].background[action.payload.backIndex].isSelected === 1) {
                    draft.data.slides[action.payload.slideIndex].background[+action.payload.backIndex - 1].isSelected = 1
                }
                draft.data.slides[action.payload.slideIndex].background.splice(action.payload.backIndex, 1)
            })

        case "CHANGE_BG_INTERVAL":
            return produce(state, (draft) => {
                let background = draft.data.slides[action.payload].background
                let bgSelectedIndex = background.findIndex(({ isSelected }) => +isSelected === 1)

                bgSelectedIndex = bgSelectedIndex === -1 ? 0 : bgSelectedIndex
                background[bgSelectedIndex].isSelected = 0
                if (bgSelectedIndex === background.length - 1) {
                    background[0].isSelected = 1
                } else {
                    background[bgSelectedIndex + 1].isSelected = 1
                }
            })

        case "GRADIENT_LEFT":

            return produce(state, (draft) => {
                let bg = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background
                bg = bg.split(",")
                let newArr = bg[1].split(" ")
                newArr[1] = action.payload.hex
                newArr = newArr.join(" ")
                bg[1] = newArr
                let gradient = bg.join(",")
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = gradient
            })

        case "GRADIENT_RIGHT":
            return produce(state, (draft) => {
                let bg = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background
                bg = bg.split(",")
                let newArr = bg[2].split(" ")
                newArr[1] = action.payload.hex
                newArr = newArr.join(" ")
                bg[2] = newArr
                let gradient = bg.join(",")
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = gradient
            })

        case "UNMOUNT_EDITOR":
            return produce(state, (draft) => {
                draft.data = false
            })

        case "TOGGLE_TALKING_HEAD":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.enable = action.payload.checked
            })

        case "TOGGLE_HEAD_MOVEMENT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.headMovement = action.payload.checked
                draft.data.slides[action.payload.slideIndex].faceNarration.status = 1
            })
        case "UPDATE_TAKING_MEDIA":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.media.url = action.payload.url
                draft.data.slides[action.payload.slideIndex].faceNarration.status = 1
                draft.data.slides[action.payload.slideIndex].faceNarration.media.type = action.payload.type
                if (!draft.data.slides[action.payload.slideIndex].faceNarration.media.pos) {
                    draft.data.slides[action.payload.slideIndex].faceNarration.media.pos = "bottom-right"
                }
                if (!draft.data.slides[action.payload.slideIndex].faceNarration.media.height) {
                    draft.data.slides[action.payload.slideIndex].faceNarration.media.height = 125
                    draft.data.slides[action.payload.slideIndex].faceNarration.media.width = 125
                }
                if (!draft.data.slides[action.payload.slideIndex].faceNarration.media.shape) {
                    draft.data.slides[action.payload.slideIndex].faceNarration.media.shape = "circle"
                }
            })

        case "UPDATE_TALKING_POSITIONS":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.data.slides[action.payload.slideIndex].faceNarration.media,
                    pos: action.payload.val
                }
            })

        case "UPDATE_TALKING_SIZE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.data.slides[action.payload.slideIndex].faceNarration.media,
                    height: action.payload.val,
                    width: action.payload.val
                }
            })
        case "UPDATE_TALKING_SHAPE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.data.slides[action.payload.slideIndex].faceNarration.media,
                    shape: action.payload.val
                }
            })

        case "ADDING_A_SLIDE":
            return produce(state, (draft) => {
                action.payload.forEach((curElem) => {
                    let isSLideAvaliabel = draft.data.slides.findIndex(({ id }) => +id === +curElem.id)
                    if (isSLideAvaliabel === -1) {
                        let obj = { ...curElem }
                        obj.layers.forEach((layer) => {
                            if (!layer.textToHighlight && layer.type === "text") {
                                layer.textToHighlight = []
                            }
                            if (layer.type === "video" && layer.mute === undefined) {
                                layer.mute = true
                            }
                            if (layer.type === "text") {
                                if (layer.style.lineHeight === "") {
                                    layer.style.lineHeight = 1.4
                                }
                            }
                        })
                        draft.data.undoRedo.push({
                            slideId: obj.id,
                            past: [],
                            present: obj,
                            future: []
                        })
                        draft.data.slides.push(obj)
                    }
                })
            })


        case "UPDATE_VOICE_CLONE_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].voiceOver = {
                    ...draft.data.slides[action.payload.slideIndex].voiceOver,
                    enable: true,
                    type: "clone",
                    src: action.payload.data.url,
                }
                draft.data.slides[action.payload.slideIndex].voiceOver.meta = {
                    ...draft.data.slides[action.payload.slideIndex].voiceOver.meta,
                    text: action.payload.data.text,
                    languageId: "",
                    voiceId: action.payload.data.voiceClone.cloneId,
                    translateText: "",
                }
                draft.data.slides[action.payload.slideIndex].faceNarration.status = 1
            })

        case "UPDATE_COMPLETE_STATUS":
            return produce(state, (draft) => {
                draft.data.status = action.payload
            })

        case "ON_SET_FACE_ALL_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides.forEach((curElem) => {
                    curElem.faceNarration = action.payload.faceNarration
                })
            })
        default:
            return state
    }
}