import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateScript } from '../../../../../Redux/Actions/VideoActions';


const Story = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const selectedSlideIndex = campaign?.slides?.findIndex(({ isSelected }) => isSelected === "1")

    const updateStoryTile = (index) => {
        if (selectedSlideIndex !== index) {
            dispatch(updateScript(index, selectedSlideIndex))
        }
    }

    return (
        <div className="tabInner">
            <div className="story-block">
                <ul>
                    {campaign?.slides?.length > 0 ?
                        campaign?.slides.map((curElem, index) => {
                            return (
                                <li
                                    className={`${+selectedSlideIndex === index ? 'active cursor-pointer' : 'cursor-pointer'}`}
                                    key={index}
                                    onClick={() => updateStoryTile(index)}
                                >
                                    <div className="story-block-single" >
                                        <p className=''>{curElem.originalText}</p>
                                    </div>
                                </li>
                            )
                        })
                        : ""}
                </ul>
            </div>
        </div>
    );
};

export default Story;
