import React, { useEffect, useRef } from 'react'
import { onUpdateWatermarkInfo } from '../../../../../Redux/Actions/VideoActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const Watermark = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state?.video?.data)
    const watermarkRef = useRef(null)

    useEffect(() => {
        if (campaign?.watermark?.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateWatermarkInfo(watermarkRef?.current?.clientHeight, watermarkRef?.current?.clientWidth))
            }, 2000)
        } else {
            dispatch(onUpdateWatermarkInfo(0, 0))
        }
    }, [campaign?.watermark?.src])
    return (

        campaign?.watermark?.src !== "" ?
            <div className={`watermark-op logo-${campaign?.watermark?.pos} logo-style`}>
                <img
                    src={campaign?.watermark?.src}
                    alt="watermark"
                    ref={watermarkRef}
                />
            </div>
            : ''
    )
}

export default Watermark