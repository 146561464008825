import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { onCreateVoiceClone, onSaveVoiceClone } from '../../../../Redux/Actions/CommonActions'
import modalCloseIcon from '../../../../images/modal-close.png';

const VoiceCreateModal = (props) => {
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [loader, setLoader] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        if (props.fileData.type === "clone") {
            let data = {
                name,
                type: "clone",
                voiceId: props.fileData.voice_id,
                voiceName: props.fileData.name,
                url: props.fileData.preview_url,
                data: {
                    name,
                    type: "clone",
                    voiceId: props.fileData.voice_id,
                    voiceName: props.fileData.name,
                }
            }
            dispatch(onSaveVoiceClone(data, props.fetchCloneVoices, setName, props.handleClose, setLoader, props.setFileData))
        }
        else {
            let obj = {
                name,
                url: props.fileData.url,
                type: props.fileData.type,
                localPath: props.fileData.localPath,
            }
            dispatch(onCreateVoiceClone(obj, props.fetchCloneVoices, props.setFileData, setName, props.handleClose, setLoader))
        }


    }

    useEffect(() => {
        if (props.fileData.type === "clone") {
            setName(props.fileData.name)
        } else {
            if (props.show) {
                setName("")
            }
        }
    }, [props.fileData, props.show])

    return (
        <Modal className="VideoModal sm-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="d-flex align-item-center justify-content-between">
                    <p style={{ color: 'var(--font-color)', fontSize: "16px", fontWeight: "700", marginBottom: "20px" }}>Give your voice a name</p>
                    <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                </div>
                <div className='col-12' >
                    <form className="" onSubmit={handleSubmit}>
                        <div className="input-wrap">
                            <label htmlFor="">Voice Name</label>
                            <div className="inp-outer">
                                <input
                                    type="text"
                                    className="inpField-inp mt-1"
                                    placeholder="Enter Voice Name"
                                    value={name}
                                    name='name'
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-wrap mt-2 text-end">
                            <button className="site-link mt-3 create-modal-btn" type='submit'><span>
                                {loader ? <>Creating <i className="fa fa-spinner fa-spin" /> </> : "Create"}</span>
                            </button>
                        </div>
                    </form>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default VoiceCreateModal