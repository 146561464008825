
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchAllTools = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-all-collection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}
export const onFetchTools = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("/fetch-all-user-tools", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}
export const onCraeteCollection = (data, handleClose, loader, setLoader, onfetchAll) => (dispatch, getState) => {
    commonAxios("create-collection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                onfetchAll()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
        })
}

export const onEditCollection = (data, handleClose, loader, setLoader, onfetchAll) => (dispatch, getState) => {
    commonAxios("update-collection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                onfetchAll()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
        })
}

export const onDeleteCollection = (data, setSweet, onfetchAll) => (dispatch, getState) => {
    commonAxios("delete-collection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                onfetchAll()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}