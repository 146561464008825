import React, { useEffect, useState } from "react";

import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from "react-router";
import { BsThreeDotsVertical } from "react-icons/bs";

import { FiSearch } from "react-icons/fi";
import { IoSettingsOutline, IoColorPaletteOutline, IoExtensionPuzzleOutline, IoShareSocialOutline, IoCodeSlash } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { LuCopy } from "react-icons/lu";
import { BsTrash } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { fetchData } from "../../Redux/Actions/CommonActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ToolRows from "../My-Tools/ToolRows";
import { FaSpinner } from "react-icons/fa";

const DashboardContent = () => {

    const dispatch = useDispatch()
    const [tools, setTools] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const activeAgency = useSelector(state => state.agency.activeAgency)
    const fetchTools = () => {
        let obj = {
            agencyId: activeAgency.id
        }
        dispatch(fetchData("fetch-all-user-tools", obj, setTools, loader, setLoader, 3))
    }

    useEffect(() => {
        if (activeAgency) {
            setLoader({
                ...loader,
                fetch: true
            })
            fetchTools()
        }
    }, [activeAgency])


    return (
        <>

            <section className="videoProject">
                <div className="container">
                    <div className="videoProject-top">
                        <h2>Recent <span>Tools</span></h2>
                        <Link to={`/my-tools?aid=${activeAgency.id}`} className="proView">View all <span><i class="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="videoProject-bottom">
                        <div className="row">
                            {tools.length > 0 ?
                                tools.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ToolRows
                                                curElem={curElem}
                                                tools={tools}
                                                setTools={setTools}
                                                fetchTools={fetchTools}
                                            />
                                        </React.Fragment>
                                    )
                                }) :
                                <div className='col-12 text-center mt-5'>
                                    {loader.fetch ? <FaSpinner className='fa-spin' size={25} /> :
                                        <h6>You do not have tools created yet!</h6>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;