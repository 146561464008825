import React, { useEffect, useState } from 'react'
import { FaLongArrowAltLeft, FaSpinner } from 'react-icons/fa'
import { RiEdit2Line } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { fetchDataImage } from '../../../../Redux/Actions/CommonActions'
import TitleBar from '../../../Common/TitleBar';
import Header from '../../../Common/Header';
import SidePanel from '../../../Common/SidePanel';
import { useSelector } from 'react-redux';
const ImagePreview = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const [videos, setVideos] = useState([])
    const [videoLoader, setVideoLoader] = useState({
        fetch: false
    })
    const auth = useSelector(state => state.auth);
    const fetchAllVideos = () => {
        let data = { id }
        setVideoLoader({
            ...videoLoader,
            fetch: true
        })
        dispatch(fetchDataImage("fetch-custom-image", data, setVideos, videoLoader, setVideoLoader))
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = videos ? videos.imageUrl : "";
        link.target = '_blank';
        link.download = 'image.jpg';
        link.click();
    };

    useEffect(() => {
        fetchAllVideos()
    }, [])

    return (
        <>
            <TitleBar title="Image Preview" />
            <Header />
            <section className={`${videos ? videos?.imageSize?.height === 1920 ? "siteWrap kap siteWrap_9x16" : "siteWrap kap" : ""}`} >
                <TitleBar title={"Image Maker"} />
                <div className='page-wrap' style={{ display: "block" }}>
                    <div className="side-nav">
                        <SidePanel />
                    </div>
                    <div className="page-container">
                        <div className="image_preview cont_area mt-3 px-3" >

                            <div className="loader-sec kap" style={{ display: videoLoader.fetch ? "flex" : "none" }}>
                                <div className="loader" />
                            </div>
                            <div style={{ display: videoLoader.fetch ? "none" : "block" }}>
                                <div className="projectTitle-wrap kap " >
                                    <button className='site-link mt-0' onClick={() => navigate(-1)}> <span><FaLongArrowAltLeft />Back</span></button>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {auth.user.isClientAccount === "1" ? "" : <Link to={`/marketing/image-editor?id=${id}`}><button className='site-link mt-0 me-2'> <RiEdit2Line />Edit</button></Link>}
                                        <button className='site-link mt-0' onClick={handleDownload}><span>Download</span></button>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className={`${videos ? videos?.imageSize?.height === 1920 ? "image_preview_wrapper_9x16" : "image_preview_wrapper" : ""}`}>
                                                <img src={videos.imageUrl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section >
        </>
    )
}

export default ImagePreview
