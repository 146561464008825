import React, { useEffect, useState } from 'react'
import { BsStars } from 'react-icons/bs'
import { FiUpload } from 'react-icons/fi'
import { MdOutlineRecordVoiceOver } from 'react-icons/md'
import { PiFileAudioDuotone } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { themeColor } from '../../../../../Global/Global'
import { fetchData, onUploadMedia } from '../../../../../Redux/Actions/CommonActions'
import { IoPause, IoPlay } from 'react-icons/io5'
import { onAddVoiceClone, onGenerateCloneVoice } from '../../../../../Redux/Actions/VideoActions'
import Recording from './Recording'
import VoiceRows from '../../VoiceClone/VoiceRows'
import VoiceCreateModal from '../../VoiceClone/VoiceCreateModal'

let ttsAudio = false, generatedClone = false
const VoiceCloneSlide = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)

    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [percent, setPercent] = useState(0)
    const [ttsStatus, setTtsStatus] = useState(false);
    const [playUpload, setPlayUpload] = useState(false)
    const [cloneVoices, setCloneVoices] = useState([])
    const [voice, setVoice] = useState([])
    const [show, setShow] = useState(false)
    const [generateNew, setGenerateNew] = useState(false)
    const [currentTts, setCurrentTts] = useState('');

    const [data, setData] = useState({
        text: "",
        voiceClone: {
            cloneId: ""
        },
        url: ""
    })
    const [fileData, setFileData] = useState({
        url: "",
        type: "",
        localPath: ""
    })
    const [loader, setLoader] = useState({
        fetch: true,
        upload: false,
        record: false,
        clone: false
    })


    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const onCompleteLoad = (url, type, vv, localPath) => {
        setFileData({
            url: url,
            type: type,
            localPath
        })
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
            setPlayUpload(false)
            ttsAudio = false
        }
        else {
            if (!playUpload) {
                ttsAudio = new Audio(url);
                ttsAudio.play();
                setPlayUpload(true)
                ttsAudio.onended = function () {
                    setPlayUpload(false)
                    ttsAudio = false
                }
            }
        }
    }

    const playGeneratedClone = (url) => {
        if (generatedClone !== false) {
            generatedClone.pause();
        }
        if (currentTts === url) {
            generatedClone.pause();
            setCurrentTts('')
        } else {
            generatedClone = new Audio(url);
            generatedClone.play();

            generatedClone.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const onInputFile = (e, type) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            let upFile = e.target.files[0]
            if (['audio/mpeg'].includes(upFile.type)) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onloadend = (e) => {
                    const ctx = new AudioContext();
                    const audioArrayBuffer = e.target.result;
                    ctx.decodeAudioData(audioArrayBuffer, data => {
                        // this is the success callback
                        const duration = data.duration;
                        if (+duration <= 30) {
                            if (upFile.size < allowedSize) {
                                const formData = new FormData()
                                formData.append('upload_type', type)
                                formData.append('file_type', "")
                                formData.append('file', upFile)
                                let fun = type === "recording" ? handleShow : false
                                dispatch(onUploadMedia(formData, onCompleteLoad, loader, setLoader, setPercent, type, fun))
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Max allowed size for Music File is 5MB!',
                                    confirmButtonColor: themeColor
                                })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Maximum allowed duration is 30 seconds to clone your voice!',
                                confirmButtonColor: themeColor
                            })
                        }
                    }, error => {
                        console.error(error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }


    const handleVoice = (e) => {
        if (e.target.value === "") {
            setFileData({
                url: "",
                type: "",
                localPath: ""
            })
        } else {
            setFileData(JSON.parse(e.target.value))
        }
    }

    const handleGenerateVoice = () => {
        handleShow()
    }

    const handleTextToGenerate = (e) => {
        setData({
            ...data,
            text: e.target.value
        })
        setTtsStatus(false)
    }


    const handleApplyTts = () => {
        dispatch(onAddVoiceClone(data, selectedSlideIndex))
    }

    const handleCloneVoice = () => {
        setLoader({
            ...loader,
            clone: true
        })
        let obj = {
            voice_id: data.voiceClone.cloneId,
            voiceType: "voiceOver",
            text: data.text
        }
        dispatch(onGenerateCloneVoice(obj, setData, setTtsStatus, loader, setLoader))
    }

    useEffect(() => {
        if (selectedSlide) {
            if (selectedSlide.voiceOver.type === "clone") {
                setData({
                    ...data,
                    text: selectedSlide.voiceOver.meta.text,
                    voiceClone: {
                        ...data.voiceClone,
                        cloneId: selectedSlide.voiceOver.meta.voiceId
                    }
                })
            } else {
                setData({
                    ...data,
                    text: selectedSlide.voiceOver.meta.text
                })
            }
        }
    }, [selectedSlide])


    useEffect(() => {
        if (data.voiceClone.cloneId) {
            setTtsStatus(false)
        }
    }, [data.voiceClone.cloneId])


    const fetchCloneVoices = () => {
        dispatch(fetchData("list-clone-voice", {}, setCloneVoices, loader, setLoader))
    }
    const fetchElevenlabsVoice = () => {
        dispatch(fetchData("fetch-elevenlabs-voices", {}, setVoice, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchCloneVoices()
        fetchElevenlabsVoice()
    }, [])


    return (
            <>
                <div className='innertab'>
                    <div className='txt-format'>
                        <div className='mt-3'>
                            <div className="voice-block-mid">
                                <textarea
                                    placeholder="Add text here"
                                    value={data.text}
                                    onChange={(e) => handleTextToGenerate(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="add-block-single">

                        <div className="add-block-title">
                            <h2 className='text-capitalize'>Generate Voice</h2>
                            <div className="switch-single">
                                <label className="switch" >
                                    <input
                                        type="checkbox"
                                        checked={generateNew}
                                        onChange={(e) => setGenerateNew(e.target.checked)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <hr className='mt-1' />
                    </div>

                    {generateNew ?
                        <>
                            <div className="modal-block">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="file-field" style={{ background: "none" }}>
                                                <div className="col-12">
                                                    <div className="embed-user-icon fill">
                                                        {loader.upload ?
                                                            <i className="fa fa-spinner fa-spin" /> :
                                                            <PiFileAudioDuotone />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    {fileData.type === "voiceover" ?
                                                        <>
                                                            <h6>{fileData.url.slice(0, 40)}...</h6>
                                                        </> :
                                                        <>
                                                            <h6 className="fw-700" style={{ fontSize: "13px", marginBottom: "1px" }}>Upload File</h6>
                                                            <p style={{ fontSize: "12px" }} className='pt-0'>Click Here to Upload a Voice</p>
                                                        </>
                                                    }

                                                </div>
                                                {loader.upload ? null :
                                                    <input
                                                        type="file"
                                                        onChange={(e) => onInputFile(e, "voiceover")}
                                                        accept="audio/mpeg"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3 d-flex gap-2">
                                        {fileData.type === "voiceover" ?
                                            <button className="site-link small m-0 me-1"
                                                onClick={() => playTts(fileData.url)}
                                            >
                                                <span className="me-1 align-items-center d-felx">
                                                    Preview
                                                    {playUpload ? <IoPause /> : <IoPlay />
                                                    }
                                                </span>

                                            </button>
                                            : null}
                                        <button
                                            className="site-link small m-0"
                                            onClick={handleShow}
                                            style={fileData.url ? {} : { cursor: "not-allowed" }}
                                            disabled={fileData.url ? false : true}
                                        >
                                            <span>Use</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="voiceover-recording-wrap">
                                <Recording
                                    onInputFile={onInputFile}
                                    loaderRecord={loader}
                                />
                            </div>

                            <div className="or-style my-5">
                                <span>Or</span>
                            </div>

                            <div className="modal-block">
                                <div className="add-block-title pb-4">
                                    <h6>ElevenLabs</h6>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="input-wrap mt-0">
                                            <div className="inp-outer">
                                                <select
                                                    className="inpt large"
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => handleVoice(e)}
                                                >
                                                    <option value="">Select Voice</option>
                                                    {voice.length > 0 ?
                                                        voice.filter((cur) => {
                                                            return cur.category === "premade"
                                                        }).map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={JSON.stringify({ ...curElem, type: "clone" })}>{curElem.name}</option>
                                                            )
                                                        })
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            className="site-link small m-0"
                                            onClick={handleGenerateVoice}
                                            style={fileData.type !== "clone" ? { cursor: "not-allowed" } : {}}
                                            disabled={fileData.type !== "clone" ? true : false}
                                        >
                                            <span>Create</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> : null}
                    <div className='modal-block mt-3'>
                        <div className="titleBar pb-4">
                            <h6>Your Voice List</h6>
                        </div>


                        <div className='table-responsive'>

                            <table className='table themeTable themeTable-sm' style={{minWidth:"350px"}}>
                                <tr className="embed-top mt-2">
                                    <th>Voice Name</th>
                                    <th>Voice Type</th>
                                    <th>Created At</th>
                                    <th style={{ opacity: 1 }}>Action</th>
                                </tr>

                                {cloneVoices.length > 0 ?
                                    cloneVoices.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <VoiceRows
                                                    curElem={curElem}
                                                    type="clone"
                                                    cloneVoices={cloneVoices}
                                                    setCloneVoices={setCloneVoices}
                                                    state={data}
                                                    setState={setData}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4} className='text-center mt-4'>
                                            {loader.fetch ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: themeColor }} /> : "No voice found!"}
                                        </td>
                                    </tr>
                                }
                            </table>

                        </div>


                    </div>
                    <div className='txt-format mt-3'>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            {ttsStatus ?
                                <button className="site-link small  m-0" onClick={() => playGeneratedClone(data.url)} >
                                    <span>
                                    <i className={`fas ${currentTts === data.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                    Preview
                                    </span>
                                </button>
                                :
                                <button
                                    className='site-link small m-0'
                                    onClick={handleCloneVoice}
                                    disabled={!data.voiceClone.cloneId && !data.text}
                                    style={data.voiceClone.cloneId && data.text ? {} : {  }}
                                >
                                    <span><BsStars />  {loader.clone ? <>Cloning  <i className="fa fa-spinner fa-spin" /></> : "Clone Voice"}</span>
                                </button>
                            }
                            <button
                                className='site-link small m-0'
                                style={ttsStatus ? {} : {  }}
                                disabled={!ttsStatus}
                                onClick={handleApplyTts}
                            >
                                <span><MdOutlineRecordVoiceOver /> Use Voice</span>
                            </button>
                        </div>
                    </div>

                </div>

                <VoiceCreateModal
                    show={show}
                    handleClose={handleClose}
                    fileData={fileData}
                    setFileData={setFileData}
                    fetchCloneVoices={fetchCloneVoices}
                />

            </>
    )
}

export default VoiceCloneSlide