import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GiCheckMark } from 'react-icons/gi'
import { FaSpinner } from 'react-icons/fa'
import { onGenerateAi } from '../../Redux/Actions/CommonActions'

const AiImageGenerate = ({ handleBackground, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState("")
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState({
        generate: false
    })
    const handleGenerate = () => {
        let data = {
            keyword: keyword
        }
        if (keyword) {
            setLoader({
                ...keyword,
                generate: true
            })
            dispatch(onGenerateAi(data, setImage, loader, setLoader))
        }
    }

    const handleSelect = () => {
        let data = {
            url: image
        }
        setCheckMedia(image)
        handleBackground(data, "images")
    }

    return (
        <div className='photo-scroll' style={{ height: "auto" }}>
            <div className="font-block pb-4 mt-3">
                <h6 className='gradientTxt mb-2'><span>Describe the Image you want</span></h6>

                <div className='tabSearch' style={{ border: "none" }}>
                    <textarea style={{ paddingLeft: "0.9375rem", width: "100%" }}
                        className="inp-small mt-2"
                        placeholder="e.g: A rocekt launching at sunset."
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </div>

                <button className="site-link m-0 mt-1" onClick={handleGenerate} ><span>{loader.generate ? <>Generating  <FaSpinner className="fa fa-spinner fa-spin" /></> : "Generate"}</span></button>
            </div>
            {image ?
                <div className="row sm">
                    <div className="col-12">
                        <div className="photo-single" onClick={handleSelect} style={{ height: 200, cursor: 'pointer' }}>
                            <img alt="" src={image} />
                            {checkMedia === image ?
                                <div className='select-tile-style'>
                                    <GiCheckMark />
                                </div> : ""}
                        </div>
                    </div>
                </div> : ""}

        </div>
    )
}

export default AiImageGenerate